import api from "../api";
import qs from "qs";

export const pessoaService = {
  async list(params) {
    return api.get(`/pessoas`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
  },
};
