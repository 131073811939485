import api from "../api";

export const instituicaoVitimaClassificacaoService = {
  async list(codigo) {
    return await api.get(`/instituicoes/${codigo}/classificacoes`);
  },

  async edit(codigo, payload) {
    return await api.put(`/instituicoes/classificacoes/${codigo}`, payload);
  },
};
