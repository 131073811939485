import ListSubTipos from "../../Classification/Agravantes/ListSubtipos";
import ListTemas from "../../Classification/Agravantes/ListTemas";
import ListTipos from "../../Classification/Agravantes/ListTipos";
import ListAgravantes from "../components/Agravantes/ListAgravantes";
import ListBairros from "../components/Bairros/ListBairros";
import ListCanais from "../components/Channels/ListChannels";
import { ListClassificacaoAtributo } from "../components/ClassficacaoAtributos";
import Deficiencia from "../components/Deficiencia";
import { Doencas } from "../components/Doencas";
import { FrequenciaOcorrencia } from "../components/FrequenciaOcorrencia";
import { Genero } from "../components/Genero";
import { GrauInstrucao } from "../components/GrauInstrucao";
import { IdentidadeGenero } from "../components/IdentidadeGenero";
import ListLocalOcorrencia from "../components/LocalOcorrencia/ListLocalOcorrencia";
import { Ocupacao } from "../components/Ocupacao";
import { Painel } from "../components/Paineis";
import { Religiao } from "../components/Religiao";
import { Renda } from "../components/Renda";
import { Sexualidade } from "../components/Sexualidade";

export const renderComponent = (mode) => {
  const selectRenderComponent = {
    agravantes: <ListAgravantes />,
    bairros: <ListBairros />,
    canais: <ListCanais />,
    classificacaoAtributos: <ListClassificacaoAtributo />,
    deficiencias: <Deficiencia />,
    doencas: <Doencas />,
    localOcorrencia: <ListLocalOcorrencia />,
    genero: <Genero />,
    identidadeGenero: <IdentidadeGenero />,
    sexualidade: <Sexualidade />,
    renda: <Renda />,
    religiao: <Religiao />,
    frequencia: <FrequenciaOcorrencia />,
    ocupacao: <Ocupacao />,
    grauInstrucao: <GrauInstrucao />,
    temas: <ListTemas />,
    tipos: <ListTipos />,
    subtipos: <ListSubTipos />,
    paineis: <Painel />,
  };

  return selectRenderComponent[mode] || null;
};
