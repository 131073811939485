import React, { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";

import oisol from "../../images/logos/oisol_modelo_DH.png";
import logoGov from "../../images/logos/logo-gov.png";
import { useWidth } from "../../hooks";

import style from "./Header.module.css";
import moment from "moment";

const Header = ({ showVersion }) => {
  const { backendVersion } = useContext(UserContext);

  const { width } = useWidth();

  const printVersion = () =>
    console.table({
      Versão: {
        backend: backendVersion,
        frontend: process.env.REACT_APP_VERSION,
      },
    });

  return (
    <header className={style.header} style={{ borderBottom: "6px var(--main-color) solid" }}>
      {width > 900 && (
        <div className={style.logoLeft}>
          <a
            href="https://www.sps.ce.gov.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={logoGov}
              alt="Secretaria dos Direitos Humanos"
            />
          </a>
        </div>
      )}

      <div className={style.logoCenter}>
        <a href={`${process.env.PUBLIC_URL}/`}>
          <img src={oisol} alt="oi sol - Observatório de Indicadores de Direitos Humanos" />
        </a>
      </div>

      {width > 900 && (
        <div className={style.version}>
          <span onClick={() => showVersion && printVersion()}>
            {showVersion
              ? `versão: ${process.env.REACT_APP_VERSION}`
              : moment().format("DD/MM/YYYY")}
          </span>
        </div>
      )}
    </header>
  );
};

export default Header;
