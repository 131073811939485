import api from "../api";

export const anexoService = {
  async get(denuncia, codigo, mediaType) {
    return await api.get(`/denuncias/${denuncia}/anexos/${codigo}`, {
      headers: {
        accept: mediaType,
      },
      responseType: "blob",
    });
  },

  async delete(denuncia, codigo) {
    return await api.delete(`/denuncias/${denuncia}/anexos/${codigo}`);
  },
};
