import React, { useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { UserContext } from "./contexts/UserContext";

import { USER_ROLES, urls } from "./utils";

import Login from "./pages/Login/Login";

import DenunciasSPSGeral from "./pages/Denuncia/ListDenunciations/Geral";
import { DenunciasStatus } from "./pages/Denuncia/ListDenunciations/Status";
import { DenunciasInstituicao } from "./pages/Denuncia/ListDenunciations/DenunciasInstituicao";
import { DenunciasMunicipio } from "./pages/Denuncia/ListDenunciations/Municipio";

import Denuncia from "./pages/Denuncia/CreateDenunciation";

import CreateForwarding from "./pages/Encaminhamento/CreateForwarding";
import ViewForwarding from "./pages/Encaminhamento/ViewForwarding";
import { Inicio } from "./pages/Inicio";

import ListInvolveds from "./pages/Pessoas/ListPessoas";
import Pessoa from "./pages/Pessoas/Pessoa/index";

import ListInstitutions from "./pages/Institution/ListInstitutions/index";
import Instituicao from "./pages/Institution/Instituicao/index";

import { Listagem } from "./pages/Usuarios/Listagem";
import { Usuario } from "./pages/Usuarios/Usuario";

import CadastrosBasicos from "./pages/CadastrosBasicos";

import ListClassification from "./pages/Classification/ListClassification";

import ListGroups from "./pages/Groups/ListGroups";
import Group from "./pages/Groups/Group";

import { NotFound } from "./pages/NotFound";
import { Container } from "./components/Container";
import { Consulta } from "./pages/Publico/Consulta";
import { Resultado } from "./pages/Publico/ResutadoConsulta";
import EditTema from "./pages/Classification/Agravantes/FormTema";
import EditTipo from "./pages/Classification/Agravantes/FormTipos";
import EditSubtipo from "./pages/Classification/Agravantes/FormSubtipo";
import { EncaminhamentosRecebidos } from "./pages/Encaminhamento/Recebidos";
import { EncaminhamentosEnviados } from "./pages/Encaminhamento/Enviados";
import { CadastroDenunciaPublico } from "./pages/Publico/CadastroDenuncia";
import {
  PainelCRDH,
  PainelCasaCrianca,
} from "./pages/CadastrosBasicos/components/Paineis/CasaCrianca";
import { PoliticaPrivacidade } from "./pages/PoliticaPrivacidade";
import Atendimento from "./pages/Atendimento";

const Rotas = () => {
  const { isAuthenticated, user } = useContext(UserContext);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route exact path={urls.CONSULTA_PUBLICA} children={<Consulta />} />

        <Route
          exact
          path={urls.CONSULTA_PUBLICA_RESULTADO}
          children={<Resultado />}
        />

        <Route
          exact
          path={urls.CADASTRO_DENUNCIA_PUBLICA}
          children={<CadastroDenunciaPublico />}
        />

        <Route
          exact
          path={urls.POLITICA_PRIVACIDADE_OISOL_APP}
          children={<PoliticaPrivacidade />}
        />

        {isAuthenticated === false && (
          <Switch>
            <Route exact path={urls.LOGIN} children={<Login />} />
            <Route exact path="*">
              <Redirect to={urls.LOGIN} />
            </Route>
          </Switch>
        )}

        {isAuthenticated && user && (
          <Container>
            <Switch>
              <Route exac path={urls.LOGIN}>
                <Redirect to="/" />
              </Route>

              <Route exact path="/">
                {(user?.authorities?.includes(USER_ROLES.CONSULTAR_TODAS_DENUNCIAS) && user?.authorities?.includes(USER_ROLES.CONSULTAR_TODOS_ENCAMINHAMENTOS))
                ? (
                  <Redirect to={urls.DENUNCIAS_LIST} />
                ) : (
                  <Inicio />
                )}
              </Route>

              <Route exact path={urls.DENUNCIAS_LIST}>
                {user?.authorities?.includes(
                  USER_ROLES.CONSULTAR_TODAS_DENUNCIAS
                ) ? (
                  <DenunciasStatus />
                ) : (
                  <DenunciasInstituicao />
                )}
              </Route>

              {user?.authorities?.includes(
                USER_ROLES.CONSULTAR_TODAS_DENUNCIAS
              ) && (
                <Route exact path={urls.DENUNCIAS_GERAL_LIST}>
                  <DenunciasSPSGeral />
                </Route>
              )}

              {user?.authorities?.includes(
                USER_ROLES.CONSULTAR_TODAS_DENUNCIAS_MUNICIPIO
              ) && (
                <Route exact path={urls.DENUNCIAS_MUNICIPIO}>
                  <DenunciasMunicipio />
                </Route>
              )}

              {(user?.authorities?.includes(USER_ROLES.INCLUIR_DENUNCIA) ||
                user?.authorities?.includes(
                  USER_ROLES.INCLUIR_DENUNCIA_INSTITUICAO
                )) && (
                <Route path={urls.DENUNCIA_CRIAR}>
                  <Denuncia exact strict onCreate />
                </Route>
              )}

              {user?.authorities?.includes(
                USER_ROLES.CONSULTAR_DETALHE_DENUNCIA
              ) && (
                <Route exact path={urls.DENUNCIA_DETAILS}>
                  <Denuncia onView />
                </Route>
              )}

              {(user?.authorities?.includes(
                USER_ROLES.ALTERAR_TODAS_DENUNCIAS
              ) ||
                user?.authorities?.includes(
                  USER_ROLES.ALTERAR_DENUNCIAS_INSTITUICAO
                )) && (
                <Route exact path={urls.DENUNCIA_EDITAR}>
                  <Denuncia onEdit />
                </Route>
              )}

              {user?.authorities?.includes(
                USER_ROLES.CONSULTAR_ENCAMINHAMENTOS_INSTITUICAO
              ) && (
                <Route exact path={urls.ENCAMINHAMENTOS_RECEBIDOS}>
                  <EncaminhamentosRecebidos />
                </Route>
              )}

              {user?.authorities?.includes(
                USER_ROLES.CONSULTAR_ENCAMINHAMENTOS_INSTITUICAO
              ) && (
                <Route exact path={urls.ENCAMINHAMENTOS_ENVIADOS}>
                  <EncaminhamentosEnviados />
                </Route>
              )}

              <Route path={urls.ENCAMINHAMENTO_DETAILS}>
                <ViewForwarding />
              </Route>

              <Route path={urls.ENCAMINHAMENTO_CRIAR}>
                <CreateForwarding />
              </Route>

              {user?.authorities?.includes(
                USER_ROLES.CONSULTAR_PAINEL_CRDH
              ) && (
                <Route exact strict path={urls.PAINEIS_CRDH}>
                  <PainelCRDH />
                </Route>
              )}

              {(user?.authorities?.includes(USER_ROLES.CONSULTAR_ATENDIMENTOS)) && (
                <Route exact path={urls.ATENDIMENTO_LIST}>
                  <Atendimento />
                </Route>
              )}

              {(user?.authorities?.includes(USER_ROLES.CONSULTAR_PESSOAS) || user?.authorities?.includes(USER_ROLES.GERENCIAR_PESSOA)) && (
                <Route exact path={urls.PESSOAS_LIST}>
                  <ListInvolveds />
                </Route>
              )}

              {(user?.authorities?.includes(USER_ROLES.CADASTRAR_PESSOA) || user?.authorities?.includes(USER_ROLES.GERENCIAR_PESSOA)) && (
                <Route exact strict path={urls.PESSOAS_CRIAR}>
                  <Pessoa onCreate />
                </Route>
              )}

              {(user?.authorities?.includes(USER_ROLES.CONSULTAR_DETALHE_PESSOA)  || user?.authorities?.includes(USER_ROLES.GERENCIAR_PESSOA)) && (
                <Route exact path={urls.PESSOAS_DETAILS}>
                  <Pessoa onView />
                </Route>
              )}

              {(user?.authorities?.includes(USER_ROLES.ALTERAR_PESSOA) || user?.authorities?.includes(USER_ROLES.GERENCIAR_PESSOA)) && (
                <Route path={urls.PESSOAS_EDITAR}>
                  <Pessoa onEdit />
                </Route>
              )}

              {user?.authorities?.includes(USER_ROLES.GERENCIAR_USUARIOS) && (
                <Route exact path={urls.USUARIOS_LIST}>
                  <Listagem />
                </Route>
              )}

              {user?.authorities?.includes(USER_ROLES.GERENCIAR_USUARIOS) && (
                <Route exact strict path={urls.USUARIOS_CRIAR}>
                  <Usuario onCreate />
                </Route>
              )}

              {user?.authorities?.includes(USER_ROLES.GERENCIAR_USUARIOS) && (
                <Route exact path={urls.USUARIOS_DETAILS}>
                  <Usuario onView />
                </Route>
              )}

              {user?.authorities?.includes(USER_ROLES.GERENCIAR_USUARIOS) && (
                <Route path={urls.USUARIOS_EDITAR}>
                  <Usuario onEdit />
                </Route>
              )}

              {user?.authorities?.includes(USER_ROLES.GERENCIAR_USUARIOS) && (
                <Route exact path={urls.GRUPOS_LIST}>
                  <ListGroups />
                </Route>
              )}

              {user?.authorities?.includes(USER_ROLES.GERENCIAR_USUARIOS) && (
                <Route exact strict path={urls.GRUPOS_CRIAR}>
                  <Group onCreate />
                </Route>
              )}

              {user?.authorities?.includes(USER_ROLES.GERENCIAR_USUARIOS) && (
                <Route exact path={urls.GRUPOS_DETAILS}>
                  <Group onView />
                </Route>
              )}

              {user?.authorities?.includes(USER_ROLES.GERENCIAR_USUARIOS) && (
                <Route path={urls.GRUPOS_EDITAR}>
                  <Group onEdit />
                </Route>
              )}

              {user?.authorities?.includes(
                USER_ROLES.GERENCIAR_CADASTROS_BASICOS
              ) && (
                <Route exact path={urls.CADASTROS_BASICOS}>
                  <CadastrosBasicos />
                </Route>
              )}

              {user?.authorities?.includes(
                USER_ROLES.GERENCIAR_CADASTROS_BASICOS
              ) && (
                <Route exact path={urls.CLASSIFICACOES}>
                  <ListClassification />
                </Route>
              )}

              {user?.authorities?.includes(
                USER_ROLES.GERENCIAR_CADASTROS_BASICOS
              ) && (
                <Route exact path={urls.CLASSIFICACOES_TEMA_DETAILS}>
                  <EditTema />
                </Route>
              )}

              {user?.authorities?.includes(
                USER_ROLES.GERENCIAR_CADASTROS_BASICOS
              ) && (
                <Route exact path={urls.CLASSIFICACOES_TEMA_CRIAR}>
                  <EditTema />
                </Route>
              )}
              {user?.authorities?.includes(
                USER_ROLES.GERENCIAR_CADASTROS_BASICOS
              ) && (
                <Route exact path={urls.CLASSIFICACOES_TIPO_DETAILS}>
                  <EditTipo />
                </Route>
              )}

              {user?.authorities?.includes(
                USER_ROLES.GERENCIAR_CADASTROS_BASICOS
              ) && (
                <Route exact path={urls.CLASSIFICACOES_TIPO_CRIAR}>
                  <EditTipo />
                </Route>
              )}

              {user?.authorities?.includes(
                USER_ROLES.GERENCIAR_CADASTROS_BASICOS
              ) && (
                <Route exact path={urls.CLASSIFICACOES_SUBTIPO_DETAILS}>
                  <EditSubtipo />
                </Route>
              )}

              {user?.authorities?.includes(
                USER_ROLES.GERENCIAR_CADASTROS_BASICOS
              ) && (
                <Route exact path={urls.CLASSIFICACOES_SUBTIPO_CRIAR}>
                  <EditSubtipo />
                </Route>
              )}

              {(user?.authorities?.includes(
                USER_ROLES.GERENCIAR_INSTITUICOES
              ) ||
                user?.authorities?.includes(
                  USER_ROLES.CONSULTAR_TODAS_INSTITUICOES
                )) && (
                <Route exact path={urls.INSTITUICOES_LIST}>
                  <ListInstitutions />
                </Route>
              )}

              {user?.authorities?.includes(
                USER_ROLES.GERENCIAR_INSTITUICOES
              ) && (
                <Route exact strict path={urls.INSTITUICOES_CRIAR}>
                  <Instituicao onCreate />
                </Route>
              )}

              {(user?.authorities?.includes(
                USER_ROLES.GERENCIAR_INSTITUICOES
              ) ||
                user?.authorities?.includes(
                  USER_ROLES.CONSULTAR_TODAS_INSTITUICOES
                )) && (
                <Route exact path={urls.INSTITUICOES_DETAILS}>
                  <Instituicao onView />
                </Route>
              )}

              {user?.authorities?.includes(
                USER_ROLES.GERENCIAR_INSTITUICOES
              ) && (
                <Route exact path={urls.INSTITUICOES_EDITAR}>
                  <Instituicao onEdit />
                </Route>
              )}
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </Container>
        )}
      </Switch>
    </BrowserRouter>
  );
};

export default Rotas;
