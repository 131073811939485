import React, { useState, useEffect, useContext } from "react";

import { mask, unMask } from "remask";
import { cpf } from "cpf-cnpj-validator";

import {
  Form,
  DatePicker,
  Input,
  Select,
  Row,
  Col,
  Modal,
  Button,
  Radio,
} from "antd";

import moment from "moment";

import { openNotification } from "../../../../../components/Notification";
import { UserContext } from "../../../../../contexts/UserContext";
import { rendaService } from "../../../../../services/resources/rendaService";
import { religiaoService } from "../../../../../services/resources/religiaoService";
import { ocupacaoService } from "../../../../../services/resources/ocupacaoService";
import { grauInstrucaoService } from "../../../../../services/resources/grauInstrucaoService";
import { faixaEtariaService } from "../../../../../services/resources/faixaEtariaService";
import { etniaService } from "../../../../../services/resources/etniaService";
import { sexualidadeService } from "../../../../../services/resources/sexualidadeService";
import { identidadeGeneroService } from "../../../../../services/resources/identidadeGeneroService";
import { generoService } from "../../../../../services/resources/generoService";
import { estadoCivilService } from "../../../../../services/resources/estadoCivilService";
import { deficienciaService } from "../../../../../services/resources/deficienciaService";
import { doencaService } from "../../../../../services/resources/doencaService";
import { enderecoService } from "../../../../../services/resources/enderecoService";
import { envolvidoService } from "../../../../../services/resources/envolvidoService";
import { denuncianteService } from "../../../../../services/resources/denuncianteService";
import TextArea from "antd/lib/input/TextArea";

const DATA_FORMATO = "DD/MM/YYYY";
const CEP_MASK = "99999-999";
const CPF_MASK = "999.999.999-99";
const TELEFONE_MASK = ["(99) 9999-9999", "(99) 9 9999-9999"];

export function PessoaModal({
  codigo,
  onClose,
  mode,
  afterCreate,
  codigoDenuncia,
  refresh,
  ...props
}) {
  const { estados } = useContext(UserContext);

  const [form] = Form.useForm();

  const [person, setPerson] = useState(null);

  const [cidades, setCidades] = useState([]);
  const [personBairros, setPersonBairros] = useState([]);

  const [denuncia, setDenuncia] = useState(null);
  const [generos, setGeneros] = useState([]);
  const [identidadeGenero, setIdentidadeGenero] = useState([]);
  const [sexualidade, setSexualidade] = useState([]);
  const [renda, setRenda] = useState([]);
  const [religiao, setReligiao] = useState([]);
  const [etnias, setEtnias] = useState([]);
  const [statusCivil, setStatusCivil] = useState([]);
  const [doencas, setDoencas] = useState([]);
  const [deficiencias, setDeficiencias] = useState([]);
  const [faixaEtarias, setFaixaEtarias] = useState([]);
  const [grausIntrucao, setGrausInstrucao] = useState([]);
  const [ocupacoes, setOcupacoes] = useState([]);

  useEffect(() => {
    codigo && getPessoa(codigo);
    codigoDenuncia && getDenuncia(codigoDenuncia);
    // eslint-disable-next-line
  }, [codigo, codigoDenuncia]);

  useEffect(() => {
    getIdentidadeGenero();
    getSexualidade();
    getRenda();
    getReligiao();
    getDoencas();
    getDeficiencias();
    getStatusCivil();
    getEtnias();
    getGeneros();
    getOcupacoes();
    getFaixasEstarias();
    getGrausInstrucao();
    // eslint-disable-next-line
  }, []);

  async function getDenuncia(codigo) {
    await denuncianteService
      .getDenunciation(codigo)
      .then(({ data }) => {
        setDenuncia(data);
      })
      .catch((reason) => {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os dados da denúncia!</strong>,
          reason?.response?.data?.detail
        );
      });
  }

  async function getPessoa(codigo) {
    try {
      const { data: pessoa } = await envolvidoService.getInvolved(codigo);

      setPerson(pessoa);
      getCidades(pessoa?.endereco?.bairro?.cidade?.estado?.id);
      getDistricts(pessoa?.endereco?.bairro?.cidade?.id);

      form.setFieldsValue({
        ...pessoa,
        dataNascimento:
          pessoa.dataNascimento &&
          moment(new Date(pessoa.dataNascimento), DATA_FORMATO),
      });
    } catch ({ response }) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao carregar essa pessoa!</strong>,
        "Erro: " + response?.data?.detail
      );
      onClose();
    }
  }

  async function getDistricts(city_id) {
    (await city_id) &&
      enderecoService
        .getDistrictsCitys(city_id)
        .then((response) => {
          setPersonBairros(response.data);
        })
        .catch((reason) =>
          openNotification(
            "error",
            <strong>Ocorreu um erro ao carregar as bairros!</strong>,
            "" + reason
          )
        );
  }

  async function getFaixasEstarias() {
    await faixaEtariaService
      .loadFaixaEtarias()
      .then((response) => setFaixaEtarias(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os tipos de Faixa Etária!</strong>,
          "" + reason
        )
      );
  }

  async function getOcupacoes() {
    await ocupacaoService
      .loadOcupacoes()
      .then((response) => setOcupacoes(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os tipos de Ocupações!</strong>,
          "" + reason
        )
      );
  }

  async function getGrausInstrucao() {
    await grauInstrucaoService
      .loadGrausInstrucao()
      .then((response) => setGrausInstrucao(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>
            Ocorreu um erro ao buscar os tipos de Grau de Instrução!
          </strong>,
          "" + reason
        )
      );
  }

  async function getGeneros() {
    await generoService
      .loadGeneros()
      .then((response) => setGeneros(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os tipos de sexo!</strong>,
          "" + reason
        )
      );
  }

  async function getRenda() {
    await rendaService
      .load()
      .then((response) => setRenda(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar a lista de rendas!</strong>,
          "" + reason
        )
      );
  }

  async function getReligiao() {
    await religiaoService
      .load()
      .then((response) => setReligiao(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar a lista de religiões!</strong>,
          "" + reason
        )
      );
  }

  async function getEtnias() {
    await etniaService
      .loadEtnias()
      .then((response) => setEtnias(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os tipos de Etnia!</strong>,
          "" + reason
        )
      );
  }

  async function getStatusCivil() {
    await estadoCivilService
      .loadStatusCivil()
      .then((response) => setStatusCivil(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os Estados Civis!</strong>,
          "" + reason
        )
      );
  }

  async function getIdentidadeGenero() {
    await identidadeGeneroService
      .loadIdentidadeGenero()
      .then((response) => setIdentidadeGenero(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>
            Ocorreu um erro ao buscar a lista de identidade de gênero!
          </strong>,
          "" + reason
        )
      );
  }

  async function getSexualidade() {
    await sexualidadeService
      .load()
      .then((response) => setSexualidade(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar a lista de Orientações Sexuais!</strong>,
          "" + reason
        )
      );
  }

  async function getDoencas() {
    await doencaService
      .list()
      .then((response) => setDoencas(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os tipos de doenças!</strong>,
          "" + reason
        )
      );
  }

  async function getDeficiencias() {
    await deficienciaService
      .loadDeficiencias()
      .then((response) => setDeficiencias(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os tipos de Deficiência!</strong>,
          "" + reason
        )
      );
  }

  async function getCidades(estado) {
    await enderecoService
      .getCitysState(estado)
      .then((response) => {
        setCidades(response.data);
      })
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao carregar as cidades!</strong>,
          "Erro: " + reason?.response?.data?.detail
        )
      );
  }

  // Função para aplicar as mascaras nos campos que precisam
  function onChange(name, value, patterns) {
    form.setFieldsValue({
      [name]: mask(unMask(value), patterns),
    });
  }

  function onChangeCep(value) {
    form.setFieldsValue({
      endereco: { cep: mask(unMask(value), CEP_MASK) },
    });
  }

  const validarCPF = () => {
    const numeroCPF = form.getFieldValue("cpf");

    if (!numeroCPF || numeroCPF.length === 14) {
      if (!numeroCPF || cpf.isValid(numeroCPF)) {
        return Promise.resolve();
      }

      return Promise.reject(
        new Error("O cpf informado não é válido pelo digito verificador!")
      );
    }
  };

  async function handleSelectEstado(value, mantemCidades) {
    if (!mantemCidades)
      form.setFieldsValue({
        endereco: { bairro: { id: null, cidade: { id: null } } },
      });
    value && (await getCidades(value));
  }

  async function handleSelectPersonCity(value, mantemBairros) {
    if (!mantemBairros)
      form.setFieldsValue({ endereco: { bairro: { id: null } } });
    value && (await getDistricts(value));
  }

  function handleUseEndereco(mesmoEnderecoDaDenuncia) {
    if (mesmoEnderecoDaDenuncia) {
      const mantemBairros = true;
      const mantemCidades = true;

      handleSelectEstado(
        denuncia?.endereco?.bairro?.cidade?.estado?.id,
        mantemCidades
      );
      handleSelectPersonCity(
        denuncia?.endereco?.bairro?.cidade?.id,
        mantemBairros
      );

      form.setFieldsValue({
        endereco: {
          logradouro: denuncia?.endereco?.logradouro,
          cep: denuncia?.endereco?.cep,
          bairro: {
            id: denuncia?.endereco?.bairro?.id,
            cidade: {
              id: denuncia?.endereco?.bairro?.cidade?.id,
              estado: { id: denuncia?.endereco?.bairro?.cidade?.estado?.id },
            },
          },complemento: denuncia?.endereco?.complemento,
          numero: denuncia?.endereco?.numero,
        },
      });
    } else form.setFieldsValue({ endereco: null });
  }

  function transformaPessoa(pessoa) {
    return {
      nome: pessoa.nome,
      nomeSocial: pessoa.nomeSocial || null,
      nomeMae: pessoa.nomeMae || null,
      email: pessoa.email || null,
      nis: pessoa.nis || null,
      telefone1: pessoa.telefone1 || null,
      tipoTelefone1: null,
      telefone2: pessoa.telefone2 || null,
      tipoTelefone2: null,
      observacao: pessoa?.observacao,
      doencaRara: {
        codigo:
          pessoa.doencaRara.codigo ||
          doencas.filter((x) => x?.descricao === "NÃO INFORMADO")[0]?.codigo,
      },
      deficiencia: {
        codigo:
          pessoa.deficiencia.codigo ||
          deficiencias.filter((x) => x?.descricao === "NÃO INFORMADO")[0]
            .codigo,
      },
      ocupacao: {
        codigo:
          pessoa.ocupacao.codigo ||
          ocupacoes.filter((x) => x?.descricao === "NÃO INFORMADO")[0].codigo,
      },
      faixaEtaria: {
        codigo:
          pessoa.faixaEtaria.codigo ||
          faixaEtarias.filter((x) => x?.descricao === "NÃO INFORMADO")[0]
            .codigo,
      },
      racaCor: {
        codigo:
          pessoa.racaCor.codigo ||
          etnias.filter((x) => x?.descricao === "NÃO INFORMADO")[0].codigo,
      },

      grauInstrucao: {
        codigo:
          pessoa.grauInstrucao.codigo ||
          grausIntrucao.filter((x) => x?.descricao === "NÃO INFORMADO")[0]
            .codigo,
      },
      identidadeGenero: {
        codigo:
          pessoa.identidadeGenero.codigo ||
          identidadeGenero.filter((x) => x?.descricao === "NÃO INFORMADO")[0]
            .codigo,
      },
      sexualidade: {
        codigo:
          pessoa.sexualidade.codigo ||
          sexualidade.filter((x) => x?.descricao === "NÃO INFORMADO")[0].codigo,
      },
      renda: {
        codigo:
          pessoa.renda.codigo ||
          renda.filter((x) => x?.descricao === "NÃO INFORMADO")[0].codigo,
      },
      religiao: {
        codigo:
          pessoa.religiao.codigo ||
          religiao.filter((x) => x?.descricao === "NÃO INFORMADO")[0].codigo,
      },
      naturalidade: pessoa.naturalidade || null,
      nacionalidade: pessoa.nacionalidade || null,
      cpf: pessoa.cpf || null,
      rg: pessoa.rg || null,
      dataNascimento: pessoa.dataNascimento || null,
      endereco: {
        logradouro: pessoa.endereco.logradouro || null,
        numero: pessoa.endereco.numero || null,
        complemento: pessoa.endereco.complemento || null,
        cep: pessoa.endereco.cep || null,
        bairro: {
          id: pessoa.endereco.bairro.id,
        },
      },
      genero: {
        codigo: pessoa.genero.codigo,
      },
      estadoCivil: {
        codigo:
          pessoa.estadoCivil.codigo ||
          statusCivil.filter((x) => x?.descricao === "NÃO INFORMADO")[0].codigo,
      },
    };
  }

  async function createInvolved() {
    const pessoa = transformaPessoa(form.getFieldsValue());

    form.validateFields().then(() => {
      form.resetFields();
      envolvidoService
        .createInvolved(pessoa)
        .then((response) => {
          onClose();
          afterCreate(response.data);
          openNotification(
            "success",
            <strong>Pessoa criada com sucesso!</strong>,
            "Essa pessoa foi criada com sucesso e agora faz parte dessa denúncia!"
          );
        })
        .catch((reason) => {
          openNotification(
            "error",
            <strong>Erro ao criar essa pessoa!</strong>,
            reason?.response?.data?.detail
          );
        });
    });
  }

  async function editInvolved() {
    const pessoa = transformaPessoa(form.getFieldsValue());

    form.validateFields().then(() => {
      form.resetFields();
      onClose();
      envolvidoService
        .editInvolved(codigo, pessoa)
        .then(() => {
          openNotification(
            "success",
            <strong>Pessoa editada com sucesso!</strong>,
            "Essa pessoa foi atualizada com sucesso!"
          );
          refresh();
        })
        .catch((reason) => {
          openNotification(
            "error",
            <strong>Erro ao criar essa pessoa!</strong>,
            reason?.response?.data?.detail
          );
        });
    });
  }

  const footer = [
    <Button key="back" onClick={onClose}>
      {mode === "view" ? "voltar" : "cancelar"}
    </Button>,
    mode === "create" && (
      <Button key="submit" onClick={createInvolved} type="primary">
        cadastrar
      </Button>
    ),
    mode === "edit" && (
      <Button key="submitUpdate" onClick={editInvolved} type="primary">
        atualizar
      </Button>
    ),
  ];

  return (
    (person || mode === "create") && (
      <Modal
        title={<h3>{person?.nome || "Cadastrar novo indivíduo"}</h3>}
        width="90vw"
        bodyStyle={{ height: "70vh", overflowY: "auto" }}
        onCancel={onClose}
        {...props}
        centered
        footer={footer}
      >
        <Form form={form} name="cadastro-pessoa" layout="vertical">
          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Form.Item
                name="nome"
                label={<span>Nome completo:</span>}
                rules={[
                  { required: true, message: "Este campo é obrigatório!" },
                ]}
              >
                <Input
                  placeholder={mode !== "view" && "Insira o nome completo"}
                  autoComplete="none"
                  disabled={mode === "view"}
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Form.Item name="nomeSocial" label={<span>Nome social:</span>}>
                <Input
                  type="text"
                  placeholder={mode !== "view" && "insira o nome social"}
                  autoComplete="none"
                  disabled={mode === "view"}
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Form.Item name="nomeMae" label={<span>Nome da mãe:</span>}>
                <Input
                  placeholder={mode !== "view" && "Insira nome da mãe"}
                  autoComplete="none"
                  disabled={mode === "view"}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="dataNascimento"
                label={<span>Data de nascimento:</span>}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format={DATA_FORMATO}
                  placeholder={mode !== "view" && "Formato dd/mm/aaaa"}
                  disabledDate={(d) => !d || d.isAfter(new Date())}
                  disabled={mode === "view"}
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item name="rg" label={<span>RG:</span>}>
                <Input
                  placeholder={mode !== "view" && "123456789-0"}
                  autoComplete="none"
                  disabled={mode === "view"}
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="cpf"
                label={<span>CPF:</span>}
                onChange={({ target }) =>
                  onChange("cpf", target.value, CPF_MASK)
                }
                rules={[
                  {
                    validator: validarCPF,
                  },
                ]}
              >
                <Input
                  placeholder={mode !== "view" && "123.456.789-10"}
                  autoComplete="none"
                  disabled={mode === "view"}
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item name="nis" label={<span>NIS:</span>}>
                <Input
                  type="text"
                  placeholder={mode !== "view" && "insira o NIS"}
                  autoComplete="none"
                  disabled={mode === "view"}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Form.Item
                name={["genero", "codigo"]}
                label={<span>Sexo:</span>}
                rules={[
                  { required: true, message: "Este campo é obrigatório!" },
                ]}
              >
                <Select
                  disabled={mode === "view"}
                  placeholder={mode !== "view" && "selecionar sexo"}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={generos?.map(({ descricao, codigo }) => ({
                    label: descricao,
                    value: codigo,
                  }))}
                  autoComplete="none"
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Form.Item
                name={["identidadeGenero", "codigo"]}
                label={<span>Identidade de gênero:</span>}
              >
                <Select
                  disabled={mode === "view"}
                  placeholder={mode !== "view" && "selecionar identidade de gênero"}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={identidadeGenero?.map(({ descricao, codigo }) => ({
                    label: descricao,
                    value: codigo,
                  }))}
                  autoComplete="none"
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Form.Item
                name={["sexualidade", "codigo"]}
                label={<span>Orientação Sexual:</span>}
              >
                <Select
                  disabled={mode === "view"}
                  placeholder={mode !== "view" && "selecionar orientação sexual"}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={sexualidade?.map(({ descricao, codigo }) => ({
                    label: descricao,
                    value: codigo,
                  }))}
                  autoComplete="none"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Form.Item
                name={["faixaEtaria", "codigo"]}
                label={<span>Faixa etária:</span>}
              >
                <Select
                  placeholder={mode !== "view" && "selecionar faixa etária"}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={faixaEtarias?.map(({ descricao, codigo }) => ({
                    label: descricao,
                    value: codigo,
                  }))}
                  disabled={mode === "view"}
                  autoComplete="none"
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Form.Item
                name={["estadoCivil", "codigo"]}
                label={<span>Estado civil:</span>}
              >
                <Select
                  placeholder={mode !== "view" && "escolher estado civil"}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={statusCivil?.map(({ descricao, codigo }) => ({
                    label: descricao,
                    value: codigo,
                  }))}
                  disabled={mode === "view"}
                  autoComplete="none"
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Form.Item
                name={["racaCor", "codigo"]}
                label={<span>Raça/Cor:</span>}
              >
                <Select
                  placeholder={mode !== "view" && "escolher raça"}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={etnias?.map(({ descricao, codigo }) => ({
                    label: descricao,
                    value: codigo,
                  }))}
                  disabled={mode === "view"}
                  autoComplete="none"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name={["renda", "codigo"]}
                label={<span>Faixa de renda familiar:</span>}
              >
                <Select
                  placeholder={mode !== "view" && "selecionar faixa de renda"}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={renda?.map(({ descricao, codigo }) => ({
                    label: descricao,
                    value: codigo,
                  }))}
                  disabled={mode === "view"}
                  autoComplete="none"
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name={["religiao", "codigo"]}
                label={<span>Religião:</span>}
              >
                <Select
                  placeholder={mode !== "view" && "escolher religião"}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={religiao?.map(({ descricao, codigo }) => ({
                    label: descricao,
                    value: codigo,
                  }))}
                  disabled={mode === "view"}
                  autoComplete="none"
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="nacionalidade"
                label={<span>Nacionalidade:</span>}
              >
                <Input
                  placeholder={mode !== "view" ? "inserir nacionalidade" : ""}
                  name="personNacionalidade"
                  autoComplete="none"
                  disabled={mode === "view"}
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item name="naturalidade" label={<span>Naturalidade:</span>}>
                <Input
                  placeholder={mode !== "view" ? "inserir naturalidade" : ""}
                  autoComplete="none"
                  disabled={mode === "view"}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name={["ocupacao", "codigo"]}
                label={<span>Ocupação:</span>}
              >
                <Select
                  placeholder={mode !== "view" && "escolher ocupação"}
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={ocupacoes?.map(({ descricao, codigo }) => ({
                    label: descricao,
                    value: codigo,
                  }))}
                  disabled={mode === "view"}
                  autoComplete="none"
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name={["grauInstrucao", "codigo"]}
                label={<span>Grau de Instrução:</span>}
              >
                <Select
                  placeholder={mode !== "view" && "escolher grau de instrução"}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={grausIntrucao?.map(({ descricao, codigo }) => ({
                    label: descricao,
                    value: codigo,
                  }))}
                  disabled={mode === "view"}
                  autoComplete="none"
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name={["deficiencia", "codigo"]}
                label={<span>Pessoa com deficiência?</span>}
                defaultValue={null}
              >
                <Select
                  placeholder={mode !== "view" && "escolher deficiência"}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={deficiencias?.map(({ descricao, codigo }) => ({
                    label: descricao,
                    value: codigo,
                  }))}
                  disabled={mode === "view"}
                  autoComplete="none"
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name={["doencaRara", "codigo"]}
                label={<span>Doença rara?</span>}
              >
                <Select
                  placeholder={mode !== "view" && "escolher doença rara"}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={doencas?.map(({ descricao, codigo }) => ({
                    label: descricao,
                    value: codigo,
                  }))}
                  disabled={mode === "view"}
                  autoComplete="none"
                />
              </Form.Item>
            </Col>
          </Row>

          {mode !== "view" && (
            <Row>
              <Col span="24">
                <h3 style={{ display: "inline" }}>
                  Deseja carregar endereço da denúncia ?
                </h3>
                <Radio.Group
                  style={{ marginLeft: "1rem" }}
                  defaultValue={false}
                  onChange={({ target }) => handleUseEndereco(target.value)}
                >
                  <Radio value={true}>Sim</Radio>
                  <Radio value={false}>Não</Radio>
                </Radio.Group>
              </Col>
            </Row>
          )}

          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Form.Item
                label={<span>Estado:</span>}
                name={["endereco", "bairro", "cidade", "estado", "id"]}
                rules={[
                  { required: true, message: "Este campo é obrigatório!" },
                ]}
              >
                <Select
                  placeholder={mode !== "view" && "escolher estado"}
                  onChange={(estado) => handleSelectEstado(estado, false)}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={estados?.map(({ nome, id }) => ({
                    label: nome,
                    value: id,
                  }))}
                  disabled={mode === "view"}
                  autoComplete="none"
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Form.Item
                name={["endereco", "bairro", "cidade", "id"]}
                label={<span>Município:</span>}
                rules={[
                  { required: true, message: "Este campo é obrigatório!" },
                ]}
              >
                <Select
                  placeholder={mode !== "view" && "escolher município"}
                  onChange={(cidade) => handleSelectPersonCity(cidade, false)}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={cidades?.map(({ nome, id }) => ({
                    label: nome,
                    value: id,
                  }))}
                  disabled={mode === "view"}
                  autoComplete="none"
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Form.Item
                name={["endereco", "bairro", "id"]}
                label={<span>Bairro:</span>}
                rules={[
                  { required: true, message: "Este campo é obrigatório!" },
                ]}
              >
                <Select
                  placeholder={mode !== "view" && "escolher"}
                  disabled={mode === "view"}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={personBairros?.map(({ nome, id }) => ({
                    label: nome,
                    value: id,
                  }))}
                  autoComplete="none"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name={["endereco", "logradouro"]}
                label={<span>Logradouro:</span>}
              >
                <Input
                  placeholder={mode !== "view" && "insira o logradouro"}
                  autoComplete="none"
                  disabled={mode === "view"}
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name={["endereco", "numero"]}
                label={<span>Número:</span>}
              >
                <Input
                  placeholder={mode !== "view" && "Insira o número"}
                  autoComplete="none"
                  disabled={mode === "view"}
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name={["endereco", "cep"]}
                label={<span>CEP:</span>}
                onChange={({ target }) => onChangeCep(target.value)}
              >
                <Input
                  placeholder={mode !== "view" && "60000-600"}
                  autoComplete="none"
                  disabled={mode === "view"}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name={["endereco", "complemento"]}
                label={<span>Ponto de referência</span>}
              >
                <Input
                  placeholder={mode !== "view" && "insira o complemento"}
                  autoComplete="none"
                  disabled={mode === "view"}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Form.Item
                label={<span>Telefone 01:</span>}
                name="telefone1"
                onChange={({ target }) =>
                  onChange("telefone1", target.value, TELEFONE_MASK)
                }
              >
                <Input
                  autoComplete="none"
                  disabled={mode === "view"}
                  placeholder={mode !== "view" && "insira um telefone"}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Form.Item
                label={<span>Telefone 02:</span>}
                name="telefone2"
                onChange={({ target }) =>
                  onChange("telefone2", target.value, TELEFONE_MASK)
                }
              >
                <Input
                  autoComplete="none"
                  disabled={mode === "view"}
                  placeholder={mode !== "view" && "insira um telefone"}
                  allowClear
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Form.Item
                name="email"
                label={<span>E-mail:</span>}
                rules={[{ type: "email", message: "Insira um email válido" }]}
              >
                <Input
                  placeholder={mode !== "view" && "meu@email.com"}
                  autoComplete="none"
                  disabled={mode === "view"}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={32}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name={"observacao"}
              label={<span>Observação</span>}
            >
              <TextArea
                autoSize
                placeholder={mode !== "view" &&"Escreva alguma observação da pessoa"}
                autoComplete="none"
                disabled={mode === "view"}
              />
            </Form.Item>
          </Col>
        </Row>
        </Form>
      </Modal>
    )
  );
}
