import React from "react";

import { urls } from "../../../../../utils";

export const PainelCRDH= () => {
  return (
    <div className="container-section-metabase">
      <iframe src={urls.ESTATISTICAS_PAINEIS_CRDH} style={{ border: "none" }} height="1100" width="100%" title="description"></iframe>
    </div>
  );
};
