import api from "../api";
import qs from "qs";

export const denunciaService = {
  async list(params) {
    return api.get(`/denuncias/paginadas`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
  },

  async createDenunciaPublica(denuncia) {
    return await api.post("/publico/denuncias", denuncia);
  },

  async imprimirDenunciaPublica(codigoDenuncia) {
    return await api.get(`/publico/denuncias/${codigoDenuncia}/imprimir`, {
      responseType: "blob",
    });
  },

  denunciante: {
    async listar(codigoDenuncia) {
      return await api.get(`/denuncias/${codigoDenuncia}/notificantes`);
    },

    async imprimir(codigoDenuncia) {
      return await api.get(`/denuncias/${codigoDenuncia}/imprimir`, {
        responseType: "blob",
      });
    },

    async inserir(codigoDenuncia, codigoPessoa) {
      return await api.put(
        `/denuncias/${codigoDenuncia}/notificantes/${codigoPessoa}`
      );
    },

    async remover(codigoDenuncia, codigoPessoa) {
      return await api.delete(
        `/denuncias/${codigoDenuncia}/notificantes/${codigoPessoa}`
      );
    },
  },

  vitima: {
    async listar(codigoDenuncia) {
      return await api.get(`/denuncias/${codigoDenuncia}/vitimas`);
    },

    async inserir(codigoDenuncia, codigoPessoa) {
      return await api.put(
        `/denuncias/${codigoDenuncia}/vitimas/${codigoPessoa}`
      );
    },

    async remover(codigoDenuncia, codigoPessoa) {
      return await api.delete(
        `/denuncias/${codigoDenuncia}/vitimas/${codigoPessoa}`
      );
    },
  },

  suspeito: {
    async listar(codigoDenuncia) {
      return await api.get(`/denuncias/${codigoDenuncia}/infratores`);
    },

    async inserir(codigoDenuncia, codigoPessoa) {
      return await api.put(
        `/denuncias/${codigoDenuncia}/infratores/${codigoPessoa}`
      );
    },

    async remover(codigoDenuncia, codigoPessoa) {
      return await api.delete(
        `/denuncias/${codigoDenuncia}/infratores/${codigoPessoa}`
      );
    },
  },
};
