import api from "../api";

export const ocupacaoService = {
    async loadOcupacoes() {
      return await api.get("/ocupacoes");
    },
    async get(codigo) {
      return await api.get(`/ocupacoes/${codigo}`);
    },
    async create(payload) {
      return await api.post(`/ocupacoes`, payload);
    },
    async edit(codigo, payload) {
      return await api.put(`/ocupacoes/${codigo}`, payload);
    },
    async delete(codigo) {
      return await api.delete(`/ocupacoes/${codigo}`);
    },
  }