import React, { useCallback, useEffect, useState } from "react";
import { Form, Input, Row, Col, Modal, Button } from "antd";

import { Container } from "./styles";
import { openNotification } from "../../../../../../components/Notification";
import { useWidth } from "../../../../../../hooks";
import { ocupacaoService } from "../../../../../../services/resources/ocupacaoService";
import TextArea from "antd/lib/input/TextArea";

export function ModalAdd({ onClose, mode, codigo, reload }) {
  const [form] = Form.useForm();
  const { width } = useWidth();

  const [loading, setLoading] = useState(false);

  const [ocupacao, setOcupacao] = useState([]);

  const handleCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    async function getocupacao() {
      try {
        const { data } = await ocupacaoService.get(codigo);

        setOcupacao(data);
        form.setFieldsValue({
          descricao: data?.descricao,
          fundamentacao: data?.fundamentacao,
        });
      } catch (error) {
        openNotification(
          "error",
          <strong>Erro!</strong>,
          error?.response?.data?.detail
        );
      }
    }
    if (mode !== "onCreate") {
      getocupacao();
    }
  }, [codigo, mode, form]);

  async function create() {
    try {
      setLoading(true);
      await ocupacaoService.create(form.getFieldsValue());
      handleCloseModal();
      openNotification(
        "success",
        <strong>Ocupação adicionada com sucesso!</strong>
      );
      reload();
    } catch (error) {
      handleCloseModal();
      openNotification(
        "error",
        <strong>Não foi possível criar a ocupação!</strong>,
        error?.response?.data?.detail
      );
    } finally {
      setLoading(false);
    }
  }

  async function edit() {
    try {
      setLoading(true);
      await ocupacaoService.edit(codigo, form.getFieldsValue());
      handleCloseModal();
      openNotification("success", <strong>Ocupação editada!</strong>);
      reload();
    } catch (error) {
      handleCloseModal();
      openNotification(
        "error",
        <strong>Não foi possível editar a ocupação!</strong>,
        error?.response?.data?.detail
      );
    } finally {
      setLoading(false);
    }
  }

  const footer = [
    mode === "onCreate" && (
      <Button key="submit" onClick={create} type="primary" loading={loading}>
        cadastrar
      </Button>
    ),
    mode === "onEdit" && (
      <Button
        key="submitUpdate"
        onClick={edit}
        type="primary"
        loading={loading}
      >
        atualizar
      </Button>
    ),
  ];

  return (
    <Modal
      title={
        (mode === "onCreate" && "NOVA OCUPAÇÃO") ||
        (mode === "onEdit" && `EDITAR OCUPAÇÃO - ${ocupacao?.descricao}`) ||
        (mode === "onView" && `DETALHES DA OCUPAÇÃO - ${ocupacao?.descricao}`)
      }
      width={width > 1280 ? "50vw" : "100vw"}
      onCancel={onClose}
      visible
      footer={footer}
      centered
    >
      <Container>
        <Form form={form} name="ocupacao" layout="vertical">
          <Row>
            <Col span={24}>
              <Form.Item name="descricao" label={<span>Descrição:</span>}>
                <Input
                  type="text"
                  placeholder="insira a descrição"
                  autoComplete="none"
                  disabled={mode === "onView"}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item
                  name="fundamentacao"
                  label={<span>Fundamentação:</span>}
                >
                  <TextArea
                    autoSize
                    placeholder="Digite a fundamentação da ocupação"
                    disabled={mode === "onView"}
                  />
                </Form.Item>
              </Col>
          </Row>
        </Form>
      </Container>
    </Modal>
  );
}
