import { Col, Row } from "antd";
import React from "react";

export function FormActions({ inBotton, actions }) {
  return (
    <Row
      justify="space-between"
      align="middle"
      style={{
        position: "sticky",
        left: 0,
        bottom: "-2rem",
        background: "#F2F2F2",
        boxShadow: !inBotton && "0px -10px 10px -10px rgba(0,0,0,0.15)",
        padding: inBotton ? "2rem 0 0" : "2rem 0",
        transition: "all 0.5s ease-out",
        zIndex: "999",
        
      }}
    >
      {actions?.map((action, index) => (
        <Col key={index}>{action}</Col>
      ))}
    </Row>
  );
}
