import api from "../api";
import qs from "qs";

export const denuncianteService = {
  async load(params) {
    return api.get(`/denuncias/paginadas`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
  },

  async loadDenunciations(params) {
    return api.get(`/denuncias`, {
      params,
    });
  },

  async total(params) {
    return api.get(`/denuncias/total`, {
      params,
    });
  },

  async getDenunciation(id) {
    return await api.get(`/denuncias/${id}`);
  },

  async createDenunciation(denunciation) {
    return await api.post(`/denuncias`, denunciation);
  },

  async editDenunciation(id, denunciation) {
    return await api.put(`/denuncias/${id}`, denunciation);
  },

  async arquivar(id, justificativa) {
    return await api.put(
      `/denuncias/${id}/arquivar`,
      { justificativa },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },

  async abrir(id) {
    return await api.put(`/denuncias/${id}/abrir`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  async finalizeDenunciation(id) {
    return await api.put(`/denuncias/${id}/finalizar`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  async desarquivarDenuncia(id) {
    return await api.put(`/denuncias/${id}/desarquivar`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  async getDenunciationsWithForwardinsByInstitution(params) {
    return api.get(`/encaminhamentos`, {
      params,
    });
  },

  async getDenunciaByProtocolo(codigoCanal, protocoloExterno) {
    return await api.get(
      `/denuncias/externa/${codigoCanal}/${protocoloExterno}`
    );
  },

  denunciante: {
    async listar(codigoDenuncia) {
      return await api.get(`/denuncias/${codigoDenuncia}/notificantes`);
    },

    async inserir(codigoDenuncia, codigoPessoa) {
      return await api.put(
        `/denuncias/${codigoDenuncia}/notificantes/${codigoPessoa}`
      );
    },

    async remover(codigoDenuncia, codigoPessoa) {
      return await api.delete(
        `/denuncias/${codigoDenuncia}/notificantes/${codigoPessoa}`
      );
    },
  },

  vitima: {
    async listar(codigoDenuncia) {
      return await api.get(`/denuncias/${codigoDenuncia}/vitimas`);
    },

    async inserir(codigoDenuncia, codigoPessoa) {
      return await api.put(
        `/denuncias/${codigoDenuncia}/vitimas/${codigoPessoa}`
      );
    },

    async remover(codigoDenuncia, codigoPessoa) {
      return await api.delete(
        `/denuncias/${codigoDenuncia}/vitimas/${codigoPessoa}`
      );
    },
  },

  suspeito: {
    async listar(codigoDenuncia) {
      return await api.get(`/denuncias/${codigoDenuncia}/infratores`);
    },

    async inserir(codigoDenuncia, codigoPessoa) {
      return await api.put(
        `/denuncias/${codigoDenuncia}/infratores/${codigoPessoa}`
      );
    },

    async remover(codigoDenuncia, codigoPessoa) {
      return await api.delete(
        `/denuncias/${codigoDenuncia}/infratores/${codigoPessoa}`
      );
    },
  },

  associada: {
    async listar(codigoDenuncia) {
      return await api.get(`/denuncias/${codigoDenuncia}/associadas`);
    },

    async inserir(codigoDenuncia, codigoDenunciaAssociada) {
      return await api.put(
        `/denuncias/${codigoDenuncia}/associadas/${codigoDenunciaAssociada}`
      );
    },

    async remover(codigoDenuncia, codigoDenunciaAssociada) {
      return await api.delete(
        `/denuncias/${codigoDenuncia}/associadas/${codigoDenunciaAssociada}`
      );
    },
  },
};
