import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router";

import { SearchColumn } from "../../../components/SearchColumn/SearchColumn";

import {
  Col,
  Form,
  Input,
  Row,
  Table,
  Switch,
  Tag,
  Tooltip,
  Button,
  Breadcrumb,
} from "antd";

import { openNotification } from "../../../components/Notification";

import DialogModal from "../../../components/DialogModal";
import { HomeOutlined } from "@ant-design/icons";
import { urls } from "../../../utils";
import { grupoService } from "../../../services/resources/grupoService";
import { permissaoService } from "../../../services/resources/permissaoService";

const OPCOES_TAMANHO_PAGINA = [5, 10, 20, 100];

const Group = ({ onEdit, onView, onCreate }) => {
  const [form] = Form.useForm();

  const { codigo } = useParams();
  const history = useHistory();

  const [permissionsList, setPermissionsList] = useState([]);
  const [group, setGroup] = useState(null);
  const [permissionSelect, setPermissionSelect] = useState(null);
  const [hasAssociate, setHasAssociate] = useState(null);

  const [openDialogModal, setOpenDialogModal] = useState(false);
  const [modalSubtitle, setModalSubtitle] = useState("");
  const [modalDialog, setModalDialog] = useState("");

  const [hasUpdate, setHasUpdate] = useState(false);

  useEffect(() => {
    !onCreate && getGroup();
    permissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codigo]);

  async function getGroup() {
    try {
      const { data } = await grupoService.get(codigo);
      setGroup(data);
      form.setFieldsValue({
        ...data,
      });
    } catch (reason) {
      openNotification(
        "error",
        <strong>Não foi possível carregar o grupo!</strong>,
        reason?.response?.data?.detail
      );
      history.push(urls.GRUPOS_LIST);
    }
  }

  async function atualizarGrupo(grupo) {
    try {
      const { data } = await grupoService.atualizar(codigo, grupo);
      setHasUpdate(false);
      setGroup(data);
      form.setFieldsValue({
        ...data,
      });
      openNotification(
        "success",
        <strong>Grupo atualizado com sucesso!</strong>,
        <span>
          O grupo foi atualizado com sucesso, seu novo nome é: 
          <strong>
            {' '+data?.nome}
          </strong>
        </span>
      );
    } catch (reason) {
      openNotification(
        "error",
        <strong>Não foi possível atualizar o grupo!</strong>,
        reason?.response?.data?.detail
      );
    }
  }

  async function createGroup(group) {
    try {
      const { data } = await grupoService.create(group);
      setGroup(data);

      openNotification(
        "success",
        <strong>Grupo cadastrado com sucesso!</strong>,
        <span>
          <strong>{group.nome} </strong>
          foi cadastrado com sucesso! O próximo passo é associar permissões a
          este grupo.
        </span>
      );
      history.push(urls.GRUPOS_EDITAR.replace(":codigo", data?.codigo));
    } catch (error) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao cadastrar o novo grupo!</strong>,
        "Erro: " + error?.response?.data?.detail
      );
    }
  }

  async function permissions() {
    try {
      const { data: permissions } = await permissaoService.list();
      setPermissionsList(permissions);
    } catch (error) {}
  }

  const handleCloseDialogModal = useCallback(() => {
    setOpenDialogModal(false);
    setPermissionSelect(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const addPermission = async () => {
    try {
      await grupoService.addPermission(codigo, permissionSelect.codigo);
      getGroup();
      openNotification(
        "success",
        <strong>Associação realizada com sucesso!</strong>,
        <span>
          O grupo
          <strong> {group?.nome} </strong>
          agora pode <strong>{permissionSelect?.nome}</strong>
        </span>
      );
    } catch (error) {
      openNotification(
        "error",
        `Erro ${error?.response?.data.status} - ${error?.response?.data.title}!`,
        "Erro: " + error?.response?.data?.detail
      );
    } finally {
      handleCloseDialogModal();
    }
  };

  const removePermission = async () => {
    try {
      await grupoService.removePermission(codigo, permissionSelect.codigo);
      getGroup();
      openNotification(
        "warning",
        <strong>Desassociação realizada com sucesso!</strong>,
        <span>
          O grupo
          <strong> {group?.nome} </strong>
          perdeu a permissão
          <strong> {permissionSelect?.nome}</strong>
        </span>
      );
    } catch (error) {
      openNotification(
        "error",
        `Erro ${error?.response?.data.status} - ${error?.response?.data.title}!`,
        "Erro: " + error.response?.data?.detail
      );
    } finally {
      handleCloseDialogModal();
    }
  };

  const handleOpenDialogModalAssociate = useCallback(
    (permission) => {
      setModalSubtitle(
        `Deseja associar a permissão "${permission.nome}" ao grupo ${group.nome}?`
      );

      setModalDialog(
        <span>
          Caso a associação seja confirmada, todos os usuários do grupo
          <strong> {group.nome} </strong>
          receberão a permissão de
          <strong> {permission.nome}.</strong>
        </span>
      );

      setOpenDialogModal(true);
      setPermissionSelect(permission);
    },
    [group]
  );

  const handleOpenDialogModalDisassociate = useCallback(
    (permission) => {
      setModalSubtitle(
        `Deseja desassociar a permissão ${permission.nome} do grupo ${group.nome}?`
      );

      setModalDialog(
        <span>
          Caso a desassociação seja confirmada, <strong>{group.nome} </strong>
          perderá acesso a todas as permissões do grupo
          <strong> {permission.nome}</strong>
        </span>
      );

      setOpenDialogModal(true);
      setPermissionSelect(permission);
    },
    [group]
  );

  const finalize = (grupo) => {
    onCreate && createGroup(grupo);
    onEdit && atualizarGrupo(grupo);
  };

  const columns = [
    {
      title: <strong>permissão</strong>,
      dataIndex: "nome",
      key: "nome",
      ...SearchColumn("nome"),
    },
    {
      title: <strong>descrição</strong>,
      dataIndex: "descricao",
      key: "descrição",
    },
    {
      title: <strong>associado</strong>,
      width: 32,
      key: "associado",
      render: ({ codigo, associado, nome }) => (
        <Switch
          title="Clique para mudar essa propriedade"
          checkedChildren="sim"
          unCheckedChildren="não"
          checked={associado}
          key={codigo}
          onClick={() => {
            if (onCreate) {
              //toggleGroup({ codigo, nome });
            } else {
              setHasAssociate(associado);
              !associado
                ? handleOpenDialogModalAssociate({ codigo, nome })
                : handleOpenDialogModalDisassociate({ codigo, nome });
            }
          }}
        />
      ),
    },
  ];

  function showTotal(total) {
    return (
      <>
        Total de <strong>{total}</strong> permissões
      </>
    );
  }

  const renderForm = () => (
    <Form
      form={form}
      name="grupo"
      onFinish={finalize}
      layout="vertical"
    >
      <Row gutter={32}>
        <Col span={24}>
          <Form.Item
            label={<span>Nome:</span>}
            name="nome"
            hasFeedback={!onView}
            rules={
              !onView && [
                { required: true, message: "Este campo é obrigatório!" },
              ]
            }
            onChange={() => setHasUpdate(true)}
          >
            <Input placeholder="Insira o nome do grupo" disabled={onView} />
          </Form.Item>
        </Col>
        {!onCreate && (
          <Col span={24}>
            <Form.Item
              label={<span>Permissões: </span>}
              rules={
                !onView && [
                  {
                    required: true,
                    message:
                      "É necessário inserir pelo menos um grupo para o usuário!",
                  },
                ]
              }
            >
              {onView && (
                <Col
                  style={{
                    border: "1px solid var(--border-color)",
                    borderRadius: "3px",
                    padding: "10px",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  {group?.permissoes?.map((permission) => (
                    <Tooltip
                      title={permission.descricao}
                      color="#ce7934"
                      key={permission.codigo}
                    >
                      <Tag
                        style={{
                          cursor: "pointer",
                        }}
                        key={permission.codigo}
                        color="#ce7934"
                      >
                        {permission.nome}
                      </Tag>
                    </Tooltip>
                  ))}
                </Col>
              )}

              {onEdit && (
                <Table
                  style={{
                    border: "1px solid var(--border-color)",
                    borderRadius: "3px",
                    padding: "10px",
                  }}
                  dataSource={permissionsList
                    .map((permission) => ({
                      ...permission,
                      associado: group?.permissoes.some(
                        ({ codigo }) => codigo === permission.codigo
                      ),
                    }))
                    .sort((x, y) =>
                      x.associado === y.associado ? 0 : x.associado ? -1 : 1
                    )}
                  columns={columns}
                  pagination={{
                    size: "small",
                    showSizeChanger: true,
                    pageSizeOptions: OPCOES_TAMANHO_PAGINA,
                    defaultPageSize: OPCOES_TAMANHO_PAGINA[0],
                    showTotal: showTotal,
                  }}
                  rowKey={({ codigo }) => codigo}
                />
              )}
            </Form.Item>
          </Col>
        )}
      </Row>

      <Row justify="space-between">
        <Form.Item>
          <Link to={urls.GRUPOS_LIST} className="button-link">
            <Button type="primary" ghost>
              {(onView || onEdit) && <span>voltar</span>}
              {!onView && !onEdit && <span>cancelar</span>}
            </Button>
          </Link>
        </Form.Item>
        {!onView && hasUpdate && (
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {onEdit ? (
                <span>salvar edições</span>
              ) : (
                <span>cadastrar grupo</span>
              )}
            </Button>
          </Form.Item>
        )}
      </Row>
    </Form>
  );

  return (
    <div id="sectionMain">
      <Breadcrumb id="breadcrumb">
        <Breadcrumb.Item key="home">
          <Link to="">
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="gerenciamento">
          controle de acesso
        </Breadcrumb.Item>
        <Breadcrumb.Item key="grupos">
          <Link to={urls.GRUPOS_LIST}>grupos</Link>
        </Breadcrumb.Item>

        {onCreate && (
          <Breadcrumb.Item key="criar">
            <strong>cadastrar grupo</strong>
          </Breadcrumb.Item>
        )}

        {onView && (
          <Breadcrumb.Item key="grupo">
            <strong>{group?.nome?.toLowerCase()}</strong>
          </Breadcrumb.Item>
        )}

        {onEdit && (
          <>
            <Breadcrumb.Item key="grupo">
              <Link to={urls.GRUPOS_DETAILS.replace(":codigo", group?.codigo)}>
                {group?.nome?.toLowerCase()}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="editar">
              <strong>editar grupo</strong>
            </Breadcrumb.Item>
          </>
        )}
      </Breadcrumb>

      <div className="container-section">
        <div id="header-container">
          <div>
            <h2>{onCreate ? "CADASTRAR GRUPO" : group?.nome}</h2>
            <span>Informações sobre o grupo</span>
          </div>

          <div className="header-container">
            {onView && (
              <Link to={urls.GRUPOS_EDITAR.replace(":codigo", codigo)}>
                <Button type="primary" >editar grupo</Button>
              </Link>
            )}
          </div>
        </div>

        {renderForm()}
      </div>

      {openDialogModal && (
        <DialogModal
          title="ATENÇÃO!"
          Subtitle={modalSubtitle}
          dialog={modalDialog}
          handle_Function={hasAssociate ? removePermission : addPermission}
          onClose={handleCloseDialogModal}
        />
      )}
    </div>
  );
};

export default Group;
