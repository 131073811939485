import React, { useCallback, useEffect, useState } from "react";
import { Form, Input, Row, Col, Modal } from "antd";

import { Container } from "./styles";
import { openNotification } from "../../../../../../components/Notification";
import { useWidth } from "../../../../../../hooks";
import { canalService } from "../../../../../../services/resources/canalService";
const { TextArea } = Input;

function ModalChannel({
  onClose,
  mode,
  codigo,
  getCanais,
}) {
  const { width } = useWidth();
  const [form] = Form.useForm();

  const [canal, setCanal] = useState([]);

  const [sigla, setSigla] = useState(canal?.sigla || "");
  const [nome, setNome] = useState(canal?.nome || "");
  const [email, setEmail] = useState(canal?.email || "");
  const [descricao, setDescricao] = useState(canal?.descricao || "");

  const [disabled, setDisabled] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    async function getCanal() {
      await canalService
        .get(codigo)
        .then(({ data }) => {
          setCanal(data);
          setNome(data?.nome);
          setEmail(data?.email);
          setSigla(data?.sigla);
          setDescricao(data?.descricao);
          form.setFieldsValue({
            sigla: data?.sigla,
            nome: data?.nome,
            email: data?.email,
            descricao: data?.descricao,
          });
        })
        .catch((reason) => {
          openNotification("error", <strong>Erro!</strong>, '' + reason);
        });
    }
    if (mode !== "onCreate") {
      getCanal();
    }
  }, [codigo, mode, form]);

  async function handleSubmit() {
    let canalInput = ({
      descricao: descricao,
      nome: nome,
      email: email,
      sigla: sigla,
    });

    if (mode === "onCreate") {
      setConfirmLoading(true);
      setDisabled(true);
      try {
        await canalService.create(canalInput);
        handleCloseModal();
        openNotification("success", <strong>Canal criado!</strong>);
        getCanais();
      } catch (reason) {
        handleCloseModal();
        openNotification("error", <strong>Não foi possível criar o canal!</strong>, 'Erro: ' + reason);
      } finally {
        setConfirmLoading(false);
        setDisabled(false);
      }
    } else if (mode === "onEdit") {
      try {
        setConfirmLoading(true);
        setDisabled(true);
        await canalService.edit(codigo, canalInput);
        handleCloseModal();
        openNotification("success", <strong>Canal editado!</strong>);
        getCanais();
      } catch (reason) {
        handleCloseModal();
        openNotification("error", <strong>Não foi possível editar o canal!</strong>, 'Erro: ' + reason);
      } finally {
        setConfirmLoading(false);
        setDisabled(false);
      }
    }
  }

  return (
    <Modal
      title={
        (mode === "onCreate" && "NOVO CANAL") ||
        (mode === "onEdit" && "EDITAR CANAL") ||
        (mode === "onView" && "DETALHES DO CANAL")
      }
      width={width > 1080 ? "30vw" : "100vw"}
      onOk={mode !== "onView" && handleSubmit}
      confirmLoading={confirmLoading}
      onCancel={onClose}
      visible
      centered
    >
      <Container>
        <Form form={form} onFinish={handleSubmit} name="channel" layout="vertical">
          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 32 }}>
              <Form.Item
                name="sigla"

                label={<span>Sigla:</span>}
              >
                <Input
                  placeholder="Digite a sigla do canal"
                  value={sigla}
                  onChange={(e) => setSigla(e.target.value)}
                  disabled={mode === "onView"}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 32 }}>
              <Form.Item
                name="nome"
                label={<span>Nome:</span>}
                rules={[
                  { required: true, message: "Este campo é obrigatório!" },
                ]}
              >
                <Input
                  placeholder="Digite o nome do canal"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  disabled={mode === "onView"}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 32 }}>
              <Form.Item
                name="email"
                label={<span>Email:</span>}
              >
                <Input
                  placeholder="Digite o email do canal"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={mode === "onView"}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="descricao"

                label={<span>Descrição:</span>}
              >
                <TextArea
                  rows={4}
                  placeholder="Digite a descrição do canal"
                  value={descricao}
                  defaultValue={descricao}
                  onChange={(e) => setDescricao(e.target.value)}
                  disabled={mode === "onView" || disabled}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Container>
    </Modal>
  );
}

export default ModalChannel;
