import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  
  >Form {
    width: 100%;
    > div {
      display: flex;
      justify-content: space-between;
    }
  }
`;