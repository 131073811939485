import {
  DownOutlined,
  HomeOutlined,
  LogoutOutlined,
  UnlockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Layout, Menu as AntMenu, Row } from "antd";
import React, { useCallback, useContext, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { useWidth } from "../../hooks";
import oisolCollapsed from "../../images/logos/oisol@1x1.svg";
import oisol from "../../images/logos/oisol_modelo_DH.png";
import sps from "../../images/logos/logo-gov.png";
import ModalNewPass from "../AlterarSenha";
import { Menu } from "../Menu/index";
import { ResponsiveMenu } from "../ResponsiveMenu";
import Versao from "../Versao";

const { Header, Sider } = Layout;

export const Container = ({ children }) => {
  const { user, userLogout } = useContext(UserContext);
  const { width } = useWidth();
  const [collapsed, setCollapsed] = useState(false);

  const [openModalNewPass, setOpenModalNewPass] = useState(false);

  const handleOpenModalNewPass = useCallback(() => {
    setOpenModalNewPass(true);
  }, []);

  const handleCloseModalNewPass = useCallback(() => {
    setOpenModalNewPass(false);
  }, []);

  return (
    <Layout style={{ height: "100vh" }}>
      {width < 900 ? (
        <ResponsiveMenu />
      ) : (
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          breakpoint="lg"
          style={{
            backgroundColor: "#f2f2f2",
            boxShadow: "5px 0 13px 3px rgb(10 10 10 / 10%)",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "1rem",
              height: "5rem",
              backgroundColor: "#f2f2f2",
            }}
          >
            <a
              href={`${process.env.PUBLIC_URL}/`}
              style={{ display: "flex", gap: "4px" }}
            >
              {collapsed && (
                <img
                  src={oisolCollapsed}
                  alt="oi sol - Observatório de indicadores sociais"
                  style={{ width: "100%", maxWidth: "56px", maxHeight: "4rem" }}
                />
              )}
              {!collapsed && (
                <img
                  src={oisol}
                  alt="oi sol - Observatório de indicadores sociais"
                  style={{
                    width: "100%",
                    maxWidth: "150px",
                    maxHeight: "3.75rem",
                  }}
                />
              )}
            </a>
          </div>
          <Menu />
        </Sider>
      )}
      <Layout style={{ backgroundColor: "#dcdcdc" }}>
        {process.env.REACT_APP_ENV !== "production" &&
          <div style={{ backgroundColor: "var(--red-color)", color: "white", textAlign: "center", fontWeight: "600" }}>
            Ambiente de homologação
          </div>
        }
        <Header
          style={{
            padding: 0,
            height: "5rem",
            backgroundColor: "#f2f2f2",
            borderBottom: ".5rem solid var(--main-color)",
            display: "flex",
            justifyContent: width >= 900 ? "start" : "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          {width >= 900 ? (
            <Row
              justify="space-between"
              align="middle"
              style={{ width: "100%" }}
            >
              <Row>
                <a
                  href={`${process.env.PUBLIC_URL}/`}
                  style={{ display: "flex", gap: "4px" }}
                >
                  <img
                    src={sps}
                    alt="Secretaria da Proteção Social, Justiça, Cidadania, Mulheres e Direitos Humanos (SPS)"
                    style={{ width: "100%", maxWidth: "160px" }}
                  />
                </a>
              </Row>

              <Dropdown
                placement="bottomRight"
                trigger={["hover", "click"]}
                overlay={
                  <AntMenu
                    items={[
                      {
                        label: (
                          <Button
                            style={{ textAlign: "start", fontSize: "0.95rem" }}
                            block
                            type="text"
                          >
                            <HomeOutlined /> {user?.nome_instituicao}
                          </Button>
                        ),
                        key: "0",
                      },
                      {
                        label: (
                          <Button
                            style={{ textAlign: "start", fontSize: "1rem" }}
                            block
                            type="text"
                            onClick={handleOpenModalNewPass}
                          >
                            <UnlockOutlined /> alterar senha
                          </Button>
                        ),
                        key: "0",
                      },
                      {
                        label: (
                          <Button
                            style={{ textAlign: "start", fontSize: "1rem" }}
                            block
                            type="text"
                            danger
                            onClick={userLogout}
                          >
                            <LogoutOutlined />
                            sair
                          </Button>
                        ),
                        key: "1",
                      },
                    ]}
                  />
                }
              >
                <Button
                  style={{
                    height: "100%",
                    fontSize: "14px",
                    border: "none",
                    background: "none",
                    position: "absolute",
                    right: "2rem",
                    marginTop: 12,
                  }}
                  onClick={(e) => e.preventDefault()}
                >
                  <UserOutlined style={{ fontSize: "1rem" }} /> Olá,{" "}
                  <strong> {user?.nome_completo} </strong>
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Row>
          ) : (
            <a
              href={`${process.env.PUBLIC_URL}/`}
              style={{ display: "flex", gap: "4px" }}
            >
              <img
                style={{ width: "100%", maxWidth: "160px" }}
                src={sps}
                alt="Secretaria da Proteção Social, Justiça, Cidadania, Mulheres e Direitos Humanos (SPS)"
              />
            </a>
          )}
        </Header>
        <div id="content">{children}</div>
        <ModalNewPass
          onClose={handleCloseModalNewPass}
          visible={openModalNewPass}
        />
      </Layout>
      {width >= 900 && (
        <Versao
          style={{
            position: "absolute",
            bottom: "0",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
    </Layout>
  );
};
