import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { urls } from "../../../utils";
import { UsuariosPaginacao } from "../components/UsuariosPaginacao";

export const Listagem = () => {
  return (
    <div id="sectionMain">
      <Breadcrumb id="breadcrumb">
        <Breadcrumb.Item key="home">
          <Link to="">
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="controle">controle de acesso</Breadcrumb.Item>
        <Breadcrumb.Item key="bairros">
          <Link to={urls.USUARIOS_LIST}>
            <strong>usuários</strong>
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className="container-section">
        <div id="header-container">
          <h2>LISTA DE USUÁRIOS</h2>
          <div id="header-actions">
            <Link to={urls.USUARIOS_CRIAR}>
              <Button type="primary">novo usuário</Button>
            </Link>
          </div>
        </div>
        <UsuariosPaginacao />
      </div>
    </div>
  );
};