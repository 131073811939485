import {
  AppstoreOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FilterOutlined,
  SearchOutlined,
  TableOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Collapse,
  Empty,
  Form,
  Input,
  Modal,
  Pagination,
  Radio,
  Row,
  Skeleton,
  Space,
  Table,
  Tag,
} from "antd";
import Meta from "antd/lib/card/Meta";
import { useContext, useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { useHistory } from "react-router-dom";
import { mask, unMask } from "remask";
import { openNotification } from "../../../../components/Notification";
import { useWidth } from "../../../../hooks/useWidth";
import { envolvidoService } from "../../../../services/resources/envolvidoService";
import { pessoaService } from "../../../../services/resources/pessoaService";
import {
  isEmpty,
  OPCOES_TAMANHO_PAGINA,
  parsedSort,
  PESSOAS_TAG_FILTER,
  removeByProp,
  removeEmpty,
  urls,
  USER_ROLES,
} from "../../../../utils";
import { UserContext } from "../../../../contexts/UserContext";

export const PessoaPaginada = ({ tableActions = null, cardActions = null }) => {
  const { user } = useContext(UserContext);

  const [form] = Form.useForm();
  const formAvancado = form;

  const history = useHistory();
  const { width } = useWidth();

  const [pessoas, setPessoas] = useState([]);

  const [loading, setLoading] = useState(false);

  const [showFilters, setShowFilters] = useState(false);
  const [isTableView, setIsTableView] = useState(true);

  const cpfPatterns = ["999.999.999-99"];

  const [filter, setFilter] = useState({
    ativo: true,
    page: 0,
    size: OPCOES_TAMANHO_PAGINA[0],
  });

  const [paginacao, setPaginacao] = useState({
    currentPage: 0,
    totalElements: 0,
    size: OPCOES_TAMANHO_PAGINA[0],
  });

  const columns = [
    {
      title: <strong>nome</strong>,
      dataIndex: "nome",
      key: "nome",
      sorter: {
        multiple: 1,
      },
      render: (text) => (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ff9945",
            padding: 0,
            color: "#fff",
          }}
          searchWords={[filter?.nome]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },

    {
      title: <strong>endereço</strong>,
      dataIndex: ["endereco", "logradouro"],
      key: "endereco.logradouro",
      sorter: {
        multiple: 1,
      },
      render: (text) => (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ff9945",
            padding: 0,
            color: "#fff",
          }}
          searchWords={[filter?.enderecoLogradouro]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },
    {
      title: <strong>bairro</strong>,
      dataIndex: ["endereco", "bairro", "nome"],
      key: "endereco.bairro.nome",
      sorter: {
        multiple: 1,
      },
      render: (text) => (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ff9945",
            padding: 0,
            color: "#fff",
          }}
          searchWords={[filter?.bairroNome]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },
    {
      title: <strong>cidade</strong>,
      dataIndex: ["endereco", "bairro", "cidade", "nome"],
      key: "endereco.bairro.cidade.nome",
      sorter: {
        multiple: 1,
      },
      render: (text) => (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ff9945",
            padding: 0,
            color: "#fff",
          }}
          searchWords={[filter?.cidadeNome]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },
    {
      title: <strong>ações</strong>,
      key: "action",
      fixed: "right",
      width: 128,
      render: tableActions
        ? tableActions
        : ({ nome, codigo }) => (
            <Space size="small">
              {(user?.authorities?.includes(USER_ROLES.CONSULTAR_DETALHE_PESSOA) || user?.authorities?.includes(USER_ROLES.GERENCIAR_PESSOA))
                && (
                    <Button
                      type="primary"
                      ghost
                      className="action-button"
                      title={`Visualizar indivíduo ${nome}`}
                      onClick={() =>
                        history.push(urls.PESSOAS_DETAILS.replace(":codigo", codigo))
                      }
                    >
                      <EyeOutlined style={{ fontSize: "1.2rem" }} />
                    </Button>
              )}

              {(user?.authorities?.includes(USER_ROLES.ALTERAR_PESSOA) || user?.authorities?.includes(USER_ROLES.GERENCIAR_PESSOA))
                && (
                  <Button
                    type="primary"
                    className="action-button"
                    title={`Editar indivíduo ${nome}`}
                    onClick={() =>
                      history.push(urls.PESSOAS_EDITAR.replace(":codigo", codigo))
                    }
                  >
                    <EditOutlined style={{ fontSize: "1.2rem" }} />
                  </Button>
              )}

              {(user?.authorities?.includes(USER_ROLES.DELETAR_PESSOA) || user?.authorities?.includes(USER_ROLES.GERENCIAR_PESSOA))
                && (
                  <Button
                    className="action-button"
                    type="danger"
                    title={`Excluir indivíduo ${nome}`}
                    onClick={() => warningDelete({ nome, codigo })}
                  >
                    <DeleteOutlined style={{ fontSize: "1.2rem" }} />
                  </Button>
              )}
            </Space>
          ),
    },
  ];

  const tagsNotShow = ["ativo", "page", "size", "sort"];

  useEffect(() => {
    getPessoas(filter);

    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    width <= 768 && setIsTableView(false);

    // eslint-disable-next-line
  }, [width]);

  async function getPessoas(filter) {
    try {
      setLoading(true);
      const response = await pessoaService.list(removeEmpty(filter));
      setPessoas(response?.data?.content);
      setPaginacao({
        currentPage: response?.data?.currentPage,
        totalElements: response?.data?.totalElements,
        size: response?.data?.size,
      });
    } catch (error) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao buscar as pessoas!</strong>,
        "Erro: " + error?.response?.data?.detail
      );
    } finally {
      setLoading(false);
    }
  }

  async function handleDeletePessoa(codigo) {
    await envolvidoService
      .deleteInvolved(codigo)
      .then(() => {
        openNotification(
          "success",
          <strong>Cadastro de pessoa excluído!</strong>
        );
      })
      .catch((reason) => {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao excluir a pessoa selecionada!</strong>,
          "Erro: " + reason?.response?.data?.detail
        );
      });
  }

  function onChange(_, filters, sorter, { action }) {
    if (action === "sort") onSort(sorter);
    if (action === "filter") onFilter(filters);
  }

  function onSort(sorter) {
    if (sorter.constructor === Object) {
      if (sorter.column)
        setFilter({
          ...filter,
          sort: parsedSort(sorter.columnKey, sorter.order),
        });
      else setFilter({ ...filter, sort: [] });
    } else {
      setFilter({
        ...filter,
        sort: sorter.map((sort) => parsedSort(sort.columnKey, sort.order)),
      });
    }
  }

  function onFilter(filters) {
    setFilter({
      ...filter,
      ...filters,
      page: 0,
    });
  }

  function clearAll() {
    if (
      !isEmpty(removeEmpty(form.getFieldsValue())) &&
      !isEmpty(removeEmpty(formAvancado.getFieldsValue()))
    ) {
      setFilter({
        ativo: true,
        page: 0,
        size: OPCOES_TAMANHO_PAGINA[0],
      });

      form.resetFields();
      formAvancado.resetFields();
    }
  }

  function warningDelete({ codigo, nome }) {
    Modal.confirm({
      title: "ATENÇÃO!",
      content: (
        <p>
          Você tem certeza que deseja <strong>EXCLUIR</strong> a pessoa{" "}
          <strong>{nome}</strong>? Se confirmada, essa operação não poderá ser
          revertida.
        </p>
      ),
      onOk: () => handleDeletePessoa(codigo),
      okText: "excluir",
      cancelText: "cancelar",
      okButtonProps: { danger: true },
    });
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        {width > 768 && (
          <Col span={6}>
            <Form
              name="pessoa_filtro"
              layout="inline"
              form={form}
              onValuesChange={({ nome }) => onFilter({ nome })}
              onFinish={({ nome }) =>
                nome !== filter.nome && onFilter({ nome })
              }
            >
              <Col span={24}>
                <Form.Item name="nome">
                  <Input
                    prefix={<SearchOutlined />}
                    placeholder="Busque pelo nome da pessoa"
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Form>
          </Col>
        )}
        <Col>
          <Button type="primary" onClick={() => setShowFilters(true)}>
            filtros avançados
            <FilterOutlined />
          </Button>
        </Col>
        <Col>
          <Button onClick={clearAll}>limpar filtros</Button>
        </Col>
        {width > 768 && (
          <Col>
            <Radio.Group
              defaultValue={isTableView}
              onChange={({ target: { value } }) => setIsTableView(value)}
            >
              <Radio.Button value={true}>
                <TableOutlined />
              </Radio.Button>
              <Radio.Button value={false}>
                <AppstoreOutlined />
              </Radio.Button>
            </Radio.Group>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={24}>
          {Object.entries(removeEmpty(filter))
            .filter(([label]) => !tagsNotShow.includes(label))
            .map(([label, value]) => (
              <Tag
                color="var(--border-color)"
                closable
                key={label + value}
                onClose={() => {
                  onFilter(removeByProp(filter, label));
                  form.setFieldsValue({ [label]: null });
                  formAvancado.setFieldsValue({ [label]: null });
                }}
              >
                {(PESSOAS_TAG_FILTER[label] || label).toUpperCase() +
                  ": " +
                  value}
              </Tag>
            ))}
        </Col>
      </Row>
      {isTableView ? (
        <Row>
          <Col span={24}>
            <Table
              loading={loading}
              dataSource={pessoas}
              columns={columns}
              size="small"
              rowKey={({ codigo }) => codigo}
              onChange={onChange}
              totalSize={paginacao.totalElements}
              locale={{
                emptyText: <Empty description={<h2>Sem pessoas</h2>} />,
              }}
              pagination={false}
            />
          </Col>
        </Row>
      ) : (
        <Row gutter={16} justify="space-between">
          {pessoas.map((pessoa) => (
            <Col key={pessoa.codigo} xs={24} md={12} lg={8} xl={6}>
              <Card
                style={{ width: "100%", marginTop: 16 }}
                actions={
                  cardActions
                    ? [...cardActions(pessoa)]
                    : [
                        <Button
                          key="view"
                          visible={(user?.authorities?.includes(USER_ROLES.CONSULTAR_DETALHE_PESSOA) || user?.authorities?.includes(USER_ROLES.GERENCIAR_PESSOA))}
                          icon={
                            <EyeOutlined
                              style={{ fontSize: "1.2rem" }}
                              onClick={() =>
                                history.push(
                                  urls.PESSOAS_DETAILS.replace(
                                    ":codigo",
                                    pessoa.codigo
                                  )
                                )
                              }
                            />
                          }
                        />,
                        <Button
                          key="edit"
                          visible={(user?.authorities?.includes(USER_ROLES.ALTERAR_PESSOA) || user?.authorities?.includes(USER_ROLES.GERENCIAR_PESSOA))}
                          type="primary"
                          icon={
                            <EditOutlined
                              style={{ fontSize: "1.2rem" }}
                              onClick={() =>
                                history.push(
                                  urls.PESSOAS_EDITAR.replace(
                                    ":codigo",
                                    pessoa.codigo
                                  )
                                )
                              }
                            />
                          }
                        />,
                        <Button
                          key="delete"
                          visible={(user?.authorities?.includes(USER_ROLES.DELETAR_PESSOA) || user?.authorities?.includes(USER_ROLES.GERENCIAR_PESSOA))}
                          danger
                          icon={
                            <DeleteOutlined
                              onClick={() => warningDelete(pessoa)}
                            />
                          }
                        />,
                      ]
                }
              >
                <Skeleton loading={loading} avatar active>
                  <Meta
                    avatar={<Avatar>{pessoa?.nome?.charAt(0)}</Avatar>}
                    title={pessoa?.nome}
                    description={
                      <>
                        {[
                          pessoa.endereco?.logradouro,
                          pessoa.endereco?.numero,
                          pessoa.endereco?.bairro?.nome,
                        ]
                          .filter(Boolean)
                          ?.join(", ")}{" "}
                        -{" "}
                        <strong>{pessoa.endereco?.bairro?.cidade?.nome}</strong>
                      </>
                    }
                  />
                </Skeleton>
              </Card>
            </Col>
          ))}
        </Row>
      )}
      <Row justify="end">
        <Col>
          <Pagination
            size="small"
            showSizeChanger={true}
            simple={width < 768}
            pageSizeOptions={OPCOES_TAMANHO_PAGINA}
            defaultPageSize={paginacao.size}
            total={paginacao.totalElements}
            showTotal={(total) => <strong>{total} pessoas no total</strong>}
            onChange={(page, size) => {
              setFilter({
                ...filter,
                page: filter.size !== size ? 0 : page - 1,
                size,
              });
            }}
            current={paginacao.currentPage + 1}
          />
        </Col>
      </Row>

      <Modal
        title="Selecionar filtros"
        visible={showFilters}
        onCancel={() => {
          setShowFilters(false);
        }}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setShowFilters(false);
              onFilter(formAvancado.getFieldsValue());
            }}
          >
            aplicar filtros
          </Button>,
        ]}
      >
        <Form name="pessoa_filtro" layout="vertical" form={formAvancado}>
          <Collapse ghost defaultActiveKey={["1", "2", "3", "4"]}>
            <Collapse.Panel header="Dados pessoais" key="1">
              <Col span={24}>
                <Form.Item name="nome" label="nome">
                  <Input placeholder="Busque pelo nome da pessoa" allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="cpf" label="cpf">
                  <Input
                    placeholder="Busque pelo cpf da pessoa"
                    allowClear
                    onChange={({ target: { value } }) => {
                      form.setFieldsValue({
                        cpf: mask(unMask(value), cpfPatterns),
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="rg" label="rg">
                  <Input placeholder="Busque pelo rg da pessoa" allowClear />
                </Form.Item>
              </Col>
            </Collapse.Panel>
            <Collapse.Panel header="Endereço" key="2">
              <Col span={24}>
                <Form.Item name="enderecoLogradouro" label="logradouro">
                  <Input
                    placeholder="Busque pelo logradouro da pessoa"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="bairroNome" label="bairro">
                  <Input
                    placeholder="Busque pelo bairro da pessoa"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="cidadeNome" label="cidade">
                  <Input
                    placeholder="Busque pela cidade da pessoa"
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Collapse.Panel>
          </Collapse>
        </Form>
      </Modal>
    </>
  );
};
