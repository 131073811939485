import api from "../api";

export const frequenciaService = {
    async loadFrequencias() {
      return await api.get("/frequencias");
    },
    async getAllFrequencias() {
      return await api.get("/publico/frequencias");
    },
    async get(codigo) {
      return await api.get(`/frequencias/${codigo}`);
    },
    async create(payload) {
      return await api.post(`/frequencias`, payload);
    },
    async edit(codigo, payload) {
      return await api.put(`/frequencias/${codigo}`, payload);
    },
    async delete(codigo) {
      return await api.delete(`/frequencias/${codigo}`);
    },
  }
