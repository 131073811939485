import {
  AppstoreOutlined,
  CalendarOutlined,
  CopyrightOutlined,
  EyeOutlined,
  FileSearchOutlined,
  FilterOutlined,
  InfoCircleOutlined,
  SearchOutlined,
  TableOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Empty,
  Form,
  Input,
  Modal,
  Pagination,
  Radio,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import Meta from "antd/lib/card/Meta";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { useHistory } from "react-router-dom";

import { openNotification } from "../../../../components/Notification";
import { UserContext } from "../../../../contexts/UserContext";
import { useWidth } from "../../../../hooks";
import { useQuery } from "../../../../hooks/useQuery";
import { encaminhamentoService } from "../../../../services/resources/encaminhamentoService";
import { enderecoService } from "../../../../services/resources/enderecoService";
import {
  ENCAMINHAMENTOS_TAG_FILTER,
  isEmpty,
  OPCOES_TAMANHO_PAGINA,
  removeByProp,
  removeEmpty,
  STATUS_COLORS,
  urls,
  USER_ROLES,
} from "../../../../utils";
import { labelTags } from "../../../../utils/dicionario";

export const TabelaEncaminhamentos = ({ recebidos, enviados }) => {
  const [form] = Form.useForm();

  const formAvancado = form;

  const { width } = useWidth();
  const history = useHistory();
  const query = useQuery();
  const { user, status } = useContext(UserContext);

  const [cidadeSelect, setCidadeSelect] = useState("");

  const [isTableView, setIsTableView] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const [encaminhamentos, setEncaminhamentos] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [bairros, setBairros] = useState([]);

  const [filter, setFilter] = useState({
    ativo: true,
    page: 0,
    size: OPCOES_TAMANHO_PAGINA[0],
    ...(recebidos && {
      codigoInstituicaoDestinataria: user.codigo_instituicao,
    }),
    ...(enviados && {
      codigoInstituicaoRemetente: enviados && user.codigo_instituicao,
    }),
  });

  const [paginacao, setPaginacao] = useState({
    currentPage: 0,
    totalElements: 0,
    size: OPCOES_TAMANHO_PAGINA[0],
  });

  const columns = [
    {
      title: <strong>status</strong>,
      dataIndex: "statusDescricao",
      key: "status",
      render: (status) => (
        <Badge
          style={{
            backgroundColor: STATUS_COLORS[status],
            color: "#333333",
            fontWeight: "bold",
          }}
          count={status}
        />
      ),
    },
    {
      title: <strong>protocolo</strong>,
      dataIndex: "denunciaIdentificadorExterno",
      key: "protocolo",
      render: (text) => (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ff9945",
            padding: 0,
            color: "#fff",
          }}
          searchWords={[filter?.identificadorExterno]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },
    {
      title: <strong>{recebidos ? "remetente" : "destinatário"}</strong>,
      dataIndex: recebidos
        ? "instituicaoRemetenteNome"
        : "instituicaoDestinatariaNome",
      key: "instituicao",
    },
    {
      title: <strong>{"cidade"}</strong>,
      dataIndex: recebidos
        ? "denunciaMunicipio"
        : "denunciaMunicipio",
      key: "denunciaMunicipio",
      render: (text) => (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ff9945",
            padding: 0,
            color: "#fff",
          }}
          searchWords={[filter?.denunciaMunicipio]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },
    {
      title: <strong>início</strong>,
      dataIndex: "dataInicio",
      key: "dataInicio",
      render: (text) => (text ? moment(text).format("DD/MM/yyyy") : ""),
    },
    {
      title: <strong>prazo</strong>,
      dataIndex: "dataPrevistaConclusao",
      key: "prazo",
      render: (text) =>
        text ? (
          moment(text).isAfter(moment()) ? (
            `${moment(text).format("DD/MM/yyyy")}`
          ) : (
            <Typography.Text strong type="danger">
              {moment(text).format("DD/MM/yyyy")}
            </Typography.Text>
          )
        ) : (
          <Typography.Text strong>indefinido</Typography.Text>
        ),
    },
    {
      title: <strong>ações</strong>,
      key: "action",
      width: "1rem",
      render: ({ denunciaCodigo, denunciaIdentificadorExterno, codigo }) => (
        <Space size="small" style={{ display: "flex", justifyContent: "end" }}>
          {((user?.authorities?.includes(USER_ROLES.CONSULTAR_ENCAMINHAMENTOS_INSTITUICAO)))
            && (
              <Button
                className="action-button"
                type="primary"
                ghost
                onClick={(event) => {
                  event.stopPropagation();
                  history.push(
                    urls.ENCAMINHAMENTO_DETAILS.replace(":codigo", codigo)
                  );
                }}
                title={`Visualizar encaminhamento da denúncia ${denunciaIdentificadorExterno}`}
              >
                <EyeOutlined style={{ fontSize: "1.2rem" }} />
              </Button>
          )}
          {((user?.authorities?.includes(USER_ROLES.CONSULTAR_DETALHE_DENUNCIA)))
            && (
              <Button
                className="action-button"
                type="primary"
                onClick={(event) => {
                  event.stopPropagation();
                  history.push(
                    urls.DENUNCIA_DETAILS.replace(":codigo", denunciaCodigo)
                  );
                }}
                title={`Visualizar denúncia ${denunciaIdentificadorExterno}`}
              >
                <FileSearchOutlined style={{ fontSize: "1.2rem" }} />
              </Button>
          )}
        </Space>
      ),
    },
  ];

  const getAllEncaminhamentos = async (params) => {
    setLoading(true);
    try {
      const response = await encaminhamentoService.getAllEncaminhamentos(
        removeEmpty(params)
      );
      const { content } = response.data;

      setEncaminhamentos(content);
      setPaginacao({
        currentPage: response?.data?.currentPage,
        totalElements: response?.data?.totalElements,
        size: response?.data?.size,
      });
    } catch (reason) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao buscar os dados da denúncia!</strong>,
        reason?.response?.data?.detail
      );
    } finally {
      setLoading(false);
    }
  };

  async function getCidades(estado) {
    await enderecoService
      .getCitysState(estado)
      .then((response) => {
        setCidades(response.data);
      })
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao carregar as cidades!</strong>,
          "Erro: " + reason?.response?.data?.userMessage
        )
      );
  }

  async function getDistricts(city_id) {
    (await city_id) &&
      enderecoService
        .getDistrictsCitys(city_id)
        .then((response) => {
          setBairros(response.data);
        })
        .catch((reason) =>
          openNotification(
            "error",
            <strong>Ocorreu um erro ao carregar as bairros!</strong>,
            "" + reason
          )
        );
  }

  function onFilter(filters) {
    if (filters?.statusList?.includes("EM ANDAMENTO"))
      filters.statusList.push("A VALIDAR");
    setFilter({
      ...filter,
      ...filters,
      page: 0,
    });
  }

  function convertValuesTags(value) {

    const valueCidade = cidades.find(
      (item) => item.id === value
    )?.nome;
    if (valueCidade) return valueCidade?.toUpperCase();

    return value;
  }

  function convertLabelsTags(label) {
    return labelTags[label];
  }

  function clearAll() {

    if (
      !isEmpty(removeEmpty(form.getFieldsValue())) &&
      !isEmpty(removeEmpty(formAvancado.getFieldsValue()))
    ) {
      setFilter({
        ativo: true,
        page: 0,
        size: OPCOES_TAMANHO_PAGINA[0],
        codigoInstituicaoDestinataria: user.codigo_instituicao,
      });

      form.resetFields();
      formAvancado.resetFields();
      history.push(urls.ENCAMINHAMENTOS_RECEBIDOS);
    }
  }

  async function handleSelectCity(value, mantemBairros) {
    setCidadeSelect(value);
    if (!mantemBairros) {
      form.setFieldsValue({ endereco: { bairro: { id: null } } });
      setBairros([]);
    }
    value && (await getDistricts(value));
  }

  const tagsNotShow = [
    "ativo",
    "page",
    "size",
    "sort",
    "codigoInstituicaoDestinataria",
    "codigoInstituicaoRemetente",
    // "cidadeIdList"
  ];

  useEffect(() => {
    const queries = query.getAll("status");

    getDistricts(filter.cidadeIdList);
    getCidades(1);

    try {
      if (queries.length) {
        formAvancado.setFieldsValue("statusList", queries);
        getAllEncaminhamentos({ ...filter, statusList: queries });
      } else getAllEncaminhamentos(filter);
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, query]);

  useEffect(() => {
    width <= 768 && setIsTableView(false);

    // eslint-disable-next-line
  }, [width]);

  return (
    <>
      <Row gutter={[16, 16]}>
        {width > 768 && (
          <Col span={6}>
            <Form
              name="encaminhamento_filtro"
              layout="inline"
              form={form}
              onValuesChange={({ identificadorExterno }) =>
                onFilter({ identificadorExterno })
              }
              onFinish={({ nome }) =>
                nome !== filter.nome && onFilter({ nome })
              }
            >
              <Col span={24}>
                <Form.Item name="identificadorExterno">
                  <Input
                    prefix={<SearchOutlined />}
                    placeholder="Busque pelo protocolo da denúncia"
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Form>
          </Col>
        )}
        <Col>
          <Button type="primary" onClick={() => setShowFilters(true)}>
            filtros avançados
            <FilterOutlined />
          </Button>
        </Col>
        <Col>
          <Button onClick={clearAll}>limpar filtros</Button>
        </Col>
        {width > 768 && (
          <Col>
            <Radio.Group
              defaultValue={isTableView}
              onChange={({ target: { value } }) => setIsTableView(value)}
            >
              <Radio.Button value={true}>
                <TableOutlined />
              </Radio.Button>
              <Radio.Button value={false}>
                <AppstoreOutlined />
              </Radio.Button>
            </Radio.Group>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={24}>
          {Object.entries(removeEmpty(filter))
            .filter(([label]) => !tagsNotShow.includes(label))
            .map(([label, value]) => (
              <Tag
                color="var(--border-color)"
                closable
                key={label + value}
                onClose={() => {
                  onFilter(removeByProp(filter, label));
                  form.setFieldsValue({ [label]: null });
                  formAvancado.setFieldsValue({ [label]: null });
                }}
              >
                {(ENCAMINHAMENTOS_TAG_FILTER[label] || convertLabelsTags(label))?.toUpperCase() +
                  ": " +
                  convertValuesTags(value, label)}
              </Tag>
            ))}
        </Col>
      </Row>
      <Col span={24}>
        {isTableView ? (
          <Table
            loading={loading}
            dataSource={encaminhamentos}
            columns={encaminhamentos?.length && columns}
            pagination={false}
            size="small"
            rowKey={({ codigo }) => codigo}
            locale={{
              emptyText: <Empty description={<h2>Sem encaminhamentos</h2>} />,
            }}
          />
        ) : (
          <Row gutter={16} justify="space-between">
            {encaminhamentos.map((encaminhamento) => (
              <Col key={encaminhamento?.codigo} xs={24} md={12} lg={8} xl={5}>
                <Card
                  key={encaminhamento?.codigo}
                  style={{ width: "100%", marginTop: 16 }}
                  hoverable
                  actions={[
                    <Row justify="space-around">
                      {(user?.authorities?.includes(USER_ROLES.CONSULTAR_ENCAMINHAMENTOS_INSTITUICAO))
                        && (
                          <Button
                            className="action-button"
                            type="primary"
                            ghost
                            icon={
                              <EyeOutlined
                                style={{ fontSize: "1.2rem" }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  history.push(
                                    urls.ENCAMINHAMENTO_DETAILS.replace(
                                      ":codigo",
                                      encaminhamento?.denunciaCodigo
                                    )
                                  );
                                }}
                              />
                            }
                            title={`Visualizar encaminhamento da denúncia ${encaminhamento?.denunciaIdentificadorExterno}`}
                          />
                      )}
                      ,
                      {(user?.authorities?.includes(USER_ROLES.CONSULTAR_DETALHE_DENUNCIA))
                        && (
                          <Button
                            className="action-button"
                            type="primary"
                            icon={
                              <FileSearchOutlined
                                style={{ fontSize: "1.2rem" }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  history.push(
                                    urls.DENUNCIA_DETAILS.replace(
                                      ":codigo",
                                      encaminhamento?.denunciaCodigo
                                    )
                                  );
                                }}
                              />
                            }
                            title={`Visualizar denúncia ${encaminhamento?.denunciaIdentificadorExterno}`}
                          />
                      )}
                    </Row>,
                  ]}
                >
                  <Skeleton loading={loading} avatar active>
                    <Meta
                      description={
                        <>
                          <Row justify="space-between">
                            <Col>
                              <p>
                                <InfoCircleOutlined /> Protocolo:
                                <strong>
                                  {encaminhamento?.denunciaIdentificadorExterno}
                                </strong>
                              </p>
                              <p>
                                <CopyrightOutlined /> Canal:
                                <strong>
                                  {" "}
                                  {encaminhamento.denunciaCanalSigla}
                                </strong>
                              </p>
                            </Col>
                            <Badge
                              style={{
                                backgroundColor:
                                  STATUS_COLORS[
                                  encaminhamento?.statusDescricao
                                  ],
                                color: "#333333",
                                fontWeight: "bold",
                              }}
                              count={encaminhamento?.statusDescricao}
                            />
                          </Row>
                          <Divider />

                          <Row justify="space-between" align="center">
                            <p>
                              <CalendarOutlined /> Ínicio: {""}
                              {moment(encaminhamento?.dataInicio).format(
                                "DD/MM/yyyy"
                              )}
                            </p>
                            <p>
                              <CalendarOutlined /> Prazo: {""}
                              {moment(
                                encaminhamento?.dataPrevistaConclusao
                              ).format("DD/MM/yyyy")}
                            </p>
                          </Row>
                        </>
                      }
                    />
                  </Skeleton>
                </Card>
              </Col>
            ))}
          </Row>
        )}

        <Row justify="end">
          <Col style={{ marginTop: 20 }}>
            <Pagination
              size="small"
              showSizeChanger
              simple={width < 768}
              pageSizeOptions={OPCOES_TAMANHO_PAGINA}
              defaultPageSize={paginacao.size}
              total={paginacao.totalElements}
              showTotal={(total) => (
                <strong>{total} encaminhamentos no total</strong>
              )}
              onChange={(page, size) => {
                setFilter({
                  ...filter,
                  page: filter.size !== size ? 0 : page - 1,
                  size,
                });
              }}
              current={paginacao.currentPage + 1}
            />
          </Col>
        </Row>
      </Col>
      <Modal
        centered
        title="Selecionar filtros"
        visible={showFilters}
        onCancel={() => {
          setShowFilters(false);
        }}
        bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setShowFilters(false);

              onFilter(formAvancado.getFieldsValue());
            }}
          >
            aplicar filtros
          </Button>,
        ]}
      >
        <Form name="denuncia_filtro" layout="vertical" form={formAvancado}>
          <Collapse ghost defaultActiveKey={["1", "2", "3", "4"]}>
            <Collapse.Panel header="Dados das denúncias" key="1">
              <Col span={24}>
                <Form.Item name="statusList" label="status">
                  <Select
                    placeholder="Escolha um status da denúncia"
                    style={{ width: "100%" }}
                    mode="multiple"
                    options={status
                      ?.filter(
                        ({ descricao }) =>
                          descricao === "EM ABERTO" ||
                          descricao === "FINALIZADA" ||
                          descricao === "EM ANDAMENTO"
                      )
                      .map(({ descricao }) => ({
                        label: descricao,
                        value: descricao,
                      }))}
                    allowClear
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="identificadorExterno" label="protocolo">
                  <Input
                    placeholder="Busque pelo protocolo da denúncia"
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span={24}>

                <Form.Item
                  name={"cidadeIdList"}
                  label={<span>cidade:</span>}
                  validateStatus="validating"
                  hasFeedback={loading.cidades}
                >
                  <Select
                    placeholder="escolher município"
                    onChange={(cidade) => handleSelectCity(cidade, false)}
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    options={cidades?.map(({ nome, id }) => ({
                      label: nome,
                      value: id,
                      key: id
                    }))}
                    autoComplete="none"
                  />
                </Form.Item>

              </Col>
              <Col span={24}>
                <Form.Item
                  name={"denunciaBairroNome"}
                  label={<span>bairro:</span>}
                  validateStatus="validating"
                  hasFeedback={loading.bairros}
                >
                  <Select
                    placeholder="escolher"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    options={bairros?.map(({ nome, id }) => ({
                      label: nome,
                      value: nome,
                      key: id
                    }))}
                    autoComplete="none"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="identificadorExterno" label="protocolo">
                  <Input
                    placeholder="Busque pelo protocolo da denúncia"
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Collapse.Panel>
          </Collapse>
        </Form>
      </Modal>
    </>
  );
};
