import { Button, Comment, Timeline } from "antd";
import { DATA_FORMATO, STATUS } from "../../../utils";
import { ClockCircleOutlined } from "@ant-design/icons";
import moment from "moment";


export const TimelineProgressoEncaminhamento = ({ statusCurrent, dataConclusao, progresso, dataInicio, titulo }) =>  {

    return (
        <>  
            <h3>{titulo}</h3>
            <Timeline
                mode="left"
                style={{ marginTop: "1.5rem", marginLeft: "-5rem" }}
            >
                {statusCurrent === STATUS.FINALIZADO && (
                <Timeline.Item
                    dot={<ClockCircleOutlined style={{ fontSize: "15px" }} />}
                    color="red"
                    label={moment(dataConclusao).format(DATA_FORMATO)}
                >
                    ENCAMINHAMENTO FINALIZADO!
                </Timeline.Item>
                )}
                {progresso
                .sort(
                    (a, b) =>
                    new Date(b.dataCadastro) - new Date(a.dataCadastro)
                )
                .map((justificativa, index) => (
                    <Timeline.Item
                    position={justificativa.cor === "blue" && "letf"}
                    color={justificativa.cor}
                    label={justificativa.tipo}
                    >
                    <Comment
                        author={
                        (justificativa.cor === "blue" &&
                            justificativa.usuario.nome) ||
                        (justificativa.cor === "green" &&
                            justificativa.usuario.nome) ||
                        (justificativa.cor === "Recusa" &&
                            justificativa.usuario.nome)
                        }
                        content={justificativa.descricao}
                        datetime={justificativa.dataCadastro}
                    />
                    </Timeline.Item>
                ))}

                <Timeline.Item
                dot={<ClockCircleOutlined style={{ fontSize: "15px" }} />}
                color="green"
                label={moment(dataInicio).format(DATA_FORMATO)}
                >
                ENCAMINHAMENTO REALIZADO!
                </Timeline.Item>
            </Timeline>
            {statusCurrent === STATUS.FINALIZADO && (
                <Button style={{ display: "none" }} type="dashed" danger>
                REABRIR ENCAMINHAMENTO
                </Button>
            )}
        </>
    );
};