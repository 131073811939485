import {
  HomeOutlined
} from "@ant-design/icons";
import { Breadcrumb, Button } from "antd";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { USER_ROLES, urls } from "../../../utils";
import { PessoaPaginada } from "../components/PessoaPaginada";
import { useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";

const ListInvolveds = () => {
  const history = useHistory();
  const { user } = useContext(UserContext);

  return (
    <div id="sectionMain">
      <Breadcrumb id="breadcrumb">
        <Breadcrumb.Item key="home">
          <Link to="">
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="registros">registros</Breadcrumb.Item>
        {(user?.authorities?.includes(USER_ROLES.CONSULTAR_PESSOAS) || user?.authorities?.includes(USER_ROLES.GERENCIAR_PESSOA)) && (
          <Breadcrumb.Item key="pessoas">
          <Link to={urls.PESSOAS_LIST}>
            <strong>pessoas</strong>
          </Link>
        </Breadcrumb.Item>
        )}
      </Breadcrumb>

      <div className="container-section">
        <div id="header-container">
          <h2>LISTA DE PESSOAS</h2>
          <div id="header-actions">
            {(user?.authorities?.includes(USER_ROLES.CADASTRAR_PESSOA) || user?.authorities?.includes(USER_ROLES.GERENCIAR_PESSOA)) && (
              <Button
                type="primary"
                onClick={() => history.push(urls.PESSOAS_CRIAR)}>
                nova pessoa
              </Button>
            )}
          </div>
        </div>
        <PessoaPaginada />
      </div>
    </div>
  );
};

export default ListInvolveds;
