import React, { useEffect, useState } from "react";

import { Form, Input, Col, Row, Button, Select } from "antd";

import { Label, LogoContainer, Page, SectionForm, LogoFooter } from "../style";

import logoOiSol from "../../../images/logos/oisol.svg";
import oisol from "../../../images/logos/oisol@1X2.svg";
import logoSPS from "../../../images/logos/logo-gov.png";
import { useWidth } from "../../../hooks";
import { openNotification } from "../../../components/Notification";
import { useHistory } from "react-router-dom";
import { publicoService } from "../../../services/resources/publicoService";

export const Consulta = () => {
  const history = useHistory();

  const { width } = useWidth();

  const [form] = Form.useForm();

  const [canais, setCanais] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getChannels() {
      try {
        setLoading(true);
        const response = await publicoService.canais();
        setCanais(response?.data);
      } catch (error) {
        openNotification(
          "error",
          <strong>Não conseguimos carregar a lista de canais</strong>,
          "Por favor recarregue a página, se o problema persistir entre em contato."
        );
      } finally {
        setLoading(false);
      }
    }

    getChannels();
  }, []);

  async function handleSubmit({ canal, protocolo }) {
    try {
      setLoading(true);
      history.push(`/consulta/${canal}/${protocolo}/resultado`);
    } catch (error) {
      openNotification(
        "error",
        <strong>Não conseguimos encontrar sua denúncia!</strong>,
        "Por favor verifique o canal e o protocolo informado."
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <Page>
      <section>
        {width > 900 && (
          <LogoContainer>
            <img
              src={logoOiSol}
              alt="Logo - Oisol"
              title={process.env.REACT_APP_VERSION}
            />
          </LogoContainer>
        )}

        <SectionForm>
          <Row>
            {width <= 900 && (
              <LogoContainer>
                <img
                  src={oisol}
                  alt="Logo - Oisol"
                  title={process.env.REACT_APP_VERSION}
                />
              </LogoContainer>
            )}
          </Row>
          <Form
            onFinish={handleSubmit}
            className="formSearch"
            requiredMark={false}
            form={form}
            name="loginForm"
            layout="vertical"
          >
            <Row gutter={[8, 8]}>
              <Col span="24">
                <h1>CONSULTE A SITUAÇÃO DA SUA DENÚNCIA</h1>
              </Col>
              <Col span="24">
                <Form.Item
                  span="24"
                  name="canal"
                  label={<Label>Canal:</Label>}
                  rules={[
                    { required: true, message: "Este campo é obrigatório!" },
                  ]}
                >
                  <Select
                    placeholder="selecione o canal..."
                    autoComplete="none"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    options={canais?.map(({ nome, codigo }) => ({
                      label: nome,
                      value: codigo,
                    }))}
                  />
                </Form.Item>
              </Col>

              <Col span="24">
                <Form.Item
                  span="24"
                  name="protocolo"
                  label={<Label>Protocolo:</Label>}
                  rules={[
                    { required: true, message: "Este campo é obrigatório!" },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="digite aqui o procolo da denúncia"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row justify="end">
              <Button
                htmlType="submit"
                type="primary"
                block={width < 576}
                style={{ height: "35px" }}
                loading={loading}
              >
                pesquisar
              </Button>
            </Row>
          </Form>

          <LogoFooter>
            <img
              src={logoSPS}
              alt="logo - Secretaria da proteção social, justiça, cidadania, mulheres e direitos humanos."
              style={{ width: "100%", maxWidth: "200px" }} 
            />
          </LogoFooter>
        </SectionForm>
      </section>
    </Page>
  );
};
