import {useEffect, useState} from 'react';

export function useWidth() {
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleStatusChange() {
      setCurrentWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleStatusChange);

    return () => {
      window.removeEventListener('resize', handleStatusChange);
    };
  });

  return {width: currentWidth};
}