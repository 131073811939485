import api from "../api";
import qs from "qs";

 export const instituicaoService = {
    async loadInstitutions(params) {
      return await api.get(`/instituicoes`, {
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });
    },

    async getInstitution(id) {
      return await api.get(`/instituicoes/${id}`);
    },

    async createInstitution(payload) {
      return await api.post(`/instituicoes`, payload);
    },

    async editInstitution(id, payload) {
      return await api.put(`/instituicoes/${id}`, payload);
    },

    async deleteInstitution(id) {
      return await api.delete(`/instituicoes/${id}`);
    },
  }