import React, { useCallback, useEffect, useState } from "react";
import { Form, Input, Row, Col, Modal } from "antd";

import { Container } from "./styles";
import { openNotification } from "../../../../../../components/Notification";
import { useWidth } from "../../../../../../hooks";
import { deficienciaService } from "../../../../../../services/resources/deficienciaService";
import TextArea from "antd/lib/input/TextArea";

function ModalChannel({
  onClose,
  mode,
  codigo,
  getDeficiencias,
}) {
  const { width } = useWidth();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const [deficiencia, setDeficiencia] = useState([]);

  const handleCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  async function getDeficiencia() {
    try {
      setLoading(true);
      if (mode !== "onCreate") {
        const { data } = await deficienciaService.get(codigo);
        setDeficiencia(data);
        form.setFieldsValue({
          descricao: data?.descricao,
          fundamentacao: data?.fundamentacao
        });
      }
    } catch (error) {
      openNotification(
        "error",
        <strong>Erro!</strong>,
        error?.response?.data?.detail
      );
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDeficiencia();
  }, [codigo, mode, form]);

  async function handleSubmit() {
    if (mode === "onCreate") {
      try {
        setLoading(true);
        await deficienciaService.create(form.getFieldValue());
        handleCloseModal();
        openNotification("success", <strong>Deficiência adicionada!</strong>);
        getDeficiencias();
      } catch (reason) {
        handleCloseModal();
        openNotification("error", <strong>Não foi possível adicionar a deficiência!</strong>,'Erro: '+reason);
      } finally {
        setLoading(false);
      }
    } else if (mode === "onEdit") {
      try {
        setLoading(true);
        await deficienciaService.edit(codigo, form.getFieldValue());
        handleCloseModal();
        openNotification("success", <strong>Deficiência editada!</strong>);
        getDeficiencias();
      } catch (reason) {
        handleCloseModal();
        openNotification("error", <strong>Não foi possível editar a deficiência!</strong>,'Erro: '+reason);
      } finally {
        setLoading(false);
      }
    }
  }
  

  return (
    <Modal
      title={
        (mode === "onCreate" && "NOVA DEFICIÊNCIA") ||
        (mode === "onEdit" && `EDITAR DEFICIÊNCIA: ${deficiencia?.descricao}`) ||
        (mode === "onView" && `DETALHES DA DEFICIÊNCIA - ${deficiencia?.descricao}`)
      }
      width={width > 1280 ? "40vw" : "100vw"}
      onOk={mode !== "onView" ? handleSubmit : onClose} 
      onCancel={onClose}
      visible
      centered
    >
      <Container>
        {!loading &&
          <Form form={form} onFinish={handleSubmit} name="deficiencia" layout="vertical">
            <Row gutter={32}>
              <Col xs={{ span: 24 }} lg={{ span: 32 }}>
                <Form.Item
                  name="descricao"
                  label={<span>Descrição:</span>}
                  rules={[
                    { required: true, message: "Este campo é obrigatório!" },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Digite a descrição da deficiência"
                    autoComplete="none"
                    disabled={mode === "onView"}
                  />
                </Form.Item>
              </Col>
            </Row>
            
            <Row gutter={32}>
              <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item
                  name="fundamentacao"
                  label={<span>Fundamentação:</span>}
                >
                  <TextArea
                    autoSize
                    placeholder="Digite a fundamentação da deficiência"
                    value={deficiencia.fundamentacao}
                    defaultValue={deficiencia.fundamentacao}
                    disabled={mode === "onView"}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        }
      </Container>
    </Modal>
  );
}

export default ModalChannel;
