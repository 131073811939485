import React, { useCallback, useEffect, useState } from "react";

import { Form, Input, Row, Col, Modal, Button, Switch } from "antd";

import { Container } from "./styles";
import { openNotification } from "../../../../../../components/Notification";
import { useWidth } from "../../../../../../hooks";
import { classificacaoAtributoService } from "../../../../../../services/resources/classificacaoAtributoService";
import TextArea from "antd/lib/input/TextArea";


export function ModalAdd({ onClose, mode, codigo, reload }) {
  const [form] = Form.useForm();
  const { width } = useWidth();

  const [loading, setLoading] = useState(false);

  const [classificacaoAtributo, setClassificacaoAtributo] = useState([]);

  const handleCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  async function getClassificacaoAtributo() {
    try {
      setLoading(true)

      if (mode !== "onCreate") {

        const { data } = await classificacaoAtributoService.get(codigo);

        setClassificacaoAtributo(data);
        form.setFieldsValue({
          descricao: data?.descricao,
          ativo: data?.ativo,
          impedeFechamentoDenuncia: data?.impedeFechamentoDenuncia,
          justificativaObrigatoria: data?.justificativaObrigatoria,
          valorPadrao: data?.valorPadrao
        });
      }
    } catch (error) {
      openNotification(
        "error",
        <strong>Erro!</strong>,
        error?.response?.data?.detail
      );
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getClassificacaoAtributo();
  }, [codigo, mode, form]);

  async function create() {
    try {
      setLoading(true);
      await classificacaoAtributoService.create(form.getFieldsValue());
      handleCloseModal();
      openNotification(
        "success",
        <strong>Classificação de procedência criada com sucesso!</strong>
      );
      reload();
    } catch (error) {
      handleCloseModal();
      openNotification(
        "error",
        <strong>Não foi possível criar nova classificação!</strong>,
        error?.response?.data?.detail
      );
    } finally {
      setLoading(false);
    }
  }

  async function edit() {
    try {
      setLoading(true);
      await classificacaoAtributoService.edit(codigo, form.getFieldsValue());
      handleCloseModal();
      openNotification("success", <strong>Classificação de procedência editado!</strong>);
      reload();
    } catch (error) {
      handleCloseModal();
      openNotification(
        "error",
        <strong>Não foi possível editar a classificação de procedência!</strong>,
        error?.response?.data?.detail
      );
    } finally {
      setLoading(false);
    }
  }

  const footer = [
    mode === "onCreate" && (
      <Button key="submit" onClick={create} type="primary" loading={loading}>
        cadastrar
      </Button>
    ),
    mode === "onEdit" && (
      <Button
        key="submitUpdate"
        onClick={edit}
        type="primary"
        loading={loading}
      >
        atualizar
      </Button>
    ),
  ];

  return (
    <Modal
      title={
        (mode === "onCreate" && "NOVA CLASSIFICAÇÃO") ||
        (mode === "onEdit" && `EDITAR CLASSIFICAÇÕES - ${classificacaoAtributo?.descricao}`) ||
        (mode === "onView" && `DETALHES DA CLASSIFICAÇÃO - ${classificacaoAtributo?.descricao}`)
      }
      width={width > 1280 ? "40vw" : "100vw"}
      onCancel={onClose}
      visible
      footer={footer}
      centered
    >
      <Container>

        {!loading &&
          <Form form={form} name="classificacao" layout="vertical">
            <Row>
              <Col span={24}>
                <Form.Item name="descricao" label={<span>descrição:</span>}>
                  <Input
                    type="text"
                    placeholder="insira a descrição"
                    autoComplete="none"
                    disabled={mode === "onView"}
                  />
                </Form.Item>
              </Col>
              <Col span={24} style={{ marginTop: 20 }}>
                <Row justify="space-between">

                  <Form.Item name="impedeFechamentoDenuncia" label={<span>impede finalizar denúncia:</span>}>
                    <Switch
                      valuePropName="checked"
                      checkedChildren="sim"
                      unCheckedChildren="não"
                      disabled={mode === "onView"}
                      defaultChecked={mode !== "onCreate" ? classificacaoAtributo.impedeFechamentoDenuncia : false}
                    />
                  </Form.Item>
                  <Form.Item
                    name="justificativaObrigatoria"
                    label={<span>necessita de justificativa:</span>}
                    >
                    <Switch
                      defaultChecked={mode !== "onCreate" ? classificacaoAtributo.justificativaObrigatoria : false}
                      checkedChildren="sim"
                      unCheckedChildren="não"
                      disabled={mode === "onView"} />
                  </Form.Item>
                  <Form.Item
                    name="valorPadrao"
                    label={<span>valor padrão:</span>}
                    initialValue={false}
                    >
                    <Switch
                      defaultChecked={mode !== "onCreate" ? classificacaoAtributo.valorPadrao : false}
                      checkedChildren="sim"
                      unCheckedChildren="não"
                      disabled={mode === "onView"} />
                  </Form.Item>
                  <Form.Item
                    name="ativo"
                    label={<span>ativo:</span>}>
                    <Switch
                      defaultChecked={mode !== "onCreate" ? classificacaoAtributo.ativo : true}
                      checkedChildren="sim"
                      unCheckedChildren="não"
                      disabled={mode === "onView"} />
                  </Form.Item>
                </Row>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item
                  name="fundamentacao"
                  label={<span>Fundamentação:</span>}
                >
                  <TextArea
                    autoSize
                    placeholder="Digite a fundamentação da classificação procedência"
                    value={classificacaoAtributo.fundamentacao}
                    defaultValue={classificacaoAtributo.fundamentacao}
                    disabled={mode === "onView"}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        }
      </Container>
    </Modal>
  );
}
