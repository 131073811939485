import api from "../api";

export const enderecoService = {
  async loadStates(params) {
    return await api.get("/estados", params);
  },
  async getAllEstados() {
    return await api.get("/publico/estados");
  },

  async getCitysState(id) {
    return await api.get(`/estados/${id}/cidades`);
  },

  async getAllCidades(id) {
    return await api.get(`/publico/estados/${id}/cidades`);
  },

  async getAllBairros(cidadeId) {
    return await api.get(`/publico/estados/cidades/${cidadeId}/bairros`);
  },

  async getDistrictsCitys(id) {
    return await api.get(`/cidades/${id}/bairros`);
  },

  async editDistrictsCitys(id, cidade, bairro) {
    return await api.put(`/cidades/${cidade}/bairros/${id}`, bairro);
  },

  async addDistrictsCitys(id, bairro) {
    return await api.post(`/cidades/${id}/bairros`, bairro);
  },

  async deleteDistrictsCitys(id, cidade) {
    return await api.delete(`/cidades/${cidade}/bairros/${id}`);
  },

  async loadCitys() {
    return await api.get("/cidades");
  },
};
