import api from "../api";

export const classificacaoService = {
  async list(codigo) {
    return await api.get(`/denuncias/${codigo}/classificacoes`);
  },

  async get(codigo) {
    return await api.get(`/denuncias/classificacoes/${codigo}`);
  },

  async create(payload) {
    return await api.post(`/denuncias/classificacoes`, payload);
  },

  async edit(codigo, payload) {
    return await api.put(`/denuncias/classificacoes/${codigo}`, payload);
  },

  async delete(codigo) {
    return await api.delete(`/denuncias/classificacoes/${codigo}`);
  },

  async changeProcedencia(codigoClassificacao, codigoClassificacaoAtributo, justificativa) {
    return await api.put(
      `/denuncias/classificacoes/${codigoClassificacao}/${codigoClassificacaoAtributo}`, {
        justificativa
      }
    );
  },
};
