import {
  AppstoreOutlined,
  CheckCircleFilled,
  EditOutlined,
  EyeOutlined,
  FilterOutlined,
  MinusCircleFilled,
  SearchOutlined,
  TableOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  Pagination,
  Radio,
  Row,
  Space,
  Table,
  Tag,
} from "antd";
import { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { Link, useHistory } from "react-router-dom";
import { mask, unMask } from "remask";
import { openNotification } from "../../../../components/Notification";
import { useWidth } from "../../../../hooks/useWidth";
import { usuarioService } from "../../../../services";
import {
  isEmpty,
  OPCOES_TAMANHO_PAGINA,
  removeByProp,
  removeEmpty,
  urls,
} from "../../../../utils";
import { getFilterBySort } from "../../../../utils/filter";
import { UsuarioCard } from "../UsuarioCard";

export const UsuariosPaginacao = () => {
  const [form] = Form.useForm();
  const formAvancado = form;

  const history = useHistory();
  const { width } = useWidth();

  const [usuarios, setUsuarios] = useState([]);

  const [loading, setLoading] = useState(false);

  const [showFilters, setShowFilters] = useState(false);
  const [isTableView, setIsTableView] = useState(true);

  const cpfPatterns = ["999.999.999-99"];

  const [filter, setFilter] = useState({
    page: 0,
    size: OPCOES_TAMANHO_PAGINA[0],
  });

  const [paginacao, setPaginacao] = useState({
    currentPage: 0,
    totalElements: 0,
    size: OPCOES_TAMANHO_PAGINA[0],
  });

  const USUARIOS_TAG_FILTER = {
    instituicaoNome: "instituicao",
    instituicaoMunicipio: "município",
  };

  const columns = [
    {
      title: <strong>ativo</strong>,
      dataIndex: "ativo",
      key: "ativo",
      responsive: ["md"],
      width: 0,
      sorter: {
        multiple: 1,
      },
      render: (ativo) =>
        ativo ? (
          <CheckCircleFilled style={{ color: "var(--green-color)" }} />
        ) : (
          <MinusCircleFilled style={{ color: "var(--red-color)" }} />
        ),
    },
    {
      title: <strong>nome</strong>,
      dataIndex: "nome",
      key: "nome",
      sorter: {
        multiple: 1,
      },
      render: (text) => (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ff9945",
            padding: 0,
            color: "#fff",
          }}
          searchWords={[filter?.nome]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },
    {
      title: <strong>login</strong>,
      dataIndex: "login",
      key: "login",
      sorter: {
        multiple: 1,
      },
      responsive: ["sm"],
    },
    {
      title: <strong>email</strong>,
      dataIndex: "email",
      key: "email",
      sorter: {
        multiple: 1,
      },
      responsive: ["md"],
      render: (text) => (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ff9945",
            padding: 0,
            color: "#fff",
          }}
          searchWords={[filter?.email]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },

    {
      title: <strong>instituição</strong>,
      dataIndex: ["instituicao"],
      key: "instituicao",
      sorter: {
        multiple: 1,
      },
      responsive: ["lg"],
      render: (instituicao) => (
        <Link
          to={urls.INSTITUICOES_DETAILS.replace(":codigo", instituicao?.codigo)}
        >
          <Highlighter
            highlightStyle={{
              backgroundColor: "var(--border-color)",
              padding: 0,
              color: "#fff",
            }}
            searchWords={[filter?.instituicaoNome]}
            autoEscape
            textToHighlight={
              instituicao.nome ? instituicao.nome.toString() : ""
            }
          />
        </Link>
      ),
    },
    {
      title: <strong>município</strong>,
      dataIndex: ["instituicao"],
      key: "instituicao-municipio",
      sorter: {
        multiple: 1,
      },
      render: (instituicao) => (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ff9945",
            padding: 0,
            color: "#fff",
          }}
          searchWords={[filter?.instituicaoMunicipio]}
          autoEscape
          textToHighlight={
            instituicao.municipio ? instituicao.municipio.toString() : ""
          }
        />
      ),
    },

    {
      title: <strong>grupos</strong>,
      dataIndex: "grupos",
      key: "grupos",
      width: "20%",
      responsive: ["lg"],
      render: (grupos) =>
        grupos?.map((grupo) => {
          return (
            <Tag color={"#f50"} key={grupo?.codigo}>
              <span>{grupo?.nome}</span>
            </Tag>
          );
        }),
    },
    {
      title: <strong>ações</strong>,
      key: "action",
      fixed: "right",
      width: 128,
      render: ({ nome, codigo, ativo }) => (
        <Space size="small">
          <Button
            type="primary"
            ghost
            className="action-button"
            title={`visualizar ${nome}`}
            onClick={() =>
              history.push(urls.USUARIOS_DETAILS.replace(":codigo", codigo))
            }
          >
            <EyeOutlined style={{ fontSize: "1.2rem" }} />
          </Button>

          <Button
            type="primary"
            className="action-button"
            title={`editar ${nome}`}
            onClick={() =>
              history.push(urls.USUARIOS_EDITAR.replace(":codigo", codigo))
            }
          >
            <EditOutlined style={{ fontSize: "1.2rem" }} />
          </Button>

          {ativo ? (
            <Button
              className="action-button"
              type="danger"
              title={`desativar ${nome}`}
              onClick={() => warningDelete({ codigo, nome, ativo })}
            >
              <UserDeleteOutlined style={{ fontSize: "1.2rem" }} />
            </Button>
          ) : (
            <Button
              className="action-button"
              type="dashed"
              title={`ativar ${nome}`}
              onClick={() => warningDelete({ codigo, nome, ativo })}
            >
              <UserAddOutlined style={{ fontSize: "1.2rem" }} />
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const tagsNotShow = ["ativo", "page", "size", "sort"];

  useEffect(() => {
    getUsuarios(filter);

    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    width <= 768 && setIsTableView(false);

    // eslint-disable-next-line
  }, [width]);

  async function getUsuarios(filter) {
    try {
      setLoading(true);
      const response = await usuarioService.list(removeEmpty(filter));
      setUsuarios(response?.data?.content);
      setPaginacao({
        currentPage: response?.data?.currentPage,
        totalElements: response?.data?.totalElements,
        size: response?.data?.size,
      });
    } catch (error) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao buscar os usuários!</strong>,
        error?.response?.data?.detail
      );
    } finally {
      setLoading(false);
    }
  }

  async function changeActive({ codigo, ativo, nome }) {
    await usuarioService
      .changeActive(codigo, !ativo)
      .then(() => {
        getUsuarios(filter);
        openNotification(
          "success",
          <strong>
            {`Usuário ${!ativo ? "ativado" : "desativado"} com sucesso`}{" "}
          </strong>,
          `${nome} foi ${
            !ativo
              ? "ativada(o) com sucesso e agora poderá acessar ao sistema"
              : "desativada(o) com sucesso e não terá mais acessar ao sistema"
          } `
        );
      })
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao alterar status!</strong>,
          "" + reason?.response?.data?.detail
        )
      );
  }

  function onChange(_, filters, sorter, { action }) {
    if (action === "sort") setFilter(getFilterBySort(sorter, filter));
    if (action === "filter") onFilter(filters);
  }

  function onFilter(filters) {
    setFilter({
      ...filter,
      ...filters,
      page: 0,
    });
  }

  function clearAll() {
    if (
      !isEmpty(removeEmpty(form.getFieldsValue())) &&
      !isEmpty(removeEmpty(formAvancado.getFieldsValue()))
    ) {
      setFilter({
        ativo: true,
        page: 0,
        size: OPCOES_TAMANHO_PAGINA[0],
      });

      form.resetFields();
      formAvancado.resetFields();
    }
  }

  function warningDelete({ codigo, nome, ativo }) {
    Modal.confirm({
      title: "ATENÇÃO!",
      content: (
        <p>
          Você tem certeza que deseja{" "}
          <strong>{ativo ? "DESATIVAR" : "ATIVAR"}</strong> a usuario{" "}
          <strong>{nome}</strong>?
        </p>
      ),
      onOk: () => changeActive({ codigo, nome, ativo }),
      okText: ativo ? "desativar" : "ativar",
      cancelText: "cancelar",
      okButtonProps: { danger: ativo },
    });
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        {width > 768 && (
          <Col span={6}>
            <Form
              name="usuario_filtro"
              layout="inline"
              form={form}
              onValuesChange={({ nome }) => onFilter({ nome })}
              onFinish={({ nome }) =>
                nome !== filter.nome && onFilter({ nome })
              }
            >
              <Col span={24}>
                <Form.Item name="nome">
                  <Input
                    prefix={<SearchOutlined />}
                    placeholder="Busque pelo nome do usuário"
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Form>
          </Col>
        )}
        <Col>
          <Button type="primary" onClick={() => setShowFilters(true)}>
            filtros avançados
            <FilterOutlined />
          </Button>
        </Col>
        <Col>
          <Button onClick={clearAll}>limpar filtros</Button>
        </Col>
        {width > 768 && (
          <Col>
            <Radio.Group
              defaultValue={isTableView}
              onChange={({ target: { value } }) => setIsTableView(value)}
            >
              <Radio.Button value={true}>
                <TableOutlined />
              </Radio.Button>
              <Radio.Button value={false}>
                <AppstoreOutlined />
              </Radio.Button>
            </Radio.Group>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={24}>
          {Object.entries(removeEmpty(filter))
            .filter(([label]) => !tagsNotShow.includes(label))
            .map(([label, value]) => (
              <Tag
                color="var(--border-color)"
                closable
                key={label + value}
                onClose={() => {
                  onFilter(removeByProp(filter, label));
                  form.setFieldsValue({ [label]: null });
                  formAvancado.setFieldsValue({ [label]: null });
                }}
              >
                {(USUARIOS_TAG_FILTER[label] || label).toUpperCase() +
                  ": " +
                  value}
              </Tag>
            ))}
        </Col>
      </Row>
      {isTableView ? (
        <Row>
          <Col span={24}>
            <Table
              loading={loading}
              dataSource={usuarios}
              columns={columns}
              size="small"
              rowKey={({ codigo }) => codigo}
              onChange={onChange}
              totalSize={paginacao.totalElements}
              pagination={false}
            />
          </Col>
        </Row>
      ) : (
        <Row gutter={16} justify="start">
          {usuarios?.map((usuario) => (
            <Col key={usuario.codigo} xs={24} md={12} lg={8} xl={6}>
              <UsuarioCard
                usuario={usuario}
                loading={loading}
                warningDelete={warningDelete}
              />
            </Col>
          ))}
        </Row>
      )}
      <Row justify="end">
        <Col>
          <Pagination
            size="small"
            showSizeChanger={true}
            simple={width < 768}
            pageSizeOptions={OPCOES_TAMANHO_PAGINA}
            defaultPageSize={paginacao.size}
            total={paginacao.totalElements}
            showTotal={(total) => <strong>{total} usuários no total</strong>}
            onChange={(page, size) => {
              setFilter({
                ...filter,
                page: filter.size !== size ? 0 : page - 1,
                size,
              });
            }}
            current={paginacao.currentPage + 1}
          />
        </Col>
      </Row>

      <Modal
        title="Selecionar filtros"
        visible={showFilters}
        onCancel={() => {
          setShowFilters(false);
        }}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setShowFilters(false);
              onFilter(formAvancado.getFieldsValue());
            }}
          >
            aplicar filtros
          </Button>,
        ]}
      >
        <Form name="usuario_filtro" layout="vertical" form={formAvancado}>
          <Collapse ghost defaultActiveKey={["1", "2", "3", "4"]}>
            <Collapse.Panel header="Dados pessoais" key="1">
              <Col span={24}>
                <Form.Item name="nome" label="nome">
                  <Input placeholder="Busque pelo nome da usuario" allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="email" label="email">
                  <Input
                    placeholder="Busque pelo email da usuario"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="cpf" label="cpf">
                  <Input
                    placeholder="Busque pelo cpf do usuario"
                    allowClear
                    onChange={({ target: { value } }) => {
                      form.setFieldsValue({
                        cpf: mask(unMask(value), cpfPatterns),
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Collapse.Panel>
            <Collapse.Panel header="Instituição" key="2">
              <Col span={24}>
                <Form.Item name="instituicaoNome" label="nome">
                  <Input
                    placeholder="Busque pelo nome da instituição do usuario"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="instituicaoMunicipio" label="município">
                  <Input
                    placeholder="Busque pelo município da instituição"
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Collapse.Panel>
          </Collapse>
        </Form>
      </Modal>
    </>
  );
};
