import { parsedSort } from "./tabela";

export const getFilterBySort = (sorter, filter) => {
  if (sorter.constructor === Object) {
    if (sorter.column)
      return {
        ...filter,
        sort: parsedSort(sorter.columnKey, sorter.order),
      };
    else return { ...filter, sort: [] };
  } else {
    return {
      ...filter,
      sort: sorter.map((sort) => parsedSort(sort.columnKey, sort.order)),
    };
  }
};
