import {
  AppstoreOutlined,
  DeleteOutlined,
  EditOutlined,
  EnvironmentOutlined,
  EyeOutlined,
  FilterOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  TableOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Radio,
  Row,
  Skeleton,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import Meta from "antd/lib/card/Meta";
import React, { useContext, useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { useHistory } from "react-router-dom";
import { openNotification } from "../../../../components/Notification";
import { UserContext } from "../../../../contexts/UserContext";
import { useWidth } from "../../../../hooks";
import { instituicaoService } from "../../../../services/resources/instituicaoService";
import {
  isEmpty,
  OPCOES_TAMANHO_PAGINA,
  parsedSort,
  PESSOAS_TAG_FILTER,
  removeByProp,
  removeEmpty,
  urls,
  USER_ROLES,
} from "../../../../utils";
import { InstituicaoCard } from "../../Components/InstituicaoCard";

const InstituicaoPaginada = ({ cardActions = null, tableActions = null }) => {
  const [form] = Form.useForm();
  const formAvancado = form;

  const history = useHistory();
  const { width } = useWidth();
  const { user } = useContext(UserContext);

  const [institutions, setInstitutions] = useState([]);
  const [isTableView, setIsTableView] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [filter, setFilter] = useState({
    ativo: true,
    page: 0,
    size: OPCOES_TAMANHO_PAGINA[0],
  });

  const [paginacao, setPaginacao] = useState({
    currentPage: 0,
    totalElements: 0,
    size: OPCOES_TAMANHO_PAGINA[0],
  });

  const deleteInstitutionButton = (codigo, nome) => (
    <Popconfirm
      title={
        <>
          <span>{`Você tem certeza que deseja excluir a instituição ${nome}?`}</span>
          <br />
          <small>{`Se confirmadsa, essa operação não poderá ser revertida.`}</small>
        </>
      }
      placement="bottomRight"
      okText="confirmar exclusão"
      cancelText="cancelar"
      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
      onConfirm={() => warningDelete(codigo, nome)}
      okButtonProps={{ loading: confirmLoading }}
      cancelButtonProps={{ disabled: confirmLoading }}
    >
      <Button
        className="action-button"
        type="danger"
        title={`Excluir instituição ${nome}`}
      >
        <DeleteOutlined style={{ fontSize: "1.2rem" }} />
      </Button>
    </Popconfirm>
  );

  const columns = [
    {
      title: <strong>nome</strong>,
      dataIndex: "nome",
      key: "nome",
      sorter: {
        multiple: 1,
      },
      render: (text) => (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ff9945",
            padding: 0,
            color: "#fff",
          }}
          searchWords={[filter?.nome]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: <strong>tema</strong>,
      dataIndex: "descricaoTema",
      key: "temaDescricao",
      sorter: {
        multiple: 1,
      },
      render: (text) => (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ff9945",
            padding: 0,
            color: "#fff",
          }}
          searchWords={[filter?.temaDescricao]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
      sortDirections: ["ascend", "descend"],
      responsive: ["md"],
    },
    {
      title: <strong>tipo</strong>,
      dataIndex: "nomeTipoInstituicao",
      key: "tipoInstituicao.nome",
      responsive: ["lg"],
      sorter: {
        multiple: 1,
      },
      render: (text) => (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ff9945",
            padding: 0,
            color: "#fff",
          }}
          searchWords={[filter?.tipoNome]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },

    {
      title: <strong>cidade</strong>,
      dataIndex: "municipio",
      key: "endereco.bairro.cidade.nome",
      responsive: ["md"],
      sorter: {
        multiple: 1,
      },
      render: (text) => (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ff9945",
            padding: 0,
            color: "#fff",
          }}
          searchWords={[filter?.cidadeNome]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },
    {
      title: <strong>bairro</strong>,
      dataIndex: "bairro",
      key: "endereco.bairro.nome",
      responsive: ["md"],
      sorter: {
        multiple: 1,
      },
      render: (text) => (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ff9945",
            padding: 0,
            color: "#fff",
          }}
          searchWords={[filter?.bairro]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },
    {
      title: <strong>observações</strong>,
      dataIndex: "observacao",
      key: "observacao",
      sorter: {
        multiple: 1,
      },
      ellipsis: true,
      responsive: ["xl"],
      render: (observacao) => (
        <Tooltip placement="topLeft" title={observacao}>
          {observacao}
        </Tooltip>
      ),
    },
    {
      title: <strong>ações</strong>,
      key: "action",
      fixed: "right",
      width: user?.authorities?.includes(USER_ROLES.GERENCIAR_INSTITUICOES)
        ? 132
        : 32,
      responsive: ["md"],
      render: tableActions
        ? tableActions
        : ({ nome, codigo }) => (
            <Space size="small">
              <Button
                type="primary"
                ghost
                className="action-button"
                title={`Visualizar indivíduo ${nome}`}
                onClick={() =>
                  history.push(
                    urls.INSTITUICOES_DETAILS.replace(":codigo", codigo)
                  )
                }
              >
                <EyeOutlined style={{ fontSize: "1.2rem" }} />
              </Button>
              {user?.authorities?.includes(
                USER_ROLES.GERENCIAR_INSTITUICOES
              ) && (
                <>
                  {(user?.authorities?.includes(USER_ROLES.ALTERAR_PESSOA) || user?.authorities?.includes(USER_ROLES.GERENCIAR_PESSOA))
                    && (
                      <Button
                        type="primary"
                        className="action-button"
                        title={`Editar indivíduo ${nome}`}
                        onClick={() =>
                          history.push(
                            urls.INSTITUICOES_EDITAR.replace(":codigo", codigo)
                          )
                        }
                      >
                        <EditOutlined style={{ fontSize: "1.2rem" }} />
                      </Button>
                    )}

                  <Button
                    className="action-button"
                    type="danger"
                    title={`Excluir instituição ${nome}`}
                    onClick={() => warningDelete({ nome, codigo })}
                  >
                    <DeleteOutlined style={{ fontSize: "1.2rem" }} />
                  </Button>
                </>
              )}
            </Space>
          ),
    },
  ];

  function onSort(sorter) {
    if (sorter.constructor === Object) {
      if (sorter.column)
        setFilter({
          ...filter,
          sort: parsedSort(sorter.columnKey, sorter.order),
        });
      else setFilter({ ...filter, sort: [] });
    } else {
      setFilter({
        ...filter,
        sort: sorter.map((sort) => parsedSort(sort.columnKey, sort.order)),
      });
    }
  }

  function onChange(_, filters, sorter, { action }) {
    if (action === "sort") onSort(sorter);
    if (action === "filter") onFilter(filters);
  }

  function onFilter(filters) {
    setFilter({
      ...filter,
      ...filters,
      page: 0,
    });
  }

  function clearAll() {
    if (
      !isEmpty(removeEmpty(form.getFieldsValue())) &&
      !isEmpty(removeEmpty(formAvancado.getFieldsValue()))
    ) {
      setFilter({
        ativo: true,
        page: 0,
        size: OPCOES_TAMANHO_PAGINA[0],
      });

      form.resetFields();
      formAvancado.resetFields();
    }
  }

  function warningDelete({ codigo, nome }) {
    Modal.confirm({
      title: "ATENÇÃO!",
      content: (
        <p>
          Você tem certeza que deseja <strong>EXCLUIR</strong> a insituição{" "}
          <strong>{nome}</strong>? Se confirmada, essa operação não poderá ser
          revertida.
        </p>
      ),
      onOk: () => handleDelete(codigo),
      okText: "excluir",
      cancelText: "cancelar",
      okButtonProps: { danger: true },
    });
  }

  async function getIntitutions(params) {
    try {
      setLoading(true);
      const response = await instituicaoService.loadInstitutions(
        removeEmpty(params)
      );
      setInstitutions(response?.data?.content);
      setPaginacao({
        currentPage: response?.data?.currentPage,
        totalElements: response?.data?.totalElements,
        size: response?.data?.size,
      });
    } catch (error) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao carregar as Instituições!</strong>,
        "Erro: " + error?.response?.data?.detail
      );
    } finally {
      setLoading(false);
    }
  }

  async function handleDelete(codigo, name) {
    setConfirmLoading(true);

    try {
      await instituicaoService.deleteInstitution(codigo);
      openNotification(
        "success",
        <strong>{`Instituição excluída com sucesso!`}</strong>,
        `${name} foi excluída e essa operação não pode ser revertida.`
      );
      getIntitutions();
    } catch ({ response }) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao buscar os temas!</strong>,
        "Erro: " + response?.data?.detail
      );
    } finally {
      setConfirmLoading(false);
    }
  }

  const tagsNotShow = ["ativo", "page", "size", "sort"];

  useEffect(() => {
    getIntitutions(filter);

    // eslint-disable-next-line
  }, [filter]);

  const renderTable = () => {
    return (
      <Table
        dataSource={institutions}
        columns={columns}
        size="small"
        rowKey={({ codigo }) => codigo}
        loading={{ spinning: loading, size: "large" }}
        totalSize={paginacao.totalElements}
        onChange={onChange}
        pagination={false}
        expandable={
          width < 768 && {
            expandRowByClick: true,
            expandedRowRender: instituicaoCard,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <MenuFoldOutlined onClick={(e) => onExpand(record, e)} />
              ) : (
                <MenuUnfoldOutlined onClick={(e) => onExpand(record, e)} />
              ),
          }
        }
      />
    );
  };

  const instituicaoCard = (instituicao) => (
    <InstituicaoCard
      instituicao={instituicao}
      deleteInstitutionButton={deleteInstitutionButton}
    />
  );

  return (
    <>
      <Row gutter={[16, 16]}>
        {width > 768 && (
          <Col span={6}>
            <Form
              name="instituicao_filtro"
              layout="inline"
              form={form}
              onValuesChange={({ nome }) => onFilter({ nome })}
              onFinish={({ nome }) =>
                nome !== filter.nome && onFilter({ nome })
              }
            >
              <Col span={24}>
                <Form.Item name="nome">
                  <Input
                    prefix={<SearchOutlined />}
                    placeholder="Busque pelo nome da instituição"
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Form>
          </Col>
        )}
        <Col>
          <Button type="primary" onClick={() => setShowFilters(true)}>
            filtros avançados
            <FilterOutlined />
          </Button>
        </Col>
        <Col>
          <Button onClick={clearAll}>limpar filtros</Button>
        </Col>
        {width > 768 && (
          <Col>
            <Radio.Group
              defaultValue={isTableView}
              onChange={({ target: { value } }) => setIsTableView(value)}
            >
              <Radio.Button value={true}>
                <TableOutlined />
              </Radio.Button>
              <Radio.Button value={false}>
                <AppstoreOutlined />
              </Radio.Button>
            </Radio.Group>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={24}>
          {Object.entries(removeEmpty(filter))
            .filter(([label]) => !tagsNotShow.includes(label))
            .map(([label, value]) => (
              <Tag
                color="var(--border-color)"
                closable
                key={label + value}
                onClose={() => {
                  onFilter(removeByProp(filter, label));
                  form.setFieldsValue({ [label]: null });
                  formAvancado.setFieldsValue({ [label]: null });
                }}
              >
                {(PESSOAS_TAG_FILTER[label] || label).toUpperCase() +
                  ": " +
                  value}
              </Tag>
            ))}
        </Col>
      </Row>
      {isTableView ? (
        renderTable()
      ) : (
        <Row gutter={16} justify="space-between">
          {institutions.map((institution) => (
            <Col key={institution.codigo} xs={24} md={12} lg={8} xl={6}>
              <Card
                style={{ width: "100%", marginTop: 16 }}
                actions={
                  cardActions
                    ? [cardActions(institution)]
                    : [
                        <Button
                          key="view"
                          icon={
                            <EyeOutlined
                              style={{ fontSize: "1.2rem" }}
                              onClick={() =>
                                history.push(
                                  urls.INSTITUICOES_DETAILS.replace(
                                    ":codigo",
                                    institution.codigo
                                  )
                                )
                              }
                            />
                          }
                        />,
                        <Button
                          key="edit"
                          type="primary"
                          icon={
                            <EditOutlined
                              style={{ fontSize: "1.2rem" }}
                              onClick={() =>
                                history.push(
                                  urls.INSTITUICOES_EDITAR.replace(
                                    ":codigo",
                                    institution.codigo
                                  )
                                )
                              }
                            />
                          }
                        />,
                        <Button
                          key="delete"
                          type="danger"
                          icon={
                            <DeleteOutlined
                              onClick={() => warningDelete(institution)}
                            />
                          }
                        />,
                      ]
                }
              >
                <Skeleton loading={loading} avatar active>
                  <Meta
                    description={
                      <>
                        <strong>{institution?.nome}</strong>
                        <Divider />
                        <p>
                          <HomeOutlined /> {institution?.nomeTipoInstituicao}
                        </p>
                        <p>
                          <EnvironmentOutlined /> {institution?.municipio} - {institution?.bairro}
                        </p>
                        <p>
                          <InfoCircleOutlined /> {institution?.descricaoTema}
                        </p>
                      </>
                    }
                  />
                </Skeleton>
              </Card>
            </Col>
          ))}
        </Row>
      )}
      <Row justify="end">
        <Col>
          <Pagination
            size="small"
            showSizeChanger={true}
            simple={width < 768}
            pageSizeOptions={OPCOES_TAMANHO_PAGINA}
            defaultPageSize={paginacao.size}
            total={paginacao.totalElements}
            showTotal={(total) => <strong>{total} instituições no total</strong>}
            onChange={(page, size) => {
              setFilter({
                ...filter,
                page: filter.size !== size ? 0 : page - 1,
                size,
              });
            }}
            current={paginacao.currentPage + 1}
          />
        </Col>
      </Row>

      <Modal
        title="Selecionar filtros"
        visible={showFilters}
        onCancel={() => {
          setShowFilters(false);
        }}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setShowFilters(false);
              onFilter(formAvancado.getFieldsValue());
            }}
          >
            aplicar filtros
          </Button>,
        ]}
      >
        <Form name="instituicao_filtro" layout="vertical" form={formAvancado}>
          <Collapse ghost defaultActiveKey={["1", "2", "3", "4"]}>
            <Collapse.Panel header="Dados das instituições" key="1">
              <Col span={24}>
                <Form.Item name="nome" label="nome">
                  <Input
                    placeholder="Busque pelo nome da instituição"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="temaDescricao" label="tema">
                  <Input
                    placeholder="Busque pelo tema da violação"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="tipoNome" label="tipo">
                  <Input
                    placeholder="Busque pelo tipo da instituição"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="cidadeNome" label="cidade">
                  <Input
                    placeholder="Busque pela cidade da instituição"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="bairro" label="bairro">
                  <Input
                    placeholder="Busque pelo bairro da instituição"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="observacao" label="observação">
                  <Input placeholder="Busque por observações" allowClear />
                </Form.Item>
              </Col>
            </Collapse.Panel>
          </Collapse>
        </Form>
      </Modal>
    </>
  );
};

export default InstituicaoPaginada;
