import React, { useCallback } from "react";

import { useQuery } from "../../../hooks/useQuery";
import { renderComponent } from "../../CadastrosBasicos/utils/Menu";

const Listclassification = () => {
  const query = useQuery();

  const category = query.get("mode");

  const renderClassification = useCallback((mode) => {
    return renderComponent(mode);
  }, []);

  return (
    <>
      <div id="sectionMain">
        <div className="container-section">
          {renderClassification(category)}
        </div>
      </div>
    </>
  );
};

export default Listclassification;
