import { Result } from "antd";

const Atendimento = () => {
  return (
    <div id="">
      <div className="section-children">
        <Result
          status="404"
          title="Página em desenvolvimento"
          subTitle="Desculpa, esta página ainda está em desenvolvimento."
        />
      </div>
    </div>
  );
};

export default Atendimento;