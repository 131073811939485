import {
  DownloadOutlined,
  EditOutlined,
  FileAddOutlined,
  FileSyncOutlined,
  HomeOutlined,
  MoreOutlined,
  RollbackOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  Upload,
} from "antd";
import { cnpj, cpf } from "cpf-cnpj-validator";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { mask, unMask } from "remask";
import { FormActions } from "../../../components/FormActions";
import { openNotification } from "../../../components/Notification";
import PageHeader from "../../../components/PageHeader";
import { useWidth } from "../../../hooks";
import { usuarioService } from "../../../services";
import { grupoService } from "../../../services/resources/grupoService";
import { instituicaoService } from "../../../services/resources/instituicaoService";
import { removeEmpty, scrollBotton, urls } from "../../../utils";

export const Usuario = ({ onEdit, onView, onCreate }) => {
  const history = useHistory();
  const { Option } = Select;
  const [form] = Form.useForm();
  const { codigo } = useParams();

  const [usuario, setUsuario] = useState(null);
  const [groupsList, setGroupsList] = useState([]);

  const [instituicoes, setInstituicoes] = useState([]);
  const [instituicaoSelect, setInstituicaoSelect] = useState("");

  const patterns = ["999.999.999-99", "99.999.999/9999-99"];
  const telefonePatterns = ["(99) 9999-9999", "(99) 99999-9999"];

  const [loading, setLoading] = useState(false);

  const [downloadLoading, setDownloadLoading] = useState(false);

  const [inBotton, setInBotton] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [filter, setFilter] = useState({
    ativo: true,
    page: 0,
    size: 10,
  });

  const [paginacao, setPaginacao] = useState({
    currentPage: 0,
    totalElements: 0,
    totalPages: 0,
  });

  const { width } = useWidth();

  useEffect(() => {
    !onCreate && getUser();
    getGroups();
    getInstitutions(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codigo]);

  useEffect(() => {
    form.setFieldsValue({
      instituicaoMunicipio: instituicaoSelect.municipio,
      tipoInstituicao: instituicaoSelect.nomeTipoInstituicao,
    });
  }, [form, instituicaoSelect]);

  useEffect(() => {
    getInstitutions(filter);

    // eslint-disable-next-line
  }, [filter]);

  async function getUser() {
    try {
      setLoading(true);

      const { data: userDetails } = await usuarioService.get(codigo);
      setUsuario(userDetails);

      form.setFieldsValue({
        ...userDetails,
        login: mask(userDetails.login, patterns),
        telefone: userDetails?.telefone
          ? mask(userDetails?.telefone, telefonePatterns)
          : null,
        grupos: userDetails?.grupos.map(({ codigo }) => codigo),
        instituicao: userDetails.instituicao.nome,
        instituicaoMunicipio: userDetails.instituicao.municipio,
        tipoInstituicao: userDetails.instituicao.nomeTipoInstituicao,
        recebeEmail: userDetails?.recebeEmail
      });

      setInstituicaoSelect(userDetails.instituicao);
    } catch (error) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao carregar essa pessoa!</strong>,
        error?.response?.data?.detail
      );
      history.push(urls.USUARIOS_LIST);
    } finally {
      setLoading(false);
    }
  }

  async function getInstitutions(filter) {
    await instituicaoService
      .loadInstitutions(removeEmpty(filter))
      .then((response) => {
        setInstituicoes([...instituicoes, ...response.data.content]);
        setPaginacao({
          currentPage: response?.data?.currentPage,
          totalElements: response?.data?.totalElements,
          size: response?.data?.size,
          totalPages: response?.data?.totalPages,
        });
      })
      .catch(({ response }) => {
        if (response?.data?.status === 403)
          openNotification(
            "error",
            "Acesso negado!",
            "Você não possui autorização para carregar as instituições."
          );
      });
  }

  async function getGroups() {
    try {
      const { data: groups } = await grupoService.list();
      setGroupsList(groups);
    } catch ({ response }) {
      if (response?.data?.status === 403)
        openNotification(
          "error",
          "Acesso negado!",
          "Você não possui autorização para carregar os grupos de permissões."
        );
    }
  }

  async function changeActive() {
    await usuarioService
      .changeActive(codigo, !usuario?.ativo)
      .then(() => {
        getUser();
        openNotification(
          "success",
          <strong>
            {`Usuário ${!usuario?.ativo ? "ativado" : "desativado"
              } com sucesso`}{" "}
          </strong>,
          `${usuario?.nome} foi ${!usuario?.ativo
            ? "ativada(o) com sucesso e agora poderá acessar ao sistema"
            : "desativada(o) com sucesso e não terá mais acessar ao sistema"
          } `
        );
      })
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao alterar status!</strong>,
          "" + reason?.response?.data?.detail
        )
      );
  }

  const onChangeLogin = (value) => {
    form.setFieldsValue({
      login: mask(unMask(value), patterns),
    });
  };

  const onChangeTelefone = (value) => {
    form.setFieldsValue({
      telefone: mask(unMask(value), telefonePatterns),
    });
  };

  const validateLogin = (value) => {
    if (value.length === 14) {
      if (cpf.isValid(value)) return true;
      else {
        openNotification(
          "error",
          <strong>login inválido! </strong>,
          "O cpf informado não é válido!"
        );
      }
    } else if (value.length >= 18) {
      if (cnpj.isValid(value)) return true;
      else {
        openNotification(
          "error",
          <strong>login inválido! </strong>,
          "O cnpj informado não é válido!"
        );
      }
    }
    return;
  };

  const onSubmit = async ({ login, nome, email, grupos, cargo, telefone, recebeEmail }) => {
    try {
      setLoading(true);
      if (validateLogin(login)) {
        const user = {
          login: unMask(login),
          nome,
          email,
          recebeEmail,
          cargo,
          grupos: grupos.map((codigo) => ({ codigo: codigo })),
          telefone: telefone ? unMask(telefone) : null,
          instituicao: { codigo: instituicaoSelect.codigo },
          cpf: cpf.isValid(login) ? login : null,
          cnpj: cnpj.isValid(login) ? login : null,
        };

        if (onEdit) {
          await usuarioService.update(codigo, user);
          openNotification(
            "success",
            <strong>Usuário {nome.split(" ")[0]} editado com sucesso!</strong>,
            <span>
              O Usuário
              <strong> {nome.split(" ")[0]} </strong>
              foi editado com sucesso e as alterações nos dados foram salvas
            </span>
          );

          history.push(urls.USUARIOS_DETAILS.replace(":codigo", codigo));
        } else {
          const { data } = await usuarioService.create(user);

          openNotification(
            "success",
            <strong>
              Usuário {nome.split(" ")[0]} cadastrado com sucesso!
            </strong>,
            <span>
              O Usuário
              <strong> {nome.split(" ")[0]} </strong>
              foi cadastrado com sucesso e as alterações nos dados foram salvas
            </span>
          );

          history.push(urls.USUARIOS_DETAILS.replace(":codigo", data?.codigo));

        }
      }
    } catch (error) {
      openNotification(
        "error",
        <strong>Dados inválidos!</strong>,
        "Erro: " + error?.response?.data?.detail
      );
    } finally {
      setLoading(false);
      getUser();

    }
  };

  async function handleDownloadTermo() {
    setDownloadLoading(true);
    try {
      const response = await usuarioService.getTermo(codigo);

      var data = new Blob([response.data], {
        type: usuario?.termo?.contentType,
      });

      var csvURL = window.URL.createObjectURL(data);
      var tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", usuario?.termo?.nomeArquivo);
      tempLink.click();

      openNotification("success", <strong>Termo baixado com sucesso!</strong>);
    } catch (error) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao baixar o termo!</strong>
      );
    } finally {
      setDownloadLoading(false);
    }
  }

  const renderForm = () =>
    loading ? (
      <Spin spinning={loading} size="large" />
    ) : (
      <Form
        id="form"
        form={form}
        name="user_form"
        onFinish={onSubmit}
        layout="vertical"
        scrollToFirstError
        onFinishFailed={() => setHasError(true)}
        onFieldsChange={() =>
          setHasError(
            !!form.getFieldsError().find(({ errors }) => errors?.length >= 1)
          )
        }
      >
        <div>
          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Form.Item
                label={<span>Nome:</span>}
                name="nome"
                hasFeedback={!onView}
                rules={
                  !onView && [
                    { required: true, message: "Este campo é obrigatório!" },
                  ]
                }
                placeholder=""
              >
                <Input disabled={onView} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Form.Item
                label={<span>E-mail:</span>}
                name="email"
                hasFeedback={!onView}
                rules={
                  !onView && [
                    { required: true, message: "Este campo é obrigatório!" },
                    { type: "email", message: "Insira um email válido" },
                  ]
                }
              >
                <Input disabled={onView} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Form.Item
                label={<span>Telefone:</span>}
                name="telefone"
                hasFeedback={!onView}
                rules={
                  !onView && [
                    {
                      telefonePatterns:
                        /^([0-9]{2}?[0-9]{5}-?[0-9]{4}|[0-9]{2}[0-9]{4}-?[0-9]{4})$/,
                      message: "Insira um telefone válido",
                    },
                  ]
                }
                onChange={({ target }) => onChangeTelefone(target.value)}
              >
                <Input disabled={onView} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label={<span>Login:</span>}
                name="login"
                rules={
                  !onView && [
                    { required: true, message: "Este campo é obrigatório!" },
                    {
                      pattern:
                        /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2})$/,
                      message: "Insira um cpf ou cnpj",
                    },
                  ]
                }
                onChange={({ target }) => onChangeLogin(target.value)}
              >
                <Input disabled={onView} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 2 }}>
              <Form.Item name="recebeEmail" label={<span>Recebe e-mail?</span>}>
                <Switch
                  disabled={onView && true}
                  checkedChildren="sim"
                  unCheckedChildren="não"
                  defaultChecked={!onCreate ? usuario?.recebeEmail : false}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 10 }}>
              <Form.Item
                label={<span>Ativo?:</span>}
                name="ativo"
                defaultValue={true}
              >
                <Select disabled>
                  <Option value={true}>Sim</Option>
                  <Option value={false}>Não</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="instituicao"
                label={<span>Instituição: </span>}
                rules={
                  !onView && [
                    { required: true, message: "Este campo é obrigatório!" },
                  ]
                }
              >
                <Select
                  showSearch={!onView}
                  allowClear={!onView}
                  placeholder="Escolher instituição"
                  onChange={(cod) => {
                    setInstituicaoSelect(
                      instituicoes.find(
                        (instituicao) => instituicao.codigo === cod
                      )
                    );
                  }}
                  disabled={onView}
                  optionFilterProp="label"
                  onSearch={(search) => {
                    setInstituicoes([]);
                    setFilter({ ...filter, page: 0, nome: search });
                  }}
                  onPopupScroll={(e) => {
                    let target = e.target;
                    if (
                      target.scrollTop + target.offsetHeight ===
                      target.scrollHeight
                    ) {
                      if (paginacao.currentPage < paginacao.totalPages)
                        setFilter({ ...filter, page: filter.page + 1 });
                    }
                  }}
                  options={instituicoes?.map(({ nome, codigo }) => ({
                    value: codigo,
                    label: nome,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label={<span>Cargo:</span>}
                name="cargo"
                hasFeedback={!onView}
              >
                <Input disabled={onView} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label={<span>Município:</span>}
                name="instituicaoMunicipio"
                hasFeedback={!onView}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label={<span>Tipo instituição:</span>}
                name="tipoInstituicao"
                hasFeedback={!onView}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label={<span>Grupos: </span>}
                name="grupos"
                rules={[
                  {
                    required: !onView,
                    message:
                      "É necessário inserir pelo menos um grupo para o usuário!",
                    type: "array",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  showSearch
                  optionFilterProp="label"
                  disabled={onView}
                  options={groupsList.map(({ codigo, nome }) => ({
                    label: nome,
                    value: codigo,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        {!onView && !hasError && (
          <FormActions
            inBotton={inBotton}
            actions={[
              <Button
                type="primary"
                ghost
                onClick={() => history.push(urls.USUARIOS_LIST)}
              >
                {onEdit && <span>cancelar edições</span>}
                {!onEdit && !onView && <span>cancelar</span>}
              </Button>,
              <Button type="primary" htmlType="submit">
                {onEdit ? (
                  <span>salvar edições</span>
                ) : (
                  <span>cadastrar usuário</span>
                )}
              </Button>,
            ]}
          />
        )}
      </Form>
    );

  const props = {
    name: "arquivo",
    multiple: false,
    action: process.env.REACT_APP_GATEWAY.concat(
      urls.USUARIOS_TERMO.replace(":codigo", codigo)
    ),
    method: "PUT",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("@oisol/token")}`,
      contentType: "multipart/form-data",
    },
    showUploadList: false,
    maxCount: 1,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        getUser();
        openNotification(
          "success",
          <strong>O arquivo {info.file.name} foi anexado como termo!</strong>
        );
      } else if (status === "error") {
        openNotification(
          "error",
          <strong>Não foi possível anexar o termo.</strong>,
          "Tente novamente ou entre em contato com o suporte do sistema."
        );
      }
    },
  };

  function warningDelete() {
    Modal.confirm({
      title: "ATENÇÃO!",
      content: (
        <p>
          Você tem certeza que deseja{" "}
          <strong>{usuario?.ativo ? "DESATIVAR" : "ATIVAR"}</strong> a usuario{" "}
          <strong>{usuario?.nome}</strong>?
        </p>
      ),
      onOk: () => changeActive(),
      okText: usuario?.ativo ? "desativar" : "ativar",
      cancelText: "cancelar",
      okButtonProps: { danger: usuario?.ativo },
    });
  }

  const menuActions = (
    <Menu>
      <Menu.Item key="editar">
        <Button
          style={{ textAlign: "start", fontSize: "1rem" }}
          block
          type="text"
          onClick={() =>
            history.push(urls.USUARIOS_EDITAR.replace(":codigo", codigo))
          }
          icon={<EditOutlined />}
        >
          editar usuário
        </Button>
      </Menu.Item>

      {onView && usuario && (
        <Menu.Item key="ativo">
          <Button
            style={{ textAlign: "start", fontSize: "1rem" }}
            block
            type="text"
            danger={usuario?.ativo}
            icon={usuario?.ativo ? <UserDeleteOutlined /> : <UserAddOutlined />}
            onClick={() => {
              warningDelete();
            }}
          >
            {usuario?.ativo ? "desativar" : "ativar"} usuário
          </Button>
        </Menu.Item>
      )}
      {onView && usuario?.termo && (
        <Menu.Item key="termo">
          <Button
            style={{ textAlign: "start", fontSize: "1rem" }}
            block
            type="text"
            icon={<DownloadOutlined />}
            onClick={handleDownloadTermo}
            loading={downloadLoading}
          >
            baixar termo
          </Button>
        </Menu.Item>
      )}
      <Menu.Item key="termo-down">
        <Upload {...props} accept=".pdf">
          <Button
            style={{ textAlign: "start", fontSize: "1rem" }}
            block
            type="text"
            icon={usuario?.termo ? <FileSyncOutlined /> : <FileAddOutlined />}
            loading={downloadLoading}
          >
            {usuario?.termo ? "atualizar" : "anexar"} termo
          </Button>
        </Upload>
      </Menu.Item>

      <Menu.Item key="voltar">
        <Button
          style={{ textAlign: "start", fontSize: "1rem" }}
          block
          type="text"
          danger
          onClick={() => history.push(urls.USUARIOS_LIST)}
          icon={<RollbackOutlined />}
        >
          voltar
        </Button>
      </Menu.Item>
    </Menu>
  );

  const renderExtraHeader = () => (
    <Dropdown
      trigger={["hover", "click"]}
      placement="bottomRight"
      overlay={menuActions}
    >
      {width < 900 ? (
        <Button
          style={{
            textAlign: "start",
            cursor: "pointer",
          }}
          block
          type="text"
          icon={<MoreOutlined style={{ fontSize: "1.2rem" }} />}
        />
      ) : (
        <Button
          style={{
            textAlign: "start",
            fontSize: "1rem",
            cursor: "pointer",
          }}
          block
          type="text"
        >
          AÇÕES <MoreOutlined style={{ fontSize: "1.2rem" }} />
        </Button>
      )}
    </Dropdown>
  );

  return (
    <div id="sectionMain" onScroll={(e) => setInBotton(scrollBotton(e))}>
      <Breadcrumb id="breadcrumb">
        <Breadcrumb.Item key="home">
          <Link to="">
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="gerenciamento">
          controle de acesso
        </Breadcrumb.Item>
        <Breadcrumb.Item key="usuarios">
          <Link to={urls.USUARIOS_LIST}>usuários</Link>
        </Breadcrumb.Item>

        {onCreate && (
          <Breadcrumb.Item key="criar">
            <strong>cadastrar usuário</strong>
          </Breadcrumb.Item>
        )}

        {onView && (
          <Breadcrumb.Item key="usuario">
            <strong>{usuario?.nome?.toLowerCase()}</strong>
          </Breadcrumb.Item>
        )}

        {onEdit && (
          <>
            <Breadcrumb.Item key="usuario">
              <Link
                to={urls.USUARIOS_DETAILS.replace(":codigo", usuario?.codigo)}
              >
                {usuario?.nome?.toLowerCase()}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="editar">
              <strong>editar usuário</strong>
            </Breadcrumb.Item>
          </>
        )}
      </Breadcrumb>
      <div className="sectionChildren">
        <PageHeader
          titulo={onCreate ? "CADASTRAR USUÁRIO" : usuario?.nome}
          subtitulo={
            onCreate
              ? "Inserir informações sobre o usuário"
              : onEdit
                ? "Editar informações sobre o usuário"
                : "Informações sobre o usuário"
          }
          extra={onView && renderExtraHeader()}
        />
        {renderForm()}
      </div>
    </div>
  );
};
