import { Badge, Button, Col, Empty, Form, Input, Modal, Row, Select, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { instituicaoVitimaClassificacaoService } from "../../../services";
import { openNotification } from "../../../components/Notification";
import { classificacaoAtributoService } from "../../../services/resources/classificacaoAtributoService";
import { CopyOutlined, QuestionOutlined } from "@ant-design/icons";

export const ClassificacaoEncaminhamento = ({ colunasAcoes, codigoEncaminhamento }) => {

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const [instituicaoVitimaClassificacoes, setInstituicaoVitimaClassificacoes] = useState([]);

    const [procedencia, setProcedencia] = useState(false);
    const [openModalProcedencia, setOpenModalProcedencia] = useState(false);

    const [classificacaoForm] = Form.useForm();
    const [codigoInstituicaoVitimaClassificacao, setCodigoInstituicaoVitimaClassificacao] = useState(null);
  
    const [classificacoesAtributos, setClassificacoesAtributos] = useState([]);

    useEffect(() => {
        getClassificacoesAtributos();
        getInstituicaoVitimaClassificacao();
        // eslint-disable-next-line
      }, []);
    
      async function getClassificacoesAtributos() {
        await classificacaoAtributoService
          .loadClassificacoes()
          .then((response) => setClassificacoesAtributos(response.data.filter((a) => a.descricao !== 'NÃO INFORMADO')))
          .catch((reason) =>
            openNotification(
              "error",
              <strong>Ocorreu um erro ao buscar os temas!</strong>,
              reason?.response?.data?.detail
            )
          );
      }

    async function getInstituicaoVitimaClassificacao() {
        setLoading(true);
        await instituicaoVitimaClassificacaoService
          .list(codigoEncaminhamento)
          .then((response) => {
            setInstituicaoVitimaClassificacoes(response.data);
          })
          .catch((reason) =>
            openNotification(
              "error",
              <strong>
                Ocorreu um erro ao buscar as classificações do encaminhamento!
              </strong>,
              reason?.response?.data?.detail
            )
          ).finally(() => {
            setLoading(false);
        });
    }

    async function handleClassificacaoAtributoSelect(codigo) {
        const classificacaoAtributo = classificacoesAtributos.find(classificacao => classificacao.codigo === codigo);
        setProcedencia(classificacaoAtributo.justificativaObrigatoria);
    }
    
    async function handleDefinirProcedencia(classificacao) {
        setCodigoInstituicaoVitimaClassificacao(classificacao.codigo);
    
        const classificacaoAtributo = classificacoesAtributos.find(c => c.descricao === classificacao.classificacaoAtributoDescricao);
        setProcedencia(classificacaoAtributo?.justificativaObrigatoria)
    
        classificacaoForm.setFieldsValue({
          classificacaoAtributo: classificacaoAtributo?.codigo,
          justificativa: classificacao?.justificativa
        });
    
        setOpenModalProcedencia(true);
    }

    const colunasClassificacao = [
        {
          title: <strong>procedência</strong>,
          dataIndex: "classificacaoAtributoDescricao",
          width: 0,
          sorter: {
            multiple: 1,
          },
    
          render: (classificacaoAtributoDescricao) => (
            <Badge
              style={{
                backgroundColor:
                  classificacaoAtributoDescricao === "PROCEDENTE"
                    ? "var(--green-color)"
                    : classificacaoAtributoDescricao === "IMPROCEDENTE"
                      ? "var(--red-color)"
                      : "#333333",
                fontWeight: "bold",
              }}
              count={classificacaoAtributoDescricao}
            />
          ),
        },
        {
          title: <strong>tema</strong>,
          dataIndex: ["classificacaoTemaDescricao"],
          key: "codigo",
        },
        {
          title: <strong>tipo de violação</strong>,
          dataIndex: ["classificacaoTipoViolacaoDescricao"],
          key: "codigo",
        },
        {
          title: <strong>subtipo de violação</strong>,
          dataIndex: ["classificacaoSubTipoViolacaoDescricao"],
          key: "codigo",
        },
        {
          title: <strong>vítima associada</strong>,
          dataIndex: ["classificacaoVitimaNome"],
          key: "codigo",
        },
      ];
    
      const colunasClassificacaoAcoes = {
        title: <strong>ações</strong>,
        key: "action",
        fixed: "right",
        width: 32,
        render: (classificacao) => (
          <Space size="small">
            <Button
              className="action-button"
              title={`definir procedência`}
              onClick={() => handleDefinirProcedencia(classificacao)}
            >
              <QuestionOutlined style={{ fontSize: "1.2rem" }} />
            </Button>
          </Space>
        ),
      };

      const colunas = colunasAcoes ? [...colunasClassificacao, colunasClassificacaoAcoes] : colunasClassificacao;
    
      async function handleChangeProcedencia() {
        setDisabled(true);
        setLoading(true);
    
        classificacaoForm.setFieldsValue({
          classificacaoAtributo: {codigo: classificacaoForm.getFieldValue().classificacaoAtributo},
          justificativa: classificacaoForm.getFieldValue().justificativa
        });
    
        await instituicaoVitimaClassificacaoService
          .edit(codigoInstituicaoVitimaClassificacao, classificacaoForm.getFieldValue())
          .then(() => {
            getInstituicaoVitimaClassificacao();
            openNotification(
              "success",
              <strong>Procedência da classificação atualizada com sucesso!</strong>
            );
          })
          .catch((error) => {
            openNotification(
              "error",
              <strong>
                Ocorreu um erro ao mudar a procedência da classificação!
              </strong>,
              error.response.data.detail
            );
          }).finally(() => {
            setLoading(false);
            setOpenModalProcedencia(false);
            classificacaoForm.resetFields();
            setDisabled(false);
        });
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    {instituicaoVitimaClassificacoes?.length > 0 ? (
                    <Table
                        loading={loading}
                        dataSource={instituicaoVitimaClassificacoes}
                        columns={colunas}
                        size="small"
                        rowKey={({ codigo }) => codigo}
                        expandable={
                        {
                            expandedRowRender: (classificacao) => {
                            return (
                                <Row>
                                <Col>
                                    <strong>Justificativa da classificação</strong>
                                    <Row align="middle" style={{ marginTop: 5 }}>
                                    <CopyOutlined
                                        onClick={() => navigator.clipboard.writeText(classificacao.justificativa)}
                                        style={{ fontSize: 18 }}
                                    />
                                    <span style={{ marginLeft: 10 }}>{classificacao.justificativa}</span>
                                    </Row>
                                </Col>
                                </Row>
                            );
                            }
                        }
                        }
                        pagination={{
                        total: instituicaoVitimaClassificacoes.length,
                        showTotal: (total) => (
                            <strong>
                            {total === 1
                                ? `${total} violação no total`
                                : `${total} violações no total`}
                            </strong>
                        ),
                        }}
                    />
                    ) : (
                    <Empty
                        description={<h2>Sem classificações para essa denúncia</h2>}
                    />
                    )}
                </Col>
                </Row>

            {openModalProcedencia && (
                <Modal
                title={"Como gostaria de definir essa classificação?"}
                width={"50vw"}
                centered
                visible
                onCancel={() => {
                    setOpenModalProcedencia(false);
                    classificacaoForm.resetFields();
                }}
                footer={null}
                >
                <Form
                    form={classificacaoForm}
                    layout="vertical"
                    onFinish={handleChangeProcedencia}
                >
                    <Row gutter={32}>
                    <Col xs={{ span: 24 }} >
                        <Form.Item
                        name="classificacaoAtributo"
                        label={<span>Classificação:</span>}
                        rules={[
                            { required: true, message: "Este campo é obrigatório!" },
                        ]}
                        >
                        <Select
                            disabled={disabled}
                            DE
                            placeholder="Selecionar..."
                            onChange={handleClassificacaoAtributoSelect}
                            options={classificacoesAtributos?.map(({ id, codigo, descricao }) => ({
                            label: descricao,
                            value: codigo,
                            key: id,
                            })
                            )}
                        ></Select>
                        </Form.Item>
                    </Col>

                    {procedencia === true && (
                        <Col span={24}>
                        <Form.Item
                            name="justificativa"
                            label={<span>Justificativa:</span>}
                            rules={[
                            { required: true, message: "Este campo é obrigatório!" },
                            ]}
                        >
                            <Input.TextArea disabled={disabled}/>
                        </Form.Item>
                        </Col>
                    )}
                    </Row>
                    <Row justify="end">
                    <Button type="primary" htmlType="submit" disabled={disabled}>
                        atualizar
                    </Button>
                    </Row>
                </Form>
                </Modal>
            )}
        </>
    );
};