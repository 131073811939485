import React from "react";

import noServe from "../../images/noserve.svg";

import { Container } from "./styles";

function ServeDown() {
  return (
    <>
      <Container>
        <h1>Servidor está inacessível !!!</h1>
        <img src={noServe} alt="" />
      </Container>
    </>
  );
}

export default ServeDown;
