export const dicionario = {
  vitima: "vítima",
  suspeito: "suspeito",
  denunciante: "denunciante",
};

export const labelTags = {
  cidadeIdList: "cidade",
  codigoTemaList: "Tema",
  codigoTipoViolacaoList: "Violação",
  codigoSubTipoViolacaoList: "Subtipo Violação",
  codigoFrequencia: "Frequência",
  codigoCriticidade: "Criticidade",
  codigoLocalOcorrencia: "Local Ocorrência",
  denunciaBairroNome: "Bairro",
  nomeBairro: "bairro",
  temas: "temas",
  tipos: "tipos",
  subtipos: "subtipos",
  procede: "procedência",
  nomePessoa: "pessoa",
  nomeNotificante: "notificante",
  nomeVitima: "vítima",
  nomeSuspeito: "suspeito",
  origemDenuncia: "origem"
};
