import api from "../api";

export const religiaoService = {
  async load() {
    return await api.get("/religioes");
  },
  async get(codigo) {
    return await api.get(`/religioes/${codigo}`);
  },
  async create(payload) {
    return await api.post(`/religioes`, payload);
  },
  async edit(codigo, payload) {
    return await api.put(`/religioes/${codigo}`, payload);
  },
  async delete(codigo) {
    return await api.delete(`/religioes/${codigo}`);
  },
}