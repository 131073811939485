import { ConfigProvider } from "antd";
import ptBR from "antd/lib/locale/pt_BR";
import { UserStorage } from "./contexts/UserContext";
import Rotas from "./rotas";

import "antd/dist/antd.less";
import "./App.css";

const App = () => {
  return (
    <ConfigProvider locale={ptBR}>
      <UserStorage>
        <Rotas />
      </UserStorage>
    </ConfigProvider>
  );
};

export default App;
