import api from "../api";

export const subTipoViolacaoService = {
  async loadViolationSubTypes(params) {
    return await api.get("/subtipos-violacoes", {
      params,
    });
  },
  async getViolationSubType(id) {
    return await api.get(`/subtipos-violacoes/${id}`);
  },
  async createViolationSubType(payload) {
    return await api.post(`/subtipos-violacoes`, payload);
  },
  async editViolationSubType(id, payload) {
    return await api.put(`/subtipos-violacoes/${id}`, payload);
  },
  async deleteViolationSubType(id) {
    return await api.delete(`/subtipos-violacoes/${id}`);
  },
};
