import React, { useState, useEffect, useContext } from "react";
import moment from "moment";

import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { UserContext } from "../../../contexts/UserContext";

import { openNotification } from "../../../components/Notification";

import {
  Form,
  Input,
  DatePicker,
  Select,
  Row,
  Col,
  Button,
  Modal,
  Breadcrumb,
} from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { urls } from "../../../utils";
import InstituicaoPaginada from "../../Institution/Components/InsituicaoPaginada";
import { categoriaService } from "../../../services/resources/categoriaService";
import { encaminhamentoService } from "../../../services/resources/encaminhamentoService";
import { denuncianteService } from "../../../services/resources/denuncianteService";

const { Option } = Select;
const { TextArea } = Input;

const CreateForwarding = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const { user } = useContext(UserContext);

  const { codigo } = useParams();

  const diasEstimadosPadrão = 15
  const dateFormat = "DD/MM/YYYY";
  const dateInitial = moment().format("DD/MM/YYYY");
  const dataPrevisaoInicial = moment(new Date(), dateFormat).add(diasEstimadosPadrão, "days")

  const [prioridadeSelect, setPrioridadeSelect] = useState("");
  const [criticidade, setCriticidade] = useState("");
  const [protocolo, setProtocolo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [dataPrevisao, setDataPrevisao] = useState(dataPrevisaoInicial);
  const [instituicao, setInstituicao] = useState("");

  const [isModalSelectVisible, setIsModalSelectVisible] = useState(false);

  const [prioridades, setPrioridades] = useState([]);

  function handleSelectinsitution(event) {
    event.preventDefault();
    setIsModalSelectVisible(true);
  }

  async function handleInserirInstituicao({ codigo, nome }) {
    setInstituicao(codigo);
    form.setFieldsValue({
      instituicao: nome,
    });
    setIsModalSelectVisible(false);
  }

  useEffect(() => {

    async function getPrioridades() {
      await categoriaService
        .loadPriorities()
        .then((response) => {
          setPrioridades(response.data);
        })
        .catch((reason) =>
          openNotification(
            "error",
            <strong>Ocorreu um erro ao buscar as Prioridades!</strong>,
            "Erro: " + reason
          )
        );
    }

    async function getDenunciation() {
      await denuncianteService
        .getDenunciation(codigo)
        .then((response) => {
          const { identificadorExterno, criticidade } = response.data;
          setProtocolo(identificadorExterno);
          setCriticidade(criticidade.descricao);
        })
        .catch((reason) =>
          openNotification(
            "error",
            <strong>Ocorreu um erro ao buscar a denúncia!</strong>,
            "Erro: " + reason
          )
        );
    }

    getPrioridades();
    getDenunciation();

  }, [codigo]);

  async function handleSubmit() {
    const newCriticidade = prioridades.find((prioridade) => prioridade.descricao === criticidade)

    const forwarding = {
      dataInicio: moment(),
      dataPrevistaConclusao: dataPrevisao,
      observacao: descricao,
      denuncia: {
        codigo: codigo,
      },
      prioridade: {
        codigo: newCriticidade.codigo,
      },
      instituicaoDestinataria: {
        codigo: instituicao,
      },
      usuario: {
        codigo: user.codigo_usuario,
      },
    };

    await encaminhamentoService
      .createForwarding(forwarding)
      .then(() => {
        openNotification(
          "success",
          <strong>{`Encaminhamento realizado com sucesso!`}</strong>,
          `A instituição receberá essa denúncia.`
        );
        history.push(urls.DENUNCIA_DETAILS.replace(":codigo", codigo));
      })
      .catch(({ response }) =>
        openNotification(
          "error",
          <strong>Erro!</strong>,
          response.data.userMessage
        )
      );
  }

  const renderForm = () => (
    <Form
      onFinish={handleSubmit}
      name="encaminhamento"
      form={form}
      layout="vertical"
    >
      <Row gutter={32}>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <Form.Item label={<span>Protocolo da denúncia:</span>}>
            <Input name="protocolo" id="protocolo" value={protocolo} disabled />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <Form.Item
            label={<span>Prioridade:</span>}
            rules={[{ required: true, message: "Este campo é obrigatório!" }]}
            initialValue={criticidade}
          >
            <Input name="criticidade" id="criticidade" value={criticidade} disabled />

            {/* <Select
              disabled
              name="prioridade"
              id="prioridade"
              required
              allowClear
              placeholder="Escolher Prioridade"
              onChange={setPrioridadeSelect}
              defaultValue={criticidade}
              value={criticidade}
            >
              {prioridades?.map(({ descricao, codigo }) => (
                <Option value={codigo} key={codigo}>
                  {descricao}
                </Option>
              ))}
            </Select> */}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span="24">
          <Form.Item
            name="instituicao"
            label={<span>Instituição responsável:</span>}
            rules={[{ required: true, message: "Este campo é obrigatório!" }]}
          >
            <Input
              showSearch
              allowClear
              onClick={(event) => {
                handleSelectinsitution(event);
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={32}>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <Form.Item label={<span>Data de início:</span>}>
            <Input
              name="dateInitial"
              id="dateInitial"
              value={dateInitial}
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <Form.Item
            initialValue={dataPrevisao}
            name="data"
            label={<span>Data prevista para a conclusão:</span>}
            rules={[{ required: true, message: "Este campo é obrigatório!" }]}
          >
            <DatePicker
              style={{ width: "100%" }}
              format={dateFormat}
              defaultValue={dataPrevisaoInicial}
              placeholder="Formato dd/mm/aaaa"
              onChange={(date) => setDataPrevisao(date)}
              disabledDate={(d) => !d || d.isBefore(new Date())}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span="24">
          <Form.Item label={<span>Observações sobre o encaminhamento:</span>}>
            <TextArea
              value={descricao}
              rows={5}
              onChange={(e) => setDescricao(e.target.value)}
              placeholder="Informações complementares sobre a instituição"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="space-between" align="bottom">
        <Link to={urls.DENUNCIA_DETAILS.replace(":codigo", codigo)}>
          <Button type="button">voltar à denúncia</Button>
        </Link>
        <Button
          htmlType="submit"
          type="primary"
          style={{ width: "160px", marginLeft: "5px" }}
        >
          encaminhar
        </Button>
      </Row>
    </Form>
  );

  return (
    <>
      <div id="sectionMain">

        <Breadcrumb id="breadcrumb">
          <Breadcrumb.Item key="home">
            <Link to="">
              <HomeOutlined />
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item key="registros">registros</Breadcrumb.Item>
          <Breadcrumb.Item key="denuncias">
            <Link to={urls.DENUNCIAS_LIST}>denúncias</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item key="denuncia">
            <Link to={urls.DENUNCIA_DETAILS.replace(":codigo", codigo)}>
              denúncia #{protocolo}
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item key="encaminhar">
            <strong>encaminhar</strong>
          </Breadcrumb.Item>
        </Breadcrumb>

        <div className="section-children">
          <div className="container-section">
            <Row justify="space-between" align="middle">
              <Col>
                <h2>ENCAMINHAR DENÚNCIA #{protocolo}</h2>
                <span>
                  Inserir informações sobre o encaminhamento da denúncia
                </span>
              </Col>
            </Row>

            {renderForm()}
          </div>
        </div>
      </div>
      <Modal
        width="90vw"
        bodyStyle={{
          height: "70vh",
          overflowY: "auto",
          display: "flex",
          gap: "2rem",
          flexDirection: "column",
        }}
        centered
        title={<strong>LISTA DE INSTITUIÇÕES</strong>}
        visible={isModalSelectVisible}
        onCancel={() => setIsModalSelectVisible(false)}
        footer={
          <Button
            key="back"
            type="danger"
            onClick={() => setIsModalSelectVisible(false)}
          >
            cancelar
          </Button>
        }
      >
        <InstituicaoPaginada
          tableActions={({ codigo, nome }) =>
            codigo !== instituicao ? (
              <Button
                onClick={() => handleInserirInstituicao({ codigo, nome })}
                type="primary"
                size="small"
              >
                inserir instituição
              </Button>
            ) : (
              <Button
                onClick={() => setInstituicao("")}
                type="danger"
                size="small"
              >
                retirar instituição
              </Button>
            )
          }
          cardActions={({ codigo, nome }) =>
            codigo !== instituicao ? (
              <Button
                block
                onClick={() => handleInserirInstituicao({ codigo, nome })}
                type="primary"
                size="large"
              >
                inserir instituição
              </Button>
            ) : (
              <Button
                block
                onClick={() => setInstituicao("")}
                type="danger"
                size="large"
              >
                retirar instituição
              </Button>
            )
          }
        />
      </Modal>

      <Modal>

      </Modal>
    </>
  );
};

export default CreateForwarding;
