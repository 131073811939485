import React, { useCallback, useEffect, useState } from "react";

import jwtDecode from "jwt-decode";
import api from "../services/api";

import { services } from "../services/login";
import ServeDown from "../components/ServeDown/ServeDown";
import { publicoService } from "../services/resources/publicoService";
import { categoriaService } from "../services/resources/categoriaService";
import { enderecoService } from "../services/resources/enderecoService";
import { temaService } from "../services/resources/temaService";
import { openNotification } from "../components/Notification";

const CEARA_ID = process.env.REACT_APP_CEARA_ID;

export const UserContext = React.createContext();

export const UserStorage = ({ children }) => {
  const [user, setUser] = useState(null);

  const [isAuthenticated, setIsAuthenticated] = useState(null);

  const [loading, setLoading] = useState(false);
  const [currentMenu, setCurrentMenu] = useState("");

  const [estados, setEstados] = useState([]);
  const [cities, setCities] = useState([]);
  const [themes, setThemes] = useState([]);
  const [status, setStatus] = useState([]);

  const [error, setError] = useState(null);
  const [noServe, setNoServe] = useState(null);

  const [backendVersion, setBackendVersion] = useState("");

  api.interceptors.response.use(
    (response) => {
      return response;
    },

    (error) => {
      if (error?.message === "Network Error") setNoServe(true);
      else if (error?.response?.status === 401) {
        userLogout();
        return Promise.reject(error);
      } else return Promise.reject(error);
    }
  );

  const userLogout = useCallback(() => {
    setIsAuthenticated(false);
    window.localStorage.removeItem("@oisol/token");
    setUser(null);
  }, []);

  useEffect(() => {
    async function getVersion() {
      await publicoService
        .versao()
        .then(({ data }) => setBackendVersion(data))
        .catch((error) => {
          if (error?.message === "Network Error") setNoServe(true);
        });
    }

    if (isAuthenticated) {
      setLoading(true);

      async function getStatus() {
        await categoriaService
          .loadStatusDenunciation()
          .then(({ data }) => setStatus(data))
          .catch((reason) =>
            openNotification(
              "error",
              <strong>Erro!</strong>,
              "" + reason?.response?.data?.detail
            )
          );
      }

      async function getCitys() {
        try {
          const { data } = await enderecoService.getCitysState(CEARA_ID);
          setCities(data);
        } catch ({ response }) {
          console.log(response);
        }
      }

      async function getThemes() {
        await temaService
          .loadThemes()
          .then(({ data }) => setThemes(data))
          .catch((reason) =>
            openNotification(
              "error",
              <strong>Erro!</strong>,
              "" + reason?.response?.data?.detail
            )
          );
      }

      async function getEstados() {
        await enderecoService
          .loadStates({ ativo: true })
          .then((response) => {
            setEstados(response.data);
          })
          .catch((reason) =>
            openNotification(
              "error",
              <strong>Ocorreu um erro ao carregar os Estados!</strong>,
              "Erro: " + reason?.response?.data?.detail
            )
          );
      }

      getEstados();
      getStatus();
      getCitys();
      getThemes();
      setLoading(false);
    }

    getVersion();
  }, [isAuthenticated]);

  useEffect(() => {
    autoLogin();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function autoLogin() {
    const token = window.localStorage.getItem("@oisol/token");
    if (token) {
      try {
        const { data } = await services.user.checkToken(token);
        setUser(data);
        setIsAuthenticated(true);
      } catch (error) {
        userLogout();
        if (error?.message === "Network Error") setNoServe(true);
        else setError(error?.response?.data?.error_description);
      }
    } else {
      setIsAuthenticated(false);
    }
  }
  async function decodeToken(token) {
    try {
      const data = await jwtDecode(token);
      return data;
    } catch (error) {
      setError(true);
      userLogout();
    }
  }

  async function userLogin(username, password) {
    setIsAuthenticated(false);
    try {
      setError(null);
      setLoading(true);
      const { data } = await services.user.login(username, password);
      window.localStorage.setItem("@oisol/token", data.access_token);
      const user = await decodeToken(data.access_token);
      setIsAuthenticated(true);
      setUser(user);
      setLoading(false);
    } catch (error) {
      if (error?.message === "Network Error") setNoServe(true);
      else setError(error?.response?.data?.error_description);
    } finally {
      setLoading(false);
    }
  }

  return (
    <UserContext.Provider
      value={{
        userLogin,
        userLogout,
        isAuthenticated,
        loading,
        error,
        status,
        setError,
        user,
        currentMenu,
        setCurrentMenu,
        cities,
        setCities,
        estados,
        themes,
        backendVersion,
        setLoading,
        autoLogin,
      }}
    >
      {noServe ? <ServeDown /> : children}
    </UserContext.Provider>
  );
};
