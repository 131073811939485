import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { UserContext } from "../../../contexts/UserContext";

import {
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Empty,
  Form,
  Input,
  Modal,
  Popconfirm,
  Popover,
  Row,
  Space,
  Table,
  Tabs,
  Timeline,
} from "antd";

import {
  BankOutlined,
  CloudDownloadOutlined,
  DeleteOutlined,
  DownloadOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileUnknownOutlined,
  FolderOpenOutlined,
  GifOutlined,
  HomeOutlined,
  InboxOutlined,
  PictureOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
  ToolOutlined,
  UserOutlined,
} from "@ant-design/icons";

import Dragger from "antd/lib/upload/Dragger";
import { openNotification } from "../../../components/Notification";
import { STATUS, urls, USER_ROLES } from "../../../utils";
import FileDonwload from "js-file-download";

import moment from "moment";
import { encaminhamentoService } from "../../../services/resources/encaminhamentoService";
import DialogModal from "../../../components/DialogModal";
import { observacaoPublicaService } from "../../../services/resources/observacaoPublicaService";
import { TimelineProgressoEncaminhamento } from "./TimelineProgressoEncaminhamento";
import { ClassificacaoEncaminhamento } from "./ClassificacaoEncaminhamento";
import { instituicaoVitimaClassificacaoService } from "../../../services";

const { TextArea } = Input;

const DATA_FORMATO = "DD/MM/YYYY";

const ViewForwarding = () => {
  const { user } = useContext(UserContext);
  const { codigo } = useParams();

  const [protocolo, setProtocolo] = useState("");
  const [prioridade, setPrioridade] = useState("");
  const [prioridadeCodigo, setPrioridadeCodigo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [observacaoPublica, setObservacaoPublica] = useState("");
  const [instituicao, setInstituicao] = useState("");
  const [instituicaoRemetente, setInstituicaoRemetente] = useState("");
  const [instituicaoCodigo, setInstituicaoCodigo] = useState("");

  const [codigoDenuncia, setCodigoDenuncia] = useState("");

  const [dataInicio, setDataInicio] = useState("");
  const [dataPrevisao, setDataPrevisao] = useState("");
  const [dataConclusao, setDataConclusao] = useState("");
  const [statusCurrent, setStatusCurrent] = useState("");

  const [encaminhadoPor, setEncaminhadoPor] = useState("");
  const [encaminhadoPorCodigo, setEncaminhadoPorCodigo] = useState("");

  const [acoes, setAcoes] = useState([]);
  const [observacoes, setObservacoes] = useState([]);
  const [justificativasRecusa, setJustificativasRecusa] = useState([]);
  const [justificativasDevolucao, setJustificativasDevolucao] = useState([]);

  const [progresso, setProgresso] = useState([]);

  const [stateModalJustify, setStateModalJustify] = useState(false);
  const [stateModalRefused, setStateModalRefused] = useState(false);
  const [stateModalReturning, setStateModalReturning] = useState(false);

  const [popLoading, setPopLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [justificativa, setJustificativa] = useState("");

  const [anexos, setAnexos] = useState([]);

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openDialogModalConfirmarAnalise, setOpenDialogModalConfirmarAnalise] =
    useState(false);
  const [isModalObservacaoPublica, setIsModalObservacaoPublica] =
    useState(false);

    const [openClassificacaoModal, setOpenClassificacaoModal] = useState(false);
    const [finalizar, setFinalizar] = useState(false);

  const [paramsPDF, setParamsPDF] = useState({});

  const [confirmLoading, setConfirmLoading] = useState(false);

  const [instituicaoVitimaClassificacoes, setInstituicaoVitimaClassificacoes] = useState([]);

  const columns = [
    {
      title: <strong>Descrição</strong>,
      dataIndex: "descricao",
      key: "descricao",
    },
    {
      title: <strong>Usuário</strong>,
      dataIndex: ["usuario", "nome"],
      key: "usuario",
    },
    {
      title: <strong>Data</strong>,
      dataIndex: "dataCadastro",
      key: "dataCadastro",
      render: (text) => (text ? moment(text).format("DD/MM/yyyy") : ""),
    },
    {
      title: <strong>Ações</strong>,
      key: "acoes",
      render: (observacaoPublica) => (
        <>
          {(user?.authorities?.includes(USER_ROLES.DELETAR_OBSERVACAO_PUBLICA)) 
                  && (
            <Popconfirm
                title={
                  <span>
                    Excluir observação pública?"
                  </span>
                }
                placement="bottomRight"
                okText="confirmar exclusão"
                cancelText="cancelar"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={() => handleDeletarObservacao(observacaoPublica)}
                okButtonProps={{ loading: confirmLoading }}
                disabled={disabled}
                cancelButtonProps={{ disabled: disabled }}
              >
              <Button
                type="danger"
                className="action-button"
                title={`Excluir observação pública`}>
                <DeleteOutlined style={{ fontSize: "1.2rem" }} />
              </Button>
            </Popconfirm>
          )}
        </>
      )
    },
  ];

  if (!user?.authorities?.includes(USER_ROLES.VISUALIZAR_OPERADOR_DENUNCIA))
    delete columns[1];

  async function handleDeletarObservacao(observacaoPublica) {
    setConfirmLoading(true);
    setDisabled(true);
    
    await observacaoPublicaService
      .deleteObservacao(codigo, observacaoPublica.codigo)
      .then((response) => {
        openNotification(
          "success",
          <strong>Observação pública excluída!</strong>
        );
        getForwarding();
      })
      .catch((reason) => {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao tentar excluir a observação pública!</strong>,
          "Erro: " + reason?.response?.data?.detail
        );
      }).finally(() => {
        setConfirmLoading(false);
        setDisabled(false);
      });
  }

  async function handleReply() {

    try {
      instituicaoVitimaClassificacaoService.list(codigo)
        .then((response) => {
          setInstituicaoVitimaClassificacoes(response.data);
          if (response.data.some((i) => i.classificacaoAtributoDescricao === 'NÃO INFORMADO')) {
            openNotification(
              "error",
              "Todas as classificações precisam esta diferente de",
              <strong>
               NÃO INFORMADO!
              </strong>
            );
          } else {
            setLoading(true);
            setConfirmLoading(true);
            setDisabled(true);

            encaminhamentoService
              .replyForwarding(codigo)
              .then(() => {
                openNotification(
                  "success",
                  <strong>Encaminhamento enviado!</strong>,
                  "Uma vez enviado, o encaminhamento terá seu status alterado para “a validar” e será conferido pelo observatório, não podendo mais ser alterado."
                );
                getForwarding();
              })
              .catch((reason) =>
                openNotification(
                  "error",
                  <strong>Ocorreu um erro ao responder o encaminhamento!</strong>,
                  "Erro: " + reason?.response?.data?.detail
                )
            ).finally(() => {
              setOpenClassificacaoModal(false);
              setLoading(false);
              setConfirmLoading(false);
              setDisabled(false);
            });
          }
        })
    } catch {

    }
  }

  async function getInstituicaoVitimaClassificacao() {
    setLoading(true);
    await instituicaoVitimaClassificacaoService
      .list(codigo)
      .then((response) => {
        setInstituicaoVitimaClassificacoes(response.data);
        setFinalizar(response.data.some((i) => i.classificacaoAtributoDescricao === 'NÃO INFORMADO')); 
      })
      .catch((reason) =>
        openNotification(
          "error",
          <strong>
            Ocorreu um erro ao buscar as classificações do encaminhamento!
          </strong>,
          reason?.response?.data?.detail
        )
      ).finally(() => {
        setLoading(false);
    });
}

  useEffect(() => {
    getInstituicaoVitimaClassificacao();
    getForwarding();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setProgresso([
      ...acoes,
      ...justificativasRecusa,
      ...justificativasDevolucao,
    ]);
    // eslint-disable-next-line
  }, [acoes, justificativasRecusa, justificativasDevolucao]);

  async function getForwarding() {
    setLoading(true);
    await encaminhamentoService
      .getForwarding(codigo)
      .then((response) => {
        const forwarding = response.data;
        setAnexos(
          forwarding?.anexos?.map((anexo) => ({
            ...anexo,
            dataCadastro: moment(anexo.dataCadastro).format(DATA_FORMATO),
          }))
        );

        setCodigoDenuncia(forwarding.denuncia.codigo);
        setProtocolo(forwarding.denuncia.identificadorExterno);
        setDescricao(forwarding.observacao);
        setDataInicio(forwarding.dataInicio);
        setDataPrevisao(forwarding.dataPrevistaConclusao);
        setDataConclusao(forwarding.dataConclusao);
        setStatusCurrent(forwarding.status.descricao);
        setInstituicaoRemetente(forwarding.instituicaoRemetente);
        setObservacaoPublica(forwarding.observacaoPublica);
        setObservacoes(forwarding?.observacoes);

        setPrioridade(
          forwarding.prioridade
            ? forwarding.prioridade.descricao
            : "Não informado"
        );

        setPrioridadeCodigo(
          forwarding.prioridade ? forwarding.prioridade.codigo : ""
        );

        setInstituicao(
          forwarding.instituicaoDestinataria.nome
            ? forwarding.instituicaoDestinataria.nome
            : ""
        );

        setInstituicaoCodigo(
          forwarding.instituicaoDestinataria.codigo
            ? forwarding.instituicaoDestinataria.codigo
            : ""
        );

        setEncaminhadoPor(forwarding.usuario.nome);
        setEncaminhadoPorCodigo(forwarding.usuario.codigo);

        transformList(
          forwarding.justificativasRecusa,
          forwarding.justificativasDevolucao,
          forwarding.acoes
        );

        setParamsPDF({
          dataOcorrencia:
            forwarding.denuncia.dataOcorrencia &&
            moment(forwarding.denuncia.dataOcorrencia).format(DATA_FORMATO),
          dataInicio:
            forwarding.dataInicio &&
            moment(forwarding.dataInicio).format(DATA_FORMATO),
          dataPrevistaConclusao: moment(
            forwarding.dataPrevistaConclusao
          ).format(DATA_FORMATO),
          dataConclusao:
            forwarding?.dataConclusao &&
            moment(forwarding?.dataConclusao).format(DATA_FORMATO),
          protocolo: forwarding.denuncia.identificadorExterno,
          cidade: forwarding.denuncia.municipio,
          criticidade: forwarding.denuncia.descricao,
          canal: forwarding.denuncia.canal.descricao,
          instituicao: forwarding?.instituicaoDestinataria?.nome,
          status: forwarding.status.descricao,
          progresso: progresso,
        });
      })
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Erro ao carregar encaminhamento!</strong>,
          "Erro: " + reason?.response?.data?.detail
        )
      );
    setLoading(false);
  }

  function transformList(recusa, devolucao, acoes) {
    setJustificativasRecusa(
      recusa.map((recusa) => ({
        ...recusa,
        cor: "red",
        dataCadastro: moment(recusa.dataCadastro).format(DATA_FORMATO),
        tipo: "Recusa",
      }))
    );
    setJustificativasDevolucao(
      devolucao.map((devolucao) => ({
        ...devolucao,
        cor: "blue",
        dataCadastro: moment(devolucao.dataCadastro).format(DATA_FORMATO),
        tipo: "Devolução",
      }))
    );
    setAcoes(
      acoes.map((acoes) => ({
        ...acoes,
        cor: "green",
        dataCadastro: moment(acoes.dataCadastro).format(DATA_FORMATO),
        tipo: "Ação",
      }))
    );
  }

  const handleDonwloadDenuncia = async (codigo) => {
    try {
      const response = await encaminhamentoService.imprimir(codigo);
      const { data: fileData } = response;

      FileDonwload(
        fileData,
        "ENCAMINHAMENTO_" + paramsPDF?.instituicao + ".pdf"
      );
    } catch (err) {
      openNotification("error", <strong>Erro ao baixar denúncia!</strong>, err);
      console.error(err);
    }
  };

  // RECEBER - INSTITUIÇÃO - TÁ FUNCIONANDO
  async function handleReceive() {
    await encaminhamentoService
      .receiveForwarding(codigo)
      .then((response) => {
        getForwarding();
        setOpenDialogModalConfirmarAnalise(false);
      })
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu ao aceitar encaminhamento!</strong>,
          "Erro: " + reason?.response?.data?.detail
        )
      );
  }

  // ENVIAR - INSTITUIÇÃO - TÁ FUNCIONANDO
  async function handleSendAction() {
    if (justificativa === "") {
      openNotification(
        "error",
        <strong>VOCÊ PRECISA INFORMAR AS AÇÕES REALIZADAS!</strong>,
        "Não é possivel responder este encaminhamento sem inserir ações."
      );
    } else {
      let actions = { descricao: justificativa };
      setJustificativa("");

      await encaminhamentoService
        .sendAction(codigo, actions)
        .then((response) => {
          openNotification(
            "success",
            <strong>Ação adicionada!</strong>,
            "A ação foi registrada na linha do tempo do encaminhamento. \n " +
              "Ao cadastrar todas as ações realizadas, finalize as ações para que o encaminhamento retorne ao processo de validação."
          );
          setStateModalJustify(false);
          getForwarding();
        })
        .catch((reason) =>
          openNotification(
            "error",
            <strong>Ocorreu um erro ao responder o encaminhamento!</strong>,
            "Erro: " + reason?.response?.data?.detail
          )
        );
    }
  }

  // RECUSAR - INSTITUIÇÃO - TÁ FUNCIONANDO
  async function handleRefuse() {
    if (justificativa === "") {
      openNotification(
        "error",
        <strong>
          VOCÊ PRECISA INFORMAR OS CAMPOS OBRIGATÓRIOS DA RECUSA!
        </strong>,
        "Não é possivel devolver este encaminhamento sem inserir justificativa(s) da recusa."
      );
    } else {
      let justifys = [];

      justifys.push({ descricao: justificativa + "- " + user?.nome_completo });
      setJustificativa("");

      let forwarding = {
        observacao: descricao,
        dataInicio,
        dataPrevistaConclusao: dataPrevisao,
        denuncia: { codigo: codigoDenuncia },
        prioridade: { codigo: prioridadeCodigo },
        instituicaoDestinataria: { codigo: instituicaoCodigo },
        usuario: { codigo: encaminhadoPorCodigo },
        justificativasRecusa: justifys,
      };

      await encaminhamentoService
        .refusedForwarding(codigo, forwarding)
        .then((response) => {
          openNotification(
            "success",
            <strong>Justificativas adicionadas!</strong>
          );
          setStateModalRefused(false);
          getForwarding();
        })
        .catch((reason) =>
          openNotification(
            "error",
            <strong>Ocorreu um erro ao retornar o encaminhamento!</strong>,
            "Erro: " + reason?.response?.data?.detail
          )
        );
    }
  }

  // DEVOLVER - SPS - TÁ FUNCIONANDO
  async function handleReturning() {
    if (justificativa === "") {
      openNotification(
        "error",
        <strong>
          VOCÊ PRECISA INFORMAR OS CAMPOS OBRIGATÓRIOS DA DEVOLUÇÃO!
        </strong>,
        "Não é possivel devolver este encaminhamento sem inserir justificativa(s) da devolução."
      );
    } else {
      let justifys = { descricao: justificativa };

      setJustificativa("");

      await encaminhamentoService
        .returningForwarding(codigo, justifys)
        .then((response) => {
          setStateModalReturning(false);
          openNotification(
            "success",
            <strong>Encaminhamento devolvido!</strong>
          );
          setStateModalReturning(false);
          getForwarding();
        })
        .catch((reason) =>
          openNotification(
            "error",
            <strong>Ocorreu um erro ao retornar o encaminhamento!</strong>,
            "Erro: " + reason?.response?.data?.detail
          )
        );
    }
  }

  // FINALIZAR - SPS
  async function handleFinalize() {
    setPopLoading(true);
    await encaminhamentoService
      .finalizeForwarding(codigo)
      .then((response) => {
        openNotification(
          "success",
          <strong>ENCAMINHAMENTO FINALIZADO COM SUCESSO!</strong>
        );
        getForwarding();
        setPopLoading(false);
      })
      .catch((reason) => {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao finalizar o encaminhamento!</strong>,
          "Erro: " + reason?.response?.data?.detail
        );
        setPopLoading(false);
      });
  }
  async function handleUpdateObservacaoPublica() {
    const newObservacaoPublica = {
      descricao: observacaoPublica,
    };
    await observacaoPublicaService
      .addObservacao(codigo, newObservacaoPublica)
      .then((response) => {
        openNotification(
          "success",
          <strong>OBSERVAÇÃO PÚBLICA CADASTRADA COM SUCESSO!</strong>
        );
        getForwarding();
        setIsModalObservacaoPublica(false);
      })
      .catch((reason) => {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao finalizar o encaminhamento!</strong>,
          "Erro: " + reason?.response?.data?.detail
        );
        setPopLoading(false);
      });
  }

  async function handleOpen() {
    setPopLoading(true);
    setDisabled(true);
    try {
      await encaminhamentoService.reopenForwarding(codigo);
      openNotification(
        "success",
        <strong>Encaminhamento reaberto!</strong>,
        `O status do encaminhamento foi alterado para "EM ANDAMENTO".`
      );
      getForwarding();
    } catch (reason) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao reabrir o encaminhamento!</strong>,
        "Erro: " + reason?.response?.data?.detail
      );
    } finally {
      setPopLoading(false);
      setDisabled(false);
    }
  }

  function handleFinalizarAcoes() {
    if (instituicaoVitimaClassificacoes.length > 0 && user?.authorities?.includes(USER_ROLES.CLASSIFICACAO_DEVOLUTIVA_REDE)) {
      setOpenClassificacaoModal(true);
    } else {
      handleReply();
    }
  }

  const renderJustifysRefused = () => (
    <>
      {justificativasRecusa.map((justificativa, index) => (
        <Row gutter={32}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item label={<span>Justificativa {index + 1}</span>}>
              <Input
                name="justificativa_recusar"
                id="justificativa_recusar"
                value={justificativa.descricao}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
      ))}

      <Row gutter={32}>
        <Col xs={{ span: 24 }} lg={{ span: 24 }}>
          <Form.Item
            label={
              <span>
                Digite abaixo a(s) justificativa(s) da recusa do encaminhamento
                referente à denúncia
              </span>
            }
          >
            <TextArea
              value={justificativa}
              rows={5}
              onChange={(e) => setJustificativa(e.target.value)}
              placeholder="Justificativas para a recusa:"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );

  const renderJustifysReturning = () => (
    <>
      <div className="row-form element-form">
        <label htmlFor="justificativasRecusa">
          Justificativas para a devolução:
        </label>
      </div>

      {justificativasDevolucao.map((justificativa, index) => (
        <Row gutter={32}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item label={<span>Justificativa {index + 1}</span>}>
              <Input
                name="justificativa_recusar"
                id="justificativa_recusar"
                value={justificativa.descricao}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
      ))}

      <Row gutter={32}>
        <Col xs={{ span: 24 }} lg={{ span: 24 }}>
          <Form.Item
            label={
              <span>
                Digite abaixo a(s) justificativa(s) para a devolução do
                encaminhamento referente à denúncia
              </span>
            }
          >
            <TextArea
              value={justificativa}
              rows={5}
              onChange={(e) => setJustificativa(e.target.value)}
              placeholder="Justificativas para a devolução:"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );

  const renderActions = () => (
    <>
      {acoes.length > 0 && (
        <div className="row-form element-form">
          <strong htmlFor="actions">Ações realizadas:</strong>
        </div>
      )}

      {acoes.map((acao, index) => (
        <Row gutter={32}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item label={<span>Ação {index + 1}</span>}>
              <Input
                name="acao_enviar"
                id="acao_enviar"
                value={acao.descricao}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
      ))}
      <Row gutter={32}>
        <Col xs={{ span: 24 }} lg={{ span: 24 }}>
          <span>Digite abaixo as ações realizadas</span>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col xs={{ span: 24 }} lg={{ span: 24 }}>
          <Form.Item>
            <TextArea
              value={justificativa}
              rows={5}
              onChange={(e) => setJustificativa(e.target.value)}
              placeholder="Ações do encaminhamento:"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );

  const renderForwardingInformations = () => (
    <>
      <Form name="encaminhamento" layout="vertical">
        <Row gutter={32}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item label={<span>Protocolo:</span>}>
              <Input
                name="protocolo"
                id="protocolo"
                value={protocolo}
                disabled
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item label={<span>Prioridade:</span>}>
              <Input
                name="prioridade"
                id="prioridade"
                value={prioridade}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label={<span>Instituição responsável:</span>}>
              <Input
                name="instituicao_responsavel"
                id="instituicao_responsavel"
                value={instituicao}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={32}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item label={<span>Data de início:</span>}>
              <Input
                name="data_inicio"
                id="data_inicio"
                value={moment(dataInicio).format(DATA_FORMATO)}
                disabled
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item label={<span>Data prevista para a conclusão:</span>}>
              <Input
                name="data_prevista"
                id="data_prevista"
                value={
                  dataPrevisao && moment(dataPrevisao).format(DATA_FORMATO)
                }
                disabled
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={32}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item label={<span>Status:</span>}>
              <Input
                name="status"
                id="status"
                value={
                  statusCurrent === STATUS.FINALIZADO
                    ? "FINALIZADO"
                    : statusCurrent
                }
                disabled
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item label={<span>Encaminhado por:</span>}>
              <Input
                name="encaminhado_por"
                id="encaminhado_por"
                value={instituicaoRemetente.nome}
                disabled
                prefix={
                  user?.authorities?.includes(USER_ROLES.VISUALIZAR_OPERADOR_DENUNCIA) && <Popover
                    title={
                      <>
                        <UserOutlined /> {encaminhadoPor}
                      </>
                    }
                    trigger={["click", "hover"]}
                    content={
                      <>
                        <BankOutlined /> {instituicaoRemetente.nome}
                      </>
                    }
                  >
                    <ProfileOutlined style={{ cursor: "pointer" }} />
                  </Popover>
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span="24">
            <Form.Item label={<span>Observações sobre o encaminhamento:</span>}>
              <TextArea
                value={descricao}
                rows={5}
                onChange={(e) => setDescricao(e.target.value)}
                placeholder="Informações complementares sobre a instituição"
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );

  function findIcon(icon) {
    if (icon === "application/pdf")
      return <FilePdfOutlined style={{ fontSize: "1.5rem" }} />;
    else if (icon === "application/json")
      return <FileTextOutlined style={{ fontSize: "1.5rem" }} />;
    else if (["image/png", "image/jpg", "image/jpeg"].includes(icon))
      return <PictureOutlined style={{ fontSize: "1.5rem" }} />;
    else if (icon === "image/gif")
      return <GifOutlined style={{ fontSize: "1.5rem" }} />;
    else return <FileUnknownOutlined style={{ fontSize: "1.5rem" }} />;
  }

  async function handleDeleteAnexo(anexo) {
    await encaminhamentoService
      .deleteAnexo(codigo, anexo)
      .then((response) => {
        getForwarding();
        openNotification(
          "success",
          <strong>Anexo removido com sucesso!</strong>
        );
      })
      .catch((error) => {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao remover o anexo!</strong>,
          error.response.data.detail
        );
      });
  }

  async function handleDownloadAnexo(anexo) {
    setDownloadLoading(true);
    await encaminhamentoService
      .getAnexo(codigo, anexo.codigo, anexo.contentType)
      .then((response) => {
        var data = new Blob([response.data], { type: anexo.contentType });
        var csvURL = window.URL.createObjectURL(data);
        var tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", anexo.nomeArquivo);
        tempLink.click();
      })
      .then(() => {
        openNotification(
          "success",
          <strong>Anexo baixado com sucesso!</strong>
        );
      })

      .catch((error) => {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao baixar o anexo!</strong>
        );
      });
    setDownloadLoading(false);
  }

  const columnsAnexos = [
    {
      dataIndex: [],
      key: "icone",
      width: 70,
      render: (anexo) => (
        <Space size="large" align="center">
          {findIcon(anexo.contentType)}
        </Space>
      ),
    },
    {
      title: <strong>Nome</strong>,
      dataIndex: ["nomeArquivo"],
      key: "nome",
    },
    {
      title: <strong>Tipo</strong>,
      dataIndex: ["contentType"],
      key: "tipo",
    },
    {
      title: <strong>Data</strong>,
      dataIndex: ["dataCadastro"],
      key: "data",
    },
  ];

  const colunasAcoes = {
    title: <strong>ações</strong>,
    key: "action",
    fixed: "right",
    width: 32,
    render: (anexo) => (
      <Space size="small">
        {(user?.authorities?.includes(USER_ROLES.DELETAR_ANEXO_ENCAMINHAMENTO)) 
                  && (
            <Popconfirm
              title="Deletar anexo?"
              onConfirm={() => handleDeleteAnexo(anexo.codigo)}
            >
              <Button type="danger">
                <DeleteOutlined style={{ fontSize: "1rem" }} />
              </Button>
            </Popconfirm>
        )}

        <Button
          onClick={() => {
            handleDownloadAnexo(anexo);
          }}
          loading={downloadLoading}
          title={`Baixar o arquivo ${anexo.nomeArquivo}`}
          type="dashed"
        >
          <CloudDownloadOutlined style={{ fontSize: "1.2rem" }} />
        </Button>
      </Space>
    ),
  };

  const props = {
    name: "arquivo",
    multiple: false,
    action: process.env.REACT_APP_GATEWAY.concat(
      urls.ENCAMINHAMENTO_ANEXO.replace(":codigo", codigo)
    ),
    method: "PUT",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("@oisol/token")}`,
      contentType: "multipart/form-data",
    },
    showUploadList: false,
    maxCount: 1,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        openNotification(
          "success",
          <strong>O arquivo {info.file.name} foi anexado à denúncia!</strong>,
          "O anexo agora pode ser baixado ou excluído na listagem de anexos."
        );
        setOpenUploadModal(false);
        getForwarding();
      } else if (status === "error") {
        openNotification(
          "error",
          <strong>
            Não foi possível anexar o arquivo {info.file.name} à denúncia.
          </strong>,
          "Tente novamente ou entre em contato com o suporte do sistema."
        );
      }
    },
  };

  const renderForm = () => (
    <form
      className={statusCurrent !== STATUS.ANDAMENTO && "form-view"}
      id="form-view-forwading"
    >
      {renderForwardingInformations()}
      <Row gutter={32} align="end" style={{ marginRight: 0 }}>
        {user?.authorities?.includes(
          USER_ROLES.CADASTRAR_OBSERVACAO_PUBLICA
        ) && (
          <Button
            type="primary"
            onClick={() => {
              setIsModalObservacaoPublica(true);
            }}
            style={{ marginRight: 10 }}
          >
            adicionar observação pública
          </Button>
        )}

        <Button
          type="primary"
          onClick={() => {
            setOpenUploadModal(true);
          }}
        >
          adicionar anexo
        </Button>
      </Row>
      {progresso && (
        <>
          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <TimelineProgressoEncaminhamento
                statusCurrent={statusCurrent}
                dataConclusao={dataConclusao}
                progresso={progresso}
                dataInicio={dataInicio}
                titulo={'PROGRESSO DO ENCAMINHAMENTO:'}
              />
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Tabs>
                <Tabs.TabPane tab="Anexos" key="1">
                  <Table
                    loading={loading}
                    dataSource={anexos.sort(
                      (a, b) =>
                        new Date(b.dataCadastro) - new Date(a.dataCadastro)
                    )}
                    columns={
                      anexos?.length > 0 && [...columnsAnexos, colunasAcoes]
                    }
                    size="small"
                    rowKey={({ codigo }) => codigo}
                    locale={{
                      emptyText: (
                        <Empty
                          description={
                            <h2>Sem anexos para esse encaminhamento</h2>
                          }
                        />
                      ),
                    }}
                  />
                </Tabs.TabPane>
                {user?.authorities?.includes(
                  USER_ROLES.CADASTRAR_OBSERVACAO_PUBLICA
                ) && (
                  <Tabs.TabPane tab="Observações públicas" key="2">
                    <Table
                      scroll
                      loading={loading}
                      dataSource={observacoes}
                      columns={columns}
                      size="small"
                      rowKey={({ codigo }) => codigo}
                      locale={{
                        emptyText: (
                          <Empty
                            description={
                              <h2>
                                Sem observações públicas para esse
                                encaminhamento
                              </h2>
                            }
                          />
                        ),
                      }}
                    />
                  </Tabs.TabPane>
                )}
              </Tabs>
            </Col>
          </Row>
        </>
      )}
    </form>
  );

  const ePermitido = useCallback(
    (status) => {
      if (statusCurrent !== status) return false;

      if (user?.authorities?.includes(USER_ROLES.ALTERAR_TODOS_ENCAMINHAMENTOS))
        return true;

      if (
        user?.authorities?.includes(
          USER_ROLES.ALTERAR_ENCAMINHAMENTO_INSTITUICAO
        ) &&
        user.codigo_instituicao === instituicaoCodigo
      )
        return true;

      return false;
    },
    [
      statusCurrent,
      user?.authorities,
      user.codigo_instituicao,
      instituicaoCodigo,
    ]
  );

  return (
    <div id="sectionMain">
      <Breadcrumb id="breadcrumb">
        <Breadcrumb.Item key="home">
          <Link to="">
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="registros">registros</Breadcrumb.Item>
        <Breadcrumb.Item key="denuncias">
          <Link to={urls.DENUNCIAS_LIST}>denúncias</Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item key="denuncia">
          <Link to={urls.DENUNCIA_DETAILS.replace(":codigo", codigoDenuncia)}>
            denúncia #{protocolo}
          </Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item key="encaminhar:01">encaminhamentos</Breadcrumb.Item>

        <Breadcrumb.Item key="encaminhar:02">
          <strong>{instituicao?.toLowerCase()}</strong>
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className="section-children">
        <div className="container-section">
          <Row justify="space-between" align="middle">
            <Col>
              {statusCurrent === STATUS.ABERTO && (
                <>
                  <h2>ENCAMINHAMENTO DA DENÚNCIA #{protocolo}</h2>
                  <span>Informações sobre o encaminhamento da denúncia</span>
                </>
              )}
              {statusCurrent === STATUS.ANDAMENTO && (
                <>
                  <h2>RESPONDER ENCAMINHAMENTO DA DENÚNCIA #{protocolo}</h2>
                  <span>
                    Inserir informações sobre a resposta encaminhamento da
                    denúncia
                  </span>
                </>
              )}
              {statusCurrent !== STATUS.ANDAMENTO &&
                statusCurrent !== STATUS.ABERTO && (
                  <>
                    <h2>RESPOSTA DO ENCAMINHAMENTO DA DENÚNCIA #{protocolo}</h2>
                    <span>
                      Informações sobre a resposta encaminhamento da denúncia
                    </span>
                  </>
                )}
            </Col>
            <Col>
              {user?.authorities?.includes(
                USER_ROLES.ALTERAR_ENCAMINHAMENTO_INSTITUICAO
              ) && (
                <Dropdown
                  placement="bottomRight"
                  arrow
                  trigger={["hover", "click"]}
                  overlay={
                    <Row>
                      {user?.authorities?.includes(
                        USER_ROLES.ALTERAR_ENCAMINHAMENTO_INSTITUICAO
                      ) && (
                        <>
                          {[STATUS.FINALIZADO].includes(statusCurrent) && user?.authorities?.includes(
                                USER_ROLES.CONSULTAR_TODOS_ENCAMINHAMENTOS
                              )&& (
                            <Col>
                              <Popconfirm
                                title={
                                  <span>{`Você tem certeza que deseja reabrir o encaminhamento?`}</span>
                                }
                                placement="bottomRight"
                                okText="confirmar ação"
                                cancelText="cancelar"
                                icon={
                                  <QuestionCircleOutlined
                                    style={{ color: "red" }}
                                  />
                                }
                                okButtonProps={{ loading: popLoading }}
                                onConfirm={() => {
                                  handleOpen(codigo);
                                }}
                                disabled={disabled}
                                cancelButtonProps={{ disabled: disabled }}
                              >
                                <Button
                                  style={{
                                    textAlign: "start",
                                    fontSize: "1rem",
                                  }}
                                  block
                                  type="text"
                                  icon={<FolderOpenOutlined />}
                                >
                                  reabrir encaminhamento
                                </Button>
                              </Popconfirm>
                            </Col>
                          )}
                        </>
                      )}
                      <Col>
                        <Button
                          style={{ textAlign: "start", fontSize: "1rem" }}
                          block
                          type="text"
                          onClick={() => {
                            handleDonwloadDenuncia(codigo);
                          }}
                          icon={<DownloadOutlined />}
                        >
                          baixar encaminhamento
                        </Button>
                      </Col>
                    </Row>
                  }
                >
                  <Button
                    style={{
                      textAlign: "start",
                      fontSize: "1rem",
                      cursor: "pointer",
                    }}
                    block
                    type="text"
                    icon={<ToolOutlined style={{ fontSize: "1rem" }} />}
                  >
                    AÇÕES
                  </Button>
                </Dropdown>
              )}
            </Col>
          </Row>

          {user && renderForm()}

          <Row justify="space-between" align="bottom">
            <Col>
              <Link
                to={urls.DENUNCIA_DETAILS.replace(":codigo", codigoDenuncia)}
                className="button-link"
              >
                <Button
                  type="button"
                  style={{ width: "160px", marginLeft: "5px" }}
                >
                  voltar à denúncia
                </Button>
              </Link>
            </Col>

            <Col>
              {ePermitido(STATUS?.ABERTO) && (
                <>
                  <Button
                    onClick={(e) => {
                      setStateModalRefused(true);
                    }}
                    type="button"
                    style={{ width: "160px", marginLeft: "5px" }}
                    className="button-link"
                  >
                    recusar
                  </Button>

                  <Button
                    type="primary"
                    style={{ width: "160px", marginLeft: "5px" }}
                    onClick={() => {
                      setOpenDialogModalConfirmarAnalise(true);
                    }}
                  >
                    receber
                  </Button>
                </>
              )}

              {statusCurrent === STATUS.VALIDAR &&
                user?.authorities?.includes(
                  USER_ROLES.ALTERAR_TODOS_ENCAMINHAMENTOS
                ) && (
                  <>
                    <Button
                      onClick={(e) => {
                        setStateModalReturning(true);
                      }}
                      style={{ width: "160px", marginLeft: "5px" }}
                    >
                      devolver
                    </Button>

                    <Popconfirm
                      title="Deseja finalizar o encaminhamento?"
                      onConfirm={() => handleFinalize()}
                      okButtonProps={{ loading: popLoading }}
                    >
                      <Button
                        type="primary"
                        style={{ width: "160px", marginLeft: "5px" }}
                      >
                        finalizar
                      </Button>
                    </Popconfirm>
                  </>
                )}

              {ePermitido(STATUS.ANDAMENTO) && (
                <>
                  {acoes.length !== 0 && (
                    <Button
                      onClick={(e) => {
                        handleFinalizarAcoes();
                      }}
                      type="button"
                      style={{ width: "160px", marginLeft: "5px" }}
                    >
                      finalizar ações
                    </Button>
                  )}
                  <Button
                    onClick={(e) => {
                      setStateModalJustify(true);
                     // getInstituicaoVitimaClassificacao();
                    }}
                    type="primary"
                    style={{ width: "160px", marginLeft: "5px" }}
                  >
                    adicionar ação
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>

      {openDialogModalConfirmarAnalise && (
        <DialogModal
          title="Confirmar recebimento do encaminhamento?"
          Subtitle="Essa ação irá confirmar o recebimento do encaminhamento!"
          handle_Function={() => handleReceive()}
          onClose={() => setOpenDialogModalConfirmarAnalise(false)}
        />
      )}

      <Modal
        title={`ADICIONAR AÇÕES EFETUADAS PARA A DENÚNCIA “${protocolo}” ?`}
        width="40vw"
        centered
        visible={stateModalJustify}
        onCancel={() => setStateModalJustify(false)}
        onOk={handleSendAction}
        okText={"Confirmar ação"}
      >
        {renderActions()}
      </Modal>

      <Modal
        title={`RECUSAR ENCAMINHAMENTO DA DENÚNCIA “${protocolo}” ?`}
        width="40vw"
        centered
        visible={stateModalRefused}
        onCancel={() => setStateModalRefused(false)}
        onOk={handleRefuse}
      >
        {renderJustifysRefused()}
      </Modal>

      <Modal
        title={`DEVOLVER ENCAMINHAMENTO DA DENÚNCIA “${protocolo}” ?`}
        width="40vw"
        centered
        visible={stateModalReturning}
        onCancel={() => setStateModalReturning(false)}
        onOk={handleReturning}
      >
        {renderJustifysReturning()}
      </Modal>

      <Modal
        title={"Anexar arquivo ao encaminhamento"}
        width="40vw"
        centered
        visible={openUploadModal}
        onCancel={() => setOpenUploadModal(false)}
        footer={[
          <Button key="back" onClick={() => setOpenUploadModal(false)}>
            Cancelar
          </Button>,
        ]}
      >
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined style={{ fontSize: "3rem" }} />
          </p>
          <p className="ant-upload-text">
            Clique ou arraste para carregar o arquivo que será anexado à
            denúncia
          </p>
        </Dragger>
      </Modal>

      <Modal
        okText="Cadastrar"
        title="Observações públicas"
        width="70%"
        visible={isModalObservacaoPublica}
        onCancel={() => setIsModalObservacaoPublica(false)}
        onOk={() => handleUpdateObservacaoPublica()}
      >
        <Row>
          <Col span="24">
            <Form.Item>
              <TextArea
                size="large"
                value={observacaoPublica}
                rows={5}
                onChange={(e) => setObservacaoPublica(e.target.value)}
                placeholder="Observações públicas"
              />
            </Form.Item>

            <Col style={{ height: "30vh", overflowY: "scroll", padding: 10 }}>
              {observacoes.length > 0 ? (
                <Timeline>
                  {observacoes.map((observacao, i) => (
                    <Timeline.Item key={i}>
                      <p>
                        <strong>
                          {moment(observacao.dataCadastro).format("DD/MM/yyyy")}{" "}
                        </strong>
                        - <UserOutlined /> {observacao?.usuario?.nome}
                      </p>
                      <Col
                        style={{
                          border: "1px solid #d1d1d6",
                          borderRadius: 10,
                          padding: 8,
                        }}
                      >
                        <Row></Row>
                        <p style={{ color: "#88888e", fontSize: 16 }}>
                          {observacao.descricao}
                        </p>
                      </Col>
                    </Timeline.Item>
                  ))}
                </Timeline>
              ) : (
                "- Sem observações públicas no momento."
              )}
            </Col>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Atualizar as classificações"
        width="80vw"
        centered
        visible={openClassificacaoModal}
        onCancel={() => setOpenClassificacaoModal(false)}
        footer={[
          <Button type="primary" key="finish" onClick={() => handleReply()} >
            Finalizar
          </Button>,
          <Button key="back" onClick={() => setOpenClassificacaoModal(false)}>
            Cancelar
          </Button>,
        ]}
      >
        <ClassificacaoEncaminhamento codigoEncaminhamento={codigo} colunasAcoes/>
      </Modal>
      
        
    </div>
  );
};

export default ViewForwarding;
