import React, { useState, useEffect, useCallback } from "react";

import { Table, Space, Button, Popconfirm, Row } from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import { SearchColumn } from "../../../../components/SearchColumn/SearchColumn";

import { ModalDoenca } from "./components/ModalDoenca";
import { openNotification } from "../../../../components/Notification";
import { doencaService } from "../../../../services/resources/doencaService";

export const Doencas = () => {
  const [doencas, setDoencas] = useState([]);

  const [openModal, setOpenModal] = useState(false);

  const [codigo, setCodigo] = useState(null);
  const [codigoDelete, setCodigoDelete] = useState(null);
  const [mode, setMode] = useState("");

  const [disabled, setDisabled] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    getDoencas();
    // eslint-disable-next-line
  }, []);

  async function getDoencas() {
    try {
      const { data } = await doencaService.list();
      transformList(data);
    } catch (error) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao buscar os tipos de doenças!</strong>,
        error?.response?.data?.detail
      );
    }
  }

  async function handleDelete(codigo) {
    setConfirmLoading(true);
    setDisabled(true);
    try {
      await doencaService.delete(codigo);
      openNotification("success", <strong>Doença rara excluída!</strong>);
      getDoencas();
    } catch (reason) {
      openNotification(
        "error",
        <strong>Não foi possível excluir a doença rara!</strong>,
        reason?.response?.data?.detail
      );
    } finally {
      setCodigoDelete(null);
      setConfirmLoading(false);
      setDisabled(false);
    }
  }

  const handleOpenModal = useCallback((doenca) => {
    setOpenModal(true);
    setCodigo(doenca?.codigo);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const transformList = useCallback((list) => {
    list.sort(function (a, b) {
      return a.descricao > b.descricao ? 1 : b.descricao > a.descricao ? -1 : 0;
    });
    setDoencas(list);
  }, []);

  const renderTable = () => {
    const columns = [
      {
        title: <strong>Descrição</strong>,
        dataIndex: "descricao",
        key: "descricao",
        ...SearchColumn("descricao"),
      },
      {
        title: <strong>ações</strong>,
        key: "action",
        width: 64,
        render: (doenca) => (
          <Space size="small">
            <Button
              type="primary"
              className="action-button"
              ghost
              title={`Visualizar doença rara ${doenca?.descricao}`}
              onClick={() => {
                handleOpenModal(doenca);
                setMode("onView");
              }}
            >
              <EyeOutlined style={{ fontSize: "1.2rem" }} />
            </Button>
            <Button
              type="primary"
              className="action-button"
              title={`Editar doença rara ${doenca?.descricao}`}
              onClick={() => {
                handleOpenModal(doenca);
                setMode("onEdit");
              }}
            >
              <EditOutlined style={{ fontSize: "1.2rem" }} />
            </Button>
            <div>
              <Popconfirm
                title={
                  <span>
                    excluir doença rara: <strong>{doenca?.descricao}</strong>?"
                  </span>
                }
                visible={doenca?.codigo === codigoDelete}
                placement="bottomRight"
                okText="confirmar exclusão"
                cancelText="cancelar"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={() => handleDelete(doenca?.codigo)}
                onCancel={() => setCodigoDelete(null)}
                okButtonProps={{ loading: confirmLoading }}
                disabled={disabled}
                cancelButtonProps={{ disabled: disabled }}
              >
                <Button
                  type="danger"
                  className="action-button"
                  title={`Excluir doença rara ${doenca?.descricao}`}
                  onClick={() => {
                    setCodigoDelete(doenca?.codigo);
                  }}
                >
                  <DeleteOutlined style={{ fontSize: "1.2rem" }} />
                </Button>
              </Popconfirm>
            </div>
          </Space>
        ),
      },
    ];
    function showTotal(total) {
      return (
        <>
          Total de <strong>{total}</strong> doenças raras
        </>
      );
    }
    return (
      <Table
        dataSource={doencas}
        columns={columns}
        size="small"
        rowKey={({ codigo }) => codigo}
        pagination={{
          size: "small",
          showSizeChanger: true,
          showTotal: showTotal,
        }}
      />
    );
  };

  return (
    <>
      <div className="container-section">
        <Row id="header">
          <h2>LISTA DE DOENÇAS RARAS</h2>

          <Button
            type="primary"
            onClick={() => {
              handleOpenModal(null);
              setMode("onCreate");
            }}
          >
            nova doença rara
          </Button>
        </Row>
        {renderTable()}
      </div>

      {openModal && (
        <ModalDoenca
          onClose={handleCloseModal}
          codigo={codigo}
          mode={mode}
          reload={getDoencas}
        />
      )}
    </>
  );
};
