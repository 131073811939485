import api from "../api";

export const classificacaoAtributoService = {
  
  async loadClassificacoes() {
    return await api.get("/classificacoes-atributos");
  },

  async list() {
    return await api.get(`/classificacoes-atributos`);
  },

  async get(codigo) {
    return await api.get(`/classificacoes-atributos/${codigo}`);
  },

  async create(payload) {
    return await api.post(`/classificacoes-atributos`, payload);
  },

  async edit(codigo, payload) {
    return await api.put(`/classificacoes-atributos/${codigo}`, payload);
  },

  async delete(codigoCanal) {
    return await api.delete(`/classificacoes-atributos/${codigoCanal}`);
  },
};
