import api from "../api";

export const canalService = {
  
  async loadCanais() {
    return await api.get("/canais");
  },

  async getAllCanais() {
    return await api.get("/publico/canais");
  },

  async list() {
    return await api.get(`/canais`);
  },

  async get(codigo) {
    return await api.get(`/canais/${codigo}`);
  },

  async create(payload) {
    return await api.post(`/canais`, payload);
  },

  async edit(codigo, payload) {
    return await api.put(`/canais/${codigo}`, payload);
  },

  async delete(codigoCanal) {
    return await api.delete(`/canais/${codigoCanal}`);
  },
};
