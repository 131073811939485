import styled from 'styled-components';

export const Container = styled.div`
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  align-items: center;
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  
`;
