export const STATUS_COLORS = {
  "A ANALISAR": "#75CDFF",
  "EM ABERTO": "#ff7575",
  "EM ANDAMENTO": "#ffe074",
  "A VALIDAR": "#ffb84c",
  FINALIZADA: "#52df9a",
  ARQUIVADA: "#c2c2c2",
};

export const DENUNCIAS_SORT_FILTER = {
  "endereco.bairro.nome": "nomeBairro",
  "endereco.bairro.cidade.nome": "cidadeIdList",
  identificadorExterno: "identificadorExterno",
  protocolo: "protocolo",
  "status.descricao": "codigoStatusList",
  canal: "codigoCanalList",
  dataCadastro: "dataCadastro",
  dataOcorrencia: "dataOcorrencia",
};
