import api from "../api";

export const etniaService = {
  async loadEtnias() {
    return await api.get("/raca-cor");
  },
  async getEtniasPublico() {
    return await api.get("/publico/raca-cores");
  },
}
