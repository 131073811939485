import api from "../api";

export const temaService = {
  async loadThemes() {
    return await api.get(`/temas`);
  },

  async getTheme(id) {
    return await api.get(`/temas/${id}`);
  },
  async createTheme(payload) {
    return await api.post(`/temas/`, payload);
  },
  async editTheme(id, payload) {
    return await api.put(`/temas/${id}`, payload);
  },
  async deleteTheme(id) {
    return await api.delete(`/temas/${id}`);
  },
};
