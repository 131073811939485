import api from "../api";

export const publicoService = {
    async canais() {
      return await api({
        method: "get",
        url: process.env.REACT_APP_GATEWAY_PUBLIC + "/canais",
      });
    },

    async denuncias(canalCodigo, protocolo) {
      return await api({
        method: "get",
        url:
          process.env.REACT_APP_GATEWAY_PUBLIC +
          `/denuncias/${canalCodigo}/${protocolo}`,
      });
    },

    async versao() {
      return await api({
        method: "get",
        url: process.env.REACT_APP_GATEWAY_PUBLIC + "/versao",
      });
    },
  }