import React, { useState, useEffect, useContext, useCallback } from "react";

import {
  Form,
  Row,
  Col,
  notification,
  Button,
  Select,
  Table,
  Space,
  Popconfirm,
} from "antd";

import { Container } from "./styles";
import { UserContext } from "../../../../../contexts/UserContext";
import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import ModalAdd from "./ModalAdd";
import { enderecoService } from "../../../../../services/resources/enderecoService";

const ListBairros = () => {
  const { estados } = useContext(UserContext);
  const [cidades, setCidades] = useState([]);
  const [bairros, setBairros] = useState([]);

  const [cidadeSelect, setCidadeSelect] = useState("");
  const [bairroSelect, setBairroSelect] = useState("");
  const [nameBairroSelect, setNameBairroSelect] = useState("");

  const [onEdit, setonEdit] = useState(false);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [popLoading, setPopLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    getDistricts(59);
    getCidades(1);
    // eslint-disable-next-line
  }, []);

  const openNotification = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
      duration: 5,
      style: {
        width: 600,
        background: type === "error" ? "#FFCBCB" : "#f6ffed",
      },
    });
  };

  async function getCidades(estado) {
    await enderecoService

      .getCitysState(estado)
      .then((response) => {
        setCidades(response.data);
      })
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao carregar as cidades!</strong>,
          "Erro: " + reason?.response?.data?.userMessage
        )
      );
  }

  async function getDistricts(city_id) {
    setLoading(true);
    (await city_id) &&
      enderecoService      
        .getDistrictsCitys(city_id)
        .then((response) => {
          setBairros(response.data);
        })
        .catch((reason) =>
          openNotification(
            "error",
            <strong>Ocorreu um erro ao carregar as bairros!</strong>,
            "" + reason
          )
        );
    setLoading(false);
  }

  async function handleDeleteBairro(bairro_id) {
    setPopLoading(true);
    setDisabled(true);
    try {
      await enderecoService.deleteDistrictsCitys(bairro_id, cidadeSelect);

      openNotification("success",
        <strong>O bairro {nameBairroSelect} foi excluído!</strong>
      );
      getDistricts(cidadeSelect);
    } catch (reason) {  
      openNotification("error",
        <strong>
          Ocorreu um erro ao excluir o bairro {nameBairroSelect}!
        </strong>,  
        "" + reason
        );
    } finally {
      setPopLoading(false);
      setDisabled(false);
    }
  }

  async function handleSelectEstado(value, mantemCidades) {
    if (!mantemCidades) {
      form.setFieldsValue({
        endereco: { bairro: { id: null, cidade: { id: null } } },
      });
      setCidades([]);
      setBairros([]);
    }
    value && (await getCidades(value));
  }

  async function handleSelectCity(value, mantemBairros) {
    setCidadeSelect(value);
    if (!mantemBairros) {
      form.setFieldsValue({ endereco: { bairro: { id: null } } });
      setBairros([]);
    }
    value && (await getDistricts(value));
    setonEdit(false);
  }

  const handleOpenModal = useCallback(() => {
    if (cidadeSelect !== "") {
      setModalVisible(true);
    } else {
      openNotification(
        "error",
        <strong>Campos vazios!</strong>,
        "Por favor, selecione o Estado e cidade."
      );
    }
  }, [cidadeSelect]);

  const handleCloseModal = useCallback(() => {
    setModalVisible(false);
    setonEdit(false);
    getDistricts(cidadeSelect);
    // eslint-disable-next-line
  }, [cidadeSelect]);

  const columns = [
    {
      title: <strong>Municipio</strong>,
      dataIndex: ["cidade", "nome"],
      key: "cidade.nome",

      filterSearch: true,
    },
    {
      title: <strong>Bairro</strong>,
      dataIndex: "nome",
      key: "nome",

      filterSearch: true,
    },

    {
      title: <strong>ações</strong>,
      key: "action",
      fixed: "right",
      width: 32,
      render: ({ id, nome }) => (
        <Space size="small">
          <Button
            type="primary"
            className="action-button"
            title={`Editar bairro `}
            onClick={(event) => {
              event.stopPropagation();
              setBairroSelect(id);
              setNameBairroSelect(nome);
              setonEdit(true);
              handleOpenModal();
            }}
          >
            <EditOutlined style={{ fontSize: "1.2rem" }} />
          </Button>

          <div>
            <Popconfirm
              title={
                <span>
                  excluir bairro: <strong>{nome}</strong>?"
                </span>
              }
              placement="bottomRight"
              okText="confirmar exclusão"
              cancelText="cancelar"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={() => handleDeleteBairro(id)}
              okButtonProps={{ loading: popLoading }}
              disabled={disabled}
              cancelButtonProps={{ disabled: disabled }}
            >
              <Button type="danger" className="action-button">
                <DeleteOutlined style={{ fontSize: "1.2rem" }} />
              </Button>
            </Popconfirm>
          </div>
        </Space>
      ),
    },
  ];

  function showTotal(total) {
    return (
      <>
        Total de <strong>{total}</strong> bairros
      </>
    );
  }

  return (
    <>
        <div className="container-section">
          <div id="header-container">
            <h2>INSERIR OU EDITAR BAIRROS</h2>
            <Button type="primary" onClick={() => handleOpenModal()}>
              novo bairro
            </Button>
          </div>

          <Container>
            <Form form={form} onSubmit={null} name="channel" layout="vertical">
              <Row gutter={32}>
                <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                  <Form.Item
                    label={<span>Estado:</span>}
                    name={["endereco", "bairro", "cidade", "estado", "id"]}
                    rules={[
                      { required: true, message: "Este campo é obrigatório!" },
                    ]}
                  >
                    <Select
                      placeholder="escolher estado"
                      onChange={(estado) => handleSelectEstado(estado, false)}
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      options={estados?.map(({ nome, id }) => ({
                        label: nome,
                        value: id,
                      }))}
                      autoComplete="none"
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                  <Form.Item
                    name={["endereco", "bairro", "cidade", "id"]}
                    label={<span>Município:</span>}
                    rules={[
                      { required: true, message: "Este campo é obrigatório!" },
                    ]}
                  >
                    <Select
                      placeholder="escolher município"
                      onChange={(cidade) => handleSelectCity(cidade, false)}
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      options={cidades?.map(({ nome, id }) => ({
                        label: nome,
                        value: id,
                      }))}
                      autoComplete="none"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Container>

          <Table
            loading={loading}
            dataSource={bairros}
            columns={columns}
            size="small"
            rowKey={({ id }) => id}
            pagination={{
              size: "small",
              showSizeChanger: true,
              showTotal: showTotal,
            }}
          />
        </div>

      {modalVisible && (
        <ModalAdd
          onClose={handleCloseModal}
          onEdit={onEdit}
          bairro_name={nameBairroSelect}
          city_id={cidadeSelect}
          bairro_id={bairroSelect}
        />
      )}
    </>
  );
};

export default ListBairros;
