import React, { useCallback, useState } from "react";
import { Form, Input, Row, Col, Modal } from "antd";
import {openNotification} from '../../../../../../components/Notification';

import { Container } from "./styles";
import { useWidth } from "../../../../../../hooks";
import { enderecoService } from "../../../../../../services/resources/enderecoService";

function ModalAdd({
  onClose,
  onEdit,
  bairro_name,
  city_id,
  bairro_id
}) {
  const { width } = useWidth();
  const [form] = Form.useForm();

  const [nome, setNome] = useState(bairro_name || "");

  const [disabled, setDisabled] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  async function addDistricts(city_id, bairro) {
    setConfirmLoading(true);
    setDisabled(true);
    try {
      await enderecoService.addDistrictsCitys(city_id, bairro);

      openNotification(
        "success",
        <strong>Bairro adicionado com sucesso!</strong>,
        `O bairro: ${bairro.nome}, foi adicionado!`
      );
      handleCloseModal();
    } catch (reason) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao adicionar o bairro: {bairro.nome}</strong>,
        "" + reason
      )
    }finally {
      setConfirmLoading(false);
      setDisabled(false);
    }
  }

  async function editDistricts(city_id, bairro_id, bairro) {
    setConfirmLoading(true);
    setDisabled(true);
    try {
      await enderecoService.editDistrictsCitys(bairro_id, city_id, bairro);

      openNotification(
        "success",
        <strong>Bairro editado com sucesso!</strong>,
        `O nome do bairro foi alterado para: ${bairro.nome}.`
      );
      handleCloseModal();
    } catch(reason) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao editar o bairro!</strong>,
        "" + reason
      )
    } finally {
      setConfirmLoading(false);
      setDisabled(false);
    }
  }

  function handleSubmit() {
    let bairro = { nome: nome};
    if (onEdit) {
      editDistricts(city_id, bairro_id, bairro);
    } else {
      addDistricts(city_id, bairro);
    }
  }

  return (
    <Modal
      title={onEdit ? `EDITAR BAIRRO ${bairro_name}` : "NOVO BAIRRO"}
      width={width > 1080 ? "30vw" : "100vw"}
      onOk={handleSubmit}
      confirmLoading={confirmLoading}
      okText={onEdit ? `Editar` : "Cadastrar"}
      onCancel={onClose}
      visible
      centered
    >
      <Container>
        <Form form={form} onSubmit={handleSubmit} name="channel" layout="vertical">
          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 32 }}>
              <Form.Item
                name="nome"
                label={<span>Nome:</span>}
                rules={[
                  { required: true, message: "Este campo é obrigatório!" },
                ]}
              >
                <Input
                  placeholder="Digite o nome do canal"
                  value={nome}
                  disabled={disabled}
                  onChange={(e) => setNome(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Container>
    </Modal>
  );
}

export default ModalAdd;
