import React, { useState, useEffect, useCallback } from "react";

import { Table, Space, Button, Popconfirm } from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import { SearchColumn } from "../../../../../components/SearchColumn/SearchColumn";

import ModalChannel from "../components/ModalChannel";
import { openNotification } from "../../../../../components/Notification";
import { useWidth } from "../../../../../hooks";
import { canalService } from "../../../../../services/resources/canalService";

const ListCanais = () => {
  const { width } = useWidth();
  const [canais, setcanais] = useState([]);

  const [openModalChannel, setOpenModalChannel] = useState(false);

  const [codigo, setCodigo] = useState("");
  const [mode, setMode] = useState("");

  const [popLoading, setPopLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    getCanais();
    // eslint-disable-next-line
  }, []);

  async function getCanais() {
    await canalService
      .list()
      .then((response) => transformList(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os Canais!</strong>,
          "" + reason
        )
      );
  }

  async function handleDelete(codigo) {
    setPopLoading(true);
    setDisabled(true);
    try {
      await canalService.delete(codigo);
      openNotification("success", <strong>Canal excluído!</strong>);
      getCanais();
    } catch (reason) {
      openNotification(
        "error",
        <strong>Não foi possível excluir o canal!</strong>,
        "Erro: " + reason.response.data.userMessage
      );
    } finally {
      setPopLoading(false);
      setDisabled(false);
    }
  }

  const handleOpenModalChannel = useCallback((codigo) => {
    setOpenModalChannel(true);
    setCodigo(codigo);
  }, []);

  const handleCloseModalChannel = useCallback(() => {
    setOpenModalChannel(false);
  }, []);

  const transformList = useCallback((list) => {
    list.sort(function (a, b) {
      return a.descricao > b.descricao ? 1 : b.descricao > a.descricao ? -1 : 0;
    });
    setcanais(list);
  }, []);

  const columns =
    width > 900
      ? [
        {
          title: <strong>Sigla</strong>,
          dataIndex: "sigla",
          key: "sigla",
          ...SearchColumn("sigla"),
        },
        {
          title: <strong>Nome</strong>,
          dataIndex: "nome",
          key: "codigo",
          sorter: (a, b) => a.nome.localeCompare(b.nome),
          sortDirections: ["ascend", "descend"],
          ...SearchColumn("nome"),
        },
        {
          title: <strong>Email</strong>,
          dataIndex: "email",
          key: "codigo",
          sorter: (a, b) => a.email.localeCompare(b.email),
          sortDirections: ["ascend", "descend"],
          ...SearchColumn("email"),
        },
        {
          title: <strong>Descrição</strong>,
          dataIndex: "descricao",
          key: "descricao",
          ...SearchColumn("descricao"),
        },
      ]
      : [
        {
          title: <strong>Sigla</strong>,
          dataIndex: "sigla",
          key: "sigla",
          ...SearchColumn("sigla"),
        },
        {
          title: <strong>Nome</strong>,
          dataIndex: "nome",
          key: "codigo",
          sorter: (a, b) => a.nome.localeCompare(b.nome),
          sortDirections: ["ascend", "descend"],
          ...SearchColumn("nome"),
        },
      ];

  const columnsAct = {
    title: <strong>ações</strong>,
    key: "action",
    width: 64,
    render: ({ codigo, descricao }) => (
      <Space size="small">
        <Button
          type="primary"
          className="action-button"
          title={`Editar canal ${descricao}`}
          onClick={() => {
            handleOpenModalChannel(codigo, descricao);
            setMode("onEdit");
          }}
        >
          <EditOutlined style={{ fontSize: "1.2rem" }} />
        </Button>
        <div>
          <Popconfirm
            title={
              <span>
                excluir canal: <strong>{descricao}</strong>?"
              </span>
            }
            placement="bottomRight"
            okText="confirmar exclusão"
            cancelText="cancelar"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={() => handleDelete(codigo)}
            okButtonProps={{ loading: popLoading }}
            disabled={disabled}
            cancelButtonProps={{ disabled: disabled }}
          >
            <Button type="danger" className="action-button">
              <DeleteOutlined style={{ fontSize: "1.2rem" }} />
            </Button>
          </Popconfirm>
        </div>
      </Space>
    ),
  };

  const renderTable = () => {
    function showTotal(total) {
      return (
        <>
          Total de <strong>{total}</strong> canais
        </>
      );
    }
    return (
      <Table
        dataSource={canais.map((canal) => ({
          ...canal,
          sigla: canal?.sigla,
          nome: canal?.nome,
          descricao: canal?.descricao,
        }))}
        columns={[...columns, columnsAct]}
        size="small"
        rowKey={({ codigo }) => codigo}
        pagination={{
          size: "small",
          showSizeChanger: true,
          showTotal: showTotal,
        }}
      />
    );
  };

  return (
    <>
      <div className="container-section">
        <div id="header-container">
          <h2>LISTA DE CANAIS</h2>
          <div id="header-actions">
            <Button
              type="primary"
              onClick={() => {
                handleOpenModalChannel();
                setMode("onCreate");
              }}
            >
              novo canal
            </Button>
          </div>
        </div>
        {renderTable()}
      </div>

      {openModalChannel && (
        <ModalChannel
          onClose={handleCloseModalChannel}
          codigo={codigo}
          mode={mode}
          getCanais={getCanais}
        />
      )}
    </>
  );
};

export default ListCanais;
