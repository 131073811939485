import { notification } from "antd";

const openNotification = (type, message, description, duration) => {
  notification[type]({
    message: message,
    description: description,
    duration : duration || 5,
    style: {
      width: 600,
      background: type === "error" ? "#FFCBCB" : "#f6ffed",
    },
  });
};

export { openNotification };
