import React, { useCallback, useEffect, useState } from "react";
import { Form, Input, Row, Col, Modal } from "antd";

import { Container } from "./styles";
import { openNotification } from "../../../../../../components/Notification";
import { locaisOcorrenciaService } from "../../../../../../services/resources/locaisOcorrenciaService";
const { TextArea } = Input;

function ModalAdd({
  onClose,
  mode,
  codigo,
  getLocalOcorrencia,
}) {
  const [form] = Form.useForm();

  const [disabled, setDisabled] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    async function getLocalOcorrencia() {
      await locaisOcorrenciaService
        .get(codigo)
        .then(({ data }) => {
          form.setFieldsValue({
            descricao: data?.descricao,
            fundamentacao: data?.fundamentacao,
          });
        })
        .catch((reason) => {
          openNotification("error", <strong>Erro!</strong>,''+reason);
        });
    }
    if (mode !== "onCreate") {
      getLocalOcorrencia();
    }
  }, [codigo, mode, form]);

  async function handleSubmit() {
    setConfirmLoading(true);
    setDisabled(true);
    if (mode === "onCreate") {
      try {
        await locaisOcorrenciaService.create(form.getFieldsValue());
        handleCloseModal();
        openNotification("success", <strong>Local de notificação/ manifestação criado!</strong>);
        getLocalOcorrencia();
      } catch (reason) {
        handleCloseModal();
        openNotification("error", <strong>Não foi possível criar o local de notificação/ manifestação!</strong>,'Erro: '+reason);
      } finally {
        setConfirmLoading(false);
        setDisabled(false);
      }
    } else if (mode === "onEdit") {
      try {
        await locaisOcorrenciaService.edit(codigo, form.getFieldsValue());
        handleCloseModal();
        openNotification("success", <strong>Local de notificação/ manifestação editado!</strong>);
        getLocalOcorrencia();
      } catch (reason) {
        handleCloseModal();
        openNotification("error", <strong>Não foi possível editar o local de notificação/ manifestação!</strong>,'Erro: '+reason);
      } finally {
        setConfirmLoading(false);
        setDisabled(false);
      }
    }
  }

  return (
    <Modal
      title={
        (mode === "onCreate" && "NOVO LOCAL DE NOTIFICAÇÃO/ MANIFESTAÇÃO") ||
        (mode === "onEdit" && "EDITAR LOCAL DE NOTIFICAÇÃO/ MANIFESTAÇÃO")
      }
      width="40vw"
      onOk={mode !== "onView" ? handleSubmit : handleCloseModal} 
      confirmLoading={confirmLoading}
      onCancel={onClose}
      visible
      centered
    >
      <Container>
        <Form form={form} onFinish={handleSubmit} name="local_ocorrencia" layout="vertical">                    
          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="descricao"
                
                label={<span>Descrição:</span>}
              >
                <Input
                  type="text"
                  placeholder="Digite a descrição do local de notificação/ manifestação"
                  autoComplete="none"
                  disabled={mode === "onView" || disabled}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item
                  name="fundamentacao"
                  label={<span>Fundamentação:</span>}
                >
                  <TextArea
                    autoSize
                    placeholder="Digite a fundamentação do local ocorrência"
                    disabled={mode === "onView"}
                  />
                </Form.Item>
              </Col>
          </Row>
        </Form>
      </Container>
    </Modal>
  );
}

export default ModalAdd;
