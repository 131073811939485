import React, { useState, useEffect, useCallback } from "react";

import { Table, Space, Button, Popconfirm, Row } from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import { SearchColumn } from "../../../../components/SearchColumn/SearchColumn";

import { ModalAdd } from "./components/ModalAdd";
import { openNotification } from "../../../../components/Notification";
import { identidadeGeneroService } from "../../../../services/resources/identidadeGeneroService";

export const IdentidadeGenero = () => {
  const [identidadegenero, setIdentidadeGenero] = useState([]);

  const [openModal, setOpenModal] = useState(false);

  const [codigo, setCodigo] = useState(null);
  const [codigoDelete, setCodigoDelete] = useState(null);
  const [mode, setMode] = useState("");

  const [disabled, setDisabled] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    getIdentidadeGenero();
    // eslint-disable-next-line
  }, []);

  async function getIdentidadeGenero() {
    try {
      const { data } = await identidadeGeneroService.loadIdentidadeGenero();
      transformList(data);
    } catch (error) {
      openNotification(
        "error",
        <strong>
          Ocorreu um erro ao buscar a lista de identidades de gênero!
        </strong>,
        error?.response?.data?.detail
      );
    }
  }

  async function handleDelete(codigo) {
    setConfirmLoading(true);
    setDisabled(true);
    try {
      await identidadeGeneroService.delete(codigo);
      openNotification(
        "success",
        <strong>Identidade de gênero excluída!</strong>
      );
      getIdentidadeGenero();
    } catch (reason) {
      openNotification(
        "error",
        <strong>Não foi possível excluir a identidade de gênero!</strong>,
        reason?.response?.data?.detail
      );
    } finally {
      setCodigoDelete(null);
      setConfirmLoading(false);
      setDisabled(false);
    }
  }

  const handleOpenModal = useCallback((identidadegenero) => {
    setOpenModal(true);
    setCodigo(identidadegenero?.codigo);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const transformList = useCallback((list) => {
    list.sort(function (a, b) {
      return a.descricao > b.descricao ? 1 : b.descricao > a.descricao ? -1 : 0;
    });
    setIdentidadeGenero(list);
  }, []);

  const renderTable = () => {
    const columns = [
      {
        title: <strong>Descrição</strong>,
        dataIndex: "descricao",
        key: "descricao",
        ...SearchColumn("descricao"),
      },
      {
        title: <strong>ações</strong>,
        key: "action",
        width: 64,
        render: (identidadegenero) => (
          <Space size="small">
            <Button
              type="primary"
              className="action-button"
              ghost
              title={`Visualizar classificação procedência ${identidadegenero?.descricao}`}
              onClick={() => {
                handleOpenModal(identidadegenero);
                setMode("onView");
              }}
            >
              <EyeOutlined style={{ fontSize: "1.2rem" }} />
            </Button>
            <Button
              type="primary"
              className="action-button"
              title={`Editar agravante ${identidadegenero?.descricao}`}
              onClick={() => {
                handleOpenModal(identidadegenero);
                setMode("onEdit");
              }}
            >
              <EditOutlined style={{ fontSize: "1.2rem" }} />
            </Button>
            <div>
              <Popconfirm
                title={
                  <span>
                    excluir identidade de gênero{" "}
                    <strong>{identidadegenero?.descricao}</strong>?"
                  </span>
                }
                visible={identidadegenero?.codigo === codigoDelete}
                placement="bottomRight"
                okText="confirmar exclusão"
                cancelText="cancelar"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={() => handleDelete(identidadegenero?.codigo)}
                onCancel={() => setCodigoDelete(null)}
                okButtonProps={{ loading: confirmLoading }}
                disabled={disabled}
                cancelButtonProps={{ disabled: disabled }}
              >
                <Button
                  type="danger"
                  className="action-button"
                  title={`Excluir identidade de gênero ${identidadegenero?.descricao}`}
                  onClick={() => {
                    setCodigoDelete(identidadegenero?.codigo);
                  }}
                >
                  <DeleteOutlined style={{ fontSize: "1.2rem" }} />
                </Button>
              </Popconfirm>
            </div>
          </Space>
        ),
      },
    ];
    function showTotal(total) {
      return (
        <>
          Total de <strong>{total}</strong> identidades de gênero
        </>
      );
    }
    return (
      <Table
        dataSource={identidadegenero}
        columns={columns}
        size="small"
        rowKey={({ codigo }) => codigo}
        pagination={{
          size: "small",
          total: identidadegenero?.length,
          showSizeChanger: true,
          showTotal: showTotal,
        }}
      />
    );
  };

  return (
    <>
      <div className="container-section">
        <Row id="header">
          <h2>LISTA DE IDENTIDADES DE GÊNERO</h2>

          <Button
            type="primary"
            onClick={() => {
              handleOpenModal(null);
              setMode("onCreate");
            }}
          >
            nova identidade de gênero
          </Button>
        </Row>
        {renderTable()}
      </div>

      {openModal && (
        <ModalAdd
          onClose={handleCloseModal}
          codigo={codigo}
          mode={mode}
          reload={getIdentidadeGenero}
        />
      )}
    </>
  );
};
