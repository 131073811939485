import React, { useState, useContext, useEffect } from "react";
import { Table, Space, Row, Col, Button, Empty, Badge, Modal } from "antd";
import { DeleteOutlined, EyeOutlined, InfoOutlined, SwapOutlined } from "@ant-design/icons";
import { UserContext } from "../../../../../contexts/UserContext";
import moment from "moment";
import { Link, useHistory, useParams } from "react-router-dom";
import { openNotification } from "../../../../../components/Notification";
import {
  USER_ROLES,
  urls,
  STATUS,
  STATUS_COLORS,
  OPCOES_TAMANHO_PAGINA,
  DATA_FORMATO,
} from "../../../../../utils";
import { encaminhamentoService } from "../../../../../services/resources/encaminhamentoService";
import { denuncianteService } from "../../../../../services/resources/denuncianteService";
import DialogModal from "../../../../../components/DialogModal";
import { TimelineProgressoEncaminhamento } from "../../../../Encaminhamento/ViewForwarding/TimelineProgressoEncaminhamento";
import { ClassificacaoEncaminhamento } from "../../../../Encaminhamento/ViewForwarding/ClassificacaoEncaminhamento";

export const Encaminhamentos = ({ onView }) => {
  const history = useHistory();
  const { codigo } = useParams();
  const { user } = useContext(UserContext);

  const [denuncia, setDenuncia] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isVisibleModalTimelineProgressoEncaminhamento, setIsVisibleModalTimelineProgressoEncaminhamento] = useState(false);
  const [encaminhamentos, setEncaminhamentos] = useState([]);
  const [encaminhamento, setEncaminhamento] = useState([]);
  const [codigoEncaminhamento, setCodigoEncaminhamento] = useState("");
  const [progresso, setProgresso] = useState([]);

  const [acoes, setAcoes] = useState([]);
  const [justificativasRecusa, setJustificativasRecusa] = useState([]);
  const [justificativasDevolucao, setJustificativasDevolucao] = useState([]);

  const [openClassificacaoModal, setOpenClassificacaoModal] = useState(false);

  useEffect(() => {
    codigo && getDenuncia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getDenuncia() {
    setLoading(true);
    await denuncianteService
      .getDenunciation(codigo)
      .then(({ data }) => {
        setEncaminhamentos(
          data?.encaminhamentos?.map((forwarding) => ({
            ...forwarding,
            dataConclusao: forwarding?.dataConclusao && moment(forwarding?.dataConclusao).format(DATA_FORMATO),
            dataInicio: moment(forwarding.dataInicio).format(DATA_FORMATO),
            dataPrevistaConclusao: moment(
              forwarding.dataPrevistaConclusao
            ).format(DATA_FORMATO),
            instituicaoVitimaClassificacao: forwarding?.instituicaoVitimaClassificacoes?.length > 0,
          }))
        );
        setDenuncia(data);
      })
      .catch((reason) => {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os dados da denúncia!</strong>,
          reason?.response?.data?.detail
        );
      });
    setLoading(false);
  }

  async function getEncaminhamento(codigo) {
    await encaminhamentoService
      .getForwarding(codigo)
      .then((response) => {
        
        setEncaminhamento(response.data);
        
        transformList(
          response.data.justificativasRecusa,
          response.data.justificativasDevolucao,
          response.data.acoes
        );

        setIsVisibleModalTimelineProgressoEncaminhamento(true);
      })
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Erro ao carregar progresso do encaminhamento!</strong>,
          "Erro: " + reason?.response?.data?.detail
        )
      );
  }

  useEffect(() => {
    setProgresso([
      ...acoes,
      ...justificativasRecusa,
      ...justificativasDevolucao,
    ]);
    // eslint-disable-next-line
  }, [acoes, justificativasRecusa, justificativasDevolucao]);

  function transformList(recusa, devolucao, acoes) {
    setJustificativasRecusa(
      recusa.map((recusa) => ({
        ...recusa,
        cor: "red",
        dataCadastro: moment(recusa.dataCadastro).format(DATA_FORMATO),
        tipo: "Recusa",
      }))
    );
    setJustificativasDevolucao(
      devolucao.map((devolucao) => ({
        ...devolucao,
        cor: "blue",
        dataCadastro: moment(devolucao.dataCadastro).format(DATA_FORMATO),
        tipo: "Devolução",
      }))
    );
    setAcoes(
      acoes.map((acoes) => ({
        ...acoes,
        cor: "green",
        dataCadastro: moment(acoes.dataCadastro).format(DATA_FORMATO),
        tipo: "Ação",
      }))
    );
  }

  async function deleteEncaminhamento(codigo) {
    try {
      await encaminhamentoService.deleteForwarding(codigo);
      openNotification("success", <strong>Encaminhamento excluído!</strong>);
      getDenuncia();
    } catch (error) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao excluir o encaminhamento!</strong>,
        error?.response?.data?.detail
      );
    }
  }

  const columns = [
    {
      title: <strong>instituição</strong>,
      dataIndex: "instituicaoDestinatariaNome",
      key: "instituicao",
    },
    {
      title: <strong>status</strong>,
      dataIndex: "statusDescricao",
      key: "status",
      render: (status) => (
        <Badge
          style={{
            backgroundColor: STATUS_COLORS[status],
            color: "#333333",
            fontWeight: "bold",
          }}
          count={status}
        />
      ),
    },
    {
      title: <strong>início</strong>,
      dataIndex: "dataInicio",
      key: "dataInicio",
    },
    {
      title: <strong>prazo</strong>,
      dataIndex: "dataPrevistaConclusao",
      key: "prazo",
    },
    user?.authorities?.includes(USER_ROLES.CONSULTAR_TODOS_ENCAMINHAMENTOS) ||
    user?.authorities?.includes(
      USER_ROLES.CONSULTAR_ENCAMINHAMENTOS_INSTITUICAO
    )
      ? {
          title: <strong>ações</strong>,
          key: "action",
          fixed: "right",
          width: 32,
          render: ({
            codigo,
            instituicaoDestinatariaCodigo,
            instituicaoVitimaClassificacao,
          }) =>  (
              <Space size="small" style={{ textAlign: 'right' }}>
                {(user?.authorities?.includes(USER_ROLES.VISUALIZAR_CLASSIFICACAO_DEVOLUTIVA_REDE) 
                              && denuncia?.status?.descricao === STATUS.VALIDAR
                              && instituicaoVitimaClassificacao)
                  && (
                    <Button
                      type="primary"
                      className="action-button"
                      ghost
                      title="Visualizar classificações do encaminhamento"
                      onClick={() => {
                        setCodigoEncaminhamento(codigo);
                        setOpenClassificacaoModal(true);
                      }}
                    >
                      <SwapOutlined style={{ fontSize: "1.2rem" }} />
                    </Button>
                )}

                {(user?.authorities?.includes(USER_ROLES.CONSULTAR_PROGRESSO_ENCAMINHAMENTO))
                  && (
                    <Button
                      type="primary"
                      className="action-button"
                      ghost
                      title="Visualizar progresso do encaminhamento"
                      onClick={() => {
                        setCodigoEncaminhamento(codigo);
                        getEncaminhamento(codigo);
                      }}
                    >
                      <InfoOutlined style={{ fontSize: "1.2rem" }} />
                    </Button>
                )}

                {((user?.codigo_instituicao === instituicaoDestinatariaCodigo) || (user?.authorities?.includes(USER_ROLES.CONSULTAR_DETALHE_ENCAMINHAMENTO)))
                  && (
                    <Button
                      type="primary"
                      className="action-button"
                      ghost
                      title="Visualizar encaminhamento"
                      onClick={(event) => {
                        event.stopPropagation();
                        history.push(
                          urls.ENCAMINHAMENTO_DETAILS.replace(":codigo", codigo)
                        );
                      }}
                    >
                      <EyeOutlined style={{ fontSize: "1.2rem" }} />
                    </Button>
                )}
                <div>
                {(user?.authorities?.includes(USER_ROLES.DELETAR_ENCAMINHAMENTO_INSTITUICAO)) 
                  && (
                    <Button
                      type="danger"
                      className="action-button"
                      title="Deletar encaminhamento"
                      onClick={() => {
                        setIsVisibleModal(true);
                        setCodigoEncaminhamento(codigo);
                      }}
                    >
                      <DeleteOutlined style={{ fontSize: "1.2rem" }} />
                    </Button>
                )}
                </div>
              </Space>
            ),
        }
      : {},
  ];

  return (
    <>
      <Row justify="end" align="middle">
        {user?.authorities?.find(
          (x) => x === USER_ROLES.INCLUIR_ENCAMINHAMENTOS
        ) &&
          denuncia?.status?.descricao !== STATUS.ARQUIVADA && (
            <Col style={{ display: "flex", gap: "1rem" }}>
              <Button type="primary">
                <Link to={urls.ENCAMINHAMENTO_CRIAR.replace(":codigo", codigo)}>
                  novo encaminhamento
                </Link>
              </Button>
            </Col>
          )}
      </Row>

      <Row>
        <Col span={24}>
          <Table
            loading={loading}
            dataSource={encaminhamentos}
            columns={encaminhamentos?.length && columns}
            pagination={{
              size: "small",
              showSizeChanger: true,
              pageSizeOptions: OPCOES_TAMANHO_PAGINA,
              total: encaminhamentos.length,
              showTotal: (total) => (
                <strong>
                  {total === 1
                    ? `${total} encaminhamento no total`
                    : `${total} encaminhamentos no total`}
                </strong>
              ),
            }}
            size="small"
            rowKey={({ codigo }) => codigo}
            locale={{
              emptyText: (
                <Empty
                  description={<h2>Sem encaminhamentos para essa denúncia</h2>}
                />
              ),
            }}
          />
        </Col>
      </Row>

      {isVisibleModal && (
        <DialogModal
          onClose={() => setIsVisibleModal(false)}
          title={
            <span>Você tem certeza que deseja excluir o encaminhamento?</span>
          }
          Subtitle="Se confirmada, essa operação não poderá ser revertida"
          dialog="Ao clicar em confirmar, você irá excluir pernamentemente o encaminhamento"
          handle_Function={() => {
            deleteEncaminhamento(codigoEncaminhamento);
            setIsVisibleModal(false);
          }}
        />
      )}

      {isVisibleModalTimelineProgressoEncaminhamento && (
            <Modal
                  title={<strong>PROGRESSO DO ENCAMINHAMENTO</strong>}
                  bodyStyle={{
                    height: "70vh",
                    overflowY: "auto",
                    display: "flex",
                    gap: "2rem",
                    flexDirection: "column",
                  }}
                  centered
                  visible
                  onCancel={() => {setIsVisibleModalTimelineProgressoEncaminhamento(false)}}
                  footer={null}
          >
            <TimelineProgressoEncaminhamento
              statusCurrent={encaminhamento.status.descricao}
              dataConclusao={encaminhamento.dataConclusao}
              progresso={progresso}
              dataInicio={encaminhamento.dataInicio}
            />
          </Modal>
      )}

      <Modal
        title="Visualizar as classificações"
        width="80vw"
        centered
        destroyOnClose
        visible={openClassificacaoModal}
        onCancel={() => setOpenClassificacaoModal(false)}
        footer={null}
      >
        <ClassificacaoEncaminhamento codigoEncaminhamento={codigoEncaminhamento} colunasAcoes={false}/>
      </Modal>
    </>
  );
};
