import {
  EditOutlined,
  EnvironmentOutlined,
  EyeOutlined,
  PartitionOutlined,
} from "@ant-design/icons";
import { Typography, Card, Col, Row, Button } from "antd";
import { useContext } from "react";

import { useHistory } from "react-router-dom";
import { UserContext } from "../../../../contexts/UserContext";
import { urls, USER_ROLES } from "../../../../utils";

export function InstituicaoCard({ instituicao, DeleteInstitutionButton }) {
  const { user } = useContext(UserContext);
  const history = useHistory();

  return (
    <Card
      style={{ display: "flex", flexDirection: "column" }}
      actions={[
        <Button
          className="action-button"
          type="primary"
          title={`Visualizar instituição ${instituicao?.nome}`}
          onClick={() =>
            history.push(
              urls.INSTITUICOES_DETAILS.replace(":codigo", instituicao?.codigo)
            )
          }
          ghost
        >
          <EyeOutlined style={{ fontSize: "1.2rem" }} />
        </Button>,
        user?.authorities?.includes(USER_ROLES.GERENCIAR_INSTITUICOES) && (
          <Button
            className="action-button"
            type="primary"
            title={`Editar instituição  ${instituicao?.nome}`}
            onClick={() =>
              history.push(
                urls.INSTITUICOES_EDITAR.replace(":codigo", instituicao?.codigo)
              )
            }
          >
            <EditOutlined style={{ fontSize: "1.2rem" }} />
          </Button>
        ),
        user?.authorities?.includes(USER_ROLES.GERENCIAR_INSTITUICOES) &&
          DeleteInstitutionButton(instituicao?.codigo, instituicao?.nome),
      ]}
      size="small"
    >
      <Row gutter={[8, 8]}>
        <Col span={2}>
          <PartitionOutlined />
        </Col>
        <Col span={22}>
          <Typography.Text strong>{instituicao?.descricaoTema}</Typography.Text>
        </Col>

        <Col span={2}>
          <EnvironmentOutlined />
        </Col>
        <Col span={22}>
          <Typography.Text type="secondary">
            {instituicao?.municipio}
          </Typography.Text>
        </Col>
      </Row>
    </Card>
  );
}
