import api from "../api";

export const doencaService = {
    async list() {
      return await api.get("/doencas-raras");
    },
    async get(codigo) {
      return await api.get(`/doencas-raras/${codigo}`);
    },
    async create(payload) {
      return await api.post(`/doencas-raras`, payload);
    },
    async edit(codigo, payload) {
      return await api.put(`/doencas-raras/${codigo}`, payload);
    },
    async delete(codigo) {
      return await api.delete(`/doencas-raras/${codigo}`);
    },
  }
