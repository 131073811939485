import { PlusOutlined } from "@ant-design/icons";
import {
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";

import { openNotification } from "../../../../components/Notification";
import { denuncianteService } from "../../../../services/resources/denuncianteService";

const opcoesJustificativasIniciais = [
  "Arquivada por insuficiência de informações",
  "Arquivada pelo falecimento da vítima",
  "Arquivada pela mudança de endereço da vítima",
  "Arquivada por duplicidade",
  "Arquivada devido a erros",
];

export function ArquivarDenunciaModal({
  onClose,
  codigo,
  protocolo,
  onFinish,
}) {
  const [opcoesJustificativas, setOpcoesJustificativas] = useState(
    opcoesJustificativasIniciais
  );
  const [name, setName] = useState("");

  const [form] = useForm();

  const submit = () => {
    form.validateFields().then(async () => {
      await denuncianteService
        .arquivar(codigo, form.getFieldValue("justificativas").join(";"))
        .then(() => {
          onClose();
          openNotification("success", <strong>Denúncia arquivada!</strong>);
          onFinish();
        })
        .catch((reason) => {
          onClose();
          openNotification(
            "error",
            <strong>Não foi possível arquivar a denúncia!</strong>,
            "Erro: " + reason?.response?.data?.detail
          );
        });
    });
  };

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    if (name) {
      setOpcoesJustificativas([
        ...opcoesJustificativas,
        name.trim().charAt(0).toUpperCase() + name.trim().slice(1),
      ]);
      setName("");
    }
  };

  return (
    <Modal
      title={
        <strong>{`Deseja realmente arquivar a denúncia ${protocolo}?`}</strong>
      }
      width="40vw"
      onOk={submit}
      onCancel={onClose}
      visible
      centered
      cancelText="cancelar"
      okText="arquivar"
    >
      <Form layout="vertical" form={form}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <p>
              Após a confirmação, essa denúncia será movida para a seção
              “denúncias arquivadas” e poderá ser restaurada a qualquer momento.
              Selecione abaixo a(s) justificativa(s) do arquivamento da denúncia
            </p>
          </Col>
          <Col span={24}>
            <Form.Item name="justificativas" rules={[{ required: true }]}>
              <Select
                allowClear
                showArrow
                mode="tags"
                style={{
                  width: "100%",
                }}
                placeholder="Selecione as justificativas"
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    <Space
                      align="center"
                      style={{
                        padding: "0 8px 4px",
                      }}
                    >
                      <Input
                        placeholder="Insira nova opção"
                        value={name}
                        onChange={onNameChange}
                      />
                      <Typography.Link
                        onClick={addItem}
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        <PlusOutlined /> adicionar opção
                      </Typography.Link>
                    </Space>
                  </>
                )}
              >
                {opcoesJustificativas.map((item) => (
                  <Select.Option key={item}>{item}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
