import { useEffect, useState } from "react";
import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";
import Geocode from "react-geocode";

import locationImage from "../../images/placeholder.png"; // Importar o ícone

import { mapStyles } from "./mapStyles";
import { Row } from "antd";

export const MapView = ({ enderecoDenuncia }) => {
  const [position, setPosition] = useState(null);
  const [endereco, setEndereco] = useState("");

  // Adiciona as coordenadas da origem e do destino aos limites do mapa

  const getCoordinates = async () => {
    try {
      const denuncia = enderecoDenuncia;
      const address = `${denuncia?.logradouro}, ${denuncia?.numero} - ${denuncia?.bairro?.nome}, ${denuncia?.bairro?.cidade?.nome} - ${denuncia?.bairro?.cidade?.estado?.sigla}`;

      setEndereco(address);

      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
      Geocode.setLanguage("pt-br");
      Geocode.setRegion("br");

      const responseGeoCode = await Geocode.fromAddress(address);
      const lat = parseFloat(
        `${responseGeoCode.results[0].geometry.location.lat}`
      );
      const lng = parseFloat(
        `${responseGeoCode.results[0].geometry.location.lng}`
      );

      setPosition({ lat, lng });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getCoordinates();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enderecoDenuncia]);

  return (
    <Row
      style={{
        border: "4px solid #ff9945",
        borderRadius: 10,
        boxShadow: " rgba(0, 0, 0, 0.3) 0px 3px 8px",
      }}
    >
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
        <GoogleMap
          options={{ styles: mapStyles[0] }}
          mapContainerStyle={{
            width: "100%",
            height: "54vh",
            borderRadius: "1rem",
            borderColor: "",
          }}
          center={position}
          zoom={15}
          streetView={true}
        >
          <MarkerF
            options={{ icon: locationImage }}
            position={position}
            title={endereco}
          />
        </GoogleMap>
      </LoadScript>
    </Row>
  );
};
