import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { mask, unMask } from "remask";
import { UserContext } from "../../../contexts/UserContext";

import {
  Col,
  Form,
  Input,
  Row,
  Spin,
  Select,
  Button,
  Popconfirm,
  Switch,
  Modal,
  Breadcrumb,
  Dropdown,
  Menu,
} from "antd";

import {
  DeleteOutlined,
  EditOutlined,
  FileSearchOutlined,
  HomeOutlined,
  MoreOutlined,
  QuestionCircleOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import { openNotification } from "../../../components/Notification";
import { DenunciaTabela } from "../../Denuncia/components/DenunciaTabela";
import { scrollBotton, urls, USER_ROLES } from "../../../utils";
import PageHeader from "../../../components/PageHeader";
import { useWidth } from "../../../hooks";
import { showPlaceholder as showPlaceholderUTILS } from "../../../utils";
import { FormActions } from "../../../components/FormActions";
import { instituicaoService } from "../../../services/resources/instituicaoService";
import { enderecoService } from "../../../services/resources/enderecoService";
import { tipoinstituicaoService } from "../../../services/resources/tipoInstituicaoService";
import { temaService } from "../../../services/resources/temaService";

const CEARA_ID = 1;
const CEP_MASK = "99999-999";
const TELEFONE_MASK = ["(99) 9999-9999", "(99) 9 9999-9999"];

const { TextArea } = Input;

const Instituicao = ({ onView, onEdit, onCreate }) => {
  const { user } = useContext(UserContext);
  const history = useHistory();

  const { codigo } = useParams();

  const [form] = Form.useForm();
  const [instituicao, setInstituicao] = useState();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [cidades, setCidades] = useState([]);
  const [cidadeSelect, setCidadeSelect] = useState("");

  const [bairros, setBairros] = useState([]);
  const { estados } = useContext(UserContext);

  const [temas, setTemas] = useState([]);
  const [tipos, setTipos] = useState([]);

  const [viewList, setViewList] = useState(false);

  const { width } = useWidth();

  const [inBotton, setInBotton] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (!onCreate) {
      getInstituicao(codigo);
    }
    getTemas();
    getTypes();
    getCidades(CEARA_ID);

    // eslint-disable-next-line
  }, []);

  // funções de buscas na api
  async function getInstituicao(codigo) {
    setLoading(true);
    await instituicaoService
      .getInstitution(codigo)
      .then(({ data }) => {
        const {
          nome,
          tema,
          tipoInstituicao,
          endereco,
          telefone1,
          telefone2,
          email,
          recebeEmail,
          emailAnexo,
          observacao,
        } = data;

        const { logradouro, cep } = endereco;

        setInstituicao({
          nome,
          tema: tema?.codigo,
          tipo: tipoInstituicao?.codigo,
          estado: endereco?.bairro?.cidade?.estado?.id,
          cidade: endereco?.bairro?.cidade?.id,
          bairro: endereco?.bairro?.id,
          logradouro,
          cep,
          numero: endereco?.numero,
          complemento: endereco?.complemento,
          telefone1,
          telefone2,
          email,
          recebeEmail,
          emailAnexo,
          observacao,
        });

        getCidades(endereco.bairro.cidade.estado.id);
        setCidadeSelect(endereco.bairro.cidade.estado.id);
        getBairros(endereco.bairro.cidade.id);
      })
      .catch(({ response }) => {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar a instituição!</strong>,
          "Erro: " + response?.data?.detail
        );
        history.push(urls.INSTITUICOES_LIST);
      });

    setLoading(false);
  }

  async function getCidades(state_id) {
    await enderecoService
      .getCitysState(state_id)
      .then((response) => {
        setCidades(response.data);
      })
      .catch(({ response }) => {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar as cidades!</strong>,
          "Erro: " + response?.data?.detail
        );
      });
  }

  async function getBairros(city_id) {
    await enderecoService
      .getDistrictsCitys(city_id)
      .then((response) => {
        setBairros(response.data);
      })
      .catch(({ response }) => {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os cidades!</strong>,
          "Erro: " + response?.data?.detail
        );
      });
  }

  async function getTemas() {
    try {
      const { data } = await temaService.loadThemes();
      setTemas(data);
    } catch ({ response }) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao buscar os temas!</strong>,
        "Erro: " + response?.data?.detail
      );
    }
  }

  async function getTypes() {
    await tipoinstituicaoService
      .loadTypes()
      .then((response) => {
        setTipos(response.data);
      })
      .catch(({ response }) => {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os tipos!</strong>,
          "Erro: " + response?.data?.detail
        );
      });
  }

  async function handleSelectCity(value) {
    setCidadeSelect(value);
    await getBairros(value);
    form.setFieldsValue({ bairro: undefined });
  }

  async function handleSelectEstado(value) {
    await getCidades(value);
    form.setFieldsValue({ cidade: undefined });
    form.setFieldsValue({ bairro: undefined });
  }

  async function handleDelete() {
    setConfirmLoading(true);
    setDisabled(true);
    try {
      await instituicaoService.deleteInstitution(codigo);
      openNotification(
        "success",
        <strong>{`Instituição excluída com sucesso!`}</strong>,
        `${instituicao?.nome} foi excluída e essa operação não pode ser revertida.`
      );
      history.push(urls.INSTITUICOES_LIST);
    } catch ({ response }) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao excluir a instituição!</strong>,
        "Erro: " + response?.data?.detail
      );
    } finally {
      setConfirmLoading(false);
      setVisible(false);
      setDisabled(false);
    }
  }

  async function handleSubmit(form) {
    setConfirmLoading(true);

    try {
      const instituicao = {
        nome: form?.nome ?? null,
        tema: { codigo: form?.tema },
        tipoInstituicao: { codigo: form?.tipo },
        endereco: {
          logradouro: form?.logradouro ?? null,
          numero: form?.numero ?? null,
          complemento: form?.complemento ?? null,
          cep: form?.cep ?? null,
          bairro: {
            id: form?.bairro,
          },
        },
        telefone1: form?.telefone1 ?? null,
        telefone2: form?.telefone2 ?? null,
        email: form?.email ?? null,
        recebeEmail: form?.recebeEmail,
        emailAnexo: form?.emailAnexo,
        observacao: form?.observacao ?? null,
      };

      if (onEdit) {
        await instituicaoService
          .editInstitution(codigo, instituicao)
          .then(() => {
            openNotification(
              "success",
              <strong>{`Instituição editada com sucesso!`}</strong>,
              `A instituição ${instituicao?.nome} foi editada com sucesso e as alterações nos dados foram salvas.`
            );
            history.push(urls.INSTITUICOES_DETAILS.replace(":codigo", codigo));
            getInstituicao(codigo);
          })
          .catch((reason) =>
            openNotification(
              "error",
              <strong>Ocorreu um erro ao editar a instituição!</strong>,
              "Erro: " + reason?.response?.data?.detail
            )
          );
      } else {
        await instituicaoService
          .createInstitution(instituicao)
          .then(async ({ data }) => {
            openNotification(
              "success",
              <strong>{`Instituição cadastrada com sucesso!`}</strong>,
              `A instituição ${data?.nome} foi cadastrada com sucesso.`
            );
            history.push(
              urls.INSTITUICOES_DETAILS.replace(":codigo", data?.codigo)
            );
            getInstituicao(data?.codigo);
          })
          .catch((reason) =>
            openNotification(
              "error",
              <strong>Ocorreu um erro ao criar a instituição!</strong>,
              "Erro: " + reason?.response?.data?.detail
            )
          );
      }
    } catch (error) {
    } finally {
      setConfirmLoading(false);
    }
  }

  // Função para aplicar as mascaras nos campos que precisam
  const onChange = (name, value, patterns) => {
    form.setFieldsValue({
      [name]: mask(unMask(value), patterns),
    });
  };

  const showPlaceholder = (value) => showPlaceholderUTILS(value, !onView);

  const renderForm = () => (
    <Spin spinning={loading} size="large">
      {!loading && (
        <Form
          form={form}
          name="instituicao"
          onFinish={handleSubmit}
          initialValues={instituicao}
          layout="vertical"
          scrollToFirstError
          onFinishFailed={() => setHasError(true)}
          onFieldsChange={() =>
            setHasError(
              !!form.getFieldsError().find(({ errors }) => errors?.length >= 1)
            )
          }
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label={<span>Nome:</span>}
                name="nome"
                id="nome"
                hasFeedback={!onView}
                rules={
                  !onView && [
                    { required: true, message: "Este campo é obrigatório!" },
                  ]
                }
              >
                <Input
                  autoComplete="none"
                  disabled={onView}
                  placeholder={showPlaceholder("Insira o nome da instituição")}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="tema"
                label={<span>Tema:</span>}
                rules={[
                  { required: true, message: "Este campo é obrigatório!" },
                ]}
              >
                <Select
                  placeholder={showPlaceholder("selecionar tema")}
                  allowClear
                  showSearch
                  disabled={onView}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={temas?.map(({ descricao, codigo }) => ({
                    label: descricao,
                    value: codigo,
                  }))}
                  autoComplete="none"
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="tipo"
                label={<span>Tipo:</span>}
                rules={[
                  { required: true, message: "Este campo é obrigatório!" },
                ]}
              >
                <Select
                  placeholder={showPlaceholder("selecionar tipo")}
                  allowClear
                  showSearch
                  disabled={onView}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={tipos?.map(({ nome, codigo }) => ({
                    label: nome,
                    value: codigo,
                  }))}
                  autoComplete="none"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Form.Item
                name="estado"
                label={<span>Estado:</span>}
                rules={[
                  { required: true, message: "Este campo é obrigatório!" },
                ]}
              >
                <Select
                  placeholder={showPlaceholder("selecionar estado")}
                  onSelect={handleSelectEstado}
                  allowClear
                  showSearch
                  disabled={onView}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={estados?.map(({ nome, id }) => ({
                    label: nome,
                    value: id,
                  }))}
                  autoComplete="none"
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Form.Item
                name="cidade"
                label={<span>Cidade:</span>}
                rules={[
                  { required: true, message: "Este campo é obrigatório!" },
                ]}
              >
                <Select
                  placeholder={showPlaceholder("selecionar cidade")}
                  onSelect={handleSelectCity}
                  allowClear
                  showSearch
                  disabled={onView}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={cidades?.map(({ nome, id }) => ({
                    label: nome,
                    value: id,
                  }))}
                  autoComplete="none"
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
              <Form.Item
                name="bairro"
                label={<span>Bairro:</span>}
                rules={[
                  { required: true, message: "Este campo é obrigatório!" },
                ]}
              >
                <Select
                  placeholder={showPlaceholder("selecionar bairro")}
                  disabled={onView || !cidadeSelect}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={bairros?.map(({ nome, id }) => ({
                    label: nome,
                    value: id,
                  }))}
                  autoComplete="none"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label={<span>Logradouro:</span>}
                name="logradouro"
                hasFeedback={!onView}
                rules={
                  !onView && [
                    { required: true, message: "Este campo é obrigatório!" },
                  ]
                }
              >
                <Input
                  autoComplete="none"
                  disabled={onView}
                  placeholder={showPlaceholder("Insira o logradouro")}
                  allowClear
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label={<span>CEP:</span>}
                name="cep"
                onChange={({ target }) =>
                  onChange("cep", target.value, CEP_MASK)
                }
              >
                <Input
                  autoComplete="none"
                  disabled={onView}
                  placeholder={showPlaceholder("60000-000")}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item label={<span>Número:</span>} name="numero">
                <Input
                  autoComplete="none"
                  disabled={onView}
                  placeholder={showPlaceholder("Insira o número")}
                  allowClear
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item label={<span>Complemento:</span>} name="complemento">
                <Input
                  autoComplete="none"
                  disabled={onView}
                  placeholder={showPlaceholder("Insira o complemento")}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                label={<span>Telefone 01:</span>}
                name="telefone1"
                onChange={({ target }) =>
                  onChange("telefone1", target.value, TELEFONE_MASK)
                }
              >
                <Input
                  autoComplete="none"
                  disabled={onView}
                  placeholder={showPlaceholder("Insira um telefone")}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                label={<span>Telefone 02:</span>}
                name="telefone2"
                onChange={({ target }) =>
                  onChange("telefone2", target.value, TELEFONE_MASK)
                }
              >
                <Input
                  autoComplete="none"
                  disabled={onView}
                  placeholder={showPlaceholder("Insira um telefone")}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                label={<span>E-mail:</span>}
                name="email"
                rules={[
                  { type: "email", message: "Insira um email válido" },
                  !onView && {
                    required: true,
                    message: "Este campo é obrigatório!",
                  },
                ]}
              >
                <Input
                  autoComplete="none"
                  disabled={onView}
                  placeholder={showPlaceholder("instituicao@email.com")}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item
                label={<span>Recebe notificação?</span>}
                name="recebeEmail"
                valuePropName="checked"
              >
                <Switch
                  disabled={onView}
                  checkedChildren="sim"
                  unCheckedChildren="não"
                  defaultChecked={onCreate ? true : instituicao?.recebeEmail}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item
                label={<span>Recebe anexos?</span>}
                name="emailAnexo"
                valuePropName="checked"
              >
                <Switch
                  disabled={onView}
                  checkedChildren="sim"
                  unCheckedChildren="não"
                  defaultChecked={onCreate ? true : instituicao?.emailAnexo}
                />
              </Form.Item>
            </Col>

          </Row>

          <Row>
            <Col span="24">
              <Form.Item
                name="observacao"
                label={<span>Observações sobre a instituição:</span>}
              >
                <TextArea
                  rows={instituicao?.observacao ? 20 : 5}
                  disabled={onView}
                />
              </Form.Item>
            </Col>
          </Row>
          {!onView && !hasError && (
            <FormActions
              inBotton={inBotton}
              actions={[
                <Button
                  type="primary"
                  ghost
                  onClick={() => history.push(urls.INSTITUICOES_LIST)}
                >
                  {onEdit && <span>cancelar edições</span>}
                  {onCreate && <span>cancelar</span>}
                </Button>,
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={confirmLoading}
                >
                  {onEdit ? (
                    <span>salvar edições</span>
                  ) : (
                    <span>cadastrar instituição</span>
                  )}
                </Button>,
              ]}
            />
          )}
        </Form>
      )}
    </Spin>
  );

  const menuActions = (
    <Menu>
      {user?.authorities?.includes(USER_ROLES.GERENCIAR_INSTITUICOES) && (
        <Menu.Item key="editar">
          <Button
            style={{ textAlign: "start", fontSize: "1rem" }}
            block
            type="text"
            icon={<EditOutlined />}
            onClick={() =>
              history.push(urls.INSTITUICOES_EDITAR.replace(":codigo", codigo))
            }
          >
            editar instituição
          </Button>
        </Menu.Item>
      )}

      {user?.authorities?.includes(USER_ROLES.CONSULTAR_TODAS_DENUNCIAS) && (
        <Menu.Item key="denúncias">
          <Button
            style={{ textAlign: "start", fontSize: "1rem" }}
            block
            type="text"
            icon={<FileSearchOutlined />}
            onClick={() => {
              setViewList(true);
            }}
          >
            denúncias
          </Button>
        </Menu.Item>
      )}

      {user?.authorities?.includes(USER_ROLES.GERENCIAR_INSTITUICOES) && (
        <Menu.Item key="excluir">
          <Popconfirm
            title={
              <>
                <span>{`Você tem certeza que deseja excluir a instituição ${instituicao?.nome}?`}</span>
                <br />
                <small>{`Se confirmada, essa operação não poderá ser revertida.`}</small>
              </>
            }
            visible={visible}
            placement="bottomRight"
            okText="Confirmar exclusão"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={handleDelete}
            onCancel={() => setVisible(false)}
            okButtonProps={{ loading: confirmLoading }}
            disabled={disabled}
            cancelButtonProps={{ disabled: disabled }}
          >
            <Button
              style={{ textAlign: "start", fontSize: "1rem" }}
              danger
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => {
                setVisible(true);
              }}
            >
              excluir instituição
            </Button>
          </Popconfirm>
        </Menu.Item>
      )}
      <Menu.Item key="voltar">
        <Button
          style={{ textAlign: "start", fontSize: "1rem" }}
          block
          type="text"
          danger
          icon={<RollbackOutlined />}
          onClick={() => history.push(urls.INSTITUICOES_LIST)}
        >
          voltar
        </Button>
      </Menu.Item>
    </Menu>
  );

  const renderExtraHeader = () => (
    <Dropdown
      trigger={["hover", "click"]}
      placement="bottomRight"
      overlay={menuActions}
    >
      {width < 900 ? (
        <Button
          style={{
            textAlign: "start",
            cursor: "pointer",
          }}
          block
          type="text"
          icon={<MoreOutlined style={{ fontSize: "1.2rem" }} />}
        />
      ) : (
        <Button
          style={{
            textAlign: "start",
            fontSize: "1rem",
            cursor: "pointer",
          }}
          block
          type="text"
        >
          AÇÕES <MoreOutlined style={{ fontSize: "1.2rem" }} />
        </Button>
      )}
    </Dropdown>
  );

  return (
    <div id="sectionMain" onScroll={(e) => setInBotton(scrollBotton(e))}>
      <Breadcrumb id="breadcrumb">
        <Breadcrumb.Item key="home">
          <Link to="">
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="gerenciamento">gerenciamento</Breadcrumb.Item>
        <Breadcrumb.Item key="instituicoes">
          <Link to={urls.INSTITUICOES_LIST}>instituições</Link>
        </Breadcrumb.Item>

        {onCreate && (
          <Breadcrumb.Item key="criar">
            <strong>cadastrar instituição</strong>
          </Breadcrumb.Item>
        )}

        {onView && (
          <Breadcrumb.Item key="instituicao">
            <strong>{instituicao?.nome?.toLowerCase()}</strong>
          </Breadcrumb.Item>
        )}

        {onEdit && (
          <>
            <Breadcrumb.Item key="instituicao">
              <Link to={urls.INSTITUICOES_DETAILS.replace(":codigo", codigo)}>
                {instituicao?.nome?.toLowerCase()}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="instituicao">
              <strong>editar instituição</strong>
            </Breadcrumb.Item>
          </>
        )}
      </Breadcrumb>

      <div className="section-children">
        <div className="container-section">
          <PageHeader
            titulo={onCreate ? "CADASTRAR INSTITUIÇÃO" : instituicao?.nome}
            subtitulo={
              onCreate
                ? "Inserir informações sobre a instituição"
                : onEdit
                  ? "Editar informações sobre a instituição"
                  : "Informações sobre a instituição"
            }
            extra={onView && renderExtraHeader()}
          />
          {renderForm()}
        </div>
      </div>

      <Modal
        title={"DENÚNICAS QUE CONTÉM ENCAMINHAMENTOS PARA ESSA INSTITUIÇÃO"}
        visible={viewList}
        onCancel={() => setViewList(false)}
        width={"90%"}
        centered
        footer={[
          <Button type="primary" onClick={() => setViewList(false)}>
            voltar
          </Button>,
        ]}
      >
        <DenunciaTabela
          showStatus
          filtro={{ codigoInstituicaoDestinataria: codigo }}
          searchClassificacao={false}
        />
      </Modal>
    </div>
  );
};

export default Instituicao;
