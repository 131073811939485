import React, { useState, useEffect, useCallback } from "react";

import { Table, Space, Button, Popconfirm } from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import { SearchColumn } from "../../../../../components/SearchColumn/SearchColumn";

import Modal from "../components/ModalAdd";
import { openNotification } from "../../../../../components/Notification";
import { locaisOcorrenciaService } from "../../../../../services/resources/locaisOcorrenciaService";

const ListLocalOcorrencia = () => {
  const [localOcorrencia, setLocalOcorrencia] = useState([]);

  const [openModal, setOpenModal] = useState(false);

  const [codigo, setCodigo] = useState("");
  const [mode, setMode] = useState("");

  const [popLoading, setPopLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    getLocalOcorrencia();
    // eslint-disable-next-line
  }, []);

  async function getLocalOcorrencia() {
    await locaisOcorrenciaService
      .loadOcorrenceLocals()
      .then((response) => transformList(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os locais de ocorrência!</strong>,
          "" + reason
        )
      );
  }

  async function handleDelete(codigo) {
    setPopLoading(true);
    setDisabled(true);
    try {
      await locaisOcorrenciaService.delete(codigo);
      openNotification("success", <strong>Local ocorrência excluído!</strong>);
      getLocalOcorrencia();
    } catch (reason) {
      openNotification(
        "error",
        <strong>Não foi possível excluir o local ocorrência!</strong>,
        "Erro: " + reason.response.data.userMessage
      );
    } finally {
      setPopLoading(false);
      setDisabled(false);
    }
  }

  const handleOpenModal = useCallback((localOcorrencia) => {
    setOpenModal(true);
    setCodigo(localOcorrencia?.codigo);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const transformList = useCallback((list) => {
    list.sort(function (a, b) {
      return a.descricao > b.descricao ? 1 : b.descricao > a.descricao ? -1 : 0;
    });
    setLocalOcorrencia(list);
  }, []);

  const renderTable = () => {
    const columns = [
      {
        title: <strong>Descrição</strong>,
        dataIndex: "descricao",
        key: "descricao",
        ...SearchColumn("descricao"),
      },
      {
        title: <strong>ações</strong>,
        key: "action",
        width: 64,
        render: (localOcorrencia ) => (
          <Space size="small">
            <Button
              type="primary"
              className="action-button"
              ghost
              title={`Visualizar local ocorrência ${localOcorrencia?.descricao}`}
              onClick={() => {
                handleOpenModal(localOcorrencia);
                setMode("onView");
              }}
            >
              <EyeOutlined style={{ fontSize: "1.2rem" }} />
            </Button>
            <Button
              type="primary"
              className="action-button"
              title={`Editar local ocorrência ${localOcorrencia?.descricao}`}
              onClick={() => {
                handleOpenModal(localOcorrencia);
                setMode("onEdit");
              }}
            >
              <EditOutlined style={{ fontSize: "1.2rem" }} />
            </Button>
            <div>
              <Popconfirm
                title={
                  <span>
                    excluir local de ocorrência: <strong>{localOcorrencia?.descricao}</strong>?"
                  </span>
                }
                placement="bottomRight"
                okText="confirmar exclusão"
                cancelText="cancelar"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={() => handleDelete(codigo)}
                okButtonProps={{ loading: popLoading }}
                disabled={disabled}
                cancelButtonProps={{ disabled: disabled }}
              >
                <Button type="danger" className="action-button">
                  <DeleteOutlined style={{ fontSize: "1.2rem" }} />
                </Button>
              </Popconfirm>
            </div>
          </Space>
        ),
      },
    ];
    function showTotal(total) {
      return (
        <>
          Total de <strong>{total}</strong> Locais de ocorrência
        </>
      );
    }
    return (
      <Table
        dataSource={localOcorrencia.map((localOcorrencia) => ({
          ...localOcorrencia,
          descricao: localOcorrencia?.descricao,
        }))}
        columns={columns}
        size="small"
        rowKey={({ codigo }) => codigo}
        pagination={{
          size: "small",
          showSizeChanger: true,
          showTotal: showTotal,
        }}
      />
    );
  };

  return (
    <>
      <div className="container-section">
        <div id="header-container">
          <h2>LISTA DE LOCAIS DE NOTIFICAÇÃO/ MANIFESTAÇÃO</h2>
          <div id="header-actions">
            <Button
              type="primary"
              onClick={() => {
                handleOpenModal();
                setMode("onCreate");
              }}
            >
              novo local de notificação/ manifestação
            </Button>
          </div>
        </div>
        {renderTable()}
      </div>
      {openModal && (
        <Modal
          onClose={handleCloseModal}
          codigo={codigo}
          mode={mode}
          getLocalOcorrencia={getLocalOcorrencia}
        />
      )}
    </>
  );
};

export default ListLocalOcorrencia;
