import api from "../api";

export const deficienciaService = {
  async loadDeficiencias() {
    return await api.get("/deficiencias");
  },
  async get(codigo) {
    return await api.get(`/deficiencias/${codigo}`);
  },

  async create(codigo) {
    return await api.post(`/deficiencias`, codigo);
  },

  async edit(codigo, payload) {
    return await api.put(`/deficiencias/${codigo}`, payload);
  },

  async delete(codigo) {
    return await api.delete(`/deficiencias/${codigo}`);
  },
};
