import React, { useState, useEffect, useContext } from "react";

import {
  Form,
  Input,
  DatePicker,
  Select,
  Row,
  Col,
  Button,
  Skeleton,
} from "antd";

import moment from "moment";
import { UserContext } from "../../../../../contexts/UserContext";
import { useHistory, useParams } from "react-router";
import { mask, unMask } from "remask";

import { openNotification } from "../../../../../components/Notification";
import {
  urls,
  USER_ROLES,
  showPlaceholder as showPlaceholderUTILS,
} from "../../../../../utils";
import { ClusterOutlined } from "@ant-design/icons";
import { agravanteService } from "../../../../../services/resources/agravanteService";
import { frequenciaService } from "../../../../../services/resources/frequenciaService";
import { criticidadeService } from "../../../../../services/resources/criticidadeService";
import { locaisOcorrenciaService } from "../../../../../services/resources/locaisOcorrenciaService";
import { canalService } from "../../../../../services/resources/canalService";
import { enderecoService } from "../../../../../services/resources/enderecoService";
import { denuncianteService } from "../../../../../services/resources/denuncianteService";

const DATA_FORMATO = "DD/MM/YYYY";
const CEP_MASK = "99999-999";
const ORIGEM_DENUNCIA = "OISOL";

const { Option } = Select;

export const Ocorrencia = ({ handleNext, onView, onEdit, onCreate }) => {
  const history = useHistory();

  const { codigo } = useParams();

  const [denuncia, setDenuncia] = useState(null);

  const [form] = Form.useForm();

  const [cidades, setCidades] = useState([]);

  const [loading, setLoading] = useState({
    all: false,
    bairros: false,
    cidades: false,
    submit: false,
  });

  const [protocoloExterno, setProtocoloExterno] = useState(null);
  const [canalSelect, setCanalSelect] = useState(null);

  const [isCanalDefault, setIsCanalDefault] = useState(null);

  const [frequenciaOcorrencia, setFrequenciaOcorrencia] = useState([]);
  const [localOcorrencia, setLocalOcorrencia] = useState([]);
  const [criticidades, setCriticidades] = useState([]);
  const [estados, setEstados] = useState([]);

  const [bairros, setBairros] = useState([]);
  const [canais, setCanais] = useState([]);
  const [agravantes, setAgravantes] = useState([]);
  const { user } = useContext(UserContext);

  const disable =
    onView ||
    user?.authorities?.includes(USER_ROLES.INCLUIR_DENUNCIA_INSTITUICAO);

  useEffect(() => {
    getAllEntidades();
    codigo && getDenuncia();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getDenuncia() {
    await denuncianteService
      .getDenunciation(codigo)
      .then(({ data }) => {
        getCidades(data?.endereco?.bairro?.cidade?.estado?.id);
        getBairros(data?.endereco?.bairro?.cidade?.id);

        form.setFieldsValue({
          ...data,
          agravantes: data?.agravantes?.map(({ codigo }) => codigo),
          dataOcorrencia:
            data.dataOcorrencia &&
            moment(new Date(data.dataOcorrencia), DATA_FORMATO),
        });

        setDenuncia(form.getFieldsValue());
        setProtocoloExterno(data?.identificadorExterno);
        setCanalSelect(data?.canal?.codigo);
        setIsCanalDefault(data?.canal?.sistema);
      })
      .catch((reason) => {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os dados da denúncia!</strong>,
          reason?.response?.data?.userMessage
        );
      });
  }

  async function getAllEntidades() {
    try {
      setLoading({ ...loading, all: true });
      const [agravantes, canais, criticidades, estados, frequencias, locais] =
        await Promise.all([
          agravanteService.list(),
          canalService.loadCanais(),
          criticidadeService.loadCriticidade(),
          enderecoService.loadStates({ ativo: true }),
          frequenciaService.loadFrequencias(),
          locaisOcorrenciaService.loadOcorrenceLocals(),
        ]);

      setAgravantes(agravantes?.data);
      setCanais(canais?.data);
      setCriticidades(criticidades?.data);
      setEstados(estados?.data);
      setFrequenciaOcorrencia(frequencias?.data);
      setLocalOcorrencia(locais?.data);

      if (
        onCreate &&
        user?.authorities?.includes(USER_ROLES.INCLUIR_DENUNCIA_INSTITUICAO)
      ) {
        form.setFieldsValue({
          canal: canais?.data?.find((canal) => canal.sistema),
        });
        setIsCanalDefault(true);
      }
    } catch (error) {
      openNotification(
        "error",
        <strong>
          Ocorreu um erro ao buscar as entidades relacionadas a denúncia!
        </strong>,
        error.response?.data?.userMessage || ""
      );
    } finally {
      setLoading({ ...loading, all: false });
    }
  }

  async function getCidades(estadoId) {
    if (estadoId)
      try {
        setLoading({ ...loading, cidades: true });
        const response = await enderecoService.getCitysState(estadoId);

        setCidades(response?.data);
      } catch (error) {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao carregar as cidades!</strong>,
          error?.response?.data?.userMessage || ""
        );
      } finally {
        setLoading({ ...loading, cidades: false });
      }
  }

  async function getBairros(cidadeId) {
    if (cidadeId)
      try {
        setLoading({ ...loading, bairros: true });
        const response = await enderecoService.getDistrictsCitys(cidadeId);
        setBairros(response?.data);
      } catch (error) {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao carregar as bairros!</strong>,
          error.response?.data?.userMessage || ""
        );
      } finally {
        setLoading({ ...loading, bairros: false });
      }
  }

  function onChangeCep(value) {
    form.setFieldsValue({
      endereco: { cep: mask(unMask(value), CEP_MASK) },
    });
  }

  async function handleSelectEstado(value, mantemCidades) {
    if (!mantemCidades) {
      form.setFieldsValue({
        endereco: { bairro: { id: null, cidade: { id: null } } },
      });
      setCidades([]);
      setBairros([]);
    }
    value && (await getCidades(value));
  }

  async function handleSelectCity(value, mantemBairros) {
    if (!mantemBairros) {
      form.setFieldsValue({ endereco: { bairro: { id: null } } });
      setBairros([]);
    }
    value && (await getBairros(value));
  }

  async function handleSelectChannel(value) {
    setIsCanalDefault(
      canais?.find((canal) => canal?.codigo === value)?.sistema || false
    );

    setCanalSelect(value);
    checkProtocol(value, protocoloExterno);
  }

  async function checkProtocol(codigoCanal, protocoloExterno) {
    codigoCanal &&
      protocoloExterno &&
      (await denuncianteService
        .getDenunciaByProtocolo(codigoCanal, protocoloExterno)
        .then((response) => {
          if (response?.data?.codigo !== codigo) {
            openNotification(
              "error",
              <strong>
                Já existe uma denúncia com esse protocolo no canal selecionado!
              </strong>,
              <a
                style={{ color: "#00569D" }}
                href={urls.DENUNCIA_DETAILS.replace(
                  ":codigo",
                  response?.data?.codigo
                )}
              >
                <strong>Clique aqui para visualizar a denúncia!</strong>
              </a>,
              10
            );
          }
        })
        .catch(() => {}));
  }

  function transformaDenuncia(denuncia) {
    return {
      ...denuncia,
      agravantes: denuncia?.agravantes?.map((codigo) => ({ codigo })),
      descricao: denuncia?.descricao?.trim(),
      identificadorExterno: denuncia?.identificadorExterno
        ? denuncia?.identificadorExterno?.trim()
        : null,
      criticidade: {
        codigo:
          denuncia.criticidade.codigo ||
          criticidades.filter((x) => x?.descricao === "BAIXA")[0].codigo,
      },
      canal: denuncia?.canal?.codigo ? denuncia?.canal : null,
      endereco: {
        logradouro: denuncia?.endereco?.logradouro?.trim() || null,
        numero: denuncia?.endereco?.numero?.trim() || null,
        complemento: denuncia?.endereco?.complemento?.trim() || null,
        cep: denuncia?.endereco?.cep || null,
        bairro: {
          id: denuncia?.endereco?.bairro?.id,
        },
      },
      origemDenuncia: ORIGEM_DENUNCIA,
    };
  }

  async function handleSubmit() {
    setLoading({ ...loading, submit: true });

    const denunciaModificada = transformaDenuncia(form.getFieldsValue());

    const houveAlteração =
      JSON.stringify(denuncia) !== JSON.stringify(form.getFieldsValue());

    if (onCreate) {
      await denuncianteService
        .createDenunciation(denunciaModificada)
        .then(({ data }) => {
          history.push(urls.DENUNCIA_EDITAR.replace(":codigo", data?.codigo));
          openNotification(
            "success",
            <strong>Denúncia cadastrada com sucesso!</strong>,
            `A denúncia com protocolo ${
              data?.identificadorExterno
                ? `externo ${data?.identificadorExterno}`
                : `interno ${data?.protocolo}`
            }  foi cadastrada com sucesso e está em análise`
          );
          handleNext("denunciator");
        })
        .catch((reason) =>
          openNotification(
            "error",
            <strong>Ocorreu um erro ao criar a denúncia!</strong>,
            "" + reason?.response?.data?.userMessage
          )
        );
    } else {
      if (onEdit && houveAlteração) {
        await denuncianteService
          .editDenunciation(codigo, denunciaModificada)
          .then(({ data }) => {
            openNotification(
              "success",
              <strong>Denúncia atualizada com sucesso!</strong>,
              `A denúncia com protocolo  ${
                data?.identificadorExterno
                  ? `externo ${data?.identificadorExterno}`
                  : `interno ${data?.protocolo}`
              } foi atualizada`
            );
            handleNext("denunciator");
          })
          .catch((reason) =>
            openNotification(
              "error",
              <strong>Ocorreu um erro ao atualizar a denúncia!</strong>,
              reason?.response?.data?.userMessage
            )
          );
      } else {
        handleNext("denunciator");
      }
    }

    setLoading({ ...loading, submit: false });
  }

  const showPlaceholder = (value) => showPlaceholderUTILS(value, !onView);

  return !loading.all ? (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      name="occurrence"
    >
      <Row gutter={32}>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <Form.Item
            name={["canal", "codigo"]}
            label={<span>Canal:</span>}
            onChange={() => checkProtocol(canalSelect, protocoloExterno)}
            rules={[
              { required: !disable, message: "Este campo é obrigatório!" },
            ]}
          >
            <Select
              placeholder={showPlaceholder("selecionar canal...")}
              autoComplete="none"
              onChange={handleSelectChannel}
              disabled={disable}
              allowClear
              showArrow={!disable}
              showSearch
              filterOption={(input, option) =>
                option?.search?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
              }
              options={canais?.map(({ sigla, codigo, sistema }) => ({
                label: (
                  <>
                    {sistema && <ClusterOutlined />} {sigla}
                  </>
                ),
                value: codigo,
                search: sigla,
              }))}
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <Form.Item
            name="identificadorExterno"
            label={<span>Protocolo externo:</span>}
            rules={[
              {
                required: !disable && !isCanalDefault,
                message: "Este campo é obrigatório!",
              },
            ]}
          >
            <Input
              placeholder={
                isCanalDefault
                  ? showPlaceholder("será gerado automaticamente")
                  : showPlaceholder("Protocolo externo")
              }
              onChange={(e) => setProtocoloExterno(e.target.value)}
              value={protocoloExterno}
              disabled={disable || isCanalDefault}
              allowClear
              autoComplete="none"
              autoCorrect="off"
              onBlur={() => checkProtocol(canalSelect, protocoloExterno)}
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <Form.Item
            name="sigilo"
            label={<span>Sigilo:</span>}
            defaultValue={true}
            disabled={true}
          >
            <span>
              <strong>
                {" "}
                Denúncia de cunho sigilosa. Proibido a divulgação de seus dados.
                Este sistema prioriza as diretrizes da Lei Geral de Proteção de
                dados - LGPD
              </strong>
            </span>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span="24">
          <Form.Item
            name="descricao"
            label={<span>Descrição a notificação/ manifestação:</span>}
            rules={[{ required: true, message: "Este campo é obrigatório!" }]}
          >
            <Input.TextArea
              rows={5}
              showCount
              placeholder="O que aconteceu?"
              disabled={onView}
              allowClear
              autoSize={{ minRows: 3 }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={32}>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <Form.Item
            name="dataOcorrencia"
            label={<span>Data de notificação/ manifestação:</span>}
            rules={[{ required: true, message: "Este campo é obrigatório!" }]}
          >
            <DatePicker
              style={{ width: "100%" }}
              format={DATA_FORMATO}
              placeholder="Formato dd/mm/aaaa"
              disabledDate={(d) => !d || d.isAfter(new Date())}
              disabled={onView}
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <Form.Item
            name={["localOcorrencia", "codigo"]}
            label={<span>Local da notificação/ manifestação:</span>}
            rules={[{ required: true, message: "Este campo é obrigatório!" }]}
          >
            <Select
              autoComplete="none"
              placeholder="selecionar..."
              disabled={onView}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={localOcorrencia?.map(({ codigo, descricao }) => ({
                label: descricao,
                value: codigo,
              }))}
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <Form.Item
            name={["frequencia", "codigo"]}
            label={<span>Frequência da notificação/ manifestação:</span>}
            rules={[{ required: true, message: "Este campo é obrigatório!" }]}
          >
            <Select
              placeholder="selecionar..."
              autoComplete="none"
              disabled={onView}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={frequenciaOcorrencia?.map(({ codigo, descricao }) => ({
                label: descricao,
                value: codigo,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={32}>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <Form.Item
            label={<span>Estado:</span>}
            name={["endereco", "bairro", "cidade", "estado", "id"]}
            rules={[{ required: true, message: "Este campo é obrigatório!" }]}
          >
            <Select
              placeholder="escolher estado"
              onChange={(estado) => handleSelectEstado(estado, false)}
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={estados?.map(({ nome, id }) => ({
                label: nome,
                value: id,
              }))}
              disabled={onView}
              autoComplete="none"
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <Form.Item
            name={["endereco", "bairro", "cidade", "id"]}
            label={<span>Cidade:</span>}
            rules={[{ required: true, message: "Este campo é obrigatório!" }]}
            validateStatus="validating"
            hasFeedback={loading.cidades}
          >
            <Select
              placeholder="escolher município"
              onChange={(cidade) => handleSelectCity(cidade, false)}
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={cidades?.map(({ nome, id }) => ({
                label: nome,
                value: id,
              }))}
              disabled={onView}
              autoComplete="none"
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
          <Form.Item
            name={["endereco", "bairro", "id"]}
            label={<span>Bairro:</span>}
            rules={[{ required: true, message: "Este campo é obrigatório!" }]}
            validateStatus="validating"
            hasFeedback={loading.bairros}
          >
            <Select
              placeholder="escolher"
              disabled={onView}
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={bairros?.map(({ nome, id }) => ({
                label: nome,
                value: id,
              }))}
              autoComplete="none"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={32}>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <Form.Item
            name={["endereco", "logradouro"]}
            label={<span>Logradouro:</span>}
          >
            <Input
              placeholder="insira o logradouro"
              autoComplete="none"
              disabled={onView}
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <Form.Item name={["endereco", "numero"]} label={<span>Número:</span>}>
            <Input
              placeholder="Insira o número"
              autoComplete="none"
              disabled={onView}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={32}>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <Form.Item
            name={["endereco", "cep"]}
            label={<span>CEP:</span>}
            onChange={({ target }) => onChangeCep(target.value)}
          >
            <Input
              placeholder="60000-600"
              autoComplete="none"
              disabled={onView}
            />
          </Form.Item>
        </Col>

        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <Form.Item
            name={["endereco", "complemento"]}
            label={<span>Ponto de referência</span>}
          >
            <Input
              placeholder="insira o ponto de referência"
              autoComplete="none"
              autoCorrect="none"
              autoCapitalize="none"
              disabled={onView}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={32}>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <Form.Item
            name={["criticidade", "codigo"]}
            label={<span>Criticidade:</span>}
          >
            <Select
              placeholder="selecionar..."
              autoComplete="none"
              disabled={onView}
              options={criticidades.map(({ descricao, codigo }) => ({
                label: descricao,
                value: codigo,
              }))}
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <Form.Item name="agravantes" label={<span>Agravante:</span>}>
            <Select
              mode="multiple"
              allowClear
              placeholder="selecionar..."
              disabled={onView}
              options={agravantes.map(({ descricao, codigo }) => ({
                label: descricao,
                value: codigo,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span="24">
          <Form.Item name="observacao" label={<span>Observações:</span>}>
            <Input.TextArea
              rows={5}
              autoSize={{ minRows: 3 }}
              showCount
              placeholder="Algum detalhe que possa facilitar o atendimento da solicitação"
              disabled={onView}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="end" style={{ marginTop: "1rem" }}>
        <Button htmlType="submit" type="primary" loading={loading.submit}>
          {onCreate ? "cadastrar" : "próximo"}
        </Button>
      </Row>
    </Form>
  ) : (
    <Skeleton active />
  );
};
