import React, { useState, useEffect, useCallback } from "react";

import { Table, Space, Button, Popconfirm } from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import { SearchColumn } from "../../../../../components/SearchColumn/SearchColumn";

import ModalAgravante from "../components/ModalAgravante";
import { openNotification } from "../../../../../components/Notification";
import { agravanteService } from "../../../../../services/resources/agravanteService";

const ListAgravantes = () => {
  const [agravantes, setAgravantes] = useState([]);

  const [openModalAgravante, setOpenModalAgravante] = useState(false);

  const [codigo, setCodigo] = useState("");
  const [mode, setMode] = useState("");

  const [popLoading, setPopLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    getAgravantes();
    // eslint-disable-next-line
  }, []);

  async function getAgravantes() {
    await agravanteService
      .list()
      .then((response) => transformList(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os agravantes!</strong>,
          "" + reason
        )
      );
  }

  async function handleDelete(codigo) {
    setPopLoading(true);
    setDisabled(true);
    try {
      await agravanteService.delete(codigo);
      openNotification("success", <strong>Agravante excluído!</strong>);
      getAgravantes();
    } catch (reason) {
      openNotification(
        "error",
        <strong>Não foi possível excluir o agravante!</strong>,
        "Erro: " + reason.response.data.userMessage
      );
    } finally {
      setPopLoading(false);
      setDisabled(false);
    }
  }

  const handleOpenModalAgravante = useCallback((codigo) => {
    setOpenModalAgravante(true);
    setCodigo(codigo);
  }, []);

  const handleCloseModalAgravante = useCallback(() => {
    setOpenModalAgravante(false);
  }, []);

  const transformList = useCallback((list) => {
    list.sort(function (a, b) {
      return a.descricao > b.descricao ? 1 : b.descricao > a.descricao ? -1 : 0;
    });
    setAgravantes(list);
  }, []);

  const renderTable = () => {
    const columns = [
      {
        title: <strong>Descrição</strong>,
        dataIndex: "descricao",
        key: "descricao",
        ...SearchColumn("descricao"),
      },
      {
        title: <strong>ações</strong>,
        key: "action",
        width: 64,
        render: ({ codigo, descricao }) => (
          <Space size="small">
            <Button
              type="primary"
              className="action-button"
              ghost
              title={`Visualizar agravante ${descricao}`}
              onClick={() => {
                handleOpenModalAgravante(codigo, descricao);
                setMode("onView");
              }}
            >
              <EyeOutlined style={{ fontSize: "1.2rem" }} />
            </Button>
            <Button
              type="primary"
              className="action-button"
              title={`Editar agravante ${descricao}`}
              onClick={() => {
                handleOpenModalAgravante(codigo, descricao);
                setMode("onEdit");
              }}
            >
              <EditOutlined style={{ fontSize: "1.2rem" }} />
            </Button>
            <div>
              <Popconfirm
                title={
                  <span>
                    excluir agravante: <strong>{descricao}</strong>?"
                  </span>
                }
                placement="bottomRight"
                okText="confirmar exclusão"
                cancelText="cancelar"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={() => handleDelete(codigo)}
                okButtonProps={{ loading: popLoading }}
                disabled={disabled}
                cancelButtonProps={{ disabled: disabled }}
              >
                <Button type="danger" className="action-button">
                  <DeleteOutlined style={{ fontSize: "1.2rem" }} />
                </Button>
              </Popconfirm>
            </div>
          </Space>
        ),
      },
    ];
    function showTotal(total) {
      return (
        <>
          Total de <strong>{total}</strong> agravantes
        </>
      );
    }
    return (
      <Table
        dataSource={agravantes.map((agravante) => ({
          ...agravante,
          descricao: agravante?.descricao,
        }))}
        columns={columns}
        size="small"
        rowKey={({ codigo }) => codigo}
        pagination={{
          size: "small",
          showSizeChanger: true,
          showTotal: showTotal,
        }}
      />
    );
  };

  return (
    <>
      <div className="container-section">
        <div id="header-container">
          <h2>LISTA DE AGRAVANTES</h2>
          <div id="header-actions">
            <Button
              type="primary"
              onClick={() => {
                handleOpenModalAgravante();
                setMode("onCreate");
              }}
            >
              novo agravante
            </Button>
          </div>
        </div>
        {renderTable()}
      </div>
      {openModalAgravante && (
        <ModalAgravante
          onClose={handleCloseModalAgravante}
          codigo={codigo}
          mode={mode}
          getAgravantes={getAgravantes}
        />
      )}
    </>
  );
};

export default ListAgravantes;
