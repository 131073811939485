import React from "react";

import { Row } from "antd";

import oisolLogo from "../../images/logos/oisol.svg";
import oisolLogoCompleta from "../../images/logos/oisol_modelo_DH.png";
import sedihLogo from "../../images/logos/logo-gov.png";

import { PageResult } from "../Publico/style";
import Header from "../../components/Header/Header";

export const PoliticaPrivacidade = () => {
  return (
    <PageResult>
      <Header showVersion={false} />

      <div id="sectionMainPrivacy">
        <div>
          <div className="container-section-privacy">
            <Row
              style={{ background: "#ff9945" }}
              justify="space-evenly"
              align="middle"
            >
              <Row align="bottom">
                <img src={oisolLogo} alt="Logo OISOL" style={{ width: 70 }} />
                <p>
                  <span style={{ color: "#fff", marginLeft: 2 }}>
                    <strong>OISOL App</strong>
                  </span>
                  <span style={{ color: "#fff" }}>®</span>
                </p>
              </Row>

              <h1 style={{ color: "#fff" }}>
                {" "}
                <strong>POLÍTICAS DE PRIVACIDADE</strong>
              </h1>
            </Row>

            <p>
              <span>
                O <strong>OISOL App®</strong> tem como objetivo proporcionar
                praticidade ao cidadão, permitindo que os cidadãos cadastrem
                denúncias e acompanhem o progresso da denúncia.
                <br />
                <br />
                Com esse propósito, buscamos diariamente oferecer a melhor
                experiência para que você possa utilizar os nossos serviços e
                produtos com a mais alta qualidade. E, para isso, a sua
                privacidade, a segurança e a proteção dos seus dados pessoais
                são – e sempre serão – muito importantes para nós. Entendemos
                que garantir a privacidade vai muito além do cumprimento da
                legislação. É respeitar nossos usuários, bem como honrar os
                princípios de transparência, segurança e proteção. Tudo isso sem
                perder a simplicidade e experiência positiva! Exatamente por
                isso, este Aviso de Privacidade (“Aviso”) tem como objetivo
                apresentar, de forma simples e transparente, informações sobre o
                tratamento dos seus dados pessoais quando se relaciona com{" "}
                <strong>OISOL App®</strong>. Além disso, você encontrará neste
                Aviso quais são os seus direitos relativos a essas informações e
                como exercê-los. Se ainda restarem quaisquer dúvidas sobre o
                tratamento dos seus dados, os canais de atendimento indicados
                abaixo estão à sua disposição.
              </span>
            </p>

            <Row
              style={{ background: "#ff9945" }}
              justify="space-evenly"
              align="middle"
            >
              <h2 style={{ color: "#fff" }}>
                <strong>1. Quem somos</strong>
              </h2>
            </Row>

            <p>
              O <strong>OISOL App®</strong> é um aplicativo dentro do
              Ecossistema do Observatório dos Direitos Humanos do Estado do
              Ceará e é gerenciado pela Secretaria dos Direitos Humanos(SEDIH -
              CNPJ: xxxxx-xxxx), a qual figura como Controladora de Dados para
              todos os fins relacionados ao <strong>OISOL App®</strong>.
            </p>

            <Row
              style={{ background: "#ff9945" }}
              justify="space-evenly"
              align="middle"
            >
              <h2 style={{ color: "#fff" }}>
                <strong>2. Definições Importantes</strong>
              </h2>
            </Row>

            <p>
              <span>
                Para que possa compreender mais sobre esse Aviso de Privacidade,
                leia abaixo:
              </span>
              <ul>
                <li>
                  <strong>
                    Não serão coletados dados pessoais como Nome, CPF, E-mail, e
                    outros dados desta natureza;
                  </strong>
                </li>

                <li>
                  <strong>
                    Tratamento de dados pessoais é qualquer operação realizada
                    com dados pessoais, como, por exemplo, a coleta, utilização,
                    acesso, processamento, armazenamento, eliminação,
                    compartilhamento, entre outros;
                  </strong>
                </li>
              </ul>
            </p>

            <Row
              style={{ background: "#ff9945" }}
              justify="space-evenly"
              align="middle"
            >
              <h2 style={{ color: "#fff" }}>
                <strong>3. Contato</strong>
              </h2>
            </Row>

            <p>
              <span>
                Para exercer os seus direitos ou esclarecer qualquer tipo de
                dúvida sobre como seus dados pessoais são tratados, você pode
                entrar em contato por meio do e-mail: appoisolsedih@gmail.com.
              </span>
            </p>

            <Row justify="space-evenly">
              <img style={{ width: 150 }} src={sedihLogo} alt="Logo SEDIH" />
              <img
                style={{ width: 150 }}
                src={oisolLogoCompleta}
                alt="Logo OISOL"
              />
            </Row>
          </div>
        </div>
      </div>
    </PageResult>
  );
};
