import { ApiOutlined, DesktopOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React, { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";

function Versao(props) {
  const { backendVersion } = useContext(UserContext);
  return (
    <Row justify="center" gutter={10} {...props}>
      <Col>
        <DesktopOutlined /> {process.env.REACT_APP_VERSION}
      </Col>
      <Col>|</Col>
      <Col>
        <ApiOutlined /> {backendVersion}
      </Col>
    </Row>
  );
}

export default Versao;
