import {
  EditOutlined,
  EyeOutlined,
  MinusOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Button, Card, Skeleton, Tooltip } from "antd";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { urls } from "../../../../utils";

export function UsuarioCard({
  usuario: { nome, ativo, codigo, email, instituicao },
  warningDelete,
  loading,
}) {
  const history = useHistory();

  return (
    <Card
      style={{ width: "100%", marginTop: 16 }}
      actions={[
        <Button
          key="view"
          icon={
            <EyeOutlined
              style={{ fontSize: "1.2rem" }}
              onClick={() =>
                history.push(urls.USUARIOS_DETAILS.replace(":codigo", codigo))
              }
            />
          }
        />,
        <Button
          key="edit"
          type="primary"
          icon={
            <EditOutlined
              style={{ fontSize: "1.2rem" }}
              onClick={() =>
                history.push(urls.USUARIOS_EDITAR.replace(":codigo", codigo))
              }
            />
          }
        />,
        <Button
          key="delete"
          danger={ativo}
          onClick={() => warningDelete({ codigo, nome, ativo })}
          icon={ativo ? <MinusOutlined /> : <PlusOutlined />}
        />,
      ]}
    >
      <Skeleton loading={loading} avatar active>
        <Card.Meta
          avatar={
            <Tooltip
              title={`usuário ${ativo ? "ativo" : "inativo"}`}
              placement="top"
            >
              <Badge
                dot
                color={ativo ? "var(--green-color)" : "var(--red-color)"}
                size="default"
              >
                <Avatar shape="square" icon={<UserOutlined />} />
              </Badge>
            </Tooltip>
          }
          title={nome}
          description={
            <>
              <strong>{email}</strong>
              <br />
              <Link
                to={urls.INSTITUICOES_DETAILS.replace(
                  ":codigo",
                  instituicao?.codigo
                )}
              >
                {instituicao.nome}
              </Link>
            </>
          }
        />
      </Skeleton>
    </Card>
  );
}
