import api from "../api";

export const identidadeGeneroService = {
    async loadIdentidadeGenero() {
      return await api.get("/identidade-genero");
    },
    async getIdentidadeGenerosPublico() {
      return await api.get("/publico/identidades-generos");
    },
    async get(codigo) {
      return await api.get(`/identidade-genero/${codigo}`);
    },
    async create(payload) {
      return await api.post(`/identidade-genero`, payload);
    },
    async edit(codigo, payload) {
      return await api.put(`/identidade-genero/${codigo}`, payload);
    },
    async delete(codigo) {
      return await api.delete(`/identidade-genero/${codigo}`);
    },
  }