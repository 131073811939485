import React, { useCallback, useContext, useState } from "react";
import { Form, Input, Col, Drawer, Space, Button, Row } from "antd";
import {
  LockOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

import { useWidth } from "../../hooks";
import { openNotification } from "../Notification";
import { UserContext } from "../../contexts/UserContext";
import { usuarioService } from "../../services/resources/usuarioService";

function ModalNewPass({ onClose, visible }) {
  const { user } = useContext(UserContext);

  const { width } = useWidth();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const [lengthPass, setLengthPass] = useState(null);
  const [upperCasePass, setUpperCasePass] = useState(null);
  const [lowerCasePass, setLowerCasePass] = useState(null);
  const [specialCharPass, setSpecialCharPass] = useState(null);
  const [numberPass, setNumberPass] = useState(null);

  const handleCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  async function handleSubmit() {
    setLoading(true);
    try {
      const senha = {
        novaSenha: newPass,
        senhaAtual: oldPass,
      };
      await usuarioService.newPass(user?.codigo_usuario, senha);
      handleCloseModal();
      openNotification(
        "success",
        <strong>Senha Alterada!</strong>,
        "Sua senha foi alterada com sucesso, no seu próximo acesso, você já usá-la."
      );
    } catch (reason) {
      handleCloseModal();
      openNotification(
        "error",
        <strong>Erro ao alterar senha!</strong>,
        reason?.response?.data?.detail
      );
    } finally {
      setLoading(false);
    }
  }

  const onCheckPass = useCallback((pass) => {
    setNewPass(pass);

    //Teste regex para mais que 8 caracteres
    if (RegExp("(?=.{8,})").test(pass)) setLengthPass(true);
    else setLengthPass(false);
    //Teste regex para Letra maiúscula
    if (RegExp("(?=.*[A-Z])").test(pass)) setUpperCasePass(true);
    else setUpperCasePass(false);
    //Teste regex para Letra minúscula
    if (RegExp("(?=.*[a-z])").test(pass)) setLowerCasePass(true);
    else setLowerCasePass(false);
    //Teste regex para caracteres especiais
    if (RegExp("([^A-Za-z0-9])").test(pass)) setSpecialCharPass(true);
    else setSpecialCharPass(false);
    //Teste regex para números
    if (RegExp("(?=.*[0-9])").test(pass)) setNumberPass(true);
    else setNumberPass(false);
  }, []);

  const CheckItem = ({ status, text }) => (
    <Col
      style={{
        color: status !== null ? (status ? "green" : "red") : "black",
      }}
    >
      {status !== null ? (
        status ? (
          <CheckCircleOutlined />
        ) : (
          <CloseCircleOutlined />
        )
      ) : (
        <MinusCircleOutlined />
      )}{" "}
      {text}
    </Col>
  );

  return (
    <Drawer
      title={<strong>Alterar senha</strong>}
      onClose={onClose}
      visible={visible}
      placement="left"
      width={width <= 532 ? "calc(100vw - 32px)" : "500px"}
      closable={false}
      extra={
        <Space>
          <CloseOutlined
            onClick={onClose}
            style={{
              position: "absolute",
              right: 8,
              top: 8, 
            }}
          />
        </Space>
      }
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        name="changePass"
        layout="vertical"
      >
        <Row>
          <Col span="24">
            <Form.Item
              name="oldPassword"
              label={<span>Senha atual:</span>}
              rules={[{ required: true, message: "Este campo é obrigatório!" }]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="digite aqui a sua senha"
                value={oldPass}
                onChange={(e) => setOldPass(e.target.value)}
              />
            </Form.Item>
          </Col>

          <Col span="24">
            <Form.Item
              name="NewPassword"
              label={<span>Nova senha:</span>}
              rules={[{ required: true, message: "Este campo é obrigatório!" }]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="digite aqui a sua senha"
                value={newPass}
                onChange={(e) => onCheckPass(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span="24">
            <Form.Item
              name="ConfirmPassword"
              label={<span>Confirme a nova senha:</span>}
              dependencies={["NewPassword"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Este campo é obrigatório!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("NewPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "A senha não é a mesma informada em *Nova Senha!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="digite aqui a sua senha"
                value={confirmPass}
                onChange={(e) => setConfirmPass(e.target.value)}
              />
            </Form.Item>
          </Col>

          <Col style={{ padding: "1rem 0" }}>
            <Col style={{ fontSize: "0.75rem" }}>
              <CheckItem
                status={lengthPass}
                text="A senha deve ter mínimo 8."
              />

              <CheckItem
                status={upperCasePass}
                text="A senha deve ter pelo menos uma letra maiúscula."
              />

              <CheckItem
                status={lowerCasePass}
                text="A senha deve ter pelo menos uma letra minúscula."
              />

              <CheckItem
                status={specialCharPass}
                text="A senha deve ter pelo menos um símbolo. Ex: * ! # $ % & + - / : ; = ? @ \ |"
              />

              <CheckItem
                status={numberPass}
                text="A senha deve ter pelo menos um número."
              />
            </Col>
          </Col>
        </Row>
        <Row justify="end">
          <Col style={{ padding: "1rem 0" }}>
            <Button
              htmlType="submit"
              type="primary"
              loading={loading}
              block={width < 576}
              style={{ height: "35px", fontWeight: "" }}
            >
              atualizar senha
            </Button>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

export default ModalNewPass;
