export const PESSOAS_TAG_FILTER = {
  nome: "nome",
  enderecoLogradouro: "logradouro",
  bairroNome: "bairro",
  cidadeNome: "cidade",
};

export const DENUNCIAS_TAG_FILTER = {
  codigoStatusList: "status",
  identificadorExterno: "protocolo",
  nomeCanal: "canal",
  nomeCidade: "cidade",
  classificacaoAtributoDescricao: "classificação"
};

export const ENCAMINHAMENTOS_TAG_FILTER = {
  identificadorExterno: "protocolo",
  statusList: "status",
  denunciaBairroNome: "bairro da denúncia",
  denunciaMunicipio: "município da denúncia"
};
