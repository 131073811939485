import api from "../api";

export const tipoViolacaoService = {
    async loadViolationTypes(params) {
      return await api.get("/tipos-violacoes", {
        params,
      });
    },
    async getViolationType(id) {
      return await api.get(`/tipos-violacoes/${id}`);
    },
    async createViolationType(payload) {
      return await api.post(`/tipos-violacoes`, payload);
    },
    async editViolationType(id, payload) {
      return await api.put(`/tipos-violacoes/${id}`, payload);
    },
    async deleteViolationType(id) {
      return await api.delete(`/tipos-violacoes/${id}`);
    },
  }