import React, { memo, useCallback } from "react";

import { Breadcrumb } from "antd";

import { Container } from "./styles";
import { HomeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { urls } from "../../utils";

import { useQuery } from "../../hooks/useQuery";
import { renderComponent } from "./utils/Menu";

const CadastrosBasicos = () => {
  const query = useQuery();

  const mode = query.get("mode");

  const render = useCallback(
    (mode) => {
      return renderComponent(mode);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mode]
  );

  return (
    <>
      <div id="sectionMain">
        <Breadcrumb id="breadcrumb">
          <Breadcrumb.Item key="home">
            <Link to="">
              <HomeOutlined />
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item key="gerenciamento">gerenciamento</Breadcrumb.Item>
          <Breadcrumb.Item key="bairros">
            <Link to={urls.CADASTROS_BASICOS}>
              <strong>cadastros básicos</strong>
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="container-section">
          <Container>{render(mode)}</Container>
        </div>
      </div>
    </>
  );
};

export default memo(CadastrosBasicos);
