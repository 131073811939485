import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import {
  Col,
  Form,
  Input,
  Spin,
  Select,
  Button,
  Modal,
  Breadcrumb,
} from "antd";

import { HomeOutlined } from "@ant-design/icons";
import { openNotification } from "../../../../components/Notification";
import PageHeader from "../../../../components/PageHeader";
import { FormActions } from "../../../../components/FormActions";
import { temaService } from "../../../../services/resources/temaService";
import { DenunciaTabela } from "../../../Denuncia/components/DenunciaTabela";
import { scrollBotton, urls } from "../../../../utils";
import {
  subTipoViolacaoService,
  tipoViolacaoService,
} from "../../../../services";

const { TextArea } = Input;

const EditTipo = ({ onView }) => {
  const history = useHistory();
  const { codigo } = useParams();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [isCreateTipo, setIsCreatTipo] = useState(false);
  const [viewList, setViewList] = useState(false);
  const [inBotton, setInBotton] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [tipoEdit, setTipoEdit] = useState(null);
  const [temasDenuncia, setTemassDenuncia] = useState([]);
  const [subtiposDenuncia, setSubtiposDenuncia] = useState([]);

  const getAllTemas = async () => {
    setLoading(true);
    await temaService
      .loadThemes()
      .then((response) => setTemassDenuncia(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Erro!</strong>,
          "Ocorreu um erro ao buscar os Temas."
        )
      );
    setLoading(false);
  };

  const getAllSubtiposDenuncias = async () => {
    setLoading(true);
    await subTipoViolacaoService
      .loadViolationSubTypes()
      .then((response) => setSubtiposDenuncia(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Erro!</strong>,
          "Ocorreu um erro ao carregar os Sub-Tipos de denúncia."
        )
      );
    setLoading(false);
  };

  const getTipo = async (codigo) => {
    try {
      const response = await tipoViolacaoService.getViolationType(codigo);
      const { data } = response;

      setTipoEdit({
        ...data,
        temas: data.temas.map((tipo) => tipo.codigo),
        subTiposViolacao: data.subTiposViolacao.map((tipo) => tipo.codigo),
      });
      form.setFieldsValue({
        ...data,
        temas: data.temas.map((tipo) => tipo.codigo),
        subTiposViolacao: data.subTiposViolacao.map((tipo) => tipo.codigo),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddTipo = async ({
    descricao,
    fundamentacao,
    temas,
    subTiposViolacao,
  }) => {
    const newTemasviolacao = temas?.map((tipo) => ({ codigo: tipo }));
    const newSubTiposViolacao = subTiposViolacao?.map((tipo) => ({
      codigo: tipo,
    }));

    try {
      await tipoViolacaoService.createViolationType({
        descricao,
        fundamentacao,
        temas: newTemasviolacao,
        subTiposViolacao: newSubTiposViolacao,
      });
      openNotification(
        "success",
        <strong>Sucesso!</strong>,
        "Tipo criado com sucesso!"
      );
    } catch (reason) {
      openNotification(
        "error",
        <strong>Erro!</strong>,
        "" + reason?.response?.data?.detail
      );
    } finally {
      history.push(`${urls.CLASSIFICACOES}?mode=tipos`);
    }
  };

  const handleUpdateTipo = async ({
    descricao,
    fundamentacao,
    temas,
    subTiposViolacao,
  }) => {
    const newTemas = temas?.map((tema) => ({ codigo: tema }));
    const newSubTiposViolacao = subTiposViolacao?.map((subtipo) => ({
      codigo: subtipo,
    }));

    try {
      await tipoViolacaoService.editViolationType(codigo, {
        descricao,
        fundamentacao,
        temas: newTemas,
        subTiposViolacao: newSubTiposViolacao,
      });
      openNotification(
        "success",
        <strong>Sucesso!</strong>,
        "Tema atualizado com sucesso!"
      );
    } catch (reason) {
      openNotification(
        "error",
        <strong>Erro!</strong>,
        "" + reason?.response?.data?.detail
      );
    } finally {
      history.push(`${urls.CLASSIFICACOES}?mode=tipos`);
    }
  };

  useEffect(() => {
    getAllTemas();
    getAllSubtiposDenuncias();

    codigo ? getTipo(codigo) : setIsCreatTipo(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codigo]);

  const renderForm = () => (
    <Spin spinning={loading} size="large">
      {!loading && (
        <Form
          onFieldsChange={() =>
            setHasError(
              !!form.getFieldsError().find(({ errors }) => errors?.length >= 1)
            )
          }
          name="novo_tema"
          onFinish={isCreateTipo ? handleAddTipo : handleUpdateTipo}
          layout="vertical"
          form={form}
        >
          <Col span={24}>
            <Form.Item
              name="descricao"
              label={<span>Descrição:</span>}
              rules={
                !onView && [
                  { required: true, message: "Este campo é obrigatório!" },
                ]
              }
            >
              <Input placeholder="Insira a descrição do tipo" allowClear />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="fundamentacao"
              label={<span>Fundamentação:</span>}
              rules={
                !onView && [
                  { required: true, message: "Este campo é obrigatório!" },
                ]
              }
            >
              <TextArea disabled={onView} allowClear autoSize/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="temas" label={<span>Temas:</span>}>
              <Select
                allowClear
                showArrow
                mode="tags"
                style={{
                  width: "100%",
                }}
                placeholder="Selecione os temas"
                id="temas"
                options={temasDenuncia?.map(({ codigo, descricao }) => ({
                  label: descricao,
                  value: codigo,
                  key: codigo,
                }))}
              ></Select>
            </Form.Item>

            <Form.Item name="subTiposViolacao" label={<span>Subtipos:</span>}>
              <Select
                allowClear
                showArrow
                mode="tags"
                style={{
                  width: "100%",
                }}
                placeholder="Selecione os subtipos"
                id="subTiposViolacao"
                options={subtiposDenuncia?.map(({ codigo, descricao }) => ({
                  label: descricao,
                  value: codigo,
                  key: codigo,
                }))}
              ></Select>
            </Form.Item>

            {!onView && !hasError && (
              <FormActions
                inBotton={inBotton}
                actions={[
                  <Button
                    type="primary"
                    ghost
                    onClick={() =>
                      history.push(`${urls.CLASSIFICACOES}?mode=tipos`)
                    }
                  >
                    {isCreateTipo ? (
                      <span>cancelar</span>
                    ) : (
                      <span>cancelar edições</span>
                    )}
                  </Button>,
                  <Button type="primary" htmlType="submit">
                    {isCreateTipo ? (
                      <span>cadastrar tipo</span>
                    ) : (
                      <span>salvar edições</span>
                    )}
                  </Button>,
                ]}
              />
            )}
          </Col>
        </Form>
      )}
    </Spin>
  );

  return (
    <div id="sectionMain" onScroll={(e) => setInBotton(scrollBotton(e))}>
      <Breadcrumb id="breadcrumb">
        <Breadcrumb.Item key="home">
          <Link to="">
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="gerenciamento">gerenciamento</Breadcrumb.Item>
        <Breadcrumb.Item key="classificacoes">
          <Link to={urls.INSTITUICOES_LIST}>classificações</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="temas">
          <Link to={`${urls.CLASSIFICACOES}?mode=tipos`}>tipos</Link>
        </Breadcrumb.Item>

        {isCreateTipo ? (
          <Breadcrumb.Item key="criar">
            <strong>cadastrar tipo</strong>
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key="editar">
            <strong>editar tipo</strong>
          </Breadcrumb.Item>
        )}
      </Breadcrumb>

      <div className="section-children">
        <div className="container-section">
          <PageHeader
            titulo={
              isCreateTipo
                ? "CADASTRAR TIPO"
                : tipoEdit?.descricao.toUpperCase()
            }
            subtitulo={
              isCreateTipo
                ? "Inserir informações sobre o tipo"
                : "Editar informações sobre o tipo"
            }
          />
          {renderForm()}
        </div>
      </div>

      <Modal
        title={"DENÚNICAS QUE CONTÉM ENCAMINHAMENTOS PARA ESSA INSTITUIÇÃO"}
        visible={viewList}
        onCancel={() => setViewList(false)}
        width={"90%"}
        centered
        footer={[
          <Button type="primary" onClick={() => setViewList(false)}>
            voltar
          </Button>,
        ]}
      >
        <DenunciaTabela
          showStatus
          filtro={{ codigoInstituicaoDestinataria: codigo }}
          searchClassificacao={false}
        />
      </Modal>
    </div>
  );
};

export default EditTipo;
