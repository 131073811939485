import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { Table, Space, Popconfirm, Button, Breadcrumb } from "antd";

import { SearchColumn } from "../../../components/SearchColumn/SearchColumn";

import { EyeOutlined, EditOutlined, HomeOutlined, DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { openNotification } from "../../../components/Notification";
import { urls } from "../../../utils";
import { grupoService } from "../../../services/resources/grupoService";

const ListGroups = () => {
  const history = useHistory();

  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);

  const [codigoDelete, setCodigoDelete] = useState(null);

  const [disabled, setDisabled] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    getGroups();
  }, []);

  async function getGroups() {
    try {
      setLoading(true);
      const { data } = await grupoService.list();
      setGroups(data);
      setLoading(false);
    } catch (error) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao buscar os grupos!</strong>,
        "Erro: " + error?.response?.data?.detail
      );
    }
  }

  async function handleDelete(codigo) {
    setConfirmLoading(true);
    setDisabled(true);
    try {
      await grupoService.delete(codigo);
      openNotification("success", <strong>Grupo excluído!</strong>);
      getGroups();
    } catch (reason) {
      openNotification(
        "error",
        <strong>Não foi possível excluir o grupo!</strong>,
        reason?.response?.data?.detail
      );
    } finally {
      setCodigoDelete(null);
      setConfirmLoading(false);
      setDisabled(false);
    }
  }

  const renderTable = () => {
    const columns = [
      {
        title: <strong>nome do grupo</strong>,
        dataIndex: "nome",
        key: "codigo",
        sorter: (a, b) => a.nome.localeCompare(b.nome),
        sortDirections: ["ascend", "descend"],
        ...SearchColumn("nome"),
      },
      {
        title: <strong>ações</strong>,
        key: "action",
        width: 64,
        render: ({ nome, codigo }) => (
          <Space size="small">
            <Button
              type="primary"
              ghost
              className="action-button"
              title={`Visualizar grupo ${nome}`}
              onClick={() => history.push(urls.GRUPOS_DETAILS.replace(":codigo", codigo))}
            >
              <EyeOutlined style={{ fontSize: "1.2rem" }} />
            </Button>

            <Button
              type="primary"
              className="action-button"
              title={`Editar grupo ${nome}`}
              onClick={() => history.push(urls.GRUPOS_EDITAR.replace(":codigo", codigo))}
            >
              <EditOutlined style={{ fontSize: "1.2rem" }} />
            </Button>

            <div>
              <Popconfirm
                title={
                  <span>
                    excluir grupo <strong>{nome}</strong>?"
                  </span>
                }
                visible={codigo === codigoDelete}
                placement="bottomRight"
                okText="confirmar exclusão"
                cancelText="cancelar"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={() => handleDelete(codigo)}
                onCancel={() => setCodigoDelete(null)}
                okButtonProps={{ loading: confirmLoading }}
                disabled={disabled}
                cancelButtonProps={{ disabled: disabled }}
              >
                <Button
                  type="danger"
                  className="action-button"
                  title={`Excluir grupo ${nome}`}
                  onClick={() => setCodigoDelete(codigo)}>
                  <DeleteOutlined style={{ fontSize: "1.2rem" }} />
                </Button>
              </Popconfirm>
            </div>
          </Space>
        ),
      },
    ];
    function showTotal(total) {
      return (
        <>
          Total de <strong>{total}</strong> grupos
        </>
      );
    }

    return (
      <Table
        dataSource={groups}
        columns={columns}
        size="small"
        rowKey={({ codigo }) => codigo}
        loading={{ spinning: loading, size: "large" }}
        pagination={{
          size: "small",
          showSizeChanger: true,
          showTotal: showTotal,
        }}
      />
    );
  };

  return (
    <div id="sectionMain">
      <Breadcrumb id="breadcrumb">
        <Breadcrumb.Item key="home">
          <Link to="">
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="controle">controle de acesso</Breadcrumb.Item>
        <Breadcrumb.Item key="grupos">
          <Link to={urls.GRUPOS_LIST}>
            <strong>grupos</strong>
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className="container-section">
        <div id="header-container">
          <h2>LISTA DE GRUPOS</h2>
          <div id="header-actions">
            <Link to={urls.GRUPOS_CRIAR}>
              <Button type="primary">novo grupo</Button>
            </Link>
          </div>
        </div>
        {renderTable()}
      </div>
    </div>
  );
};

export default ListGroups;
