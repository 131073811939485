import api from "../api";

export const   locaisOcorrenciaService = {
    async loadOcorrenceLocals() {
      return await api.get("/locais-ocorrencias");
    },

    async getAllLocaisOcorrencia() {
      return await api.get("/publico/locais-ocorrencias");
    },

    async get(codigo) {
      return await api.get(`/locais-ocorrencias/${codigo}`);
    },

    async create(payload) {
      return await api.post(`/locais-ocorrencias`, payload);
    },

    async edit(codigo, payload) {
      return await api.put(`/locais-ocorrencias/${codigo}`, payload);
    },

    async delete(codigo) {
      return await api.delete(`/locais-ocorrencias/${codigo}`);
    },
  }