import {
  CloudDownloadOutlined,
  DeleteOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileUnknownOutlined,
  GifOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Empty,
  Form,
  Popconfirm,
  Row,
  Space,
  Table,
  Tooltip,
  Upload,
} from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { openNotification } from "../../../../../components/Notification";
import { anexoService } from "../../../../../services/resources/anexoService";
import { denuncianteService } from "../../../../../services/resources/denuncianteService";
import { OPCOES_TAMANHO_PAGINA, USER_ROLES, urls } from "../../../../../utils";
import { UserContext } from "../../../../../contexts/UserContext";

const CONVERT_SIZE_FiLE_MB = (1024 * 1024)
const MAX_SIZE_FILE_UPLOAD = 30

export const Anexos = ({ handleNext, onEdit, onView }) => {
  const { user } = useContext(UserContext);

  const { codigo } = useParams();

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [anexos, setAnexos] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    getDenuncia();
    // eslint-disable-next-line
  }, []);

  async function getDenuncia() {
    setLoading(true);
    await denuncianteService
      .getDenunciation(codigo)
      .then(({ data }) => {
        setAnexos(
          data?.anexos?.map((anexo) => ({
            ...anexo,
            tamanho: transformSize(anexo.tamanho),
          }))
        );
      })
      .catch((reason) => {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os dados da denúncia!</strong>,
          reason?.response?.data?.detail
        );
      });
    setLoading(false);
  }

  function transformSize(x) {
    const units = ["bytes", "KB", "MB", "GB"];
    let l = 0,
      n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
  }

  async function handleDeleteAnexo(anexo) {
    await anexoService
      .delete(codigo, anexo)
      .then((response) => {
        getDenuncia();
        openNotification(
          "success",
          <strong>Anexo removido com sucesso!</strong>
        );
      })
      .catch((error) => {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao remover o anexo!</strong>,
          error?.response?.data?.detail
        );
      });
  }

  async function handleDownloadAnexo(anexo) {
    setDownloadLoading(true);
    await anexoService
      .get(codigo, anexo.codigo, anexo.contentType)
      .then((response) => {
        var data = new Blob([response.data], { type: anexo.contentType });
        var csvURL = window.URL.createObjectURL(data);
        var tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", anexo.nomeArquivo);
        tempLink.click();
      })
      .then(() => {
        openNotification(
          "success",
          <strong>Anexo baixado com sucesso!</strong>
        );
      })

      .catch((reason) => {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao baixar o anexo!</strong>,
          reason?.response?.data?.detail
        );
      });
    setDownloadLoading(false);
  }

  const uploadValues = useCallback(() => {
    if (onView) {
      handleNext("associated");
    } else {
      if (null) {
        openNotification(
          "error",
          <strong>Lista de classificação vazia!</strong>,
          "É necessário haver, pelo menos, uma classificação para cara denúncia."
        );
      } else {
        handleNext("associated");
      }
    }
  }, [handleNext, onView]);

  function findIcon(icon) {
    if (icon === "application/pdf")
      return <FilePdfOutlined style={{ fontSize: "1.5rem" }} />;
    else if (icon === "application/json")
      return <FileTextOutlined style={{ fontSize: "1.5rem" }} />;
    else if (["image/png", "image/jpg", "image/jpeg"].includes(icon))
      return <PictureOutlined style={{ fontSize: "1.5rem" }} />;
    else if (icon === "image/gif")
      return <GifOutlined style={{ fontSize: "1.5rem" }} />;
    else return <FileUnknownOutlined style={{ fontSize: "1.5rem" }} />;
  }

  const columnsAnexos = [
    {
      key: "icone",
      width: 70,
      render: (anexo) => (
        <Space size="large" align="center">
          {findIcon(anexo.contentType)}
        </Space>
      ),
    },
    {
      title: <strong>nome</strong>,
      dataIndex: ["nomeArquivo"],
      key: "nome",
    },

    {
      title: <strong>tamanho</strong>,
      dataIndex: ["tamanho"],
      key: "tamanho",
    },
  ];

  const colunasAcoes = {
    title: <strong>ações</strong>,
    key: "action",
    fixed: "right",
    width: 32,
    render: (anexo) => (
      <Space size="small">
        {onEdit && (
          <>
            {(user?.authorities?.includes(USER_ROLES.DELETAR_ANEXO_DENUNCIA)) 
                  && (
                <Popconfirm
                  title="Deletar anexo?"
                  onConfirm={() => handleDeleteAnexo(anexo.codigo)}
                >
                  <Button type="danger">
                    <DeleteOutlined style={{ fontSize: "1rem" }} />
                  </Button>
                </Popconfirm>
            )}
          </>
        )}
        <Button
          onClick={() => {
            handleDownloadAnexo(anexo);
          }}
          loading={downloadLoading}
          title={`Baixar o arquivo ${anexo.nomeArquivo}`}
          type="dashed"
        >
          <CloudDownloadOutlined style={{ fontSize: "1.2rem" }} />
        </Button>
      </Space>
    ),
  };

  const props = {
    name: "arquivo",
    multiple: true,
    action: process.env.REACT_APP_GATEWAY.concat(
      urls.DENUNCIA_ANEXO.replace(":codigo", codigo)
    ),
    method: "PUT",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("@oisol/token")}`,
      contentType: "multipart/form-data",
    },
    showUploadList: true,
    maxCount: 1,
    beforeUpload: (file) => {
      return !anexos.some(anexo => anexo.nomeArquivo === file.name);
    },
    onChange(info) {
      const { status } = info.file;

      const fileSize = (info.file.size / CONVERT_SIZE_FiLE_MB).toFixed(2);
      
      if (fileSize > MAX_SIZE_FILE_UPLOAD) {
        openNotification(
          "error",
          <strong>
            Arquivo {info.file.name} maior que o limite de {MAX_SIZE_FILE_UPLOAD} MB.
          </strong>,
          "Tente novamente ou entre em contato com o suporte do sistema."
        );
      }

      if (status === undefined) {
        openNotification(
          "info",
          <strong>
            Arquivo {info.file.name} já foi anexado.
          </strong>,
          "Caso precise de ajuda entre em contato com o suporte do sistema."
        );
      }

      if (status !== "uploading") {
      }
      if (status === "done") {
        openNotification(
          "success",
          <strong>O arquivo {info.file.name} foi anexado à denúncia!</strong>,
          "O anexo agora pode ser baixado ou excluído na listagem de anexos."
        );

        getDenuncia();
      } else if (status === "error") {
        openNotification(
          "error",
          <strong>
            Não foi possível anexar o arquivo {info.file.name} à denúncia.
          </strong>,
          "Tente novamente ou entre em contato com o suporte do sistema."
        );
      }
    },
  };

  return (
    <Upload.Dragger
      {...props}
      openFileDialogOnClick={false}
      style={{ border: "none", background: "transparent" }}
    >
      <Form form={form} onSubmit={uploadValues} name="Anexo" layout="vertical">
        <Row justify="end" align="middle" style={{ marginBottom: "10px" }}>
          {onEdit && (
            <Col style={{ display: "flex", gap: "1rem" }}>
              <Upload {...props}>
                <Button type="primary">adicionar anexo</Button>
              </Upload>
            </Col>
          )}
        </Row>

        <Row>
          <Col span={24}>
            {anexos?.length > 0 ? (
              <Table
                loading={loading}
                dataSource={anexos}
                columns={[...columnsAnexos, colunasAcoes]}
                pagination={{
                  size: "small",
                  showSizeChanger: true,
                  pageSizeOptions: OPCOES_TAMANHO_PAGINA,
                  total: anexos.length,
                  showTotal: (total) => (
                    <strong>
                      {total === 1
                        ? `${total} anexo no total`
                        : `${total} anexos no total`}
                    </strong>
                  ),
                }}
                size="small"
                rowKey={({ codigo }) => codigo}
              />
            ) : (
              <Empty description={<h2>Sem anexos para essa denúncia</h2>} />
            )}
          </Col>
        </Row>
      </Form>

      <Row justify="space-between" align="bottom">
        <Tooltip
          placement="top"
          title={"Todas as alterações nessa página já estão salvas!"}
        >
          <Button onClick={() => handleNext("classification")}>anterior</Button>
        </Tooltip>

        <Tooltip
          placement="top"
          title={"Todas as alterações nessa página já estão salvas!"}
        >
          <Button onClick={() => handleNext("associated")}>próximo</Button>
        </Tooltip>
      </Row>
    </Upload.Dragger>
  );
};
