import React, { useState, useEffect, useCallback } from "react";
import { Table, Space, Button, Popconfirm, Row } from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import { SearchColumn } from "../../../../components/SearchColumn/SearchColumn";
import ModalChannel from "./components/Modal";
import { openNotification } from "../../../../components/Notification";
import { deficienciaService } from "../../../../services/resources/deficienciaService";

const Deficiencia = () => {
  const [codigo, setCodigo] = useState(null);
  const [deficienciaList, setDeficienciaList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [mode, setMode] = useState("");
  const [popLoading, setPopLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    getDeficiencias();
    // eslint-disable-next-line
  }, []);

  async function getDeficiencias() {
    await deficienciaService
      .loadDeficiencias()
      .then((response) => transformList(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar a lista de deficiência!</strong>,
          "" + reason
        )
      );
  }

  async function handleDelete(codigo) {
    setPopLoading(true);
    setDisabled(true);
    try {
      await deficienciaService.delete(codigo);
      openNotification("success", <strong>Deficiência excluído!</strong>);
      getDeficiencias();
    } catch (reason) {
      openNotification(
        "error",
        <strong>Não foi possível excluir a deficiência!</strong>,
        "Erro: " + reason.response.data.userMessage
      );
    } finally {
      setPopLoading(false);
      setDisabled(false);
    }
  }

  const handleOpenModal = useCallback((deficiencia) => {
    setOpenModal(true);
    setCodigo(deficiencia?.codigo);
  }, []);

  const handleCloseModalChannel = useCallback(() => {
    setOpenModal(false);
  }, []);

  const transformList = useCallback((list) => {
    list.sort(function (a, b) {
      return a.descricao > b.descricao ? 1 : b.descricao > a.descricao ? -1 : 0;
    });
    setDeficienciaList(list);
  }, []);

  const renderTable = () => {
    const columns = [
      {
        title: <strong>Descrição</strong>,
        dataIndex: "descricao",
        key: "descricao",
        ...SearchColumn("descricao"),
      },
      {
        title: <strong>ações</strong>,
        key: "action",
        width: 64,
        render: (deficiencia) => (
          <Space size="small">
            <Button
              type="primary"
              className="action-button"
              ghost
              title={`Visualizar deficiencia ${deficiencia?.descricao}`}
              onClick={() => {
                handleOpenModal(deficiencia);
                setMode("onView");
              }}
            >
              <EyeOutlined style={{ fontSize: "1.2rem" }} />
            </Button>
            
            <Button
              type="primary"
              className="action-button"
              title={`Editar deficiencia ${deficiencia?.descricao}`}
              onClick={() => {
                handleOpenModal(deficiencia);
                setMode("onEdit");
              }}
            >
              <EditOutlined style={{ fontSize: "1.2rem" }} />
            </Button>

            <Popconfirm
              title={
                <span>
                  excluir deficiencia: <strong>{deficiencia?.descricao}</strong>?"
                </span>
              }
              placement="bottomRight"
              okText="confirmar exclusão"
              cancelText="cancelar"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={() => handleDelete(deficiencia?.codigo)}
              okButtonProps={{ loading: popLoading }}
              disabled={disabled}
              cancelButtonProps={{ disabled: disabled }}
            >
              <Button type="danger" className="action-button">
                <DeleteOutlined style={{ fontSize: "1.2rem" }} />
              </Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];
    function showTotal(total) {
      return (
        <>
          Total de <strong>{total}</strong> deficiências
        </>
      );
    }
    return (
      <Table
        dataSource={deficienciaList.map((deficiencia) => ({
          ...deficiencia,
          descricao: deficiencia?.descricao,
        }))}
        columns={columns}
        size="small"
        rowKey={({ codigo }) => codigo}
        pagination={{
          size: "small",
          showSizeChanger: true,
          showTotal: showTotal,
        }}
      />
    );
  };

  return (
    <>
      <div className="container-section">
        <Row id="header">
          <h2>LISTA DE DEFICIÊNCIAS</h2>

          <Button
            type="primary"
            onClick={() => {
              handleOpenModal();
              setMode("onCreate");
            }}
          >
            nova deficiência
          </Button>
        </Row>
        {renderTable()}
      </div>

      {openModal && (
        <ModalChannel
          onClose={handleCloseModalChannel}
          codigo={codigo}
          mode={mode}
          getDeficiencias={getDeficiencias}
        />
      )}
    </>
  );
};

export default Deficiencia;
