import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import {
  Col,
  Form,
  Input,
  Spin,
  Select,
  Button,
  Modal,
  Breadcrumb,
} from "antd";

import { HomeOutlined } from "@ant-design/icons";
import { openNotification } from "../../../../components/Notification";
import PageHeader from "../../../../components/PageHeader";
import { FormActions } from "../../../../components/FormActions";
import { temaService } from "../../../../services/resources/temaService";
import { DenunciaTabela } from "../../../Denuncia/components/DenunciaTabela";
import { scrollBotton, urls } from "../../../../utils";
import { tipoViolacaoService } from "../../../../services";

const { TextArea } = Input;

const EditTema = ({ onView }) => {
  const history = useHistory();
  const { codigo } = useParams();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [isCreateTema, setIsCreatTema] = useState(false);
  const [tiposDenuncia, setTiposDenuncia] = useState([]);
  const [viewList, setViewList] = useState(false);
  const [inBotton, setInBotton] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [temaEdit, setTemaEdit] = useState(null);

  const getDenunciationTypes = async () => {
    setLoading(true);
    await tipoViolacaoService
      .loadViolationTypes()
      .then((response) => setTiposDenuncia(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Erro!</strong>,
          "Ocorreu um erro ao buscar os Tipos de Denúncia."
        )
      );
    setLoading(false);
  };

  const getTema = async (codigo) => {
    try {
      const response = await temaService.getTheme(codigo);
      const { data } = response;

      setTemaEdit({
        ...data,
        tiposViolacao: data.tiposViolacao.map((tipo) => tipo.codigo),
      });
      form.setFieldsValue({
        ...data,
        tiposViolacao: data.tiposViolacao.map((tipo) => tipo.codigo),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddTema = async ({ descricao, fundamentacao, tiposViolacao }) => {
    const newTiposviolacao = tiposViolacao?.map((tipo) => ({ codigo: tipo }));

    try {
      await temaService.createTheme({
        descricao,
        fundamentacao,
        tiposViolacao: newTiposviolacao,
      });
      openNotification(
        "success",
        <strong>Sucesso!</strong>,
        "Tema criado com sucesso!"
      );
    } catch (reason) {
      openNotification(
        "error",
        <strong>Erro!</strong>,
        "" + reason?.response?.data?.detail
      );
    } finally {
      history.push(`${urls.CLASSIFICACOES}?mode=temas`);
    }
  };

  const handleUpdateTema = async ({
    descricao,
    fundamentacao,
    tiposViolacao,
  }) => {
    const newTiposviolacao = tiposViolacao?.map((tipo) => ({ codigo: tipo }));

    try {
      await temaService.editTheme(codigo, {
        descricao,
        fundamentacao,
        tiposViolacao: newTiposviolacao,
      });
      openNotification(
        "success",
        <strong>Sucesso!</strong>,
        "Tema atualizado com sucesso!"
      );
    } catch (reason) {
      openNotification(
        "error",
        <strong>Erro!</strong>,
        "" + reason?.response?.data?.detail
      );
    } finally {
      history.push(`${urls.CLASSIFICACOES}?mode=temas`);
    }
  };

  useEffect(() => {
    getDenunciationTypes();

    codigo ? getTema(codigo) : setIsCreatTema(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codigo]);

  const renderForm = () => (
    <Spin spinning={loading} size="large">
      {!loading && (
        <Form
          onFieldsChange={() =>
            setHasError(
              !!form.getFieldsError().find(({ errors }) => errors?.length >= 1)
            )
          }
          name="novo_tema"
          onFinish={isCreateTema ? handleAddTema : handleUpdateTema}
          layout="vertical"
          form={form}
        >
          <Col span={24}>
            <Form.Item
              name="descricao"
              label={<span>Descrição:</span>}
              rules={
                !onView && [
                  { required: true, message: "Este campo é obrigatório!" },
                ]
              }
            >
              <Input placeholder="Insira a descrição do tema" allowClear />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="fundamentacao"
              label={<span>Fundamentação:</span>}
              rules={
                !onView && [
                  { required: true, message: "Este campo é obrigatório!" },
                ]
              }
            >
              <TextArea disabled={onView} allowClear autoSize/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="tiposViolacao" label={<span>Tipos:</span>}>
              <Select
                allowClear
                showArrow
                mode="tags"
                style={{
                  width: "100%",
                }}
                placeholder="Selecione os tipos"
                id="tema"
                options={tiposDenuncia?.map(({ codigo, descricao }) => ({
                  label: descricao,
                  value: codigo,
                  key: codigo,
                }))}
              ></Select>
            </Form.Item>

            {!onView && !hasError && (
              <FormActions
                inBotton={inBotton}
                actions={[
                  <Button
                    type="primary"
                    ghost
                    onClick={() =>
                      history.push(`${urls.CLASSIFICACOES}?mode=temas`)
                    }
                  >
                    {isCreateTema ? (
                      <span>cancelar</span>
                    ) : (
                      <span>cancelar edições</span>
                    )}
                  </Button>,
                  <Button type="primary" htmlType="submit">
                    {isCreateTema ? (
                      <span>cadastrar tema</span>
                    ) : (
                      <span>salvar edições</span>
                    )}
                  </Button>,
                ]}
              />
            )}
          </Col>
        </Form>
      )}
    </Spin>
  );

  return (
    <div id="sectionMain" onScroll={(e) => setInBotton(scrollBotton(e))}>
      <Breadcrumb id="breadcrumb">
        <Breadcrumb.Item key="home">
          <Link to="">
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="gerenciamento">gerenciamento</Breadcrumb.Item>
        <Breadcrumb.Item key="classificacoes">
          <Link to={urls.INSTITUICOES_LIST}>classificações</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="temas">
          <Link to={`${urls.CLASSIFICACOES}?mode=temas`}>temas</Link>
        </Breadcrumb.Item>

        {isCreateTema ? (
          <Breadcrumb.Item key="criar">
            <strong>cadastrar tema</strong>
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key="editar">
            <strong>editar tema</strong>
          </Breadcrumb.Item>
        )}
      </Breadcrumb>

      <div className="section-children">
        <div className="container-section">
          <PageHeader
            titulo={
              isCreateTema
                ? "CADASTRAR TEMA"
                : temaEdit?.descricao.toUpperCase()
            }
            subtitulo={
              isCreateTema
                ? "Inserir informações sobre o tema"
                : "Editar informações sobre o tema"
            }
          />
          {renderForm()}
        </div>
      </div>

      <Modal
        title={"DENÚNICAS QUE CONTÉM ENCAMINHAMENTOS PARA ESSA INSTITUIÇÃO"}
        visible={viewList}
        onCancel={() => setViewList(false)}
        width={"90%"}
        centered
        footer={[
          <Button type="primary" onClick={() => setViewList(false)}>
            voltar
          </Button>,
        ]}
      >
        <DenunciaTabela
          showStatus
          filtro={{ codigoInstituicaoDestinataria: codigo }}
          searchClassificacao={false}
        />
      </Modal>
    </div>
  );
};

export default EditTema;
