import { Col, Row } from "antd";

function PageHeader({ titulo, subtitulo, extra }) {
  return (
    <Row wrap={false}>
      <Col flex="auto">
        <h2>{titulo}</h2>
        <span>{subtitulo}</span>
      </Col>

      <Col>{extra}</Col>
    </Row>
  );
}

export default PageHeader;
