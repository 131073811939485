import { SearchOutlined } from "@ant-design/icons";
import { Badge, Button, Col, Empty, Modal, Row, Table, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { openNotification } from "../../../../../components/Notification";
import { SearchColumn } from "../../../../../components/SearchColumn/SearchColumn";
import { UserContext } from "../../../../../contexts/UserContext";
import { denuncianteService } from "../../../../../services/resources/denuncianteService";
import {
  removeEmpty,
  STATUS_COLORS,
  urls,
  USER_ROLES,
} from "../../../../../utils";
import { usuarioService } from "../../../../../services";

const OPCOES_TAMANHO_PAGINA = [5, 10, 20, 100];
const DENUNCIAS_SORT_FILTER = {
  "endereco.bairro.cidade.nome": "cidadeIdList",
  identificadorExterno: "identificadorExterno",
  protocolo: "protocolo",
  "status.descricao": "codigoStatusList",
};

export const DenunciasAssociadas = ({ onEdit, handleNext, showActions }) => {
  const { codigo: codigoDenuncia } = useParams();
  const history = useHistory();

  const { cities, status, user } = useContext(UserContext);

  const [associadas, setAssociadas] = useState([]);
  const [denunciations, setDenunciations] = useState([]);

  const [paginacao, setPaginacao] = useState({
    currentPage: 0,
    totalElements: 0,
    size: OPCOES_TAMANHO_PAGINA[0],
  });

  const [filter, setFilter] = useState({
    ativo: true,
    page: 0,
    size: OPCOES_TAMANHO_PAGINA[0],
    codigoInstituicaoUsuario: user?.authorities?.includes(USER_ROLES.CONSULTAR_TODAS_DENUNCIAS) ? '' : user.codigo_instituicao,
  });

  const [searchModal, setSearchModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAssociadas();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getDenunciations(filter);
    // eslint-disable-next-line
  }, [filter]);

  async function getAssociadas() {
    setLoading(true);
    await denuncianteService.associada
      .listar(codigoDenuncia)
      .then(({ data }) => {
        setAssociadas(data);
      })
      .catch((reason) => {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os dados da denúncia!</strong>,
          reason?.response?.data?.detail
        );
      });
    setLoading(false);
  }

  async function getDenunciations(filter) {
    try {
      setLoading(true);
      const response = await usuarioService.listDenuncias(removeEmpty(filter));
      setDenunciations(response?.data?.content);
      
      setPaginacao({
        currentPage: response.data.currentPage,
        totalElements: response.data.totalElements,
        size: response.data.size,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function associar(paraAssociar) {
    setLoading(true);
    await denuncianteService.associada
      .inserir(codigoDenuncia, paraAssociar)
      .then(() => {
        openNotification(
          "success",
          <strong>Denúncia associada com sucesso!</strong>,
          "A denúncia foi associada com sucesso e agora faz parte desta denúncia."
        );
        getAssociadas();
      })
      .catch((reason) => {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao inserir a vítima a essa denúncia!</strong>,
          reason?.response?.data?.detail
        );
      });
    setLoading(false);
  }

  async function desassociar(paraDesassociar) {
    setLoading(true);
    await denuncianteService.associada
      .remover(codigoDenuncia, paraDesassociar)
      .then(() => {
        openNotification(
          "success",
          <strong>Denúncia associada com sucesso!</strong>,
          "A denúncia foi associada com sucesso e agora faz parte desta denúncia."
        );
        getAssociadas();
      })
      .catch((reason) => {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao inserir a vítima a essa denúncia!</strong>,
          reason?.response?.data?.detail
        );
      });
    setLoading(false);
  }

  function parsedSort(sort, order) {
    return `${sort},${order === "descend" ? "desc" : "asc"}`;
  }

  function onSort(sorter) {
    if (sorter.constructor === Object) {
      if (sorter.column)
        setFilter({
          ...filter,
          sort: parsedSort(sorter.columnKey, sorter.order),
        });
      else setFilter({ ...filter, sort: [] });
    } else {
      setFilter({
        ...filter,
        sort: sorter.map((sort) => parsedSort(sort.columnKey, sort.order)),
      });
    }
  }

  function onFilter(filters) {
    const filtered = Object.fromEntries(
      Object.entries(filters).map(([key, value]) => [
        DENUNCIAS_SORT_FILTER[key],
        value,
      ])
    );

    setFilter({ ...filter, ...filtered });
  }

  function onChange(_, filters, sorter, { action }) {
    if (action === "sort") onSort(sorter);
    if (action === "filter") onFilter(filters);
  }

  const columns = [
    {
      title: <strong>status</strong>,
      dataIndex: ["status", "descricao"],
      key: "status.descricao",
      sorter: {
        multiple: 1,
      },
      filters: status?.map(({ descricao, codigo }) => ({
        text: descricao,
        value: codigo,
      })),
      render: (status) => (
        <Badge
          style={{
            backgroundColor: STATUS_COLORS[status],
            color: "#333333",
            fontWeight: "bold",
          }}
          count={status}
        />
      ),
    },
    {
      title: <strong>identificador</strong>,
      dataIndex: "protocolo",
      key: "protocolo",
      ...SearchColumn("protocolo", "identificador"),
      sorter: {
        multiple: 2,
      },
    },
    {
      title: <strong>protocolo</strong>,
      dataIndex: "identificadorExterno",
      key: "identificadorExterno",
      ...SearchColumn("identificadorExterno", "protocolo"),
      sorter: {
        multiple: 3,
      },
    },
    {
      title: <strong>canal</strong>,
      dataIndex: ["canal", "sigla"],
      key: "canal.descricao",
      sorter: {
        multiple: 4,
      },
    },
    {
      title: <strong>municipio</strong>,
      dataIndex: "municipio",
      key: "endereco.bairro.cidade.nome",

      filters: cities.map(({ nome, id }) => ({
        text: nome,
        value: id,
      })),
      filterSearch: true,
    },

    {
      title: <strong>ações</strong>,
      key: "action",
      fixed: "right",
      width: 32,
      render: ({ identificadorExterno: protocolo, codigo }) => (
        <Button
          onClick={() => {
            associar(codigo);
            setSearchModal(false);
          }}
          title={`associar denúncia ${protocolo} nessa denúncia`}
          type="primary"
        >
          associar denúncia
        </Button>
      ),
    },
  ];
  const columnsAssociada = [
    {
      title: <strong>status</strong>,
      dataIndex: ["status", "descricao"],
      key: "status.descricao",
      sorter: (a, b) => a.status.descricao.localeCompare(b.status.descricao),
      filters: status?.map(({ descricao }) => ({
        text: descricao,
        value: descricao,
      })),
      onFilter: (value, record) => record.status.descricao.indexOf(value) === 0,
      render: (status) => (
        <Badge
          style={{
            backgroundColor: STATUS_COLORS[status],
            color: "#333333",
            fontWeight: "bold",
          }}
          count={status}
        />
      ),
    },
    {
      title: <strong>identificador</strong>,
      dataIndex: "protocolo",
      key: "protocolo",
      ...SearchColumn("protocolo", "identificador"),
    },
    {
      title: <strong>protocolo</strong>,
      dataIndex: "identificadorExterno",
      key: "identificadorExterno",
      ...SearchColumn("identificadorExterno", "protocolo"),
    },
    {
      title: <strong>canal</strong>,
      dataIndex: ["canal", "sigla"],
      key: "canal.descricao",
      filterSearch: true,
      onFilter: (value, record) => record.canalDescricao.indexOf(value) === 0,
    },
    {
      title: <strong>municipio</strong>,
      dataIndex: "municipio",
      key: "endereco.bairro.cidade.nome",
      filters: cities.map(({ nome, codigo }) => ({
        text: nome,
        value: nome,
        key: codigo,
      })),
      onFilter: (value, record) => record.municipio.indexOf(value) === 0,
      filterSearch: true,
    },

    {
      title: <strong>ações</strong>,
      key: "action",
      fixed: "right",
      width: 32,
      render: ({ identificadorExterno: protocolo, codigo }) => (
        <Button
          onClick={() => {
            desassociar(codigo);
          }}
          title={`desassociar denúncia ${protocolo} nessa denúncia`}
          type="danger"
        >
          desassociar denúncia
        </Button>
      ),
    },
  ];

  return (
    <>
      <Row justify="end" align="middle">
        {onEdit &&
          user?.authorities?.includes(USER_ROLES.CONSULTAR_TODAS_DENUNCIAS) && (
            <Col style={{ display: "flex", gap: "1rem" }}>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                onClick={() => {
                  setSearchModal(true);
                }}
              >
                buscar denúncia
              </Button>
            </Col>
          )}
      </Row>

      <Row>
        <Col span={24}>
          <Table
            loading={loading}
            dataSource={associadas}
            columns={associadas?.length > 0 && columnsAssociada}
            size="small"
            rowKey={({ codigo }) => codigo}
            locale={{
              emptyText: (
                <Empty
                  description={
                    <h2>Sem denúncias associadas para essa denúncia</h2>
                  }
                />
              ),
            }}
          />
        </Col>
      </Row>

      {showActions && (
        <Row justify="space-between" align="bottom">
          <Tooltip
            placement="top"
            title={"Todas as alterações nessa página já estão salvas!"}
          >
            <Button onClick={() => handleNext("anexos")}>anterior</Button>
          </Tooltip>

          {onEdit && (
            <Tooltip
              placement="top"
              title={"Todas as alterações nessa página já estão salvas!"}
            >
              <Button
                type="primary"
                onClick={() => history.push(urls.DENUNCIAS_LIST)}
              >
                finalizar edições
              </Button>
            </Tooltip>
          )}
        </Row>
      )}

      <Modal
        title={"Escolha uma denúncia para ser associada"}
        width="90vw"
        bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
        centered
        visible={searchModal}
        onCancel={() => setSearchModal(false)}
        footer={
          <Button
            key="back"
            type="danger"
            onClick={() => setSearchModal(false)}
          >
            cancelar
          </Button>
        }
      >
        <Table
          loading={loading}
          dataSource={denunciations}
          columns={columns}
          size="small"
          rowKey={({ codigo }) => codigo}
          onChange={onChange}
          totalSize={paginacao.totalElements}
          pagination={{
            size: "small",
            showSizeChanger: true,
            pageSizeOptions: OPCOES_TAMANHO_PAGINA,
            defaultPageSize: paginacao.size,
            total: paginacao.totalElements,
            showTotal: (total) => <strong>{total} de denúncia(s)</strong>,
            onChange: (page, size) => {
              setFilter({
                ...filter,
                page: filter.size !== size ? 0 : page - 1,
                size,
              });
            },
          }}
          current={paginacao.currentPage + 1}
        />
      </Modal>
    </>
  );
};
