import api from "../api";

export const sexualidadeService = {
  async load() {
    return await api.get("/sexualidade");
  },
  async getSexualidadesPublico() {
    return await api.get("/publico/sexualidades");
  },
  async get(codigo) {
    return await api.get(`/sexualidade/${codigo}`);
  },
  async create(payload) {
    return await api.post(`/sexualidade`, payload);
  },
  async edit(codigo, payload) {
    return await api.put(`/sexualidade/${codigo}`, payload);
  },
  async delete(codigo) {
    return await api.delete(`/sexualidade/${codigo}`);
  },
};
