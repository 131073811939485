import { useContext } from "react";
import { Link } from "react-router-dom";

import { UserContext } from "../../../../contexts/UserContext";
import moment from "moment";

import { Collapse, Button, Breadcrumb, Menu } from "antd";

import { CaretRightOutlined, HomeOutlined } from "@ant-design/icons";

import { DenunciaTabela } from "../../components/DenunciaTabela";
import { STATUS, urls, USER_ROLES } from "../../../../utils";

const { Panel } = Collapse;

const menuBreadcrump = (
  <Menu>
    <Menu.Item>
      <Link to={urls.DENUNCIAS_GERAL_LIST}>geral</Link>
    </Menu.Item>
  </Menu>
);

export const DenunciasStatus = () => {
  const { user } = useContext(UserContext);

  return (
    <div id="sectionMain">
      <Breadcrumb id="breadcrumb">
        <Breadcrumb.Item key="home">
          <Link to="">
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="registros">registros</Breadcrumb.Item>
        <Breadcrumb.Item key="denuncias">denúncias</Breadcrumb.Item>
        <Breadcrumb.Item key="denuncias" overlay={menuBreadcrump}>
          <strong>status</strong>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div>
        <div className="container-section">
          <div id="header-container">
            <div>
              <h2>LISTA DE DENÚNCIAS</h2>
              <span>
                atualizado às {moment().format("HH:mm")} do dia{" "}
                {moment().format("DD/MM/YYYY")}
              </span>
            </div>

            <div id="header-actions">
              {user?.authorities?.includes(USER_ROLES.INCLUIR_DENUNCIA) && (
                <Link to={urls.DENUNCIA_CRIAR} className="button-link">
                  <Button type="primary" style={{ width: "160px" }}>
                    nova denúncia
                  </Button>
                </Link>
              )}
            </div>
          </div>

          <Collapse
            className="collapse-personal"
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            expandIconPosition="right"
            ghost
          >
            <Panel
              header={<strong>denúncias a analisar</strong>}
              key="1"
              className="linear-blue"
            >
              <DenunciaTabela
                filtro={{ statusDescricao: STATUS.ANALISAR }}
                showTableEmpty={false}
              />
            </Panel>

            <Panel
              header={<strong>denúncias em aberto</strong>}
              key="2"
              className="linear-red"
            >
              <DenunciaTabela
                filtro={{ statusDescricao: STATUS.ABERTO }}
                showTableEmpty={false}
              />
            </Panel>

            <Panel
              header={<strong>denúncias em andamento</strong>}
              key="3"
              className="linear-yellow"
            >
              <DenunciaTabela
                filtro={{ statusDescricao: STATUS.ANDAMENTO }}
                showTableEmpty={false}
              />
            </Panel>

            <Panel
              header={<strong>denúncias a validar</strong>}
              key="4"
              className="linear-yellow-dark"
            >
              <DenunciaTabela
                filtro={{ statusDescricao: STATUS.VALIDAR }}
                showTableEmpty={false}
              />
            </Panel>

            <Panel
              header={<strong>denúncias finalizadas</strong>}
              key="5"
              className="linear-green"
            >
              <DenunciaTabela
                filtro={{ statusDescricao: STATUS.FINALIZADO }}
                showTableEmpty={false}
              />
            </Panel>

            {user?.authorities?.includes(USER_ROLES.ARQUIVAR_DENUNCIA) && (
              <Panel
                header={<strong>denúncias arquivadas</strong>}
                key="6"
                className="linear-grey"
              >
                <DenunciaTabela
                  filtro={{ statusDescricao: STATUS.ARQUIVADA }}
                  showTableEmpty={false}
                />
              </Panel>
            )}
          </Collapse>
        </div>
      </div>
    </div>
  );
};
