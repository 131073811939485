import React, { useState, useEffect } from "react";

import { Table, Space, Button, Popconfirm } from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import { SearchColumn } from "../../../../components/SearchColumn/SearchColumn";

import { openNotification } from "../../../../components/Notification";
import { subTipoViolacaoService } from "../../../../services";
import { useHistory } from "react-router-dom";
import { urls } from "../../../../utils";

const ListSubTipos = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [subTiposDenuncia, setSubTiposDenuncia] = useState([]);

  const getDenunciationSubTypes = async (codigoTipo) => {
    setLoading(true);
    await subTipoViolacaoService
      .loadViolationSubTypes({ codigoTipo })
      .then((response) => setSubTiposDenuncia(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Erro!</strong>,
          "Ocorreu um erro ao carregar os SubTipos de denúncia."
        )
      );
    setLoading(false);
  };

  const handleDeleteSubtipo = async (codigo) => {
    try {
      await subTipoViolacaoService.deleteViolationSubType(codigo);
      openNotification("success", <strong>Subtipo excluído com sucesso!</strong>);
      getDenunciationSubTypes();
    } catch (reason) {
      openNotification(
        "error",
        <strong>Não foi possível excluir o subtipo!</strong>,
        "Erro: " + reason.response.data.userMessage
      );
    } finally {
    }
  };

  useEffect(() => {
    getDenunciationSubTypes();
  }, []);

  const renderTable = () => {
    const columns = [
      {
        title: <strong>descrição</strong>,
        dataIndex: "descricao",
        key: "descricao",
        ...SearchColumn("descricao"),
      },
      {
        title: <strong>ações</strong>,
        key: "action",
        width: 64,
        render: ({ codigo, descricao }) => (
          <Space size="small">
            <Button
              type="primary"
              className="action-button"
              title={`Editar agravante ${descricao}`}
              onClick={() =>
                history.push(
                  urls.CLASSIFICACOES_SUBTIPO_DETAILS.replace(":codigo", codigo)
                )
              }
            >
              <EditOutlined style={{ fontSize: "1.2rem" }} />
            </Button>
            <div>
              <Popconfirm
                title={
                  <span>
                    excluir subtipo: <strong>{descricao}</strong>?"
                  </span>
                }
                placement="bottomRight"
                okText="confirmar exclusão"
                cancelText="cancelar"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={() => handleDeleteSubtipo(codigo)}
              >
                <Button type="danger" className="action-button">
                  <DeleteOutlined style={{ fontSize: "1.2rem" }} />
                </Button>
              </Popconfirm>
            </div>
          </Space>
        ),
      },
    ];
    function showTotal(total) {
      return (
        <>
          Total de <strong>{total}</strong> subtipos
        </>
      );
    }
    return (
      <Table
        loading={loading}
        dataSource={subTiposDenuncia}
        columns={columns}
        size="small"
        rowKey={({ codigo }) => codigo}
        pagination={{
          size: "small",
          showSizeChanger: true,
          showTotal: showTotal,
        }}
      />
    );
  };

  return (
    <>
      <div className="container-section">
        <div id="header-container">
          <h2>LISTA DE SUBTIPOS</h2>
          <div id="header-actions">
            <Button
              type="primary"
              onClick={() =>
                history.push(
                  urls.CLASSIFICACOES_SUBTIPO_CRIAR.replace("/:codigo", "")
                )
              }
            >
              novo subtipo
            </Button>
          </div>
        </div>
        {renderTable()}
      </div>
      {/* {openModalAgravante && (
        <ModalAgravante
          onClose={handleCloseModalAgravante}
          codigo={codigo}
          mode={mode}
          getAgravantes={getAgravantes}
        />
      )} */}
    </>
  );
};

export default ListSubTipos;
