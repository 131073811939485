import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  > h1 {
    color: rgb(47, 46, 65);
    font-weight: 900;
  }

  > img {
    max-width: 600px;
    width: 100%;
  }
`;
