import React, { useEffect, useState } from "react";

import {
  Col,
  Row,
  Space,
  Badge,
  Spin,
  Divider,
  Button,
  Modal,
  Timeline,
} from "antd";
import {
  CalendarOutlined,
  CopyOutlined,
  EnvironmentOutlined,
  EyeOutlined,
  HomeOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

import moment from "moment";

import { PageResult } from "../style";

import { openNotification } from "../../../components/Notification";
import { useHistory, useParams } from "react-router-dom";
import Header from "../../../components/Header/Header";
import { publicoService } from "../../../services/resources/publicoService";
import { STATUS_COLORS } from "../../../utils";
import DialogModal from "../../../components/DialogModal";

export const Resultado = () => {
  let { canal, protocolo } = useParams();
  const history = useHistory();

  const [denuncia, setDenuncia] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openDialogModal, setOpenDialogModal] = useState(false);
  const [observacaoPublica, setObservacaoPublica] = useState("");
  const [observacoes, setObservacoes] = useState([]);

  useEffect(() => {
    if (canal && protocolo) handleSubmit({ canal, protocolo });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSubmit({ canal, protocolo }) {
    try {
      setLoading(true);

      const response = await publicoService.denuncias(canal, protocolo);

      setDenuncia(response?.data);
    } catch (error) {
      openNotification(
        "error",
        <strong>Não conseguimos encontrar sua denúncia!</strong>,
        "Por favor verifique o canal e o protocolo informado."
      );
      history.push("/consulta");
    } finally {
      setLoading(false);
    }
  }

  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );

  const handleOpenModal = (observacoes) => {
    setOpenDialogModal(true);
    setObservacaoPublica(observacaoPublica);
    setObservacoes(observacoes);
  };

  return (
    <PageResult>
      <Header showVersion={false} />

      <div id="sectionMain">
        <div>
          <div className="container-section">
            <div id="header-container">
              <Col>
                <h2>
                  {" "}
                  A DENÚNCIA{" "}
                  <strong>
                    {" "}
                    <mark style={{ backgroundColor: "#e4e4e4" }}>
                      {denuncia?.identificadorExterno}
                    </mark>
                  </strong>{" "}
                  ENCONTRA-SE:
                  <Badge
                    style={{
                      backgroundColor:
                        STATUS_COLORS[denuncia?.denunciaStatusDescricao],
                      color: "#333333",
                      fontWeight: "bold",
                      marginLeft: 5,
                    }}
                    count={denuncia?.denunciaStatusDescricao}
                  />
                </h2>

                <Divider dashed />

                <p>
                  Encaminhamentos da denúncia{" "}
                  <strong>
                    {" "}
                    <mark style={{ backgroundColor: "#e4e4e4" }}>
                      {denuncia?.identificadorExterno}
                    </mark>
                  </strong>
                  :
                </p>
              </Col>
            </div>

            <Col style={{ marginTop: -20 }} align={loading && "center"}>
              {loading ? (
                <Spin size="large" />
              ) : (
                <Col>
                  {denuncia?.encaminhamentos.map((encaminhamento) => (
                    <Col
                      style={{
                        borderRadius: 10,
                        boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        padding: 10,
                        marginBottom: 25,
                      }}
                    >
                      <Col>
                        <Row
                          key={encaminhamento?.telefone}
                          justify="space-between"
                        >
                          <IconText
                            icon={CalendarOutlined}
                            text={
                              <strong>
                                {moment(encaminhamento?.dataInicio).format(
                                  "DD/MM/yyyy"
                                )}
                              </strong>
                            }
                            key="list-vertical-nome"
                          />
                          <Badge
                            style={{
                              backgroundColor:
                                STATUS_COLORS[encaminhamento.statusDescricao],
                              color: "#333333",
                              fontWeight: "bold",
                              marginLeft: 5,
                            }}
                            count={encaminhamento.statusDescricao}
                          />
                        </Row>

                        <Row
                          key={encaminhamento?.telefone}
                          style={{ marginTop: 20 }}
                        >
                          <IconText
                            icon={HomeOutlined}
                            text={encaminhamento?.instituicaoDestinataria?.nome}
                            key="list-vertical-nome"
                          />
                        </Row>
                        <Row
                          key={encaminhamento?.telefone}
                          style={{ marginTop: 10 }}
                        >
                          <IconText
                            icon={EnvironmentOutlined}
                            text={[
                              encaminhamento?.instituicaoDestinataria?.endereco
                                ?.logradouro,
                              encaminhamento?.instituicaoDestinataria?.endereco
                                ?.numero,
                              encaminhamento?.instituicaoDestinataria?.endereco
                                ?.bairro?.nome,
                              encaminhamento?.instituicaoDestinataria
                                ?.municipio,
                            ]
                              .filter(Boolean)
                              ?.join(", ")}
                            key="list-vertical-nome"
                          />
                        </Row>
                        <Row justify="space-between" style={{ marginTop: 10 }}>
                          <Row justify="space-between">
                            <IconText
                              icon={PhoneOutlined}
                              text={
                                encaminhamento?.instituicaoDestinataria
                                  ?.telefone1 || "-"
                              }
                              key="list-vertical-nome"
                            />

                            <span style={{ marginLeft: 15 }}>
                              <IconText
                                icon={PhoneOutlined}
                                text={
                                  encaminhamento?.instituicaoDestinataria
                                    ?.telefone2 || "-"
                                }
                                key="list-vertical-nome"
                              />
                            </span>

                            <span style={{ marginLeft: 15 }}>
                              <IconText
                                icon={MailOutlined}
                                text={
                                  encaminhamento?.instituicaoDestinataria?.email
                                }
                                key="list-vertical-nome"
                              />
                            </span>
                          </Row>
                        </Row>

                        <Row justify="end" style={{ marginTop: 15 }}>
                          <Button
                            style={{ background: "#dcdcdc", borderRadius: 5 }}
                            onClick={() =>
                              handleOpenModal(encaminhamento?.observacoes)
                            }
                          >
                            <IconText
                              icon={EyeOutlined}
                              text="Ver observações públicas"
                              key="list-vertical-nome"
                            />
                          </Button>
                        </Row>
                      </Col>
                    </Col>
                  ))}
                </Col>
              )}
            </Col>
          </div>
        </div>
      </div>

      {openDialogModal && (
        <Modal
          width={"70%"}
          visible={openDialogModal}
          title={
            <strong style={{ fontSize: 20 }}> Observações públicas:</strong>
          }
          footer={false}
          onCancel={() => setOpenDialogModal(false)}
        >
          <strong style={{ fontSize: 18, textAlign: "left" }}>
            {observacoes.length > 0 ? (
              <Timeline>
                {observacoes.map((observacao, i) => (
                  <Timeline.Item key={i}>
                    <strong>
                      {moment(observacao.dataCadastro).format("DD/MM/yyyy")}
                    </strong>
                    <Col
                      style={{
                        border: "1px solid #d1d1d6",
                        borderRadius: 10,
                        padding: 8,
                      }}
                    >
                      <Row></Row>
                      <p style={{ color: "#88888e", fontSize: 16 }}>
                        {observacao.descricao}
                      </p>
                    </Col>
                  </Timeline.Item>
                ))}
              </Timeline>
            ) : (
              "- Sem observações públicas no momento."
            )}
          </strong>
        </Modal>
      )}
    </PageResult>
  );
};
