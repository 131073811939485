import api from "../api";
import qs from "qs";

export const encaminhamentoService = {
  async getAllEncaminhamentos(params) {
    return api.get(`/encaminhamentos`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
  },

  async getCountEncaminhamentos(params) {
    return api.get(`/encaminhamentos/total`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
  },

  async createForwarding(payload) {
    return await api.post(`/encaminhamentos/`, payload);
  },

  async getForwarding(codigo) {
    return await api.get(`/encaminhamentos/${codigo}`);
  },

  async imprimir(codigo) {
    return await api.get(`/encaminhamentos/${codigo}/imprimir`, { responseType: 'blob' });
  },

  async deleteForwarding(codigo) {
    return await api.delete(`/encaminhamentos/${codigo}`);
  },

  async reopenForwarding(codigo) {
    return await api.put(`/encaminhamentos/${codigo}/reabrir`);
  },

  async receiveForwarding(codigo) {
    // receber - Intituicao
    return await api.put(`/encaminhamentos/${codigo}/receber`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  async sendAction(codigoEncaminhamento, action) {
    return await api.put(
      `/encaminhamentos/${codigoEncaminhamento}/acoes`,
      action
    );
  },

  async updateObservacaoPublica(codigoEncaminhamento, observacaoPublica) {
    return await api.put(
      `/encaminhamentos/${codigoEncaminhamento}/observacaoPublica`,
      observacaoPublica,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      });
  },

  async replyForwarding(codigo, body) {
    // responder/enviar - Intituicao
    return await api.put(`/encaminhamentos/${codigo}/enviar`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  async refusedForwarding(codigo, body) {
    // recusar - Instituicao
    return await api.put(`/encaminhamentos/${codigo}/recusar`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  async returningForwarding(codigo, body) {
    // devolver - SPS
    return await api.put(`/encaminhamentos/${codigo}/justificativas`, body);
  },

  async finalizeForwarding(codigo) {
    // finalizar - SPS
    return await api.put(`/encaminhamentos/${codigo}/finalizar`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  async getAnexo(encaminhamento, codigo, mediaType) {
    return await api.get(
      `/encaminhamentos/${encaminhamento}/anexos/${codigo}`,
      {
        headers: {
          accept: mediaType,
        },
        responseType: "blob",
      }
    );
  },

  async deleteAnexo(encaminhamento, codigo) {
    return await api.delete(
      `/encaminhamentos/${encaminhamento}/anexos/${codigo}`
    );
  },
};
