import api from "../api";

export const generoService = {
  async loadGeneros() {
    return await api.get("/generos");
  },
  async getAllGenerosPublico() {
    return await api.get("/publico/generos");
  },
  async get(codigo) {
    return await api.get(`/generos/${codigo}`);
  },
  async create(payload) {
    return await api.post(`/generos`, payload);
  },
  async edit(codigo, payload) {
    return await api.put(`/generos/${codigo}`, payload);
  },
  async delete(codigo) {
    return await api.delete(`/generos/${codigo}`);
  },
};
