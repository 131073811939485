import api from "../api";

export const faixaEtariaService = {
    async loadFaixaEtarias() {
      return await api.get("/faixa-etarias");
    },
    async getFaixaEtariasPublico() {
      return await api.get("/publico/faixas-etarias");
    },
  }
