import api from "../api";

export const grupoService = {
    async list() {
      return await api.get(`/grupos`);
    },

    async get(codigo) {
      return await api.get(`/grupos/${codigo}`);
    },

    async create(group) {
      return await api.post(`/grupos`, group);
    },

    async atualizar(codigo, grupo) {
      return await api.put(`/grupos/${codigo}`, grupo);
    },

    async delete(codigo) {
      return await api.delete(`/grupos/${codigo}`);
    },

    async permissions(codigo) {
      return await api.get(`/grupos/${codigo}/permissoes`);
    },

    async addPermission(groupCod, permissionCod) {
      return await api.put(`/grupos/${groupCod}/permissoes/${permissionCod}`);
    },

    async removePermission(groupCod, permissionCod) {
      return await api.delete(
        `/grupos/${groupCod}/permissoes/${permissionCod}`
      );
    },
  }
