import React, { useCallback, useEffect, useState } from "react";
import { Form, Input, Row, Col, Modal } from "antd";

import { Container } from "./styles";
import { openNotification } from "../../../../../../components/Notification";
import { agravanteService } from "../../../../../../services/resources/agravanteService";
const { TextArea } = Input;

function ModalAgravante({
  onClose,
  mode,
  codigo,
  getAgravantes,
}) {

  const [form] = Form.useForm();

  const [agravante, setAgravante] = useState([]);  
  const [descricao, setDescricao] = useState(agravante?.descricao || "");
  const [fundamentacao, setFundamentacao] = useState(agravante?.fundamentacao || "");

  const [disabled, setDisabled] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    async function getAgravante() {
      await agravanteService
        .get(codigo)
        .then(({ data }) => {
          setAgravante(data);
          setDescricao(data?.descricao);
          setFundamentacao(data?.fundamentacao);
          form.setFieldsValue({
            descricao: data?.descricao,
            fundamentacao: data?.fundamentacao,
          });
        })
        .catch((reason) => {
          openNotification("error", <strong>Erro!</strong>,''+reason);
        });
    }
    if (mode !== "onCreate") {
      getAgravante();
    }
  }, [codigo, mode, form]);

  async function handleSubmit() {
    setConfirmLoading(true);
    setDisabled(true);
    if (mode === "onCreate") {
      try {
        await agravanteService.create({descricao: descricao, fundamentacao: fundamentacao});
        handleCloseModal();
        openNotification("success", <strong>Agravante criado!</strong>);
        getAgravantes();
      } catch (reason) {
        handleCloseModal();
        openNotification("error", <strong>Não foi possível criar o agravante!</strong>,'Erro: '+reason);
      } finally {
        setConfirmLoading(false);
        setDisabled(false);
      }
    } else if (mode === "onEdit") {
      try {
        await agravanteService.edit(codigo, {descricao: descricao, fundamentacao: fundamentacao});
        handleCloseModal();
        openNotification("success", <strong>Agravante editado!</strong>);
        getAgravantes();
      } catch (reason) {
        handleCloseModal();
        openNotification("error", <strong>Não foi possível editar o agravante!</strong>,'Erro: '+reason);
      } finally {
        setConfirmLoading(false);
        setDisabled(false);
      }
    }
  }

  return (
    <Modal
      title={
        (mode === "onCreate" && "NOVO AGRAVANTE") ||
        (mode === "onEdit" && "EDITAR AGRAVANTE") ||
        (mode === "onView" && "DETALHES DO AGRAVANTE")
      }
      width="40vw"
      onOk={mode !== "onView" ? handleSubmit : onClose} 
      confirmLoading={confirmLoading}
      onCancel={onClose}
      visible
      centered
    >
      <Container>
        <Form form={form} onFinish={handleSubmit} name="agravante" layout="vertical">                    
          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="descricao"
                label={<span>Descrição:</span>}
              >
                <Input
                  placeholder="Digite a descrição do agravante"
                  value={descricao}
                  defaultValue={descricao}
                  onChange={(e) => setDescricao(e.target.value)}
                  disabled={mode === "onView" || disabled}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="fundamentacao"
                label={<span>Fundamentação:</span>}
              >
                <TextArea
                  autoSize
                  placeholder="Digite a fundamentação do agravante"
                  value={fundamentacao}
                  defaultValue={fundamentacao}
                  onChange={(e) => setFundamentacao(e.target.value)}
                  disabled={mode === "onView" || disabled}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Container>
    </Modal>
  );
}

export default ModalAgravante;
