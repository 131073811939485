import { Button, Row } from "antd";
import React from "react";

import { urls } from "../../../../utils";

export const Painel = () => {
  return (
    <div className="container-section-metabase">
      <iframe src={urls.ESTATISTICAS} style={{ border: "none" }} height="1800" width="100%" title="description"></iframe>
    </div>
  );
};
