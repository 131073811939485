import api from "../api";
 
 export const agravanteService = {
    async list() {
      return await api.get(`/agravantes`);
    },

    async get(codigo) {
      return await api.get(`/agravantes/${codigo}`);
    },

    async create(payload) {
      return await api.post(`/agravantes`, payload);
    },

    async edit(codigo, payload) {
      return await api.put(`/agravantes/${codigo}`, payload);
    },

    async delete(codigo) {
      return await api.delete(`/agravantes/${codigo}`);
    },
  }