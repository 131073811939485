import React, { useContext, useState } from "react";

import {
  CloseOutlined, MenuOutlined
} from "@ant-design/icons";

import { Avatar, Button, Col, Drawer, Popover, Row } from "antd";
import { UserContext } from "../../contexts/UserContext";

import { useWidth } from "../../hooks";
import logoSPS from "../../images/logos/oisol@1X2.svg";
import { Menu } from "../Menu";
import Versao from "../Versao";

export function ResponsiveMenu() {
  const { user } = useContext(UserContext);

  const { width } = useWidth();

  const [visible, setVisible] = useState(false);
  const [startTouch, setStartTouch] = useState(0);

  const handleClose = () => {
    setVisible(false);
  };

  const Title = () => (
    <Row justify="center">
      <img src={logoSPS} alt="SPS" style={{ width: "200px" }} />

      <Popover placement="top" content={user?.nome_instituicao} trigger="hover">
      <Avatar
        size={48}
        style={{
          color: "#f56a00",
          backgroundColor: "#fde3cf",
          fontSize: "1.5rem",
          position: "absolute",
          right: "50%",
          top: 50,
          transform: "translate(50%, 50%)",
          zIndex: 1000,
        }}
      >
        <strong> {user?.nome_completo?.charAt(0)} </strong>
      </Avatar>
      </Popover>
    </Row>
  );

  return (
    <>
      <Button
        type="text"
        style={{
          display: "flex",
          alignItems: "center",
          position: "absolute",
          top: "calc(var(--header-height)/2)",
          transform: "translate(0, -50%)",
          zIndex: 10,
        }}
        onClick={() => setVisible(true)}
      >
        <MenuOutlined
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            fontSize: "1.2rem",
          }}
        />
      </Button>
      <Drawer
        title={<Title />}
        extra={
          <CloseOutlined
            onClick={handleClose}
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              fontSize: "1.2rem",
            }}
          />
        }
        onClose={handleClose}
        onTouchStart={({ touches }) => setStartTouch(touches[0]?.clientX)}
        onTouchMove={({ touches }) =>
          startTouch > touches[0]?.clientX + 75 && handleClose()
        }
        visible={visible}
        placement="left"
        closable={false}
        width={width <= 432 ? "calc(100vw - 32px)" : "400px"}
        bodyStyle={{
          padding: "2rem 0",
          background: "#dcdcdc",
          position: "relative",
        }}
        footer={<Versao />}
      >
        <Row justify="center">
          <Col>
            <strong
              style={{
                color: "#f56a00",
              }}
            >
              {user?.nome_completo?.split(" ")[0]}
            </strong>
          </Col>
        </Row>
        <Menu mode="inline" />
      </Drawer>
    </>
  );
}
