import React from "react";
import { Link } from "react-router-dom";

import { Button, Breadcrumb } from "antd";

import { HomeOutlined } from "@ant-design/icons";
import { urls } from "../../../utils";
import PageHeader from "../../../components/PageHeader";

import InstituicaoPaginada from "../Components/InsituicaoPaginada";

const ListInstitution = () => {
  return (
    <div id="sectionMain">
      <Breadcrumb id="breadcrumb">
        <Breadcrumb.Item key="home">
          <Link to="">
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="gerenciamento">gerenciamento</Breadcrumb.Item>
        <Breadcrumb.Item key="doencas">
          <Link to={urls.INSTITUICOES_LIST}>
            <strong>instituições</strong>
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className="container-section">
        <PageHeader
          titulo={"LISTA DE INSTITUIÇÕES"}
          extra={[
            <Link to={urls.INSTITUICOES_CRIAR} key="nova">
              <Button type="primary">nova instituição</Button>
            </Link>,
          ]}
        />
        <InstituicaoPaginada />
      </div>
    </div>
  );
};

export default ListInstitution;
