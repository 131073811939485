import { Modal } from 'antd';
import React, { useCallback } from 'react';
import { useWidth } from '../../hooks/useWidth';
import { Container } from './styles';

function DialogModal({ onClose, title, Subtitle, dialog, handle_Function }) {
  const { width } = useWidth();

  const handleCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleFunction = useCallback(() => {
    handle_Function();
  }, [handle_Function]);

  return (
    <Modal
      title={title}
      width={width > 1280 ? "40vw" : "100vw"}
      centered
      visible
      onClose={onClose}
      onOk={handleFunction}
      okText="Confirmar"
      onCancel={handleCloseModal}
    >
      <Container>
        <span>
          <strong>{Subtitle}</strong>
        </span>
        <span>
          <small>{dialog}</small>
        </span>
      </Container>
    </Modal>
  );
}

export default DialogModal;