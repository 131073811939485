import api from "../api";
import qs from "qs";

export const usuarioService = {
  async list(params) {
    return api.get(`/usuarios`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
  },

  async get(codigo) {
    return await api.get(`/usuarios/${codigo}`);
  },

  async create(user) {
    return await api.post(`/usuarios`, user);
  },

  async newPass(codigo, senha) {
    return await api.put(`/usuarios/${codigo}/senha`, JSON.stringify(senha), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  async update(codigo, user) {
    return await api.put(`/usuarios/${codigo}`, user);
  },

  async changeActive(codigo, ativo) {
    return await api.put(`/usuarios/${codigo}/ativo `, JSON.stringify(ativo), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  async addGroup(userCod, groupCod) {
    return await api.put(`/usuarios/${userCod}/grupos/${groupCod}`);
  },

  async removeGroup(userCod, groupCod) {
    return await api.delete(`/usuarios/${userCod}/grupos/${groupCod}`);
  },

  async listDenuncias(params) {
    return await api.get(`/usuarios/denuncias`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
  },

  async getTermo(codigo) {
    return await api.get(`/usuarios/${codigo}/termo`, {
      responseType: "blob",
    });
  },
};
