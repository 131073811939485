import api from "../api";

export const observacaoPublicaService = {
  async addObservacao(codigoEncaminhamento, observacaoPublica) {
    return await api.put(
      `/encaminhamentos/${codigoEncaminhamento}/observacoes`,
      observacaoPublica
    );
  },
  async deleteObservacao(codigoEncaminhamento, codigoObservacaoPublic) {
    return await api.delete(`/encaminhamentos/${codigoEncaminhamento}/observacoes/${codigoObservacaoPublic}`)
  }
};
