import {
  CheckSquareOutlined,
  ContainerOutlined,
  HomeOutlined,
  SwapOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignTopOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Badge, Breadcrumb, Calendar, Card, Col, Row, Tag } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { openNotification } from "../../components/Notification";
import { UserContext } from "../../contexts/UserContext";
import { usuarioService } from "../../services";
import { encaminhamentoService } from "../../services/resources/encaminhamentoService";
import { urls } from "../../utils";

export const Inicio = () => {
  const history = useHistory();

  const { user, status } = useContext(UserContext);
  const [encaminhamentos, setEncaminhamentos] = useState({
    recebidos: 0,
    enviados: 0,
  });

  const [encaminhamentosNow, setEncaminhamentosNow] = useState(null);

  const filter = {
    codigoInstituicaoUsuario: user?.codigo_instituicao
  }

  useEffect(() => {
    if (status && user) {
      getForwardins();
      getForwardinsByDate(moment());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, status]);

  const getForwardins = async () => {
    try {
      const [
        { data: em_aberto },
        { data: em_andamento },
        { data: a_validar },
        { data: finalizados },
        { data: recebidos },
        { data: enviados },
        {
          data: { totalElements: criadas },
        },
      ] = await Promise.all([
        encaminhamentoService.getCountEncaminhamentos({
          codigoInstituicaoDestinataria: user.codigo_instituicao,
          codigoStatusList: status
            .filter(({ descricao }) => descricao === "EM ABERTO")
            .map(({ codigo }) => codigo),
        }),
        encaminhamentoService.getCountEncaminhamentos({
          codigoInstituicaoDestinataria: user.codigo_instituicao,
          codigoStatusList: status
            .filter(({ descricao }) => descricao === "EM ANDAMENTO")
            .map(({ codigo }) => codigo),
        }),
        encaminhamentoService.getCountEncaminhamentos({
          codigoInstituicaoDestinataria: user.codigo_instituicao,
          codigoStatusList: status
            .filter(({ descricao }) => descricao === "A VALIDAR")
            .map(({ codigo }) => codigo),
        }),
        encaminhamentoService.getCountEncaminhamentos({
          codigoInstituicaoDestinataria: user.codigo_instituicao,
          codigoStatusList: status
            .filter(({ descricao }) => descricao === "FINALIZADA")
            .map(({ codigo }) => codigo),
        }),
        encaminhamentoService.getCountEncaminhamentos({
          codigoInstituicaoDestinataria: user.codigo_instituicao,
        }),
        encaminhamentoService.getCountEncaminhamentos({
          codigoInstituicaoRemetente: user.codigo_instituicao,
        }),
        usuarioService.listDenuncias(filter),
      ]);

      setEncaminhamentos({
        ...encaminhamentos,
        recebidos,
        enviados,
        criadas,
        a_validar,
        em_aberto,
        em_andamento,
        finalizados,
      });
    } catch (error) {
      openNotification(
        "error",
        <strong>Erro!</strong>,
        error?.response?.detail
      );
      return 0;
    }
  };

  const getForwardinsByDate = async (date) => {
    try {
      const [{ data: em_aberto }, { data: em_andamento }, { data: a_validar }] =
        await Promise.all([
          encaminhamentoService.getCountEncaminhamentos({
            dataPrevistaConclusao: new Date(date),
            codigoInstituicaoDestinataria: user.codigo_instituicao,
            codigoStatusList: status
              .filter(({ descricao }) => descricao === "EM ABERTO")
              .map(({ codigo }) => codigo),
          }),
          encaminhamentoService.getCountEncaminhamentos({
            dataPrevistaConclusao: new Date(date),
            codigoInstituicaoDestinataria: user.codigo_instituicao,
            codigoStatusList: status
              .filter(({ descricao }) => descricao === "EM ANDAMENTO")
              .map(({ codigo }) => codigo),
          }),
          encaminhamentoService.getCountEncaminhamentos({
            dataPrevistaConclusao: new Date(date),
            codigoInstituicaoDestinataria: user.codigo_instituicao,
            codigoStatusList: status
              .filter(({ descricao }) => descricao === "A VALIDAR")
              .map(({ codigo }) => codigo),
          }),
        ]);

      setEncaminhamentosNow(
        Object.entries({
          em_aberto,
          em_andamento,
          a_validar,
        })
      );
    } catch (error) {
      openNotification(
        "error",
        <strong>Erro!</strong>,
        error?.response?.detail
      );
      return 0;
    }
  };

  const cardsView = [
    {
      title: "Encaminhamentos",
      subtitle: "recebidos",
      primaryColor: "#34495e",
      secondaryColor: "#2c3e50",
      path: "/encaminhamentos/recebidos",
      icon: (
        <VerticalAlignBottomOutlined
          style={{
            fontSize: "3rem",
            color: "white",
          }}
        />
      ),
    },
    {
      title: "Encaminhamentos",
      subtitle: "enviados",
      primaryColor: "#34495e",
      secondaryColor: "#2c3e50",
      path: "/encaminhamentos/enviados",
      icon: (
        <VerticalAlignTopOutlined
          style={{
            fontSize: "3rem",
            color: "white",
          }}
        />
      ),
    },
    {
      title: "Denuncias",
      subtitle: "criadas",
      primaryColor: "#34495e",
      secondaryColor: "#2c3e50",
      path: "/denuncias",
      icon: (
        <ContainerOutlined
          style={{
            fontSize: "3rem",
            color: "white",
          }}
        />
      ),
    },
  ];

  const encaminhamentosStatus = [
    {
      title: "Encaminhamentos recebidos",
      subtitle: "em_aberto",
      enums: ["EM ABERTO"],
      primaryColor: "#ff7575",
      secondaryColor: "#fa4545",
      icon: (
        <WarningOutlined
          style={{
            fontSize: "3rem",
            color: "white",
          }}
        />
      ),
    },
    {
      title: "Encaminhamentos recebidos",
      subtitle: "em_andamento",
      enums: ["EM ANDAMENTO"],
      primaryColor: "#ffe074",
      secondaryColor: "#d6bd62",
      icon: (
        <SwapOutlined
          style={{
            fontSize: "3rem",
            color: "white",
          }}
        />
      ),
    },
    {
      title: "Encaminhamentos recebidos aguardando validação do OISOL",
      subtitle: "a_validar",
      enums: ["A VALIDAR"],
      primaryColor: "#ff9945",
      secondaryColor: "#ce7934",
      icon: (
        <CheckSquareOutlined
          style={{
            fontSize: "3rem",
            color: "white",
          }}
        />
      ),
    },
    {
      title: "Encaminhamentos recebidos",
      subtitle: "finalizados",
      enums: ["FINALIZADA"],
      primaryColor: "#52df9a",
      secondaryColor: "#46bd83",
      icon: (
        <CheckSquareOutlined
          style={{
            fontSize: "3rem",
            color: "white",
          }}
        />
      ),
    },
  ];

  const makePath = (queries) => {
    if (queries.length) {
      return `${urls.ENCAMINHAMENTOS_RECEBIDOS}?${queries
        .map((query) => `status=${query}`)
        .join("&")}`;
    }
  };

  return (
    <div id="sectionMain">
      <Breadcrumb id="breadcrumb">
        <Breadcrumb.Item key="home">
          <Link to="">
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="home">
          <strong>início</strong>
        </Breadcrumb.Item>
      </Breadcrumb>

      <div>
        <div className="container-section">
          <div id="header-container">
            <div>
              <h2>{`Seja bem-vinda (o), ${user?.nome_completo?.split(" ")[0]
                }!`}</h2>
              <span>
                atualizado ás {moment().format("HH:mm")} do dia{" "}
                {moment().format("DD/MM/YYYY")}
              </span>
            </div>
          </div>

          <Row gutter={[16, 16]}>
            {encaminhamentosStatus.map((item, index) => (
              <Col
                key={index}
                xs={24}
                lg={12}
                onClick={() => history.push(makePath(item.enums))}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "top",
                }}
              >
                <Card
                  bodyStyle={{ padding: 0 }}
                  style={{ border: "none" }}
                  hoverable
                >
                  <div
                    style={{
                      display: "flex",
                      background: item.primaryColor,
                    }}
                    className="card-effect"
                  >
                    <div
                      style={{
                        padding: "1rem",
                        background: item.secondaryColor,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {item.icon}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        background: item.backgroundColor,
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingLeft: "1rem",
                        gap: ".25rem",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "1rem",
                          color: "white",
                          lineHeight: "1rem",
                        }}
                      >
                        {item.title}
                      </span>
                      <span
                        style={{
                          fontSize: "2rem",
                          color: "white",
                          lineHeight: "2rem",
                        }}
                      >
                        {`${encaminhamentos[item.subtitle] ?? 0
                          } ${item.subtitle.replace("_", " ")}`}
                      </span>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} lg={12} style={{ display: "flex" }}>
              <Card
                bordered={false}
                style={{ width: "70%" }}
                bodyStyle={{
                  padding: 0,
                  display: "flex",
                  gap: "1rem",
                  flexWrap: "wrap",
                }}
              >
                <Calendar
                  fullscreen={false}
                  className="calendario-year-not"
                  onChange={(date) => getForwardinsByDate(date)}
                  validRange={[
                    moment("01/01/2020"),
                    moment(`1/1/${moment().add(2, "year").format("yyyy")}`),
                  ]}
                />
              </Card>

              <Card
                bordered={false}
                bodyStyle={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "start",
                  gap: "1rem",
                }}
              >
                {encaminhamentosNow
                  ?.filter(([_, value]) => value)
                  .map(
                    ([label, value]) =>
                      value && (
                        <Badge
                          count={value}
                          style={{}}
                          color={
                            encaminhamentosStatus.find(
                              ({ subtitle }) => subtitle === label
                            ).primaryColor
                          }
                        >
                          <Tag>{label.replace("_", " ")}</Tag>
                        </Badge>
                      )
                  )}
              </Card>
            </Col>
            <Col
              lg={12}
              xs={24}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "top",
                gap: "1rem",
              }}
            >
              {cardsView.map((item, index) => (
                <Card
                  bodyStyle={{ padding: 0 }}
                  style={{ border: "none" }}
                  hoverable
                  key={index}
                  onClick={() => history.push(item.path)}
                >
                  <div
                    style={{
                      display: "flex",
                      background: item.primaryColor,
                    }}
                    className="card-effect"
                  >
                    <div
                      style={{
                        padding: "1rem",
                        background: item.secondaryColor,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {item.icon}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        background: item.backgroundColor,
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingLeft: "1rem",
                        gap: ".25rem",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "1rem",
                          color: "white",
                          lineHeight: "1rem",
                        }}
                      >
                        {item.title}
                      </span>
                      <span
                        style={{
                          fontSize: "2rem",
                          color: "white",
                          lineHeight: "2rem",
                        }}
                      >
                        {`${encaminhamentos[item.subtitle] ?? 0} ${item.subtitle
                          }`}
                      </span>
                    </div>
                  </div>
                </Card>
              ))}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
