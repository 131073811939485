import api from "../api";

export const envolvidoService = {
  async loadInvolveds(params) {
    return await api.get(`/pessoas`, {
      params,
    });
  },

  async getInvolved(id) {
    return await api.get(`/pessoas/${id}`);
  },

  async createInvolved(payload) {
    return await api.post(`/pessoas`, payload);
  },

  async editInvolved(id, payload) {
    return await api.put(`/pessoas/${id}`, payload);
  },

  async deleteInvolved(id) {
    return await api.delete(`/pessoas/${id}`);
  },
};
