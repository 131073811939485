import api from "../api";

export const rendaService = {
    async load() {
      return await api.get("/renda");
    },
    async get(codigo) {
      return await api.get(`/renda/${codigo}`);
    },
    async create(payload) {
      return await api.post(`/renda`, payload);
    },
    async edit(codigo, payload) {
      return await api.put(`/renda/${codigo}`, payload);
    },
    async delete(codigo) {
      return await api.delete(`/renda/${codigo}`);
    },
  }