import React, { useState, useEffect, useContext } from "react";

import { mask, unMask } from "remask";
import { cpf } from "cpf-cnpj-validator";
import { Link, useHistory, useParams } from "react-router-dom";

import {
  Form,
  DatePicker,
  Input,
  Select,
  Row,
  Col,
  Button,
  Popconfirm,
  Modal,
  Breadcrumb,
} from "antd";

import moment from "moment";

import { openNotification } from "../../../components/Notification";
import { UserContext } from "../../../contexts/UserContext";
import { HomeOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { DenunciaTabela } from "../../Denuncia/components/DenunciaTabela";
import { urls, USER_ROLES } from "../../../utils";
import { showPlaceholder as showPlaceholderUTILS } from "../../../utils";
import { rendaService } from "../../../services/resources/rendaService";
import { religiaoService } from "../../../services/resources/religiaoService";
import { ocupacaoService } from "../../../services/resources/ocupacaoService";
import { grauInstrucaoService } from "../../../services/resources/grauInstrucaoService";
import { faixaEtariaService } from "../../../services/resources/faixaEtariaService";
import { etniaService } from "../../../services/resources/etniaService";
import { sexualidadeService } from "../../../services/resources/sexualidadeService";
import { identidadeGeneroService } from "../../../services/resources/identidadeGeneroService";
import { generoService } from "../../../services/resources/generoService";
import { estadoCivilService } from "../../../services/resources/estadoCivilService";
import { deficienciaService } from "../../../services/resources/deficienciaService";
import { doencaService } from "../../../services/resources/doencaService";
import { enderecoService } from "../../../services/resources/enderecoService";
import { envolvidoService } from "../../../services/resources/envolvidoService";
import TextArea from "antd/lib/input/TextArea";

const DATA_FORMATO = "DD/MM/YYYY";
const CEP_MASK = "99999-999";
const CPF_MASK = "999.999.999-99";
const TELEFONE_MASK = ["(99) 9999-9999", "(99) 9 9999-9999"];

function Pessoa({ onView, onCreate, onEdit }) {
  const history = useHistory();

  const { codigo } = useParams();

  const { estados, user } = useContext(UserContext);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [pessoa, setPessoa] = useState(null);

  const [cidades, setCidades] = useState([]);
  const [personBairros, setPersonBairros] = useState([]);

  const [generos, setGeneros] = useState([]);
  const [identidadeGenero, setIdentidadeGenero] = useState([]);
  const [sexualidade, setSexualidade] = useState([]);
  const [renda, setRenda] = useState([]);
  const [religiao, setReligiao] = useState([]);
  const [etnias, setEtnias] = useState([]);
  const [statusCivil, setStatusCivil] = useState([]);
  const [doencas, setDoencas] = useState([]);
  const [deficiencias, setDeficiencias] = useState([]);
  const [faixaEtarias, setFaixaEtarias] = useState([]);
  const [grausIntrucao, setGrausInstrucao] = useState([]);
  const [ocupacoes, setOcupacoes] = useState([]);

  const [searchModal, setSearchModal] = useState(false);

  useEffect(() => {
    !onCreate && getPessoa();

    getDoencas();
    getDeficiencias();
    getStatusCivil();
    getEtnias();
    getGeneros();
    getIdentidadeGenero();
    getSexualidade();
    getRenda();
    getReligiao();
    getOcupacoes();
    getFaixasEstarias();
    getGrausInstrucao();
    // eslint-disable-next-line
  }, []);

  async function getPessoa() {
    setLoading(true);
    try {
      const { data: pessoa } = await envolvidoService.getInvolved(codigo);

      setPessoa(pessoa);
      getCidades(pessoa?.endereco?.bairro?.cidade?.estado?.id);
      getDistricts(pessoa?.endereco?.bairro?.cidade?.id);

      form.setFieldsValue({
        ...pessoa,
        dataNascimento:
          pessoa.dataNascimento &&
          moment(new Date(pessoa.dataNascimento), DATA_FORMATO),
      });
    } catch (error) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao carregar essa pessoa!</strong>,
        error?.response?.data?.detail
      );
      history.push(urls.PESSOAS_LIST);
    }
    setLoading(false);
  }

  async function getDistricts(city_id) {
    (await city_id) &&
      enderecoService
        .getDistrictsCitys(city_id)
        .then((response) => {
          setPersonBairros(response.data);
        })
        .catch((reason) =>
          openNotification(
            "error",
            <strong>Ocorreu um erro ao carregar as bairros!</strong>,
            "" + reason
          )
        );
  }

  async function getFaixasEstarias() {
    await faixaEtariaService
      .loadFaixaEtarias()
      .then((response) => setFaixaEtarias(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os tipos de Faixa Etária!</strong>,
          "" + reason
        )
      );
  }

  async function getOcupacoes() {
    await ocupacaoService
      .loadOcupacoes()
      .then((response) => setOcupacoes(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os tipos de Ocupações!</strong>,
          "" + reason
        )
      );
  }

  async function getGrausInstrucao() {
    await grauInstrucaoService
      .loadGrausInstrucao()
      .then((response) => setGrausInstrucao(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>
            Ocorreu um erro ao buscar os tipos de Grau de Instrução!
          </strong>,
          "" + reason
        )
      );
  }

  async function getGeneros() {
    await generoService
      .loadGeneros()
      .then((response) => setGeneros(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os tipos de Sexo!</strong>,
          "" + reason
        )
      );
  }

  async function getIdentidadeGenero() {
    await identidadeGeneroService
      .loadIdentidadeGenero()
      .then((response) => setIdentidadeGenero(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>
            Ocorreu um erro ao buscar a lista de identidade de gênero!
          </strong>,
          "" + reason
        )
      );
  }

  async function getSexualidade() {
    await sexualidadeService
      .load()
      .then((response) => setSexualidade(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar a lista de Orientações Sexuais!</strong>,
          "" + reason
        )
      );
  }

  async function getRenda() {
    await rendaService
      .load()
      .then((response) => setRenda(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar a lista de rendas!</strong>,
          "" + reason
        )
      );
  }

  async function getReligiao() {
    await religiaoService
      .load()
      .then((response) => setReligiao(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar a lista de religiões!</strong>,
          "" + reason
        )
      );
  }

  async function getEtnias() {
    await etniaService
      .loadEtnias()
      .then((response) => setEtnias(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os tipos de Etnia!</strong>,
          "" + reason
        )
      );
  }

  async function getStatusCivil() {
    await estadoCivilService
      .loadStatusCivil()
      .then((response) => setStatusCivil(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os Estados Civis!</strong>,
          "" + reason
        )
      );
  }

  async function getDoencas() {
    await doencaService
      .list()
      .then((response) => setDoencas(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os tipos de doenças!</strong>,
          "" + reason
        )
      );
  }

  async function getDeficiencias() {
    await deficienciaService
      .loadDeficiencias()
      .then((response) => setDeficiencias(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os tipos de Deficiência!</strong>,
          "" + reason
        )
      );
  }

  async function getCidades(estado) {
    await enderecoService
      .getCitysState(estado)
      .then((response) => {
        setCidades(response.data);
      })
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao carregar as cidades!</strong>,
          "Erro: " + reason?.response?.data?.detail
        )
      );
  }

  // Função para aplicar as mascaras nos campos que precisam
  function onChangeItem(name, value, patterns) {
    form.setFieldsValue({
      [name]: mask(unMask(value), patterns),
    });
  }

  function onChangeCep(value) {
    form.setFieldsValue({
      endereco: { cep: mask(unMask(value), CEP_MASK) },
    });
  }

  const validarCPF = () => {
    const numeroCPF = form.getFieldValue("cpf");

    if (!numeroCPF || numeroCPF.length === 14) {
      if (!numeroCPF || cpf.isValid(numeroCPF)) {
        return Promise.resolve();
      }

      return Promise.reject(
        new Error("O cpf informado não é válido pelo digito verificador!")
      );
    }
  };

  async function handleSelectEstado(value, mantemCidades) {
    if (!mantemCidades)
      form.setFieldsValue({
        endereco: { bairro: { id: null, cidade: { id: null } } },
      });
    value && (await getCidades(value));
  }

  async function handleSelectPersonCity(value, mantemBairros) {
    if (!mantemBairros)
      form.setFieldsValue({ endereco: { bairro: { id: null } } });
    value && (await getDistricts(value));
  }

  function transformaPessoa(pessoa) {
    return {
      nome: pessoa.nome,
      nomeSocial: pessoa.nomeSocial || null,
      nomeMae: pessoa.nomeMae || null,
      email: pessoa.email || null,
      nis: pessoa.nis || null,
      telefone1: pessoa.telefone1 || null,
      tipoTelefone1: null,
      telefone2: pessoa.telefone2 || null,
      tipoTelefone2: null,
      observacao: pessoa?.observacao,
      doencaRara: {
        codigo:
          pessoa.doencaRara.codigo ||
          doencas.filter((x) => x?.descricao === "NÃO INFORMADO")[0].codigo,
      },
      deficiencia: {
        codigo:
          pessoa.deficiencia.codigo ||
          deficiencias.filter((x) => x?.descricao === "NÃO INFORMADO")[0]
            .codigo,
      },
      ocupacao: {
        codigo:
          pessoa.ocupacao.codigo ||
          ocupacoes.filter((x) => x?.descricao === "NÃO INFORMADO")[0].codigo,
      },
      faixaEtaria: {
        codigo:
          pessoa.faixaEtaria.codigo ||
          faixaEtarias.filter((x) => x?.descricao === "NÃO INFORMADO")[0]
            .codigo,
      },
      racaCor: {
        codigo:
          pessoa.racaCor.codigo ||
          etnias.filter((x) => x?.descricao === "NÃO INFORMADO")[0].codigo,
      },

      grauInstrucao: {
        codigo:
          pessoa.grauInstrucao.codigo ||
          grausIntrucao.filter((x) => x?.descricao === "NÃO INFORMADO")[0]
            .codigo,
      },
      identidadeGenero: {
        codigo:
          pessoa.identidadeGenero.codigo ||
          identidadeGenero.filter((x) => x?.descricao === "NÃO INFORMADO")[0]
            .codigo,
      },
      sexualidade: {
        codigo:
          pessoa.sexualidade.codigo ||
          sexualidade.filter((x) => x?.descricao === "NÃO INFORMADO")[0].codigo,
      },
      renda: {
        codigo:
          pessoa.renda.codigo ||
          renda.filter((x) => x?.descricao === "NÃO INFORMADO")[0].codigo,
      },
      religiao: {
        codigo:
          pessoa.religiao.codigo ||
          religiao.filter((x) => x?.descricao === "NÃO INFORMADO")[0].codigo,
      },
      naturalidade: pessoa.naturalidade || null,
      nacionalidade: pessoa.nacionalidade || null,
      cpf: pessoa.cpf || null,
      rg: pessoa.rg || null,
      dataNascimento: pessoa.dataNascimento || null,
      endereco: {
        logradouro: pessoa.endereco.logradouro || null,
        numero: pessoa.endereco.numero || null,
        complemento: pessoa.endereco.complemento || null,
        cep: pessoa.endereco.cep || null,
        bairro: {
          id: pessoa.endereco.bairro.id,
        },
      },
      genero: {
        codigo: pessoa.genero.codigo,
      },
      estadoCivil: {
        codigo:
          pessoa.estadoCivil.codigo ||
          statusCivil.filter((x) => x?.descricao === "NÃO INFORMADO")[0].codigo,
      },
    };
  }

  const showPlaceholder = (value) => showPlaceholderUTILS(value, !onView);

  async function createInvolved() {
    const pessoa = transformaPessoa(form.getFieldsValue());

    form.validateFields().then(() => {
      form.resetFields();
      envolvidoService
        .createInvolved(pessoa)
        .then((response) => {
          openNotification(
            "success",
            <strong>Pessoa criada com sucesso!</strong>,
            "Essa pessoa foi criada com sucesso e agora faz parte dessa denúncia!"
          );
          history.push(urls.PESSOAS_DETAILS.replace(":codigo", codigo));
        })
        .catch((reason) => {
          openNotification(
            "error",
            <strong>Erro ao criar essa pessoa!</strong>,
            reason?.response?.data?.detail
          );
        });
    });
  }

  async function editInvolved() {
    const pessoa = transformaPessoa(form.getFieldsValue());

    form.validateFields().then(() => {
      form.resetFields();

      envolvidoService
        .editInvolved(codigo, pessoa)
        .then(() => {
          openNotification(
            "success",
            <strong>Pessoa editada com sucesso!</strong>,
            "Essa pessoa foi atualizada com sucesso!"
          );
          getPessoa();
          history.push(urls.PESSOAS_DETAILS.replace(":codigo", codigo));
        })
        .catch((reason) => {
          openNotification(
            "error",
            <strong>Erro ao criar essa pessoa!</strong>,
            reason?.response?.data?.detail
          );
        });
    });
  }

  async function handleDelete() {
    setConfirmLoading(true);
    setDisabled(true);
    try {
      await envolvidoService.deleteInvolved(codigo);
      openNotification(
        "success",
        <strong>{`Instituição excluída com sucesso!`}</strong>,
        `${pessoa?.nome} foi excluída e essa operação não pode ser revertida.`
      );
      history.push(urls.PESSOAS_LIST);
    } catch ({ response }) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao excluir a pessoa!</strong>,
        "Erro: " + response?.data?.detail
      );
    } finally {
      setConfirmLoading(false);
      setVisible(false);
      setDisabled(false);
    }
  }

  const renderForm = () =>
    ((pessoa && !loading) || onCreate) && (
      <Form
        form={form}
        name="cadastro-pessoa"
        layout="vertical"
        onFinish={onCreate ? createInvolved : editInvolved}
      >
        <Row gutter={32}>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              name="nome"
              label={<span>Nome completo:</span>}
              rules={[{ required: true, message: "Este campo é obrigatório!" }]}
            >
              <Input
                placeholder={showPlaceholder("Insira o nome completo")}
                autoComplete="none"
                disabled={onView}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item name="nomeSocial" label={<span>Nome social:</span>}>
              <Input
                type="text"
                placeholder={showPlaceholder("insira o nome social")}
                autoComplete="none"
                disabled={onView}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item name="nomeMae" label={<span>Nome da mãe:</span>}>
              <Input
                placeholder={showPlaceholder("Insira nome da mãe")}
                autoComplete="none"
                disabled={onView}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={32}>
          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item
              name="dataNascimento"
              label={<span>Data de nascimento:</span>}
            >
              <DatePicker
                style={{ width: "100%" }}
                format={DATA_FORMATO}
                placeholder={showPlaceholder("Formato dd/mm/aaaa")}
                disabledDate={(d) => !d || d.isAfter(new Date())}
                disabled={onView}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item name="rg" label={<span>RG:</span>}>
              <Input
                placeholder={showPlaceholder("123456789-0")}
                autoComplete="none"
                disabled={onView}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item
              name="cpf"
              label={<span>CPF:</span>}
              onChange={({ target }) =>
                onChangeItem("cpf", target.value, CPF_MASK)
              }
              rules={[
                {
                  validator: validarCPF,
                },
              ]}
            >
              <Input
                placeholder={showPlaceholder("123.456.789-10")}
                autoComplete="none"
                disabled={onView}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item name="nis" label={<span>NIS:</span>}>
              <Input
                type="text"
                placeholder={showPlaceholder("insira o NIS")}
                autoComplete="none"
                disabled={onView}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={32}>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              name={["genero", "codigo"]}
              label={<span>Sexo:</span>}
              rules={[{ required: true, message: "Este campo é obrigatório!" }]}
            >
              <Select
                disabled={onView}
                placeholder={showPlaceholder("selecionar sexo")}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={generos?.map(({ descricao, codigo }) => ({
                  label: descricao,
                  value: codigo,
                }))}
                autoComplete="none"
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              name={["identidadeGenero", "codigo"]}
              label={<span>Identidade de gênero:</span>}
            >
              <Select
                disabled={onView}
                placeholder={showPlaceholder("selecionar identidade de gênero")}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={identidadeGenero?.map(({ descricao, codigo }) => ({
                  label: descricao,
                  value: codigo,
                }))}
                autoComplete="none"
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              name={["sexualidade", "codigo"]}
              label={<span>Orientação Sexual:</span>}
            >
              <Select
                disabled={onView}
                placeholder={showPlaceholder("selecionar orientação sexual")}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={sexualidade?.map(({ descricao, codigo }) => ({
                  label: descricao,
                  value: codigo,
                }))}
                autoComplete="none"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={32}>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              name={["faixaEtaria", "codigo"]}
              label={<span>Faixa etária:</span>}
            >
              <Select
                placeholder={showPlaceholder("selecionar faixa etária")}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={faixaEtarias?.map(({ descricao, codigo }) => ({
                  label: descricao,
                  value: codigo,
                }))}
                disabled={onView}
                autoComplete="none"
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              name={["estadoCivil", "codigo"]}
              label={<span>Estado civil:</span>}
            >
              <Select
                placeholder={showPlaceholder("escolher estado civil")}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={statusCivil?.map(({ descricao, codigo }) => ({
                  label: descricao,
                  value: codigo,
                }))}
                disabled={onView}
                autoComplete="none"
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              name={["racaCor", "codigo"]}
              label={<span>Raça/Cor:</span>}
            >
              <Select
                placeholder={showPlaceholder("escolher raça")}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={etnias?.map(({ descricao, codigo }) => ({
                  label: descricao,
                  value: codigo,
                }))}
                disabled={onView}
                autoComplete="none"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={32}>
          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item
              name={["renda", "codigo"]}
              label={<span>Faixa de renda familiar:</span>}
            >
              <Select
                placeholder={showPlaceholder("selecionar faixa de renda")}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={renda?.map(({ descricao, codigo }) => ({
                  label: descricao,
                  value: codigo,
                }))}
                disabled={onView}
                autoComplete="none"
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item
              name={["religiao", "codigo"]}
              label={<span>Religião:</span>}
            >
              <Select
                placeholder={showPlaceholder("escolher religião")}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={religiao?.map(({ descricao, codigo }) => ({
                  label: descricao,
                  value: codigo,
                }))}
                disabled={onView}
                autoComplete="none"
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item name="nacionalidade" label={<span>Nacionalidade:</span>}>
              <Input
                placeholder={showPlaceholder("inserir nacionalidade")}
                name="personNacionalidade"
                autoComplete="none"
                disabled={onView}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item name="naturalidade" label={<span>Naturalidade:</span>}>
              <Input
                placeholder={showPlaceholder("inserir naturalidade")}
                autoComplete="none"
                disabled={onView}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={32}>
          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item
              name={["ocupacao", "codigo"]}
              label={<span>Ocupação:</span>}
            >
              <Select
                placeholder={showPlaceholder("escolher ocupação")}
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={ocupacoes?.map(({ descricao, codigo }) => ({
                  label: descricao,
                  value: codigo,
                }))}
                disabled={onView}
                autoComplete="none"
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item
              name={["grauInstrucao", "codigo"]}
              label={<span>Grau de Instrução:</span>}
            >
              <Select
                placeholder={showPlaceholder("escolher grau de instrução")}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={grausIntrucao?.map(({ descricao, codigo }) => ({
                  label: descricao,
                  value: codigo,
                }))}
                disabled={onView}
                autoComplete="none"
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item
              name={["deficiencia", "codigo"]}
              label={<span>Pessoa com deficiência?</span>}
              defaultValue={null}
            >
              <Select
                placeholder={showPlaceholder("escolher deficiência")}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={deficiencias?.map(({ descricao, codigo }) => ({
                  label: descricao,
                  value: codigo,
                }))}
                disabled={onView}
                autoComplete="none"
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item
              name={["doencaRara", "codigo"]}
              label={<span>Doença rara?</span>}
            >
              <Select
                placeholder={showPlaceholder("escolher doença rara")}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={doencas?.map(({ descricao, codigo }) => ({
                  label: descricao,
                  value: codigo,
                }))}
                disabled={onView}
                autoComplete="none"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={32} span>
          <Col xs={{ span: 8 }} lg={{ span: 8 }}>
            <Form.Item
              label={<span>Estado:</span>}
              name={["endereco", "bairro", "cidade", "estado", "id"]}
              rules={[{ required: true, message: "Este campo é obrigatório!" }]}
            >
              <Select
                placeholder={showPlaceholder("escolher estado")}
                onChange={(estado) => handleSelectEstado(estado, false)}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={estados?.map(({ nome, id }) => ({
                  label: nome,
                  value: id,
                }))}
                disabled={onView}
                autoComplete="none"
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              name={["endereco", "bairro", "cidade", "id"]}
              label={<span>Município:</span>}
              rules={[{ required: true, message: "Este campo é obrigatório!" }]}
            >
              <Select
                placeholder={showPlaceholder("escolher município")}
                onChange={(cidade) => handleSelectPersonCity(cidade, false)}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={cidades?.map(({ nome, id }) => ({
                  label: nome,
                  value: id,
                }))}
                disabled={onView}
                autoComplete="none"
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              name={["endereco", "bairro", "id"]}
              label={<span>Bairro:</span>}
              rules={[{ required: true, message: "Este campo é obrigatório!" }]}
            >
              <Select
                placeholder={showPlaceholder("escolher bairro")}
                disabled={onView}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={personBairros?.map(({ nome, id }) => ({
                  label: nome,
                  value: id,
                }))}
                autoComplete="none"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={32}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name={["endereco", "logradouro"]}
              label={<span>Logradouro:</span>}
            >
              <Input
                placeholder={showPlaceholder("insira o logradouro")}
                autoComplete="none"
                disabled={onView}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item
              name={["endereco", "numero"]}
              label={<span>Número:</span>}
            >
              <Input
                placeholder={showPlaceholder("Insira o número")}
                autoComplete="none"
                disabled={onView}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item
              name={["endereco", "cep"]}
              label={<span>CEP:</span>}
              onChange={({ target }) => onChangeCep(target.value)}
            >
              <Input
                placeholder={showPlaceholder("60000-600")}
                autoComplete="none"
                disabled={onView}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={32}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name={["endereco", "complemento"]}
              label={<span>Ponto de referência</span>}
            >
              <Input
                placeholder={showPlaceholder("insira o ponto de referência")}
                autoComplete="none"
                disabled={onView}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={32}>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              label={<span>Telefone 01:</span>}
              name="telefone1"
              onChange={({ target }) =>
                onChangeItem("telefone1", target.value, TELEFONE_MASK)
              }
            >
              <Input
                autoComplete="none"
                disabled={onView}
                placeholder={showPlaceholder("insira um telefone")}
                allowClear
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              label={<span>Telefone 02:</span>}
              name="telefone2"
              onChange={({ target }) =>
                onChangeItem("telefone2", target.value, TELEFONE_MASK)
              }
            >
              <Input
                autoComplete="none"
                disabled={onView}
                placeholder={showPlaceholder("insira um telefone")}
                allowClear
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <Form.Item
              name="email"
              label={<span>E-mail:</span>}
              rules={[{ type: "email", message: "Insira um email válido" }]}
            >
              <Input
                placeholder={showPlaceholder("meu@email.com")}
                autoComplete="none"
                disabled={onView}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={32}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name={"observacao"}
              label={<span>Observação</span>}
            >
              <TextArea
                autoSize
                placeholder={showPlaceholder("Escreva alguma observação da pessoa")}
                autoComplete="none"
                disabled={onView}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Form.Item>
            <Link to={urls.PESSOAS_LIST} className="button-link">
              <Button type="primary" ghost>
                {onEdit && <span>cancelar edições</span>}
                {onView && <span>voltar</span>}
                {!onView && !onEdit && <span>cancelar</span>}
              </Button>
            </Link>
          </Form.Item>
          {!onView && (
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={confirmLoading}>
                {onEdit ? (
                  <span>salvar edições</span>
                ) : (
                  <span>cadastrar pessoa</span>
                )}
              </Button>
            </Form.Item>
          )}
        </Row>
      </Form>
    );

  return (
    <div id="sectionMain">
      <Breadcrumb id="breadcrumb">
        <Breadcrumb.Item key="home">
          <Link to="">
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="registros">registros</Breadcrumb.Item>
        <Breadcrumb.Item key="pessoas">
          <Link to={urls.PESSOAS_LIST}>pessoas</Link>
        </Breadcrumb.Item>

        {onCreate && (
          <Breadcrumb.Item key="criar">
            <strong>cadastrar pessoa</strong>
          </Breadcrumb.Item>
        )}

        {onView && (
          <Breadcrumb.Item key="pessoa">
            <strong>{pessoa?.nome?.toLowerCase()}</strong>
          </Breadcrumb.Item>
        )}

        {onEdit && (
          <>
            <Breadcrumb.Item key="pessoa">
              <Link
                to={urls.PESSOAS_DETAILS.replace(":codigo", pessoa?.codigo)}
              >
                {pessoa?.nome?.toLowerCase()}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="editar">
              <strong>editar pessoa</strong>
            </Breadcrumb.Item>
          </>
        )}
      </Breadcrumb>

      <div className="section-children">
        <div className="container-section">
          <div id="header-container">
            {onEdit && (
              <div>
                <h2>{pessoa?.nome}</h2>
                <span>Editar informações sobre a pessoa</span>
              </div>
            )}
            {onView && (
              <div>
                <h2>{pessoa?.nome}</h2>
                <span>Informações sobre a pessoa</span>
              </div>
            )}
            {onCreate && (
              <div>
                <h2>CADASTRAR PESSOA</h2>
                <span>Inserir informações sobre a pessoa</span>
              </div>
            )}

            {onView && (
              <div id="header-actions">
                {(user?.authorities?.includes(USER_ROLES.DELETAR_PESSOA) || user?.authorities?.includes(USER_ROLES.GERENCIAR_PESSOA)) 
                  && (
                  <Popconfirm
                    title={
                      <>
                        <span>{`Você tem certeza que deseja excluir a pessoa ${pessoa?.nome}?`}</span>
                        <br />
                        <small>{`Se confirmada, essa operação não poderá ser revertida.`}</small>
                      </>
                    }
                    visible={visible}
                    placement="bottomRight"
                    okText="Confirmar exclusão"
                    icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                    onConfirm={handleDelete}
                    onCancel={() => setVisible(false)}
                    okButtonProps={{ loading: confirmLoading }}
                    disabled={disabled}
                    cancelButtonProps={{ disabled: disabled }}
                  >
                    <Button
                      type="primary"
                      danger
                      onClick={() => {
                        setVisible(true);
                      }}
                    >
                      excluir pessoa
                    </Button>
                  </Popconfirm>
                )}

                {(user?.authorities?.includes(USER_ROLES.ALTERAR_PESSOA) || user?.authorities?.includes(USER_ROLES.GERENCIAR_PESSOA)) 
                  && (
                    <Link
                      to={urls.PESSOAS_EDITAR.replace(":codigo", pessoa?.codigo)}
                      className="button-link"
                    >
                      <Button type="primary">editar pessoa</Button>
                    </Link>
                )}

                {user?.authorities?.includes(
                  USER_ROLES.CONSULTAR_DENUNCIAS_RELACIONADA_PESSOA
                ) && (
                    <Button
                      onClick={() => {
                        setSearchModal(true);
                      }}
                    >
                      denuncias
                    </Button>
                  )}
              </div>
            )}
          </div>
          {renderForm()}
        </div>
      </div>

      <Modal
        title={`Estas são as denúncias que ${pessoa?.nome} está envolvida`}
        width="90vw"
        bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
        centered
        visible={searchModal}
        onCancel={() => setSearchModal(false)}
        footer={
          <Button
            key="back"
            type="danger"
            onClick={() => setSearchModal(false)}
          >
            cancelar
          </Button>
        }
      >
        <DenunciaTabela
          showStatus
          filtro={{ codigoPessoa: codigo }}
          searchClassificacao={false}
        />
      </Modal>
    </div>
  );
}

export default Pessoa;
