import React, { useContext } from "react";

import { Link } from "react-router-dom";

import moment from "moment";

import { Breadcrumb, Button, Menu } from "antd";

import { HomeOutlined } from "@ant-design/icons";
import { UserContext } from "../../../contexts/UserContext";
import { urls, USER_ROLES } from "../../../utils";
import { TabelaEncaminhamentos } from "../components/TabelaEncaminhamentos";

export const EncaminhamentosRecebidos = () => {
  const { user } = useContext(UserContext);

  const menuBreadcrump = (
    <Menu>
      <Menu.Item>
        <Link to={urls.DENUNCIAS_LIST}>enviados</Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <div id="sectionMain">
      <Breadcrumb id="breadcrumb">
        <Breadcrumb.Item key="home">
          <Link to="">
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="registros">registros</Breadcrumb.Item>
        <Breadcrumb.Item key="denuncias">encaminhamentos</Breadcrumb.Item>
        <Breadcrumb.Item key="denuncias" overlay={menuBreadcrump}>
          <strong>recebidos</strong>
        </Breadcrumb.Item>
      </Breadcrumb>

      <div>
        <div className="container-section">
          <div id="header-container">
            <div>
              <h2>LISTA DE ENCAMINHAMENTOS RECEBIDOS</h2>
              <span>
                atualizado às {moment().format("HH:mm")} do dia{" "}
                {moment().format("DD/MM/YYYY")}
              </span>
            </div>

            <div id="header-actions">
              {user?.authorities?.includes(USER_ROLES.INCLUIR_DENUNCIA) && (
                <Link to={urls.DENUNCIA_CRIAR} className="button-link">
                  <Button type="primary">nova denúncia</Button>
                </Link>
              )}
            </div>
          </div>

          <TabelaEncaminhamentos onView recebidos />
        </div>
      </div>
    </div>
  );
};
