import styled from "styled-components";

export const Page = styled.div`
  margin: 0;
  width: 100%;
  height: 100%;

  background-color: var(--main-bg-color);

  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;

  > section {
    max-width: 900px;
    max-height: 500px;
    height: 100%;
    width: 100%;
    display: flex;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 900px) {
    > section {
      max-width: initial;
      max-height: initial;
      flex-direction: column;
    }
  }
`;

export const PageResult = styled(Page)`
  justify-content: initial;
  gap: 2rem;

    > div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 80%;
    width: 80%;
    padding: 2rem;
    align: center;
  }
`;

export const LogoContainer = styled.div`
  background-color: var(--main-color);
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;

  > img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 900px) {
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;

    > img {
      max-width: 350px;
    }
  }
`;

export const Label = styled.span`
  font-weight: bold;
  color: #333333;
`;

export const SectionForm = styled.div`
  background-color: var(--second-bg-color);
  width: 60%;
  padding: 5%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    color: #333333;
    margin: 0;
    font-size: 1rem;
    font-weight: 900;
  }

  button {
    width: 7rem;
    height: 2rem;
    background: var(--main-color);
    margin: 10px 0;
    font-size: 1rem;
  }

  .formSearch {
    width: 100%;
  }

  @media (max-width: 900px) {
    max-width: initial;
    max-height: initial;
    flex-direction: column;

    width: 100%;
    height: 100%;

    .formSearch {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

export const LogoFooter = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    max-width: 350px;
  }
`;
