import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  > Form {
    width: 100%;
  }
`
export const Content = styled.div`
  display: flex;
  width: 70%;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  > Button {
    margin-top: 10px;
    height: 50px;
    border-radius: 5px;
  }
  @media (max-width: 1080px) {
    width: 100%;
  }
`
