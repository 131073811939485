import { useContext, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import moment from "moment";
import { UserContext } from "../../../../contexts/UserContext";
import { denuncianteService, usuarioService } from "../../../../services";

import {
  Badge,
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Pagination,
  Radio,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Tag,
  Tooltip,
  List,
  Popconfirm,
} from "antd";

import {
  ApartmentOutlined,
  AppstoreOutlined,
  CalendarOutlined,
  CopyrightOutlined,
  EditOutlined,
  EnvironmentOutlined,
  ExceptionOutlined,
  EyeOutlined,
  FilterOutlined,
  FolderOpenOutlined,
  InfoCircleOutlined,
  LaptopOutlined,
  MobileOutlined,
  TableOutlined,
} from "@ant-design/icons";

import Highlighter from "react-highlight-words";
import { ArquivarDenunciaModal } from "../ArquivarDenunciaModal";

import Meta from "antd/lib/card/Meta";
import { openNotification } from "../../../../components/Notification";
import { useWidth } from "../../../../hooks/useWidth";
import { canalService } from "../../../../services/resources/canalService";
import { denunciaService } from "../../../../services/resources/denunciaService";
import { subTipoViolacaoService } from "../../../../services/resources/subTipoViolacaoService";
import { tipoViolacaoService } from "../../../../services/resources/tipoViolacaoService";
import {
  DENUNCIAS_TAG_FILTER,
  isEmpty,
  OPCOES_TAMANHO_PAGINA,
  parsedSort,
  removeByProp,
  removeEmpty,
  STATUS,
  STATUS_COLORS,
  urls,
  USER_ROLES,
} from "../../../../utils";
import { labelTags } from "../../../../utils/dicionario";
import { locaisOcorrenciaService } from "../../../../services/resources/locaisOcorrenciaService";
import { frequenciaService } from "../../../../services/resources/frequenciaService";
import { criticidadeService } from "../../../../services/resources/criticidadeService";
import { enderecoService } from "../../../../services/resources/enderecoService";
import { classificacaoAtributoService } from "../../../../services/resources/classificacaoAtributoService";

export const DenunciaTabela = ({
  filtro,
  showStatus = false,
  apenasInstituicao = false,
  cardActions = null,
  cidadeIdList,
  municipioFilter,
}) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const formAvancado = form;

  const { width } = useWidth();
  const { user, themes, status } = useContext(UserContext);

  const [paginacao, setPaginacao] = useState({
    currentPage: 0,
    totalElements: 0,
    size: OPCOES_TAMANHO_PAGINA[0],
  });

  const filterInitial = {
    ativo: true,
    page: 0,
    size: OPCOES_TAMANHO_PAGINA[0],
    codigoPessoa: filtro?.codigoPessoa,
    codigoInstituicaoDestinataria: filtro?.codigoInstituicaoDestinataria,
    codigoInstituicaoUsuario:
      !user?.authorities?.includes(USER_ROLES.CONSULTAR_TODAS_DENUNCIAS) &&
      !user?.authorities?.includes(
        USER_ROLES.CONSULTAR_TODAS_DENUNCIAS_MUNICIPIO
      )
        ? user.codigo_instituicao
        : null,
    cidadeIdList: municipioFilter ? cidadeIdList : filtro?.cidadeIdList,
    codigoStatusList: status
      .filter(({ descricao }) => descricao === filtro?.statusDescricao)
      .map(({ codigo }) => codigo),
  };

  const [filter, setFilter] = useState(filterInitial);

  const [loading, setLoading] = useState(false);
  const [openModalFileReport, setOpenModalFileReport] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [isTableView, setIsTableView] = useState(true);
  const [isVisiblePopConfirm, setIsVisiblePopConfirm] = useState(false);
  const [onEdit, setonEdit] = useState(false);

  const [nameDelete, setNameDelete] = useState("");
  const [codigoDelete, setCodigoDelete] = useState("");

  const [temaSelect, setTemaSelect] = useState("");
  const [tipoSelect, setTipoSelect] = useState("");
  const [cidadeSelect, setCidadeSelect] = useState("");

  const [tiposViolacao, setTiposViolacao] = useState([]);
  const [subTiposViolacao, setSubTiposViolacao] = useState([]);
  const [canais, setCanais] = useState([]);
  const [locaisOcorrencia, setLocaisOcorrencia] = useState([]);
  const [frequencias, setFrequencias] = useState([]);
  const [criticidades, setCriticidades] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [classificacoesAtributos, setClassificacoesAtributos] = useState([]);
  const [bairros, setBairros] = useState([]);
  const [denunciations, setDenunciations] = useState([]);

  useEffect(() => {
    getDenunciations(filter);
    getAllCanais();
    getLocaisOcorrencia();
    getFrequencias();
    getCriticidades();
    municipioFilter
      ? getDistricts(cidadeIdList)
      : getDistricts(filtro.cidadeIdList);
    getCidades(1);
    getClassificacoes();

    // eslint-disable-next-line
  }, [filter]);

  const origem = [
    { value: "OISOL", label: "OISOL"},
    { value: "APP", label: "APP"},
    { value: "SITE", label: "SITE"}
  ]

  async function getDenunciations(filter) {
    try {
      setLoading(true);
      const { data } = apenasInstituicao
        ? await usuarioService.listDenuncias(removeEmpty(filter))
        : await denunciaService.list(removeEmpty(filter));

      setDenunciations(data.content);
      setPaginacao({
        currentPage: data.currentPage,
        totalElements: data.totalElements,
        size: data.size,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function getCidades(estado) {
    await enderecoService
      .getCitysState(estado)
      .then((response) => {
        setCidades(response.data);
      })
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao carregar as cidades!</strong>,
          "Erro: " + reason?.response?.data?.userMessage
        )
      );
  }
  async function getClassificacoes() {
    await classificacaoAtributoService
      .loadClassificacoes()
      .then((response) => {
        setClassificacoesAtributos(response.data);
      })
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao carregar as classificações!</strong>,
          "Erro: " + reason?.response?.data?.userMessage
        )
      );
  }

  async function getDistricts(city_id) {
    (await city_id) &&
      enderecoService
        .getDistrictsCitys(city_id)
        .then((response) => {
          setBairros(response.data);
        })
        .catch((reason) =>
          openNotification(
            "error",
            <strong>Ocorreu um erro ao carregar os bairros!</strong>,
            "" + reason
          )
        );
  }

  async function getTiposViolacao(codigoTema) {
    await tipoViolacaoService
      .loadViolationTypes({ codigoTema })
      .then(({ data }) => setTiposViolacao(data))
      .catch((error) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os tipos da denúncia!</strong>,
          error?.response?.data?.detail
        )
      );
  }

  async function getSubTiposViolacao(codigoTipo) {
    await subTipoViolacaoService
      .loadViolationSubTypes({ codigoTipo })
      .then(({ data }) => setSubTiposViolacao(data))
      .catch((error) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os subtipos da denúncia!</strong>,
          error?.response?.data?.detail
        )
      );
  }

  async function getAllCanais() {
    await canalService
      .list()
      .then(({ data }) => setCanais(data))
      .catch((error) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os canais da denúncia!</strong>,
          error?.response?.data?.detail
        )
      );
  }

  async function getLocaisOcorrencia() {
    await locaisOcorrenciaService
      .loadOcorrenceLocals()
      .then(({ data }) => setLocaisOcorrencia(data))
      .catch((error) =>
        openNotification(
          "error",
          <strong>
            Ocorreu um erro ao buscar os locais da notificação/ manifestação da
            denúncia!
          </strong>,
          error?.response?.data?.detail
        )
      );
  }

  async function getFrequencias() {
    await frequenciaService
      .loadFrequencias()
      .then(({ data }) => setFrequencias(data))
      .catch((error) =>
        openNotification(
          "error",
          <strong>
            Ocorreu um erro ao buscar dados sobre a frequência da denúncia!
          </strong>,
          error?.response?.data?.detail
        )
      );
  }

  async function getCriticidades() {
    await criticidadeService
      .loadCriticidade()
      .then(({ data }) => setCriticidades(data))
      .catch((error) =>
        openNotification(
          "error",
          <strong>
            Ocorreu um erro ao buscar dados sobre a frequência da denúncia!
          </strong>,
          error?.response?.data?.detail
        )
      );
  }

  async function desarquivarDenuncia(codigoDenuncia) {
    try {
      await denuncianteService.desarquivarDenuncia(codigoDenuncia);
      openNotification(
        "success",
        <strong>Denúncia desarquivada com sucesso!</strong>
      );
      getDenunciations(filter);
    } catch (error) {
      console.log(error);
      openNotification(
        "error",
        <strong>Ocorreu um erro ao buscar os tipos da denúncia!</strong>,
        error?.response?.data?.detail
      );
    } finally {
      setIsVisiblePopConfirm(false);
    }
  }

  async function handleSelectCity(value, mantemBairros) {
    setCidadeSelect(value);
    if (!mantemBairros) {
      form.setFieldsValue({ endereco: { bairro: { id: null } } });
      setBairros([]);
    }
    value && (await getDistricts(value));
    setonEdit(false);
  }

  function handleSelectTipoViolacao(codigoTipo) {
    setTipoSelect(codigoTipo);
    setFilter({ ...filter, codigoTipoViolacaoList: [codigoTipo], page: 0 });

    getSubTiposViolacao(codigoTipo);
  }

  function handleSelectSubTipoViolacao(codigoSubTipo) {
    setFilter({
      ...filter,
      codigoSubTipoViolacaoList: [codigoSubTipo],
      page: 0,
    });
  }

  function handleSelectTema(codigoTema) {
    setTemaSelect(codigoTema);
    setFilter({ ...filter, codigoTemaList: [codigoTema], page: 0 });
    getTiposViolacao(codigoTema);
  }

  function handleOpenModalFileReport(denuncia) {
    setOpenModalFileReport(true);
    setNameDelete(denuncia?.protocolo);
    setCodigoDelete(denuncia?.codigo);
  }

  function onSort(sorter) {
    if (sorter.constructor === Object) {
      if (sorter.column)
        setFilter({
          ...filter,
          sort: parsedSort(sorter.columnKey, sorter.order),
        });
      else setFilter({ ...filter, sort: [] });
    } else {
      setFilter({
        ...filter,
        sort: sorter.map((sort) => parsedSort(sort.columnKey, sort.order)),
      });
    }
  }

  function onChange(_, filters, sorter, { action }) {
    if (action === "sort") onSort(sorter);
    if (action === "filter") onFilter(filters);
  }

  function onFilter(filters) {
    const dataCadastroFilter =
      filters?.dataCadastro?.length > 1
        ? {
            dataCadastroInicio: new Date(filters?.dataCadastro[0]),
            dataCadastroFim: new Date(filters?.dataCadastro[1]),
          }
        : { dataCadastroInicio: null, dataCadastroFim: null };

    const dataOcorrenciaFilter =
      filters?.dataOcorrencia?.length > 1
        ? {
            dataOcorrenciaInicio: new Date(filters?.dataOcorrencia[0]),
            dataOcorrenciaFim: new Date(filters?.dataOcorrencia[1]),
          }
        : { dataOcorrenciaInicio: null, dataOcorrenciaFim: null };

    delete filters.dataCadastro;
    delete filters.dataOcorrencia;

    setFilter({
      ...filter,
      ...filters,
      ...dataCadastroFilter,
      ...dataOcorrenciaFilter,
      page: 0,
    });
  }

  function clearAll() {
    if (
      !isEmpty(removeEmpty(form.getFieldsValue())) &&
      !isEmpty(removeEmpty(formAvancado.getFieldsValue()))
    ) {
      setFilter(filterInitial);

      form.resetFields();
      formAvancado.resetFields();
    }
  }

  function convertValuesTags(value) {
    const newValue = status?.find((item) => item.codigo === value)?.descricao;
    if (newValue) return newValue;

    const valueTema = themes.find((item) => item.codigo === value)?.descricao;
    if (valueTema) return valueTema?.toUpperCase();

    const valueViolacao = tiposViolacao.find(
      (item) => item.codigo === value
    )?.descricao;
    if (valueViolacao) return valueViolacao?.toUpperCase();

    const valueSubtipoViolacao = subTiposViolacao.find(
      (item) => item.codigo === value
    )?.descricao;
    if (valueSubtipoViolacao) return valueSubtipoViolacao?.toUpperCase();

    const valueLocalOcorrencia = locaisOcorrencia.find(
      (item) => item.codigo === value
    )?.descricao;
    if (valueLocalOcorrencia) return valueLocalOcorrencia?.toUpperCase();

    const valueCriticidade = criticidades.find(
      (item) => item.codigo === value
    )?.descricao;
    if (valueCriticidade) return valueCriticidade?.toUpperCase();

    const valueFrequencia = frequencias.find(
      (item) => item.codigo === value
    )?.descricao;
    if (valueFrequencia) return valueFrequencia?.toUpperCase();

    const valueCidade = cidades.find((item) => item.id === value)?.nome;
    if (valueCidade) return valueCidade?.toUpperCase();

    return value;
  }

  function convertLabelsTags(label) {
    return labelTags[label];
  }

  function countEncaminhamentos(encaminhamentos) {
    const status = Object.values(STATUS);

    return status
      .map((statusItem) => ({
        descricao: statusItem,
        count: encaminhamentos.filter(
          ({ statusDescricao }) => statusDescricao === statusItem
        ).length,
      }))
      .filter(({ count }) => count);
  }

  const tagsNotShow = [
    "ativo",
    "page",
    "size",
    "sort",
    "codigoTemaList",
    "codigoTipoViolacaoList",
    "codigoSubTipoViolacaoList",
    "codigoInstituicaoDestinataria",
    "codigoPessoa",
    "dataCadastroInicio",
    "dataCadastroFim",
    "dataOcorrenciaInicio",
    "dataOcorrenciaFim",
    "codigoInstituicaoUsuario",
    !municipioFilter && "cidadeIdList",
  ];

  const actionRender = ({
    identificadorExterno: protocolo,
    codigo,
    status,
  }) => (
    <Space size="small" style={{ display: "flex", justifyContent: "end" }}>
      <Button
        className="action-button"
        type="primary"
        title={`Visualizar denúncia ${protocolo}`}
        ghost
        onClick={(event) => {
          event.stopPropagation();
          history.push(urls.DENUNCIA_DETAILS.replace(":codigo", codigo));
        }}
      >
        <EyeOutlined style={{ fontSize: "1.2rem" }} />
      </Button>

      { (user?.authorities?.includes(USER_ROLES.ALTERAR_DENUNCIAS_INSTITUICAO) ||
      user?.authorities?.includes(USER_ROLES.ALTERAR_TODAS_DENUNCIAS)) &&
      status?.descricao !== STATUS.ARQUIVADA && (
        <Button
          className="action-button"
          type="primary"
          title={`Editar denúncia ${protocolo}`}
          onClick={(event) => {
            event.stopPropagation();
            history.push(urls.DENUNCIA_EDITAR.replace(":codigo", codigo));
          }}
        >
          <EditOutlined style={{ fontSize: "1.2rem" }} />
        </Button>
      )}

      {user?.authorities?.includes(USER_ROLES.ARQUIVAR_DENUNCIA) &&
      status?.descricao === STATUS.ANALISAR ? (
        <Button
          className="action-button"
          type="danger"
          title={`Arquivar denúncia ${protocolo}`}
          onClick={(event) => {
            event.stopPropagation();
            handleOpenModalFileReport({ protocolo, codigo });
          }}
        >
          <ExceptionOutlined style={{ fontSize: "1.2rem" }} />
        </Button>
      ) : null}

      {user?.authorities?.includes(USER_ROLES.ARQUIVAR_DENUNCIA) &&
      status?.descricao === STATUS.ARQUIVADA ? (
        <Space>
          <Popconfirm
            title={
              <span>
                Deseja desarquivar a denúncia <strong>{protocolo}</strong>?
              </span>
            }
            placement="bottomRight"
            okText="desarquivar"
            cancelText="cancelar"
            visible={isVisiblePopConfirm}
            onConfirm={() => desarquivarDenuncia(codigo)}
            onCancel={() => setIsVisiblePopConfirm(false)}
          >
            <Button
              className="action-button"
              type="primary"
              title={`Desarquivar denúncia ${protocolo}`}
              onClick={(event) => {
                event.stopPropagation();
                setIsVisiblePopConfirm(true);
              }}
            >
              <FolderOpenOutlined style={{ fontSize: "1.2rem" }} />
            </Button>
          </Popconfirm>
        </Space>
      ) : null}
    </Space>
  );

  const statusColumn = {
    title: <strong>status</strong>,
    dataIndex: ["status", "descricao"],
    key: "status.descricao",
    width: 0,
    sorter: {
      multiple: 1,
    },
    render: (status) => (
      <Badge
        style={{
          backgroundColor: STATUS_COLORS[status],
          color: "#333333",
          fontWeight: "bold",
        }}
        count={status}
      />
    ),
  };

  const columns = [
    Table.EXPAND_COLUMN,
    {
      title: <strong>procedência</strong>,
      dataIndex: ["classificacaoAtributoDescricao"],
      key: "classificacaoAtributoDescricao",
      width: 0,
      sorter: {
        multiple: 1,
      },
      render: (classificacaoAtributoDescricao) => (
        <Badge
          style={{
            backgroundColor:
              classificacaoAtributoDescricao === "PROCEDENTE"
                ? "var(--green-color)"
                : classificacaoAtributoDescricao === "IMPROCEDENTE"
                ? "var(--red-color)"
                : "#333333",
            fontWeight: "bold",
          }}
          count={classificacaoAtributoDescricao}
        />
      ),
    },
    {
      title: <strong>protocolo</strong>,
      dataIndex: "identificadorExterno",
      key: "identificadorExterno",
      width: 0,
      sorter: {
        multiple: 1,
      },
      render: (text) => (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ff9945",
            padding: 0,
            color: "#fff",
          }}
          searchWords={[filter?.identificadorExterno]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },
    {
      title: <strong>canal</strong>,
      dataIndex: ["canal"],
      key: "canal",
      sorter: {
        multiple: 1,
      },

      responsive: ["md"],
      render: (canal) => (
        <Tooltip title={canal?.descricao || canal?.sigla} key={canal?.codigo}>
          {canal?.sigla}
        </Tooltip>
      ),
    },
    {
      title: <strong>cidade</strong>,
      dataIndex: "municipio",
      key: "endereco.bairro.cidade.nome",
      render: (text) => (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ff9945",
            padding: 0,
            color: "#fff",
          }}
          searchWords={[filter?.nomeCidade]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
      responsive: ["md"],
    },
    {
      title: <strong>bairro</strong>,
      dataIndex: "bairro",
      key: "endereco.bairro.nome",
    },
    {
      title: <strong>origem</strong>,
      dataIndex: "origemDenunciaDescricao",
      key: "origemDenunciaDescricao",
      sorter: {
        multiple: 1,
      },
      render: (text) =>
        text === "APP" ? (
          <p>
            Aplicativo <MobileOutlined style={{ fontSize: 18 }} />
          </p>
        ) : text === "SITE" ? (
          <p>
            Página <LaptopOutlined style={{ fontSize: 18 }} />
          </p>
        ) : (
          <p>
            {text} <ApartmentOutlined />
          </p>
        ),
    },
    {
      title: <strong>data da manifestação</strong>,
      dataIndex: "dataOcorrencia",
      key: "dataOcorrencia",
      sorter: {
        multiple: 1,
      },
      responsive: ["md"],
      render: (init) => (init ? moment(init).format("DD/MM/yyyy") : ""),
    },
    {
      title: <strong>início</strong>,
      sorter: {
        multiple: 1,
      },
      dataIndex: "dataCadastro",
      key: "dataCadastro",
      responsive: ["xl"],
      onFilter: (data) => {
        return data;
      },
      render: (init) => (init ? moment(init).format("DD/MM/yyyy") : ""),
    },
    {
      title: <strong>ações</strong>,
      key: "action",
      width: 32,
      responsive: ["md"],
      align: "right",
      render: actionRender,
    },
  ];

  useEffect(() => {
    width <= 768 && setIsTableView(false);

    // eslint-disable-next-line
  }, [width]);

  return (
    <section style={{ display: "flex", gap: "2rem", flexDirection: "column" }}>
      <Row gutter={[16, 16]}>
        {width > 768 && (
          <Col span={6}>
            <Form
              name="denuncia_filtro"
              layout="inline"
              form={form}
              onValuesChange={({ identificadorExterno }) =>
                onFilter({ identificadorExterno })
              }
              onFinish={({ nome }) =>
                nome !== filter.nome && onFilter({ nome })
              }
            >
              <Col span={24}>
                <Form.Item name="identificadorExterno">
                  <Input
                    placeholder="Busque pelo protocolo da denúncia"
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Form>
          </Col>
        )}
        <Col>
          <Button type="primary" onClick={() => setShowFilters(true)}>
            filtros avançados
            <FilterOutlined />
          </Button>
        </Col>
        <Col>
          <Button onClick={clearAll}>limpar filtros</Button>
        </Col>
        {width > 768 && (
          <Col>
            <Radio.Group
              defaultValue={isTableView}
              onChange={({ target: { value } }) => setIsTableView(value)}
            >
              <Radio.Button value={true}>
                <TableOutlined />
              </Radio.Button>
              <Radio.Button value={false}>
                <AppstoreOutlined />
              </Radio.Button>
            </Radio.Group>
          </Col>
        )}
      </Row>

      <Row>
        <Col span={24}>
          {Object.entries(removeEmpty(filter))
            .filter(
              ([label]) =>
                ![...tagsNotShow, !showStatus && "codigoStatusList"].includes(
                  label
                )
            )
            .map(([label, value]) => (
              <Tag
                color="var(--border-color)"
                closable
                key={label + value}
                onClose={() => {
                  onFilter(removeByProp(filter, label));
                  form.setFieldsValue({ [label]: null });
                  formAvancado.setFieldsValue({ [label]: null });
                }}
              >
                {(
                  DENUNCIAS_TAG_FILTER[label] || convertLabelsTags(label)
                )?.toUpperCase() +
                  ": " +
                  convertValuesTags(value, label)}
              </Tag>
            ))}
        </Col>
      </Row>
      {isTableView ? (
        <Table
          loading={loading}
          dataSource={denunciations}
          columns={showStatus ? [statusColumn, ...columns] : columns}
          size="small"
          rowKey={({ codigo }) => codigo}
          onChange={onChange}
          pagination={false}
          expandable={{
            expandedRowRender: (denuncia) => {
              if (denuncia?.status.descricao === STATUS.ARQUIVADA) {
                return (
                  <Row>
                    <Col span={24}>
                      <List
                        header={"Justificativas de arquivamento"}
                        itemLayout="horizontal"
                        dataSource={denuncia.justificativa.split(";")}
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta avatar="-" description={item} />
                          </List.Item>
                        )}
                      />
                    </Col>
                  </Row>
                );
              } else if (denuncia?.encaminhamentos?.length) {
                return (
                  <Row>
                    <Col span={24}>
                      <List
                        header={"Encaminhamentos"}
                        itemLayout="horizontal"
                        dataSource={countEncaminhamentos(
                          denuncia?.encaminhamentos
                        )}
                        renderItem={(encaminhamento) => (
                          <List.Item>
                            <List.Item.Meta
                              avatar={encaminhamento.count}
                              description={encaminhamento.descricao}
                            />
                          </List.Item>
                        )}
                      />
                    </Col>
                  </Row>
                );
              } else return denuncia.descricao;
            },
          }}
        />
      ) : (
        <Row gutter={16} justify="space-between">
          {denunciations.map((denuncia) => (
            <Col key={denuncia?.codigo} xs={24} md={12} lg={8} xl={6}>
              <Card
                key={denuncia?.codigo}
                style={{ width: "100%", marginTop: 16 }}
                actions={
                  cardActions
                    ? [cardActions(denuncia)]
                    : [
                        <Button
                          key="view"
                          icon={
                            <EyeOutlined
                              style={{ fontSize: "1.2rem" }}
                              onClick={() =>
                                history.push(
                                  urls.DENUNCIA_DETAILS.replace(
                                    ":codigo",
                                    denuncia?.codigo
                                  )
                                )
                              }
                            />
                          }
                        />,
                        <Button
                          key="edit"
                          type="primary"
                          icon={
                            <EditOutlined
                              style={{ fontSize: "1.2rem" }}
                              onClick={() =>
                                history.push(
                                  urls.DENUNCIA_EDITAR.replace(
                                    ":codigo",
                                    denuncia?.codigo
                                  )
                                )
                              }
                            />
                          }
                        />,
                        user?.authorities?.includes(
                          USER_ROLES.ARQUIVAR_DENUNCIA
                        ) && denuncia?.status?.descricao === STATUS.ANALISAR ? (
                          <Button
                            key="arquivar"
                            style={{ padding: 5 }}
                            type="danger"
                            title={`Arquivar denúncia ${denuncia?.protocolo}`}
                            onClick={(event) => {
                              event.stopPropagation();
                              const {
                                identificadorExterno: protocolo,
                                codigo,
                              } = denuncia;
                              handleOpenModalFileReport({ protocolo, codigo });
                            }}
                          >
                            <ExceptionOutlined style={{ fontSize: "1.2rem" }} />
                          </Button>
                        ) : null,
                      ]
                }
              >
                <Skeleton loading={loading} avatar active>
                  <Meta
                    description={
                      <>
                        <Row justify="space-between">
                          <Col>
                            <p>
                              <InfoCircleOutlined /> Protocolo:
                              <strong> {denuncia?.identificadorExterno}</strong>
                            </p>
                            <p>
                              <CopyrightOutlined /> Canal:
                              <strong> {denuncia?.canal?.sigla}</strong>
                            </p>
                          </Col>
                          <Badge
                            style={{
                              backgroundColor:
                                STATUS_COLORS[denuncia?.status.descricao],
                              color: "#333333",
                              fontWeight: "bold",
                            }}
                            count={denuncia?.status.descricao}
                          />
                        </Row>
                        <Divider />
                        <Row justify="space-between">
                          <p>
                            <EnvironmentOutlined /> {denuncia?.municipio}
                          </p>

                          <p>
                            <CalendarOutlined /> Ocorrência: {""}
                            {moment(denuncia?.dataOcorrencia).format(
                              "DD/MM/yyyy"
                            )}
                          </p>
                        </Row>

                        <Row justify="space-between" align="center">
                          <p>
                            <CalendarOutlined /> Ínicio: {""}
                            {moment(denuncia?.dataCadastro).format(
                              "DD/MM/yyyy"
                            )}
                          </p>
                          <p>
                            <CalendarOutlined /> Prazo: {""}
                            {moment(denuncia?.dataLimite).format("DD/MM/yyyy")}
                          </p>
                        </Row>
                      </>
                    }
                  />
                </Skeleton>
              </Card>
            </Col>
          ))}
        </Row>
      )}
      <Row justify="end">
        <Col>
          <Pagination
            size="small"
            showSizeChanger={true}
            simple={width < 768}
            pageSizeOptions={OPCOES_TAMANHO_PAGINA}
            defaultPageSize={paginacao.size}
            total={paginacao.totalElements}
            showTotal={(total) => <strong>{total} denúncias no total</strong>}
            onChange={(page, size) => {
              setFilter({
                ...filter,
                page: filter.size !== size ? 0 : page - 1,
                size,
              });
            }}
            current={paginacao.currentPage + 1}
          />
        </Col>
      </Row>

      {/* Conjunto de modais para a ação de arquivar uma denúncia. */}
      {openModalFileReport && (
        <ArquivarDenunciaModal
          onClose={() => setOpenModalFileReport(false)}
          refresh={getDenunciations}
          protocolo={nameDelete}
          codigo={codigoDelete}
          onFinish={() => getDenunciations(filter)}
        />
      )}

      <Modal
        centered
        title="Selecionar filtros"
        visible={showFilters}
        onCancel={() => {
          setShowFilters(false);
        }}
        bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setShowFilters(false);
              onFilter(formAvancado.getFieldsValue());
            }}
          >
            aplicar filtros
          </Button>,
        ]}
      >
        <Form name="denuncia_filtro" layout="vertical" form={formAvancado}>
          <Collapse ghost >
            <Collapse.Panel header="Dados das denúncias" key="1">
              {showStatus && (
                <Col span={24}>
                  <Form.Item name="codigoStatusList" label="status">
                    <Select
                      placeholder="Escolha um status da denúncia"
                      style={{ width: "100%" }}
                      options={status?.map(({ descricao, codigo }) => ({
                        label: descricao,
                        value: codigo,
                      }))}
                      allowClear
                    ></Select>
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Form.Item
                  name="classificacaoAtributoDescricao"
                  label="classificação"
                >
                  <Select
                    placeholder="Escolha uma classificação"
                    style={{ width: "100%" }}
                    options={classificacoesAtributos?.map(({ descricao }) => ({
                      label: descricao,
                      value: descricao,
                    }))}
                    allowClear
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="identificadorExterno" label="protocolo">
                  <Input
                    placeholder="Busque pelo protocolo da denúncia"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="nomeCanal" label="canal">
                  <Select
                    placeholder="Escolha um canal"
                    style={{ width: "100%" }}
                    options={canais?.map(({ nome, sigla, codigo }) => ({
                      label: sigla,
                      value: nome,
                      key: codigo,
                    }))}
                    allowClear
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                {municipioFilter && (
                  <Form.Item
                    name={"cidadeIdList"}
                    label={<span>cidade:</span>}
                    validateStatus="validating"
                    hasFeedback={loading.cidades}
                  >
                    <Select
                      placeholder="escolher município"
                      onChange={(cidade) => handleSelectCity(cidade, false)}
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      options={cidades?.map(({ nome, id }) => ({
                        label: nome,
                        value: id,
                      }))}
                      autoComplete="none"
                    />
                  </Form.Item>
                )}
              </Col>
              <Col span={24}>
                <Form.Item
                  name={"nomeBairro"}
                  label={<span>bairro:</span>}
                  validateStatus="validating"
                  hasFeedback={loading.bairros}
                >
                  <Select
                    placeholder="escolher"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    options={bairros?.map(({ nome, id }) => ({
                      label: nome,
                      value: nome,
                    }))}
                    autoComplete="none"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name={"codigoLocalOcorrencia"}
                  label={<span>local da notificação/ manifestação:</span>}
                >
                  <Select
                    autoComplete="none"
                    placeholder="Busque pelo local da notificação/ manifestação"
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    options={locaisOcorrencia?.map(({ codigo, descricao }) => ({
                      label: descricao,
                      value: codigo,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={"codigoFrequencia"}
                  label={<span>frequência:</span>}
                >
                  <Select
                    autoComplete="none"
                    placeholder="Busque pelo frequência"
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    options={frequencias?.map(({ codigo, descricao }) => ({
                      label: descricao,
                      value: codigo,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name={"codigoCriticidade"}
                  label={<span>criticidade:</span>}
                >
                  <Select
                    autoComplete="none"
                    placeholder="Busque pela criticidade"
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    options={criticidades?.map(({ codigo, descricao }) => ({
                      label: descricao,
                      value: codigo,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="origemDenuncia" label="Origem da denúncia">
                  <Select
                    autoComplete="none"
                    placeholder="Busque pela origem da denúncia"
                    allowClear
                    options={origem}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="dataCadastro" label="data de início">
                  <DatePicker.RangePicker
                    style={{ width: "100%" }}
                    ranges={{
                      Hoje: [moment(), moment()],
                      "Esta semana": [
                        moment().startOf("isoWeek"),
                        moment().endOf("isoWeek"),
                      ],
                      "Este mês": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                      "Este ano": [
                        moment().startOf("year"),
                        moment().endOf("year"),
                      ],
                      Ontem: [
                        moment().subtract(1, "days"),
                        moment().subtract(1, "days"),
                      ],
                      "Semana passada": [
                        moment().startOf("isoWeek").subtract(1, "isoWeek"),
                        moment().endOf("isoWeek").subtract(1, "isoWeek"),
                      ],
                      "Mês Passado": [
                        moment().startOf("month").subtract(1, "month"),
                        moment().endOf("month").subtract(1, "month"),
                      ],
                      "Ano Passado": [
                        moment().startOf("year").subtract(1, "year"),
                        moment().endOf("year").subtract(1, "year"),
                      ],
                    }}
                    format="DD/MM/YYYY"
                    on
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="dataOcorrencia"
                  label="data da notificação/ manifestação"
                >
                  <DatePicker.RangePicker
                    style={{ width: "100%" }}
                    ranges={{
                      Hoje: [moment(), moment()],
                      "Esta semana": [
                        moment().startOf("isoWeek"),
                        moment().endOf("isoWeek"),
                      ],
                      "Este mês": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                      "Este ano": [
                        moment().startOf("year"),
                        moment().endOf("year"),
                      ],
                      Ontem: [
                        moment().subtract(1, "days"),
                        moment().subtract(1, "days"),
                      ],
                      "Semana passada": [
                        moment().startOf("isoWeek").subtract(1, "isoWeek"),
                        moment().endOf("isoWeek").subtract(1, "isoWeek"),
                      ],
                      "Mês Passado": [
                        moment().startOf("month").subtract(1, "month"),
                        moment().endOf("month").subtract(1, "month"),
                      ],
                      "Ano Passado": [
                        moment().startOf("year").subtract(1, "year"),
                        moment().endOf("year").subtract(1, "year"),
                      ],
                    }}
                    format="DD/MM/YYYY"
                    on
                  />
                </Form.Item>
              </Col>
            </Collapse.Panel>
            <Collapse.Panel header="Filtro por temas ou violações" key="2">
              <Col span={24}>
                <Row justify="space-between">
                  <Col style={{ display: "flex", alignItems: "center" }}></Col>
                  <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                    <Form.Item name="temas" label="tema">
                      <Select
                        placeholder="Escolha um tema"
                        style={{ width: "100%" }}
                        options={themes?.map(({ codigo, descricao }) => ({
                          label: descricao,
                          value: codigo,
                        }))}
                        allowClear
                        showSearch
                        onChange={handleSelectTema}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                    <Form.Item name="tipos" label="violação">
                      <Select
                        placeholder="Escolha um tipo de violação"
                        allowClear
                        style={{ width: "100%" }}
                        options={tiposViolacao?.map(
                          ({ codigo, descricao }) => ({
                            label: descricao,
                            value: codigo,
                          })
                        )}
                        disabled={!temaSelect}
                        showSearch
                        onChange={handleSelectTipoViolacao}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                    <Form.Item name="subtipos" label="subtipos">
                      <Select
                        placeholder="Escolha um subtipo de violação"
                        style={{ width: "100%" }}
                        disabled={!tipoSelect}
                        options={subTiposViolacao?.map(
                          ({ codigo, descricao }) => ({
                            label: descricao,
                            value: codigo,
                            key: codigo,
                          })
                        )}
                        onChange={handleSelectSubTipoViolacao}
                        allowClear
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Collapse.Panel>

            <Collapse.Panel header="Dados da pessoa" key="3">
            <Col span={24}>
                <Form.Item name="nomePessoa" label="nome da pessoa">
                  <Input
                    placeholder="Busque pelo nome da pessoa independente se é notificante, vítima ou suspeito"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="nomeNotificante" label="nome do notificante">
                  <Input
                    placeholder="Busque pelo nome do notificante"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="nomeVitima" label="nome da vítima">
                  <Input
                    placeholder="Busque pelo nome da vítima"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="nomeSuspeito" label="nome do suspeito">
                  <Input
                    placeholder="Busque pelo nome do suspeito"
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Collapse.Panel>
          </Collapse>
        </Form>
      </Modal>
    </section>
  );
};
