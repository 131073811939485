import axios from "axios";

const gateway = process.env.REACT_APP_GATEWAY;

const api = axios.create({
  baseURL: gateway,
  headers: {
    Accept: `application/*`,
  },
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("@oisol/token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default api;
