import React, { useContext, useEffect, useState } from "react";

import { UserContext } from "../../contexts/UserContext";
import { mask, unMask } from "remask";
import { cpf, cnpj } from "cpf-cnpj-validator";

import { services } from "../../services/login";

import { Form, Input, Col, Row, Tooltip, notification, Button } from "antd";
import {
  UserOutlined,
  LockOutlined,
  MailOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import style from "./Login.module.css";

import logoOiSol from "../../images/logos/oisol.svg";
import oisol from "../../images/logos/oisol@1X2.svg";
import logoSPS from "../../images/logos/logo-gov.png";
import { useWidth } from "../../hooks";

const Login = () => {
  const { userLogin, loading, setLoading, error, setError, autoLogin } =
    useContext(UserContext);

  const { width } = useWidth();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [form] = Form.useForm();
  const patterns = ["999.999.999-99", "99.999.999/9999-99"];
  const [hasForgotPassword, setHasForgotPassword] = useState(false);

  useEffect(() => {
    error && openNotificationWithIcon("error", <strong>{error}!</strong>);
  }, [error]);

  const onChange = (value) => {
    setLogin(unMask(value));
    form.setFieldsValue({
      login: mask(unMask(value), patterns),
    });
  };

  const handleMode = () => {
    setHasForgotPassword(!hasForgotPassword);
  };

  async function handleSubmit() {
    autoLogin();
    if (login.length <= 11) {
      if (cpf.isValid(form.getFieldValue("login")))
        await userLogin(login, password);
      else
        openNotificationWithIcon(
          "error",
          <strong>CPF inválido!</strong>,
          "CPF inválido pelo dígito verificador. Insira um CPF válido!"
        );
    } else {
      if (cnpj.isValid(form.getFieldValue("login"))) userLogin(login, password);
      else
        openNotificationWithIcon(
          "error",
          <strong>CNPJ inválido!</strong>,
          "CNPJ inválido pelo dígito verificador. Insira um CNPJ válido!"
        );
    }
  }

  async function handleSubmitForgotPassword(user) {
    setLoading(true);
    try {
      await services.user.forgotPassword({
        login: unMask(user.login),
        email: user.email,
      });

      setHasForgotPassword(false);

      openNotificationWithIcon(
        "success",
        <strong>Senha redefinida com sucesso!</strong>,
        <span>
          Uma nova senha foi enviada para seu email <strong>{email}</strong>,
          verifique sua caixa de email.{" "}
        </span>
      );
    } catch ({ response }) {
      openNotificationWithIcon(
        "error",
        <strong>{response?.data?.title}</strong>,
        response?.data?.userMessage
      );
    } finally {
      setLoading(false);
      setError("");
    }
  }

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
      duration: 10,
      style: {
        width: 900,
        background: type === "error" ? "#FFCBCB" : "#f6ffed",
      },
    });
  };

  return (
    <div className={style.page}>
      {process.env.REACT_APP_ENV !== "production" &&
        <div style={{ backgroundColor: "var(--red-color)", color: "white", textAlign: "center", fontWeight: "600", width: "100%", maxWidth: "900px" }}>
            Ambiente de homologação
        </div>
      } 
      <section className={style.sectionLogin}>
        {width > 900 && (
          <div className={style.logo}>
            <img
              src={logoOiSol}
              alt="Logo - Oisol"
              title={process.env.REACT_APP_VERSION}
            />
          </div>
        )}

        <div className={style.sectionForm}>
          {width > 900 && (
            <h1>
              {hasForgotPassword
                ? "RECUPERAÇÂO DE SENHA DO "
                : "BEM VINDA(O) AO "}
              SISTEMA <br /> DO OBSERVATÓRIO DE INDICADORES SOCIAIS
            </h1>
          )}

          <Form
            onFinish={
              hasForgotPassword ? handleSubmitForgotPassword : handleSubmit
            }
            className={style.formLogin}
            requiredMark={false}
            form={form}
            name="loginForm"
            layout="vertical"
          >
            {width <= 900 && (
              <div className={style.logo}>
                <img
                  src={oisol}
                  alt="Logo - Oisol"
                  title={process.env.REACT_APP_VERSION}
                />
              </div>
            )}
            <Col>
              <Form.Item
                span="24"
                name="login"
                label={<span className={style.label}>Login:</span>}
                rules={[
                  { required: true, message: "Este campo é obrigatório!" },
                ]}
                onChange={(e) => onChange(e.target.value)}
              >
                <Input
                  size="large"
                  prefix={<UserOutlined />}
                  placeholder="digite aqui o seu CPF ou CNPJ"
                  id="login"
                  suffix={
                    <Tooltip title="O seu login pode ser um cpf ou um cnpj!">
                      <InfoCircleOutlined
                        style={{ color: "rgba(0,0,0,.45)" }}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </Col>

            <Col>
              {hasForgotPassword ? (
                <Form.Item
                  span="24"
                  name="email"
                  label={<span className={style.label}>Email:</span>}
                  rules={[
                    { required: true, message: "Este campo é obrigatório!" },
                    { type: "email", message: "Insira um email válido" },
                  ]}
                >
                  <Input
                    size="large"
                    prefix={<MailOutlined />}
                    placeholder="digite aqui seu email de acesso"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    suffix={
                      <Tooltip title="O email de acesso é que foi cadastrado ao seu usuário!">
                        <InfoCircleOutlined
                          style={{ color: "rgba(0,0,0,.45)" }}
                        />
                      </Tooltip>
                    }
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  span="24"
                  name="password"
                  label={<span className={style.label}>Senha:</span>}
                  rules={[
                    { required: true, message: "Este campo é obrigatório!" },
                  ]}
                >
                  <Input.Password
                    size="large"
                    prefix={<LockOutlined />}
                    placeholder="digite aqui a sua senha"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>
              )}
            </Col>

            {!hasForgotPassword ? (
              <span className={style.forgotPassword}>
                esqueceu sua senha?{" "}
                <span onClick={handleMode}>recuperar acesso</span>
              </span>
            ) : (
              <span className={style.forgotPassword}>
                ir para
                <span onClick={handleMode}> login</span>?
              </span>
            )}

            <Row justify="end">
              {hasForgotPassword ? (
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={loading}
                  block={width < 576}
                  style={{ height: "35px" }}
                >
                  recuperar
                </Button>
              ) : (
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={loading}
                  block={width < 576}
                  style={{ height: "35px", fontWeight: "" }}
                >
                  entrar
                </Button>
              )}
            </Row>
          </Form>

          <div className={style.loginFooter}>
            <img
              style={{ maxWidth: 220 }}
              src={logoSPS}
              alt="logo - Secretaria da proteção social, justiça, cidadania, mulheres e direitos humanos."
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
