import { Result } from "antd";

export const NotFound = () => {
  return (
    <div id="">
      <div className="section-children">
        <Result
          status="404"
          title="Erro 404 - Página não encontrada"
          subTitle="Desculpa, esta página não existe."
        ></Result>
      </div>
    </div>
  );
};