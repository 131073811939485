import React, { useState, useEffect, useCallback } from "react";
import { openNotification } from "../../../../../components/Notification";
import { useWidth } from "../../../../../hooks";

import {
  Form,
  Select,
  Row,
  Col,
  Button,
  Table,
  Space,
  Tooltip,
  Empty,
  Modal,
  Input,
  Badge,

} from "antd";
import { useParams } from "react-router-dom";

import {
  CopyOutlined,
  DeleteOutlined,
  EyeOutlined,
  QuestionOutlined,
} from "@ant-design/icons";

import {
  classificacaoService,
  denuncianteService,
  subTipoViolacaoService,
  temaService,
  tipoViolacaoService,
} from "../../../../../services";
import { classificacaoAtributoService } from "../../../../../services/resources/classificacaoAtributoService";
import DialogModal from "../../../../../components/DialogModal";

const { TextArea } = Input;
const { Option } = Select;

export const Classificacao = ({
  handleNext,
  onEdit,
  onView,
  showActions = true,
}) => {
  const { codigo } = useParams();

  const [temaSelect, setTemaSelect] = useState("");
  const [tipoDenunciaSelect, setTipoDenunciaSelect] = useState("");
  const [subTipoDenunciaSelect, setSubTipoDenunciaSelect] = useState("");
  const [victimSelect, setVictimSelect] = useState("");
  const [classificacaoAtributoSelect, setClassificacaoAtributoSelect] = useState(null);

  const [tipoDenunciaDisable, setTipoDenunciaDisable] = useState(true);
  const [subTipoDenunciaDisable, setSubTipoDenunciaDisable] = useState(true);
  const [vitctimDisable, setVitctimDisable] = useState(true);
  const [AddDisable, setAddDisable] = useState(true);

  const [classificacoes, setClassificacoes] = useState([]);
  const [vitimas, setVitimas] = useState([]);
  const [tiposDenuncia, setTiposDenuncia] = useState([]);
  const [temas, setTemas] = useState([]);
  const [classificacoesAtributos, setClassificacoesAtributos] = useState([]);
  const [subTiposDenuncia, setSubTiposDenuncia] = useState([]);

  const [popLoading, setPopLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [openDialogModal, setOpenDialogModal] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [mode, setMode] = useState("");
  const [idSelect, setIdSelect] = useState([]);
  const [fundamentacao, setFundamentacao] = useState("");

  const [form] = Form.useForm();
  const [procedeForm] = Form.useForm();
  const [procedencia, setProcedencia] = useState(false);
  const [codigoClassificacao, setCodigoClassificacao] = useState(null);
  const [modalProcedenciaVisible, setModalProcedenciaVisible] = useState(false);

  const { width } = useWidth();

  useEffect(() => {
    getDenunciationTypes();
    getThemes();
    getDenunciationSubTypes();
    getClassificacao();
    getVitimas();
    getClassificacoesAtributos();
    // eslint-disable-next-line
  }, []);


  function handleDeleteClassificacao(codigo) {
    setOpenDialogModal(true);
    setCodigoClassificacao(codigo);
  }

  async function handleSelectTema(codigo_tema) {
    setTemaSelect(codigo_tema);
    getDenunciationTypes(codigo_tema);
    setTipoDenunciaDisable(false);
  }

  async function getVitimas() {
    setLoading(true);
    await denuncianteService.vitima
      .listar(codigo)
      .then(({ data }) => {
        setVitimas(data);
      })
      .catch((reason) => {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os dados da denúncia!</strong>,
          reason?.response?.data?.detail
        );
      });
    setLoading(false);
  }

  async function getDenunciationTypes(codigoTema) {
    await tipoViolacaoService
      .loadViolationTypes({ codigoTema })
      .then((response) => setTiposDenuncia(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os tipos da denúncia!</strong>,
          reason?.response?.data?.detail
        )
      );
  }

  async function handleVictimSelect(id) {
    setVictimSelect(id);
    setAddDisable(false);
  }

  async function handleClassificacaoAtributoSelect(codigo) {
    const classificacaoAtributo = classificacoesAtributos.find(classificacao => classificacao.codigo === codigo);
    setProcedencia(classificacaoAtributo.justificativaObrigatoria)
  }

  async function handleTipoDenunciaSelect(codigo_tipo) {
    setTipoDenunciaSelect(codigo_tipo);
    getDenunciationSubTypes(codigo_tipo);
    setSubTipoDenunciaDisable(false);
  }

  async function handleSubTipoDenunciaSelect(codigo_subTipo) {
    setSubTipoDenunciaSelect(codigo_subTipo);
    setVitctimDisable(false);
  }

  async function getDenunciationSubTypes(codigoTipo) {
    await subTipoViolacaoService
      .loadViolationSubTypes({ codigoTipo })
      .then((response) => setSubTiposDenuncia(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os sub-tipos da denúncia!</strong>,
          reason?.response?.data?.detail
        )
      );
  }

  async function getThemes() {
    await temaService
      .loadThemes()
      .then((response) => setTemas(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os temas!</strong>,
          reason?.response?.data?.detail
        )
      );
  }

  async function getClassificacoesAtributos() {
    await classificacaoAtributoService
      .loadClassificacoes()
      .then((response) => setClassificacoesAtributos(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Ocorreu um erro ao buscar os temas!</strong>,
          reason?.response?.data?.detail
        )
      );
  }

  async function getClassificacao() {
    setLoading(true);
    await classificacaoService
      .list(codigo)
      .then((response) => {
        setClassificacoes(response.data);
      })
      .catch((reason) =>
        openNotification(
          "error",
          <strong>
            Ocorreu um erro ao buscar as classificações da denúncia!
          </strong>,
          reason?.response?.data?.detail
        )
      );
    setLoading(false);
  }

  async function handleAddClassification() {
    setAddLoading(true);

    let todosOsCodigosAux = {
      denuncia: { codigo: codigo },
      tema: { codigo: temaSelect },
      tipoViolacao: { codigo: tipoDenunciaSelect },
      subTipoViolacao: { codigo: subTipoDenunciaSelect },
      vitima: { codigo: victimSelect },
    };

    await classificacaoService
      .create(todosOsCodigosAux)
      .then(() => {
        openNotification("success", <strong>Classificação adicionada!</strong>);
        setTemaSelect("");
        setTipoDenunciaSelect("");
        setSubTipoDenunciaSelect("");
        setTipoDenunciaDisable(true);
        setSubTipoDenunciaDisable(true);
        getClassificacao();
        form.resetFields();
      })
      .catch((error) => {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao adicionar a classificação!</strong>,
          error.response.data.detail
        );
      });
    setAddLoading(false);
  }

  async function handleRemoveClassification(codigo) {
    setPopLoading(true);

    await classificacaoService
      .delete(codigo)
      .then(() => {
        getClassificacao();
        openNotification(
          "success",
          <strong>Classificação removida com sucesso!</strong>
        );
      })
      .catch((error) => {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao remover a classificação!</strong>,
          error.response.data.detail
        );
      }).finally(() => {
        setOpenDialogModal(false);
      });

    setPopLoading(false);
  }

  async function handleChangeProcede(codigoClassificacaoAtributo, justificativa) {
    await classificacaoService
      .changeProcedencia(codigoClassificacao, codigoClassificacaoAtributo, justificativa)
      .then(() => {
        getClassificacao();
        setClassificacaoAtributoSelect(null)
        openNotification(
          "success",
          <strong>Procedência da classificação atualizada com sucesso!</strong>
        );
      })
      .catch((error) => {
        openNotification(
          "error",
          <strong>
            Ocorreu um erro ao mudar a procedência da classificação!
          </strong>,
          error.response.data.detail
        );
      });
  }

  const uploadValues = useCallback(() => {
    if (onView) {
      handleNext("anexos");
    } else {
      if (classificacoes.length < 1) {
        openNotification(
          "error",
          <strong>Lista de classificação vazia!</strong>,
          "É necessário haver, pelo menos, uma classificação para cara denúncia."
        );
      } else {
        handleNext("anexos");
      }
    }
  }, [classificacoes, handleNext, onView]);

  const columnsClassificacao = [
    {
      title: <strong>procedência</strong>,
      dataIndex: "classificacaoAtributoDescricao",
      width: 0,
      sorter: {
        multiple: 1,
      },

      render: (classificacaoAtributoDescricao) => (
        <Badge
          style={{
            backgroundColor:
              classificacaoAtributoDescricao === "PROCEDENTE"
                ? "var(--green-color)"
                : classificacaoAtributoDescricao === "IMPROCEDENTE"
                  ? "var(--red-color)"
                  : "#333333",
            fontWeight: "bold",
          }}
          count={classificacaoAtributoDescricao}
        />
      ),
    },
    {
      title: <strong>tema</strong>,
      dataIndex: ["tema", "descricao"],
      key: "codigo",
    },
    {
      title: <strong>tipo de violação</strong>,
      dataIndex: ["tipoViolacao", "descricao"],
      key: "codigo",
    },
    {
      title: <strong>subtipo de violação</strong>,
      dataIndex: ["subTipoViolacao", "descricao"],
      key: "codigo",
    },
    {
      title: <strong>vítima associada</strong>,
      dataIndex: ["vitima", "nome"],
      key: "codigo",
    },
  ];

  const colunasAcoes = onView
    ? {}
    : {
      title: <strong>ações</strong>,
      key: "action",
      fixed: "right",
      width: 32,
      render: ({ codigo, classificacaoAtributoDescricao, justificativa }) => (
        <Space size="small">
          {onEdit && (
            <>
              <Button
                onClick={() => handleDeleteClassificacao(codigo)}
                loading={popLoading}
                title={`remover classificação dessa denúncia`}
                type="danger"
                icon={<DeleteOutlined style={{ fontSize: "1.2rem" }} />}
              />

              <Button
                className="action-button"
                title={`definir procedência`}
                onClick={() => {
                  procedeForm.setFieldsValue({
                    procede: classificacaoAtributoSelect,
                    justificativa: null,
                  });
                  setCodigoClassificacao(codigo);
                  setModalProcedenciaVisible(true);
                }}
              >
                <QuestionOutlined style={{ fontSize: "1.2rem" }} />
              </Button>
            </>
          )}
        </Space>
      ),
    };

  async function getFundamentacao() {
    if (mode === "TEMA") {
      await temaService
        .getTheme(idSelect?.codigo)
        .then((response) => setFundamentacao(response.data.fundamentacao))
        .catch((reason) =>
          openNotification(
            "error",
            <strong>Ocorreu um erro ao buscar a fundamentação do tema!</strong>,
            reason?.response?.data?.detail
          )
        );
    } else if (mode === "TIPO") {
      await tipoViolacaoService
        .getViolationType(idSelect?.codigo)
        .then((response) => setFundamentacao(response.data.fundamentacao))
        .catch((reason) =>
          openNotification(
            "error",
            <strong>
              Ocorreu um erro ao buscar a fundamentação do tipo de violação!
            </strong>,
            reason?.response?.data?.detail
          )
        );
    } else if (mode === "SUBTIPO") {
      await subTipoViolacaoService
        .getViolationSubType(idSelect?.codigo)
        .then((response) => setFundamentacao(response.data.fundamentacao))
        .catch((reason) =>
          openNotification(
            "error",
            <strong>
              Ocorreu um erro ao buscar a fundamentação do subtipo de violação!
            </strong>,
            reason?.response?.data?.detail
          )
        );
    }
  }

  function renderFundamentacao() {
    getFundamentacao();
    return (
      <>
        <TextArea rows={4} autoSize maxLength={15} value={fundamentacao} />
      </>
    );
  }

  return (
    <>
      <Form
        form={form}
        onSubmit={uploadValues}
        name="Classification"
        layout="vertical"
      >
        {!onView && (
          <Row gutter={32}>
            <Col xs={{ span: 24 }} lg={{ span: width < 1281 ? 10 : 5 }}>
              <Form.Item
                name="temaSelect"
                label={<span>Tema:</span>}
                rules={[
                  { required: true, message: "Este campo é obrigatório!" },
                ]}
              >
                <Select
                  placeholder="Selecionar..."
                  onChange={handleSelectTema}
                  disabled={onView}
                  optionLabelProp="label"
                >
                  {temas &&
                    temas.map((tema) => {
                      const selectTema = tema.codigo === temaSelect;
                      return (
                        <Option
                          value={tema.codigo}
                          key={tema.codigo}
                          label={tema.descricao}
                          bordered
                          selected={temaSelect === "" ? "" : selectTema}
                        >
                          {tema.fundamentacao && (
                            <EyeOutlined
                              style={{
                                fontSize: "1rem",
                                color: "#ff9945",
                                marginRight: "0.5rem",
                              }}
                              onClick={(event) => {
                                event.stopPropagation();
                                setMode("TEMA");
                                setIdSelect(tema);
                                setModalVisible(true);
                              }}
                            />
                          )}
                          <Tooltip placement="top" title={tema.descricao}>
                            {tema.descricao}
                          </Tooltip>
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: width < 1281 ? 10 : 5 }}>
              <Form.Item
                name="tipoDenunciaSelect"
                label={<span>Tipo de violação:</span>}
                rules={[
                  { required: true, message: "Este campo é obrigatório!" },
                ]}
              >
                <Select
                  placeholder="Selecionar..."
                  onChange={handleTipoDenunciaSelect}
                  disabled={tipoDenunciaDisable}
                  optionLabelProp="label"
                >
                  {tiposDenuncia &&
                    tiposDenuncia.map((tipo) => {
                      const selectTipoDenuncia =
                        tipo.codigo === tipoDenunciaSelect;
                      return (
                        <Option
                          value={tipo.codigo}
                          key={tipo.codigo}
                          selected={selectTipoDenuncia}
                          label={tipo.descricao}
                        >
                          {tipo.fundamentacao && (
                            <EyeOutlined
                              style={{
                                fontSize: "1rem",
                                color: "#ff9945",
                                marginRight: "0.5rem",
                              }}
                              onClick={(event) => {
                                event.stopPropagation();
                                setMode("TIPO");
                                setIdSelect(tipo);
                                setModalVisible(true);
                              }}
                            />
                          )}
                          <Tooltip placement="top" title={tipo.descricao}>
                            {tipo.descricao}
                          </Tooltip>
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: width < 1281 ? 10 : 5 }}>
              <Form.Item
                name="subTipoDenunciaSelect"
                label={<span>Subtipo de Violação:</span>}
                rules={[
                  { required: true, message: "Este campo é obrigatório!" },
                ]}
              >
                <Select
                  placeholder="Selecionar..."
                  onChange={handleSubTipoDenunciaSelect}
                  disabled={subTipoDenunciaDisable}
                  optionLabelProp="label"
                >
                  {subTiposDenuncia &&
                    subTiposDenuncia.map((subtipos) => {
                      const selectSubtipo =
                        subtipos.codigo === subTipoDenunciaSelect;
                      return (
                        <Option
                          value={subtipos.codigo}
                          key={subtipos.codigo}
                          selected={selectSubtipo}
                          label={subtipos.descricao}
                        >
                          {subtipos.fundamentacao && (
                            <EyeOutlined
                              style={{
                                fontSize: "1rem",
                                color: "#ff9945",
                                marginRight: "0.5rem",
                              }}
                              onClick={(event) => {
                                event.stopPropagation();
                                setMode("SUBTIPO");
                                setIdSelect(subtipos);
                                setModalVisible(true);
                              }}
                            />
                          )}
                          <Tooltip placement="top" title={subtipos.descricao}>
                            {subtipos.descricao}
                          </Tooltip>
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: width < 1281 ? 10 : 5 }}>
              <Form.Item
                name="victimSelect"
                label={<span>Vítima associada:</span>}
                rules={[
                  { required: true, message: "Este campo é obrigatório!" },
                ]}
              >
                <Select
                  placeholder="Selecionar..."
                  onChange={handleVictimSelect}
                  disabled={vitctimDisable}
                  options={vitimas?.map(({ codigo, nome }) => ({
                    label: nome,
                    value: codigo,
                  }))}
                ></Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 4 }}>
              <Form.Item label={<span></span>}>
                <Tooltip
                  placement="bottom"
                  title={
                    (subTipoDenunciaDisable ||
                      tipoDenunciaDisable ||
                      vitctimDisable) &&
                    "Preencha os campos obrigatórios!"
                  }
                >
                  <Button
                    type="primary"
                    onClick={handleAddClassification}
                    disabled={AddDisable}
                    loading={addLoading}
                    block
                  >
                    cadastrar
                  </Button>
                </Tooltip>
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row>
          <Col span={24}>
            {classificacoes?.length > 0 ? (
              <Table
                loading={loading}
                dataSource={classificacoes}
                columns={[...columnsClassificacao, colunasAcoes]}
                size="small"
                rowKey={({ codigo }) => codigo}
                expandable={
                  {
                    expandedRowRender: (classificacao) => {
                      if (classificacao?.classificacaoAtributoDescricao !== "PROCEDENTE") {
                        return (
                          <Row>
                            <Col>
                              <strong>Justificativa da classificação</strong>
                              <Row align="middle" style={{ marginTop: 5 }}>
                                <CopyOutlined
                                  onClick={() => navigator.clipboard.writeText(classificacao.justificativa)}
                                  style={{ fontSize: 18 }}
                                />
                                <span style={{ marginLeft: 10 }}>{classificacao.justificativa}</span>
                              </Row>
                            </Col>
                          </Row>
                        );
                      }
                    }
                  }
                }
                pagination={{
                  total: classificacoes.length,
                  showTotal: (total) => (
                    <strong>
                      {total === 1
                        ? `${total} violação no total`
                        : `${total} violações no total`}
                    </strong>
                  ),
                }}
              />
            ) : (
              <Empty
                description={<h2>Sem classificações para essa denúncia</h2>}
              />
            )}
          </Col>
        </Row>
      </Form>
      {showActions && (
        <Row justify="space-between" align="bottom">
          <Tooltip
            placement="top"
            title={"Todas as alterações nessa página já estão salvas!"}
          >
            <Button onClick={() => handleNext("suspect")}>anterior</Button>
          </Tooltip>

          <Tooltip
            placement="top"
            title={"Todas as alterações nessa página já estão salvas!"}
          >
            <Button onClick={() => handleNext("anexos")}>próximo</Button>
          </Tooltip>
        </Row>
      )}
      <Modal
        title={`FUNDAMENTAÇÃO DO ${mode}: ${idSelect?.descricao}`}
        width={width > 1280 ? "45vw" : "60vw"}
        centered
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button
            key="back"
            onClick={() => setModalVisible(false)}
            type="primary"
          >
            confirmar
          </Button>,
        ]}
      >
        {renderFundamentacao()}
      </Modal>

      {modalProcedenciaVisible && (
        <Modal
          title={"Como gostaria de definir essa classificação?"}
          width={width > 1280 ? "45vw" : "60vw"}
          centered
          visible
          onCancel={() => {
            setModalProcedenciaVisible(false);
            procedeForm.resetFields();
            setProcedencia(null);
            setClassificacaoAtributoSelect(null)
          }}
          footer={null}
        >
          <Form
            form={procedeForm}
            layout="vertical"
            onFinish={(values) => {
              setModalProcedenciaVisible(false);
              handleChangeProcede(values.procede, values.justificativa);
              procedeForm.resetFields();
              setProcedencia(null);
            }}
          >
            <Row gutter={32}>
              <Col xs={{ span: 24 }} >
                <Form.Item
                  name="procede"
                  label={<span>Classificação:</span>}
                  rules={[
                    { required: true, message: "Este campo é obrigatório!" },
                  ]}
                >
                  <Select
                    DE
                    placeholder="Selecionar..."
                    onChange={handleClassificacaoAtributoSelect}
                    options={classificacoesAtributos?.map(({ id, codigo, descricao }) => ({
                      label: descricao,
                      value: codigo,
                      key: id,
                    })
                    )}
                  ></Select>
                </Form.Item>
              </Col>

              {procedencia === true && (
                <Col span={24}>
                  <Form.Item
                    name="justificativa"
                    label={<span>Justificativa:</span>}
                    rules={[
                      { required: true, message: "Este campo é obrigatório!" },
                    ]}
                  >
                    <Input.TextArea />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row justify="end">
              <Button type="primary" htmlType="submit">
                atualizar
              </Button>
            </Row>
          </Form>
        </Modal>
      )}

      {openDialogModal && (
        <DialogModal
          title="ATENÇÃO! Deseja realmente excluir essa classificação?"
          Subtitle="Essa ação irá excluir a classificação permanentemente!"
          type="fileReport"
          handle_Function={() => handleRemoveClassification(codigoClassificacao)}
          onClose={() => setOpenDialogModal(false)}
        />
      )}
    </>
  );
};
