import React, { useState, useEffect, useCallback } from "react";

import { Table, Space, Button, Popconfirm, Row } from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import { SearchColumn } from "../../../../components/SearchColumn/SearchColumn";

import { ModalAdd } from "./components/ModalAdd";
import { openNotification } from "../../../../components/Notification";
import { generoService } from "../../../../services/resources/generoService";

export const Genero = () => {
  const [genero, setGenero] = useState([]);

  const [openModal, setOpenModal] = useState(false);

  const [codigo, setCodigo] = useState(null);
  const [codigoDelete, setCodigoDelete] = useState(null);
  const [mode, setMode] = useState("");

  const [disabled, setDisabled] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    getGenero();
    // eslint-disable-next-line
  }, []);

  async function getGenero() {
    try {
      const { data } = await generoService.loadGeneros();
      transformList(data);
    } catch (error) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao buscar os sexos!</strong>,
        error?.response?.data?.detail
      );
    }
  }

  async function handleDelete(codigo) {
    setConfirmLoading(true);
    setDisabled(true);
    try {
      await generoService.delete(codigo);
      openNotification("success", <strong>Sexo excluído!</strong>);
      getGenero();
    } catch (reason) {
      openNotification(
        "error",
        <strong>Não foi possível excluir o sexo!</strong>,
        reason?.response?.data?.detail
      );
    } finally {
      setCodigoDelete(null);
      setConfirmLoading(false);
      setDisabled(false);
    }
  }

  const handleOpenModalAdd = useCallback((genero) => {
    setOpenModal(true);
    setCodigo(genero?.codigo);
  }, []);

  const handleCloseModalAdd = useCallback(() => {
    setOpenModal(false);
  }, []);

  const transformList = useCallback((list) => {
    list.sort(function (a, b) {
      return a.descricao > b.descricao ? 1 : b.descricao > a.descricao ? -1 : 0;
    });
    setGenero(list);
  }, []);

  const renderTable = () => {
    const columns = [
      {
        title: <strong>Descrição</strong>,
        dataIndex: "descricao",
        key: "descricao",
        ...SearchColumn("descricao"),
      },
      {
        title: <strong>ações</strong>,
        key: "action",
        width: 64,
        render: (genero) => (
          <Space size="small">
            <Button
              type="primary"
              className="action-button"
              ghost
              title={`Visualizar sexo ${genero?.descricao}`}
              onClick={() => {
                handleOpenModalAdd(genero);
                setMode("onView");
              }}
            >
              <EyeOutlined style={{ fontSize: "1.2rem" }} />
            </Button>
            <Button
              type="primary"
              className="action-button"
              title={`Editar  ${genero?.descricao}`}
              onClick={() => {
                handleOpenModalAdd(genero);
                setMode("onEdit");
              }}
            >
              <EditOutlined style={{ fontSize: "1.2rem" }} />
            </Button>
            <div>
              <Popconfirm
                title={
                  <span>
                    excluir sexo <strong>{genero?.descricao}</strong>?"
                  </span>
                }
                visible={genero?.codigo === codigoDelete}
                placement="bottomRight"
                okText="confirmar exclusão"
                cancelText="cancelar"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={() => handleDelete(genero?.codigo)}
                onCancel={() => setCodigoDelete(null)}
                okButtonProps={{ loading: confirmLoading }}
                disabled={disabled}
                cancelButtonProps={{ disabled: disabled }}
              >
                <Button
                  type="danger"
                  className="action-button"
                  title={`Excluir sexo ${genero?.descricao}`}
                  onClick={() => {
                    setCodigoDelete(genero?.codigo);
                  }}
                >
                  <DeleteOutlined style={{ fontSize: "1.2rem" }} />
                </Button>
              </Popconfirm>
            </div>
          </Space>
        ),
      },
    ];
    function showTotal(total) {
      return (
        <>
          Total de <strong>{total}</strong> sexos
        </>
      );
    }
    return (
      <Table
        dataSource={genero.sort()}
        columns={columns}
        size="small"
        rowKey={({ codigo }) => codigo}
        pagination={{
          size: "small",
          showSizeChanger: true,
          showTotal: showTotal,
        }}
      />
    );
  };

  return (
    <>
      <div className="container-section">
        <Row id="header">
          <h2>LISTA DE SEXOS</h2>

          <Button
            type="primary"
            onClick={() => {
              handleOpenModalAdd(null);
              setMode("onCreate");
            }}
          >
            novo sexo
          </Button>
        </Row>
        {renderTable()}
      </div>

      {openModal && (
        <ModalAdd
          onClose={handleCloseModalAdd}
          codigo={codigo}
          mode={mode}
          reload={getGenero}
        />
      )}
    </>
  );
};
