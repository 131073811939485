//Essa função remove atributos de um objeto que tenha valores nulo
export function removeEmpty(obj) {
  return Object.entries(obj)
    .filter(([_, value]) => (value && (Array.isArray(value) ? value.length > 0 && value : value)))
    .reduce((acc, [k, value]) => ({ ...acc, [k]: value }), {});
}

export function removeByProp(obj, prop) {
  if (prop) {
    delete obj[prop];
    return obj;
  } else return obj;
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}
