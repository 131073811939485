import React, { useContext } from "react";

import { Link } from "react-router-dom";

import { UserContext } from "../../../../contexts/UserContext";
import moment from "moment";

import { Button, Breadcrumb, Menu } from "antd";

import { HomeOutlined } from "@ant-design/icons";
import { DenunciaTabela } from "../../components/DenunciaTabela";
import { urls, USER_ROLES } from "../../../../utils";

const DenunciasSPSGeral = () => {
  const { user } = useContext(UserContext);

  const menuBreadcrump = (
    <Menu>
      <Menu.Item>
        <Link to={urls.DENUNCIAS_LIST}>status</Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <div id="sectionMain">
      <Breadcrumb id="breadcrumb">
        <Breadcrumb.Item key="home">
          <Link to="">
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="registros">registros</Breadcrumb.Item>
        <Breadcrumb.Item key="denuncias">denúncias</Breadcrumb.Item>
        <Breadcrumb.Item key="denuncias" overlay={menuBreadcrump}>
          <strong>geral</strong>
        </Breadcrumb.Item>
      </Breadcrumb>

      <div>
        <div className="container-section">
          <div id="header-container">
            <div>
              <h2>LISTA DE DENÚNCIAS EM GERAL</h2>
              <span>
                atualizado às {moment().format("HH:mm")} do dia{" "}
                {moment().format("DD/MM/YYYY")}
              </span>
            </div>

            <div id="header-actions">
              {user?.authorities?.includes(USER_ROLES.INCLUIR_DENUNCIA) && (
                <Link to={urls.DENUNCIA_CRIAR} className="button-link">
                  <Button type="primary" style={{ width: "160px" }}>
                    nova denúncia
                  </Button>
                </Link>
              )}
            </div>
          </div>

          <DenunciaTabela showStatus municipioFilter={true}/>
        </div>
      </div>
    </div>
  );
};

export default DenunciasSPSGeral;
