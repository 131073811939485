import api from "../api";

export const grauInstrucaoService = {
    async loadGrausInstrucao() {
      return await api.get("/graus-instrucoes");
    },
    async get(codigo) {
      return await api.get(`/graus-instrucoes/${codigo}`);
    },
    async create(payload) {
      return await api.post(`/graus-instrucoes`, payload);
    },
    async edit(codigo, payload) {
      return await api.put(`/graus-instrucoes/${codigo}`, payload);
    },
    async delete(codigo) {
      return await api.delete(`/graus-instrucoes/${codigo}`);
    },
  }