import React, { useEffect, useState } from "react";

import {
  Col,
  Form,
  Row,
  Spin,
  Divider,
  Button,
  Input,
  Select,
  DatePicker,
} from "antd";

import { PageResult } from "../style";

import { mask, unMask } from "remask";

import { openNotification } from "../../../components/Notification";
import Header from "../../../components/Header/Header";
import { canalService } from "../../../services/resources/canalService";
import { locaisOcorrenciaService } from "../../../services/resources/locaisOcorrenciaService";
import { frequenciaService } from "../../../services/resources/frequenciaService";
import { enderecoService } from "../../../services/resources/enderecoService";
import { denunciaService } from "../../../services/resources/denunciaService";
import { criticidadeService } from "../../../services/resources/criticidadeService";

import FileDonwload from "js-file-download";
import { InfoCircleOutlined } from "@ant-design/icons";
import { generoService } from "../../../services/resources/generoService";
import { identidadeGeneroService } from "../../../services/resources/identidadeGeneroService";
import { sexualidadeService } from "../../../services/resources/sexualidadeService";
import { faixaEtariaService } from "../../../services/resources/faixaEtariaService";
import { etniaService } from "../../../services/resources/etniaService";

const DATA_FORMATO = "DD/MM/YYYY";
const CPF_MASK = "999.999.999-99";
const CEP_MASK = "99999-999";
const PHONE_MASK = "(99) 99999-9999";
const ORIGEM_DENUNCIA = "SITE";

export const CadastroDenunciaPublico = () => {
  const [loading, setLoading] = useState(false);
  const [canalSelect, setCanalSelect] = useState(null);
  const [criticidade, setCriticidade] = useState(null);

  const [generos, setGeneros] = useState([]);
  const [identidadeGeneros, setIdentidadesGeneros] = useState([]);
  const [sexualidades, setSexualidades] = useState([]);
  const [frequenciaOcorrencia, setFrequenciaOcorrencia] = useState([]);
  const [localOcorrencia, setLocalOcorrencia] = useState([]);
  const [faixaEtarias, setFaixaEtarias] = useState([]);
  const [racaCores, setRacaCores] = useState([]);
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [bairros, setBairros] = useState([]);

  const [form] = Form.useForm();

  const getCanais = async () => {
    try {
      const response = await canalService.getAllCanais();

      const { data } = response;

      const newCanalDefault = data?.find((canal) => canal?.sigla === "SEDIH");
      setCanalSelect(newCanalDefault);
    } catch (err) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao buscar os canais!</strong>
      );
    }
  };

  const getLocaisOcorrencia = async () => {
    try {
      const response = await locaisOcorrenciaService.getAllLocaisOcorrencia();
      const { data } = response;

      setLocalOcorrencia(data);
    } catch (err) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao buscar os dados!</strong>
      );
    }
  };

  const getFrequencias = async () => {
    try {
      const response = await frequenciaService.getAllFrequencias();
      const { data } = response;

      setFrequenciaOcorrencia(data);
    } catch (err) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao buscar os dados!</strong>
        // reason?.response?.data?.userMessage
      );
    }
  };

  const getGeneros = async () => {
    try {
      const response = await generoService.getAllGenerosPublico();
      const { data } = response;

      setGeneros(data);
    } catch (err) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao buscar os dados!</strong>
        // reason?.response?.data?.userMessage
      );
    }
  };

  const getIdentidadeGeneros = async () => {
    try {
      const response =
        await identidadeGeneroService.getIdentidadeGenerosPublico();
      const { data } = response;

      setIdentidadesGeneros(data);
    } catch (err) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao buscar os dados!</strong>
        // reason?.response?.data?.userMessage
      );
    }
  };

  const getSexualidades = async () => {
    try {
      const response = await sexualidadeService.getSexualidadesPublico();
      const { data } = response;

      setSexualidades(data);
    } catch (err) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao buscar os dados!</strong>
        // reason?.response?.data?.userMessage
      );
    }
  };

  const getFaixasEstarias = async () => {
    try {
      const response = await faixaEtariaService.getFaixaEtariasPublico();
      const { data } = response;

      setFaixaEtarias(data);
    } catch (err) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao buscar os dados!</strong>
        // reason?.response?.data?.userMessage
      );
    }
  };

  const getEtnias = async () => {
    try {
      const response = await etniaService.getEtniasPublico();
      const { data } = response;

      setRacaCores(data);
    } catch (err) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao buscar os dados!</strong>
        // reason?.response?.data?.userMessage
      );
    }
  };

  const getCriticidades = async () => {
    try {
      const response = await criticidadeService.getPublico();
      const { data } = response;

      const newCriticidade = data.find(
        (criticidade) => criticidade.descricao === "BAIXA"
      );
      setCriticidade(newCriticidade);
    } catch (err) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao buscar os dados!</strong>
        // reason?.response?.data?.userMessage
      );
    }
  };

  const getEstados = async () => {
    try {
      const response = await enderecoService.getAllEstados();
      const { data } = response;

      setEstados(data);
    } catch (err) {
      openNotification(
        "error",
        <strong>Ocorreu um erro ao buscar os dados!</strong>
        // reason?.response?.data?.userMessage
      );
    }
  };

  const getCidades = async (estadoId) => {
    if (estadoId)
      try {
        //setLoading({ ...loading, cidades: true });
        const response = await enderecoService.getAllCidades(estadoId);

        setCidades(response?.data);
      } catch (error) {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao carregar as cidades!</strong>,
          error?.response?.data?.userMessage || ""
        );
      } finally {
        //setLoading({ ...loading, cidades: false });
      }
  };

  const getBairros = async (cidadeId) => {
    if (cidadeId)
      try {
        //setLoading({ ...loading, bairros: true });
        const response = await enderecoService.getAllBairros(cidadeId);
        setBairros(response?.data);
      } catch (error) {
        openNotification(
          "error",
          <strong>Ocorreu um erro ao carregar as bairros!</strong>,
          error.response?.data?.userMessage || ""
        );
      } finally {
        //setLoading({ ...loading, bairros: false });
      }
  };

  const onChangeCep = (value) => {
    form.setFieldsValue({
      endereco: { cep: mask(unMask(value), CEP_MASK) },
    });
  };

  const onChangeTelefone = (value) => {
    form.setFieldsValue({
      telefoneDenunciante: mask(unMask(value), PHONE_MASK),
    });
  };

  const onChange = (name, value, patterns) => {
    form.setFieldsValue({
      [name]: mask(unMask(value), patterns),
    });
  };

  const handleSelectEstado = async (value, mantemCidades) => {
    try {
      if (!mantemCidades) {
        form.setFieldsValue({
          endereco: { bairro: { id: null, cidade: { id: null } } },
        });
        setCidades([]);
        setBairros([]);
      }
      value && (await getCidades(value));
    } catch (err) {
      console.error(err);
    }
  };

  const handleSelectCidade = async (value, mantemBairros) => {
    try {
      if (!mantemBairros) {
        form.setFieldsValue({ endereco: { bairro: { id: null } } });
        setBairros([]);
      }
      value && (await getBairros(value));
    } catch (err) {
      console.error(err);
    }
  };

  const handleSubmit = async (data) => {
    try {
      setLoading(true);

      const cpfVitima = data.cpfVitima || "Não informado";
      const cpfSuspeito = data.cpfSuspeito || "Não informado";

      const vitima = ` Dados da vítima: \n\n Nome: ${
        data?.vitimaNome
      } \n CPF: ${cpfVitima || "Não informado"} \n Endereço: ${
        data.vitima.rua
      }, ${data.vitima.numero} - ${data.vitima.bairro}, ${
        data.vitima.cidade
      } \n Sexo: ${data.generoVitima} \n Identidade de Gênero: ${
        data.identidadeGeneroVitima
      } \n Orientação Sexual: ${data.sexualidadeVitima} \n Faixa Etária: ${
        data.faixaEtariaVitima
      } \n Raça/ cor: ${data.racaCorVitima}\n`;

      const suspeito = ` Dados do suspeito: \n\n Nome: ${data.suspeitoNome} \n CPF: ${cpfSuspeito} \n Endereço: ${data.suspeito.rua}, ${data.suspeito.numero} - ${data.suspeito.bairro}, ${data.suspeito.cidade} \n Sexo: ${data.generoSuspeito} \n Identidade de Gênero: ${data.identidadeGeneroSuspeito} \n Orientação Sexual: ${data.sexualidadeSuspeito} \n Faixa Etária: ${data.faixaEtariaSuspeito} \n Raça/ cor: ${data.racaCorSuspeito} \n`;

      const denuniante = ` Dados do denunciante: \n\n E-mail: ${
        data.emailDenunciante ? data.emailDenunciante : "NÃO INFORMADO"
      } \n Telefone: ${
        data.telefoneDenunciante ? data.telefoneDenunciante : "NÃO INFORMADO"
      }`;
      const descricaoConcat =
        data.descricao +
        "\n\n\n" +
        vitima +
        "\n" +
        suspeito +
        "\n" +
        denuniante;

      const denuncia = {
        canal: { codigo: canalSelect?.codigo },
        criticidade: { codigo: criticidade?.codigo },
        dataOcorrencia: data?.dataOcorrencia,
        descricao: descricaoConcat,
        endereco: {
          bairro: { id: data?.endereco?.bairro?.id },
          cep: data?.endereco?.cep,
          complemento: data?.endereco?.complemento,
          logradouro: data?.endereco?.logradouro,
          numero: data?.endereco?.numero,
        },
        frequencia: data?.frequencia,
        identificadorExterno: data?.identificadorExterno,
        localOcorrencia: data?.localOcorrencia,
        observacao: data?.observacao,
        origemDenuncia: ORIGEM_DENUNCIA,
        sigilo: true,
      };

      const response = await denunciaService.createDenunciaPublica(denuncia);
      const { data: newDenuncia } = response;

      openNotification(
        "success",
        <strong>Denúncia registrada com sucesso!</strong>,
        "Salve os dados e acompanhe o andamento da denúncia."
      );

      const responseDenuncia = await denunciaService.imprimirDenunciaPublica(
        newDenuncia?.codigo
      );

      const { data: fileData } = responseDenuncia;

      FileDonwload(
        fileData,
        "OISOL_" +
          newDenuncia?.canal?.sigla +
          "_" +
          newDenuncia?.identificadorExterno +
          ".pdf"
      );

      cleanDenuncia();
    } catch (error) {
      openNotification(
        "error",
        <strong>Não foi possível cadastrar sua denúncia!</strong>,
        "Por favor, tente novamente."
      );
    } finally {
      setLoading(false);
    }
  };

  const cleanDenuncia = () => {
    form.resetFields();
    setCanalSelect(null);
    setCriticidade(null);
    setBairros([]);
    setCidades([]);
    setEstados([]);
    setGeneros([]);
    setIdentidadesGeneros([]);
    setSexualidades([]);
    setFaixaEtarias([]);
    setRacaCores([]);
  };

  useEffect(() => {
    getCanais();
    getLocaisOcorrencia();
    getFrequencias();
    getEstados();
    getCriticidades();
    getGeneros();
    getIdentidadeGeneros();
    getSexualidades();
    getFaixasEstarias();
    getEtnias();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageResult>
      <Header showVersion={false} />

      <div id="sectionMain">
        <div>
          <div className="container-section">
            <div id="header-container">
              <Col>
                <h2> CADASTRE SUA DENÚNCIA:</h2>
                <p>
                  <InfoCircleOutlined /> Salve o canal e o protocolo para
                  acompanhar o andamento da denúncia
                </p>
                <Divider dashed />
              </Col>
            </div>

            <Col style={{ marginTop: -40 }} align={loading && "center"}>
              {loading ? (
                <Spin size="large" />
              ) : (
                <Form
                  form={form}
                  onFinish={handleSubmit}
                  layout="vertical"
                  name="denuncia"
                >
                  <Row gutter={32}>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                      <Form.Item
                        name={["canal", "codigo"]}
                        label={<span>Canal:</span>}
                        rules={[
                          {
                            message: "Este campo é obrigatório!",
                          },
                        ]}
                      >
                        <Input
                          placeholder={canalSelect?.nome}
                          value={canalSelect?.codigo}
                          disabled
                          allowClear
                          autoComplete="none"
                          autoCorrect="off"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                      <Form.Item
                        name="identificadorExterno"
                        label={<span>Protocolo externo:</span>}
                        rules={[
                          {
                            message: "Este campo é obrigatório!",
                          },
                        ]}
                      >
                        <Input
                          disabled
                          placeholder="O protocolo será gerado automaticamente"
                          allowClear
                          autoComplete="none"
                          autoCorrect="off"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                      <Form.Item
                        name="sigilo"
                        label={<span>Sigilo:</span>}
                        defaultValue={true}
                        disabled={true}
                      >
                        <span>
                          <strong>
                            Denúncia de cunho sigilosa. Proibido a divulgação de
                            seus dados. Este sistema prioriza as diretrizes da
                            Lei Geral de Proteção de dados - LGPD
                          </strong>
                        </span>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span="24">
                      <Form.Item
                        name="descricao"
                        label={
                          <span>Notificação/ manifestação/ ocorrência:</span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Este campo é obrigatório!",
                          },
                        ]}
                      >
                        <Input.TextArea
                          rows={5}
                          showCount
                          placeholder="O que aconteceu?"
                          allowClear
                          autoSize={{ minRows: 3 }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={32}>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                      <Form.Item
                        name="dataOcorrencia"
                        label={
                          <span>
                            Data da notificação/ manifestação/ ocorrência:
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Este campo é obrigatório!",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          format={DATA_FORMATO}
                          placeholder="Formato dd/mm/aaaa"
                          disabledDate={(d) => !d || d.isAfter(new Date())}
                          // disabled={onView}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                      <Form.Item
                        name={["localOcorrencia", "codigo"]}
                        label={
                          <span>
                            Local da notificação/ manifestação/ ocorrência:
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Este campo é obrigatório!",
                          },
                        ]}
                      >
                        <Select
                          autoComplete="none"
                          placeholder="selecionar..."
                          showSearch
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={localOcorrencia?.map(
                            ({ codigo, descricao }) => ({
                              label: descricao,
                              value: codigo,
                            })
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                      <Form.Item
                        name={["frequencia", "codigo"]}
                        label={
                          <span>
                            Frequência da notificação/ manifestação/ ocorrência:
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Este campo é obrigatório!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="selecionar..."
                          autoComplete="none"
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={frequenciaOcorrencia?.map(
                            ({ codigo, descricao }) => ({
                              label: descricao,
                              value: codigo,
                            })
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={32}>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                      <Form.Item
                        label={<span>Estado:</span>}
                        name={["endereco", "bairro", "cidade", "estado", "id"]}
                        rules={[
                          {
                            required: true,
                            message: "Este campo é obrigatório!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="escolher estado"
                          onChange={(estado) =>
                            handleSelectEstado(estado, false)
                          }
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={estados?.map(({ nome, id }) => ({
                            label: nome,
                            value: id,
                          }))}
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                      <Form.Item
                        name={["endereco", "bairro", "cidade", "id"]}
                        label={<span>Cidade:</span>}
                        rules={[
                          {
                            required: true,
                            message: "Este campo é obrigatório!",
                          },
                        ]}
                        validateStatus="validating"
                        //hasFeedback={loading.cidades}
                      >
                        <Select
                          placeholder="escolher município"
                          onChange={(cidade) =>
                            handleSelectCidade(cidade, false)
                          }
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={cidades?.map(({ nome, id }) => ({
                            label: nome,
                            value: id,
                          }))}
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                      <Form.Item
                        name={["endereco", "bairro", "id"]}
                        label={<span>Bairro:</span>}
                        rules={[
                          {
                            required: true,
                            message: "Este campo é obrigatório!",
                          },
                        ]}
                        validateStatus="validating"
                        //hasFeedback={loading.bairros}
                      >
                        <Select
                          placeholder="escolher"
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={bairros?.map(({ nome, id }) => ({
                            label: nome,
                            value: id,
                          }))}
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={32}>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                      <Form.Item
                        name={["endereco", "logradouro"]}
                        label={<span>Logradouro:</span>}
                      >
                        <Input
                          placeholder="Ex: Rua São João"
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                      <Form.Item
                        name={["endereco", "numero"]}
                        label={<span>Número:</span>}
                      >
                        <Input
                          placeholder="Insira o número"
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={32}>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                      <Form.Item
                        name={["endereco", "cep"]}
                        label={<span>CEP:</span>}
                        onChange={({ target }) => onChangeCep(target.value)}
                      >
                        <Input placeholder="60000-600" autoComplete="none" />
                      </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                      <Form.Item
                        name={["endereco", "complemento"]}
                        label={<span>Ponto de referência</span>}
                      >
                        <Input
                          placeholder="insira o ponto de referência"
                          autoComplete="none"
                          autoCorrect="none"
                          autoCapitalize="none"
                          // disabled={onView}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />

                  <Row gutter={32}>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                      <Form.Item name="vitimaNome" label={<span>Vítima:</span>}>
                        <Input
                          placeholder="Insira o nome da vítima"
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                      <Form.Item
                        name="cpfVitima"
                        label={<span>CPF da vítima:</span>}
                        onChange={({ target }) =>
                          onChange("cpfVitima", target.value, CPF_MASK)
                        }
                      >
                        <Input
                          placeholder="Insira o CPF da vítima"
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                      <Form.Item
                        name="faixaEtariaVitima"
                        label={<span>Faixa etária da vítima:</span>}
                      >
                        <Select
                          placeholder="Selecione a faixa etária da vítima"
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={faixaEtarias?.map(
                            ({ descricao, codigo }) => ({
                              label: descricao,
                              value: descricao,
                            })
                          )}
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={32}>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                      <Form.Item
                        name={["vitima", "cidade"]}
                        label={<span>Cidade da vítima:</span>}
                      >
                        <Input
                          placeholder="Insira a cidade da vítima"
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                      <Form.Item
                        name={["vitima", "bairro"]}
                        label={<span>Bairro da vítima:</span>}
                      >
                        <Input
                          placeholder="Insira o bairro da vítima"
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={32}>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                      <Form.Item
                        name={["vitima", "rua"]}
                        label={<span>Logradouro da vítima:</span>}
                      >
                        <Input
                          placeholder="Ex: Rua São João"
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                      <Form.Item
                        name={["vitima", "numero"]}
                        label={<span>Número do endereço da vítima:</span>}
                      >
                        <Input
                          placeholder="Insira o número"
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={32}>
                    <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                      <Form.Item
                        name="generoVitima"
                        label={<span>Sexo da vítima:</span>}
                        rules={[
                          {
                            required: true,
                            message: "Este campo é obrigatório!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Selecione o sexo da vítima"
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={generos?.map(({ descricao, codigo }) => ({
                            label: descricao,
                            value: descricao,
                          }))}
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                      <Form.Item
                        name="identidadeGeneroVitima"
                        label={<span>Identidade de gênero da vítima:</span>}
                      >
                        <Select
                          placeholder="Selecione a identidade de gênero"
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={identidadeGeneros?.map(
                            ({ descricao, codigo }) => ({
                              label: descricao,
                              value: descricao,
                            })
                          )}
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                      <Form.Item
                        name={"sexualidadeVitima"}
                        label={<span>Orientação Sexual da vítima:</span>}
                      >
                        <Select
                          placeholder="Selecione a orientação sexual da vítima"
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={sexualidades?.map(
                            ({ descricao, codigo }) => ({
                              label: descricao,
                              value: descricao,
                            })
                          )}
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                      <Form.Item
                        name={"racaCorVitima"}
                        label={<span>Raça/cor da vítima:</span>}
                      >
                        <Select
                          placeholder="Selecione a raça/cor da vítima"
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={racaCores?.map(({ descricao, codigo }) => ({
                            label: descricao,
                            value: descricao,
                          }))}
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Divider />

                  <Row gutter={32}>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                      <Form.Item
                        name="suspeitoNome"
                        label={<span>Suspeito:</span>}
                      >
                        <Input
                          placeholder="Insira o nome do suspeito"
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                      <Form.Item
                        name="cpfSuspeito"
                        label={<span>CPF do suspeito:</span>}
                        onChange={({ target }) =>
                          onChange("cpfSuspeito", target.value, CPF_MASK)
                        }
                      >
                        <Input
                          placeholder="Insira o CPF do suspeito"
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                      <Form.Item
                        name="faixaEtariaSuspeito"
                        label={<span>Faixa etária do suspeito:</span>}
                      >
                        <Select
                          placeholder="Selecione a faixa etária do suspeito"
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={faixaEtarias?.map(
                            ({ descricao, codigo }) => ({
                              label: descricao,
                              value: descricao,
                            })
                          )}
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={32}>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                      <Form.Item
                        label={<span>Cidade do suspeito:</span>}
                        name={["suspeito", "cidade"]}
                      >
                        <Input
                          placeholder="Insira a cidade do suspeito"
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                      <Form.Item
                        name={["suspeito", "bairro"]}
                        label={<span>Bairro do suspeito:</span>}
                      >
                        <Input
                          placeholder="Insira o bairro do suspeito"
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={32}>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                      <Form.Item
                        name={["suspeito", "rua"]}
                        label={<span>Logradouro do suspeito:</span>}
                      >
                        <Input
                          placeholder="Ex: Rua São João"
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                      <Form.Item
                        name={["suspeito", "numero"]}
                        label={<span>Número do endereço do suspeito:</span>}
                      >
                        <Input
                          placeholder="Insira o número"
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={32}>
                    <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                      <Form.Item
                        name="generoSuspeito"
                        label={<span>Sexo do suspeito:</span>}
                        rules={[
                          {
                            required: true,
                            message: "Este campo é obrigatório!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Selecione o sexo do suspeito"
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={generos?.map(({ descricao, codigo }) => ({
                            label: descricao,
                            value: descricao,
                          }))}
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                      <Form.Item
                        name="identidadeGeneroSuspeito"
                        label={<span>Identidade de gênero do suspeito:</span>}
                      >
                        <Select
                          placeholder="Selecione a identidade de gênero"
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={identidadeGeneros?.map(
                            ({ descricao, codigo }) => ({
                              label: descricao,
                              value: descricao,
                            })
                          )}
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                      <Form.Item
                        name={"sexualidadeSuspeito"}
                        label={<span>Orientação Sexual do suspeito:</span>}
                      >
                        <Select
                          placeholder="Selecione a orientação sexual do suspeito"
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={sexualidades?.map(
                            ({ descricao, codigo }) => ({
                              label: descricao,
                              value: descricao,
                            })
                          )}
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                      <Form.Item
                        name={"racaCorSuspeito"}
                        label={<span>Raça/cor do suspeito:</span>}
                      >
                        <Select
                          placeholder="Selecione a raça/cor do suspeito"
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={racaCores?.map(({ descricao, codigo }) => ({
                            label: descricao,
                            value: descricao,
                          }))}
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Divider />

                  <Row gutter={32}>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                      <Form.Item
                        name={"emailDenunciante"}
                        label={<span>E-mail para contato(opcional):</span>}
                      >
                        <Input
                          placeholder="Insira um e-mail"
                          autoComplete="none"
                          autoCorrect="none"
                          autoCapitalize="none"
                          // disabled={onView}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                      <Form.Item
                        name={"telefoneDenunciante"}
                        label={<span>Telefone para contato(opcional):</span>}
                        onChange={({ target }) =>
                          onChangeTelefone(target.value)
                        }
                      >
                        <Input
                          placeholder="(00) 00000-0000"
                          autoComplete="none"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />

                  <Row>
                    <Col span="24">
                      <Form.Item
                        name="observacao"
                        label={<span>Observações:</span>}
                      >
                        <Input.TextArea
                          rows={5}
                          autoSize={{ minRows: 3 }}
                          showCount
                          placeholder="Algum detalhe que possa facilitar o atendimento da solicitação"
                          // disabled={onView}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row justify="end" style={{ marginTop: "1rem" }}>
                    <Button
                      htmlType="submit"
                      type="primary"
                      loading={loading.submit}
                    >
                      Registrar denúncia
                    </Button>
                  </Row>
                </Form>
              )}
            </Col>
          </div>
        </div>
      </div>
    </PageResult>
  );
};
