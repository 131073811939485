import React, { useState, useEffect } from "react";

import { Table, Space, Button, Popconfirm } from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import { SearchColumn } from "../../../../components/SearchColumn/SearchColumn";

import { openNotification } from "../../../../components/Notification";
import { temaService } from "../../../../services";
import { useHistory } from "react-router-dom";
import { urls } from "../../../../utils";

const ListTemas = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [temas, setTemas] = useState([]);

  const renderTable = () => {
    const columns = [
      {
        title: <strong>descrição</strong>,
        dataIndex: "descricao",
        key: "descricao",
        ...SearchColumn("descricao"),
      },
      {
        title: <strong>ações</strong>,
        key: "action",
        width: 64,
        render: ({ codigo, descricao }) => (
          <Space size="small">
            <Button
              type="primary"
              className="action-button"
              title={`Editar tema ${descricao}`}
              onClick={() =>
                history.push(
                  urls.CLASSIFICACOES_TEMA_DETAILS.replace(":codigo", codigo)
                )
              }
            >
              <EditOutlined style={{ fontSize: "1.2rem" }} />
            </Button>
            <div>
              <Popconfirm
                title={
                  <span>
                    excluir tema: <strong>{descricao}</strong>?"
                  </span>
                }
                onConfirm={() => handleDeleteTema(codigo)}
                placement="bottomRight"
                okText="confirmar exclusão"
                cancelText="cancelar"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              >
                <Button type="danger" className="action-button">
                  <DeleteOutlined style={{ fontSize: "1.2rem" }} />
                </Button>
              </Popconfirm>
            </div>
          </Space>
        ),
      },
    ];

    function showTotal(total) {
      return (
        <>
          Total de <strong>{total}</strong> temas
        </>
      );
    }
    return (
      <Table
        loading={loading}
        dataSource={temas}
        columns={columns}
        size="small"
        rowKey={({ codigo }) => codigo}
        pagination={{
          size: "small",
          showSizeChanger: true,
          showTotal: showTotal,
        }}
      />
    );
  };

  const getThemes = async () => {
    setLoading(true);
    await temaService
      .loadThemes()
      .then((response) => setTemas(response.data))
      .catch((reason) =>
        openNotification(
          "error",
          <strong>Erro!</strong>,
          "Ocorreu um erro ao buscar os Temas."
        )
      );
    setLoading(false);
  };

  const handleDeleteTema = async (codigo) => {
    try {
      await temaService.deleteTheme(codigo);
      openNotification("success", <strong>Tema excluído com sucesso!</strong>);
      getThemes();
    } catch (reason) {
      openNotification(
        "error",
        <strong>Não foi possível excluir o tema!</strong>,
        "Erro: " + reason.response.data.userMessage
      );
    } finally {
    }
  };

  useEffect(() => {
    getThemes();
  }, []);

  return (
    <>
      <div className="container-section">
        <div id="header-container">
          <h2>LISTA DE TEMAS</h2>
          <div id="header-actions">
            <Button
              type="primary"
              onClick={() =>
                history.push(
                  urls.CLASSIFICACOES_TEMA_CRIAR.replace("/:codigo", "")
                )
              }
            >
              novo tema
            </Button>
          </div>
        </div>
        {renderTable()}
      </div>
    </>
  );
};

export default ListTemas;
