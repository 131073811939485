import {
  EditOutlined,
  EyeOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Empty,
  Modal,
  Pagination,
  Row,
  Space,
  Table,
  Tooltip,
} from "antd";
import React, {  useCallback, useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { openNotification } from "../../../../../components/Notification";
import { useWidth } from "../../../../../hooks";
import { denunciaService } from "../../../../../services/resources/denunciaService";
import { OPCOES_TAMANHO_PAGINA, USER_ROLES } from "../../../../../utils";
import { dicionario } from "../../../../../utils/dicionario";
import { capitalize } from "../../../../../utils/js/string";
import { PessoaPaginada } from "../../../../Pessoas/components/PessoaPaginada";
import { PessoaModal } from "../PessoaModal";
import { UserContext } from "../../../../../contexts/UserContext";

export function Envolvidos({
  handleNext,
  enable = false,
  tipo,
  showActions = true,
  next,
  prev,
}) {
  const { user } = useContext(UserContext);

  const { codigo: codigoDenuncia } = useParams();
  const { width } = useWidth();

  const [searchModal, setSearchModal] = useState(false);

  const [novo, setNovo] = useState(null);

  const [envolvidos, setEnvolvidos] = useState([]);

  const [person, setPerson] = useState(null);
  const [modeModal, setModeModal] = useState(null);
  const [loading, setloading] = useState(false);

  const [pessoalModalOpen, setPessoalModalOpen] = useState(false);

  const [paginacao, setPaginacao] = useState({
    currentPage: 0,
    totalElements: 0,
    size: OPCOES_TAMANHO_PAGINA[0],
  });

  useEffect(() => {
    codigoDenuncia && getEnvolvidos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codigoDenuncia]);

  useEffect(() => {
    async function inserir() {
      try {
        setloading(true);

        await denunciaService[tipo].inserir(codigoDenuncia, novo?.codigo);

        openNotification(
          "success",
          <strong>{capitalize(tipo)} inserida(o) com sucesso!</strong>,
          `${capitalize(
            tipo
          )} inserida(o) com sucesso e agora faz parte dessa denúncia`
        );
        getEnvolvidos();
      } catch (error) {
        openNotification(
          "error",
          <strong>
            Ocorreu um erro ao inserir a(o) {dicionario[tipo]} nessa denúncia!
          </strong>,
          error.response.data.userMessage
        );
      } finally {
        setloading(false);
      }
    }

    novo && inserir();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [novo]);

  async function getEnvolvidos() {
    try {
      setloading(true);
      const response = await denunciaService[tipo].listar(codigoDenuncia);
      setEnvolvidos(response?.data);
      setPaginacao({
        currentPage: response?.data?.currentPage,
        totalElements: response?.data?.totalElements,
        size: response?.data?.size,
      });

    } catch (error) {
      openNotification(
        "error",
        <strong>
          Ocorreu um erro ao buscar {dicionario[tipo]}(s) da denúncia!
        </strong>,
        error?.response?.data?.userMessage
      );
    } finally {
      setloading(false);
    }
  }

  async function remover(codigo) {
    try {
      setloading(true);
      await denunciaService[tipo].remover(codigoDenuncia, codigo);
      openNotification(
        "success",
        <strong>
          {capitalize(dicionario[tipo])} removida(o) com sucesso!
        </strong>,
        `Essa pessoa deixou de ser ${dicionario[tipo]} dessa denúncia! Contudo ainda está registrada no sistema.`
      );

      getEnvolvidos();
    } catch (error) {
      openNotification(
        "error",
        <strong>
          Ocorreu um erro ao remover a(a) {dicionario[tipo]} a essa denúncia!
        </strong>,
        error.response.data.userMessage
      );
    } finally {
      setloading(false);
    }
  }

  const columns = [
    {
      title: <strong>nome</strong>,
      dataIndex: "nome",
      key: "codigo",
    },
    {
      title: <strong>cpf</strong>,
      dataIndex: "cpf",
      key: "cpf",
    },
    {
      title: <strong>endereço</strong>,
      dataIndex: ["endereco", "logradouro"],
      key: "endereco.logradouro",
    },
    {
      title: <strong>bairro</strong>,
      dataIndex: ["endereco", "bairro", "nome"],
      key: "endereco.bairro.nome",
    },
    {
      title: <strong>cidade</strong>,
      dataIndex: ["endereco", "bairro", "cidade", "nome"],
      key: "endereco.bairro.cidade.nome",
    },
  ];

  const colunasAcoes = {
    title: <strong>ações</strong>,
    key: "action",
    fixed: "right",
    width: 32,
    render: ({ nome, codigo }) => (
      <Space size="small">
        <Button
          type="primary"
          className="action-button"
          ghost
          title={`visualizar ${nome}`}
          onClick={() => handleOpenCrudModal("view", codigo)}
        >
          <EyeOutlined style={{ fontSize: "1.2rem" }} />
        </Button>

        {enable && (
          <>
            <Button
              type="primary"
              className="action-button"
              title={`editar ${nome}`}
              onClick={() => handleOpenCrudModal("edit", codigo)}
            >
              <EditOutlined style={{ fontSize: "1.2rem" }} />
            </Button>
            <Button
              onClick={() => remover(codigo)}
              title={`remover ${nome} dessa denúncia`}
              type="danger"
            >
              remover da denúncia
            </Button>
          </>
        )}
      </Space>
    ),
  };

  const colunaAcoesPessoas = ({ nome, codigo }) => (
    <Space size="small">
      {envolvidos.find((pessoa) => pessoa.codigo === codigo) ? (
        <Button
          key="remove"
          type="danger"
          title={`remover ${nome} dessa denúncia`}
          onClick={() => {
            remover(codigo);
            setSearchModal(false);
          }}
        >
          remover da denúncia
        </Button>
      ) : (
        <Button
          onClick={() => {
            setNovo({ nome, codigo });
            setSearchModal(false);
          }}
          title={`inserir ${nome} nessa denúncia`}
          type="primary"
        >
          inserir na denúncia
        </Button>
      )}

      <Button
        type="primary"
        ghost
        className="action-button"
        title={`visualizar ${nome}`}
        onClick={() => handleOpenCrudModal("view", codigo)}
      >
        <EyeOutlined style={{ fontSize: "1.2rem" }} />
      </Button>

      <Button
        type="primary"
        className="action-button"
        title={`editar ${nome}`}
        onClick={() => handleOpenCrudModal("edit", codigo)}
      >
        <EditOutlined style={{ fontSize: "1.2rem" }} />
      </Button>
    </Space>
  );

  const handleOpenCrudModal = useCallback((mode, person) => {
    setPerson(person);
    setModeModal(mode);
    setPessoalModalOpen(true);
  }, []);

  const handleCloseCrudModal = useCallback(() => {
    setPessoalModalOpen(false);
  }, []);

  return (
    <Col span={24}>
      <Row justify="end" align="middle">
        {enable && (
          <Col>
            {(user?.authorities?.includes(USER_ROLES.CONSULTAR_PESSOAS) || user?.authorities?.includes(USER_ROLES.GERENCIAR_PESSOA)) && (
              <Button
                type="primary"
                icon={<SearchOutlined />}
                onClick={() => {
                  setSearchModal(true);
                }}>
                {width >= 720 && "buscar pessoa"}
              </Button>
            )}  
          </Col>
        )}
      </Row>

      <Row gutter={[16, 16]} justify="center">
        {envolvidos.length > 0 ? (
          <Col span={24}>
            <Row gutter={[16, 16]} justify="end">
              <Col span={24}>
                <Table
                  loading={loading}
                  dataSource={envolvidos}
                  columns={envolvidos?.length > 0 && [...columns, colunasAcoes]}
                  size="small"
                  rowKey={({ codigo }) => codigo}
                  pagination={false}
                />
              </Col>

              <Row justify="end">
                <Col>
                  <Pagination
                    size="small"
                    showSizeChanger={true}
                    simple={width < 768}
                    pageSizeOptions={OPCOES_TAMANHO_PAGINA}
                    total={envolvidos.length}
                    showTotal={(total) => (
                      <strong>
                        {total === 1
                          ? `${total} pessoa no total`
                          : `${total} pessoas no total`}
                      </strong>
                    )}
                    current={paginacao.currentPage + 1}
                  />
                </Col>
              </Row>
            </Row>
          </Col>
        ) : (
          <Row justify="center">
            <Empty
              description={<h2>Sem {dicionario[tipo]}s nessa denúncia</h2>}
            />
          </Row>
        )}
      </Row>
      <Col>
        {showActions && (
          <Row
            style={{ marginTop: 20 }}
            gutter={[16, 16]}
            justify="space-between"
            align="bottom"
          >
            <Tooltip
              placement="top"
              title={"Todas as alterações nessa página já estão salvas!"}
            >
              <Button onClick={() => handleNext(prev)}>anterior</Button>
            </Tooltip>

            <Tooltip
              placement="top"
              title={"Todas as alterações nessa página já estão salvas!"}
            >
              <Button onClick={() => handleNext(next)}>próximo</Button>
            </Tooltip>
          </Row>
        )}
      </Col>
      <PessoaModal
        onClose={handleCloseCrudModal}
        mode={modeModal}
        visible={pessoalModalOpen}
        codigo={person}
        codigoDenuncia={codigoDenuncia}
        afterCreate={(novo) => setNovo(novo)}
        refresh={() => {
          getEnvolvidos();
        }}
      />

      <Modal
        title={
          <Row justify="space-between" align="middle">
            <strong>Escolha uma pessoa para ser {dicionario[tipo]}</strong>
            <Button
              type="primary"
              onClick={() => {
                handleOpenCrudModal("create");
                setSearchModal(false);
              }}
            >
              cadastrar {dicionario[tipo]}
            </Button>
          </Row>
        }
        width="90vw"
        bodyStyle={{
          height: "70vh",
          overflowY: "auto",
          display: "flex",
          gap: "2rem",
          flexDirection: "column",
        }}
        closable={false}
        centered
        visible={searchModal}
        destroyOnClose
        onCancel={() => setSearchModal(false)}
        footer={
          <Button
            key="back"
            type="danger"
            onClick={() => setSearchModal(false)}
          >
            cancelar
          </Button>
        }
      >
        <PessoaPaginada
          tableActions={colunaAcoesPessoas}
          cardActions={({ nome, codigo }) => [
            envolvidos.find((pessoa) => pessoa.codigo === codigo) ? (
              <Button
                key="remove"
                type="danger"
                icon={<MinusCircleOutlined style={{ fontSize: "1.2rem" }} />}
                title={`remover ${nome} dessa denúncia`}
                onClick={() => {
                  remover(codigo);
                  setSearchModal(false);
                }}
              />
            ) : (
              <Button
                key="add"
                type="primary"
                icon={<PlusCircleOutlined style={{ fontSize: "1.2rem" }} />}
                title={`inserir ${nome} nessa denúncia`}
                onClick={() => {
                  setNovo({ nome, codigo });
                  setSearchModal(false);
                }}
              />
            ),
            <Button
              key="view"
              type="primary"
              ghost
              title={`visualizar ${nome}`}
              onClick={() => handleOpenCrudModal("view", codigo)}
              icon={<EyeOutlined style={{ fontSize: "1.2rem" }} />}
            />,
            <Button
              key="edit"
              type="primary"
              title={`editar ${nome}`}
              onClick={() => handleOpenCrudModal("edit", codigo)}
              icon={<EditOutlined style={{ fontSize: "1.2rem" }} />}
            />,
          ]}
        />
      </Modal>
    </Col>
  );
}
