import api from "../api";

export const permissaoService = {
  async list() {
    return await api.get(`/permissoes`);
  },

  async get(codigo) {
    return await api.get(`/grupos/${codigo}`);
  },

  async permissions(codigo) {
    return await api.get(`/grupos/${codigo}/permissoes`);
  },
};
